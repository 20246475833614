// DensitySizes: Very Low = 0-14 // Low = 15-39 // Medium = 40-69 // Big = 70-100
// Last Id: 981
export const mobSpawnsList = [
  { // Aetherex
    Name: 'Aetherex',
    id: '1',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Low', DensitySize: 10, Mix: 'Fugabarba, Gibnib', Notes: '', Id: 882, Pos: '73724.1,81897.6 74576.64,81888 74547.9,81734.4 74413.79,81456 74413.79,81244.8 74222.21,81206.4 74250.95,80956.8 74155.16,80774.4 74001.9,80755.2 73724.1,80764.8' },
    ]
  },
  { // Allophyl
    Name: 'Allophyl',
    id: '2',
    notes: '',
    spawns: [
      { Min: 'Old Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 1, Pos: '34866.89,51772.8 35441.63,51763.2 35441.63,51494.4 34780.68,51523.2' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 2, Pos: '35384.16,51110.2 35365,50899.2 35326.68,50812.8 35154.26,50908.8 35096.79,50899.2 34953.1,50611.2 34914.79,50707.2 34809.42,50812.8 34742.36,51110.2' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'mid', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 3, Pos: '34742.36,51504 35384.16,51484.8 35403.31,51120 34704.05,51120.2 34646.57,51235.2 34541.2,51312 34512.47,51388.8 34569.94,51436.8' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Big', DensitySize: 81, Mix: 'Estophyl', Notes: '', Id: 4, Pos: '70948.59,78872.63 71343.72,78860.63 71583.2,78668.63 71918.46,78524.63 72205.83,78488.63 72313.6,78332.63 72553.07,78296.63 72481.23,77912.63 72337.54,77984.63 72289.65,77792.63 72421.36,77468.63 72289.65,77396.63 72325.57,77300.63 71990.3,76988.63 71834.65,77012.63 71810.7,77288.63 71583.2,77576.63 71415.57,77624.63 71068.33,77912.63 71188.06,78260.63 71116.22,78320.63 71020.43,78272.63 70984.51,78344.63 70948.59,78488.63 70840.83,78728.63' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Big', DensitySize: 80, Mix: 'Estophyl', Notes: '', Id: 5, Pos: '65572.31,85062.07 66290.73,84990.07 66649.94,84966.07 67009.16,84618.07 67069.02,84234.07 67057.05,83898.07 66374.55,83910.07 65572.31,83946.07' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Big', DensitySize: 75, Mix: 'Estophyl', Notes: '', Id: 6, Pos: '66823.63,86108.63 67122.98,86396.63 67362.45,86168.63 68128.77,85892.63 67589.95,85772.63 67278.63,85244.63 66955.34,85400.63 66955.34,85640.63 66823.63,85892.63' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'small', Density: 'Big', DensitySize: 73, Mix: 'Estophyl', Notes: '', Id: 7, Pos: '71176.09,81632.63 71283.85,81560.63 71762.8,81572.63 72565.05,81392.63 72684.78,81104.63 72062.15,80852.63 71918.46,80996.63 71678.99,80996.63 71571.22,80888.63 71559.25,79808.63 71188.06,79640.63 71200.04,79424.63 70757.01,79340.63 70685.17,79892.63 70816.88,80384.63 70828.85,80996.63 70565.43,81380.63 70733.06,81488.63 70864.77,81392.63 71044.38,81428.63' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Big', DensitySize: 70, Mix: 'Estophyl', Notes: '', Id: 8, Pos: '70164.31,81212.63 70391.81,81404.63 70619.31,81104.63 70655.23,80720.63 70715.1,80312.63 70571.42,80036.63 70619.31,79784.63 70583.39,79484.63 70511.55,79364.63 70487.6,79148.63 70535.49,78932.63 70284.05,78788.63 70415.76,78752.63 70379.84,78488.63 70487.6,78224.63 70679.18,78152.63 70451.68,78080.63 70248.12,78080.63 70152.33,77852.63 69709.31,78200.63 69541.67,78248.63 69625.49,78380.63 69541.67,78584.63 70128.39,78560.63 70212.2,78704.63 69912.86,78764.63 69649.44,78764.63 69481.8,78968.63 69769.17,78908.63 70248.12,79028.63 70260.1,79340.63 69948.78,79520.63 69649.44,79484.63 69864.96,79664.63 70224.18,79532.63 70367.86,79460.63 70415.76,79676.63 70415.76,79940.63 70391.81,80108.63 70487.6,80348.63 70559.44,80504.63 70415.76,80900.63 70296.02,81080.63' },
      { Min: 'Young', Max: 'Alpha', Maturity: 'mid', Density: 'Medium', DensitySize: 55, Mix: 'Tripudion', Notes: '', Id: 9, Pos: '75935.26,68838.4 76299.26,68838.4 76328,68963.2 76577.05,69097.6 76950.63,69174.4 77113.47,68944 77084.74,68704 77075.16,68444.8 77027.26,68320 76787.79,68435.2 76730.31,68368 76653.68,68396.8 76442.94,68281.6 76356.73,68214.4 76117.26,68262.4 76040.63,68550.4 75925.68,68694.4' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 51, Mix: 'Tripudion', Notes: '', Id: 10, Pos: '74919.88,69020.8 75121.04,69001.6 75274.31,68876.8 75370.1,68742.4 75389.25,68636.8 75609.57,68627.2 75705.36,68550.4 75868.2,68348.8 75935.26,68060.8 75954.41,67868.8 75916.1,67686.4 75753.26,67744 75628.73,67840 75571.25,68032 75389.25,68118.4 75370.1,68214.4 75427.57,68291.2 75456.31,68396.8 75408.41,68492.8 75140.2,68704 74881.57,68915.2 74881.57,68992' },
      { Min: 'Young', Max: 'Alpha', Maturity: 'mid', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 717, Pos: '55876.51,68698.62 56336.3,68756.22 56527.88,68410.62 56336.3,68122.62 55895.67,68257.02' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Medium', DensitySize: 40, Mix: 'Estophyl', Notes: '', Id: 11, Pos: '78365.13,72787.2 78451.34,72825.6 78623.76,72768 78997.34,72739.2 78978.19,72672 79370.92,72547.2 79591.24,72412.8 79572.08,72326.4 79485.87,72278.4 79399.66,72288 79294.29,72374.4 79188.92,72384 79102.71,72451.2 79121.87,72518.4 79035.66,72576 79016.5,72441.6 78844.08,72374.4 78777.03,72278.4 78623.76,72288 78537.55,72259.2 78508.81,72326.4 78518.39,72384 78441.76,72432 78451.34,72691.2' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Low', DensitySize: 26.1, Mix: 'Diripi, Gokibusagi, Tripudion', Notes: '', Id: 12, Pos: '52640.73,64554.67 52853.59,64458.67 53087.75,64277.33 53162.25,64512 53364.47,64597.33 53417.69,64341.33 53438.97,64149.33 53215.47,64010.67 53268.68,63914.67 53492.19,64021.33 53715.7,64021.33 54056.29,64192 53928.57,64437.33 53960.5,64618.67 54130.79,64586.67 54375.59,64074.67 54652.32,63957.33 54535.24,63658.67 54811.97,63541.33 54780.04,63360 54258.51,63434.67 54173.37,63712 53726.35,63530.67 53854.07,63424 53875.35,63178.67 54226.58,63018.67 54247.87,62858.67 53992.43,62901.33 53683.77,63061.33 53438.97,62997.33 53247.4,63114.67 52757.8,63146.67 52555.58,63637.33 52906.81,63850.67 52811.02,64192 52640.73,64320' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Low', DensitySize: 26, Mix: 'Estophyl', Notes: '', Id: 13, Pos: '69912.86,67032 70140.36,67188 70200.23,67344 70260.1,67500 70379.84,67548 70367.86,67692 70391.81,67776 70487.6,67668 70523.52,67416 70499.57,67164 70679.18,67080 70763,66924 70667.21,66792 70451.68,66756 70487.6,66624 70583.39,66612 70571.42,66432 70367.86,66252 70212.2,66168 70212.2,66072 70116.41,66036 70032.6,65880 70104.44,65676 69996.68,65676 69852.99,65832 69829.04,65916 69673.38,65928 69697.33,66084 69613.52,66144 69529.7,66156 69374.04,66432 69577.59,66588 69577.59,66768 69769.17,66864' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Low', DensitySize: 25.9, Mix: 'Estophyl', Notes: '', Id: 14, Pos: '69931.24,68010.78 70237.77,68193.18 70304.82,68135.58 70228.19,68058.78 70276.08,67914.78 70333.56,67962.78 70314.4,67847.58 70247.35,67645.98 70151.56,67482.78 70036.61,67482.78 69902.5,67338.78 69816.29,67127.58 69691.77,67146.78 69615.13,66925.98 69433.13,66762.78 69413.97,66628.38 69318.18,66628.38 69260.71,66733.98 69203.24,66877.98 68973.34,66945.18 69107.45,67021.98 69327.76,67377.18 69442.71,67492.38 69663.03,67482.78 69979.14,67732.38 69883.35,67885.98' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Low', DensitySize: 25.8, Mix: '', Notes: '', Id: 15, Pos: '58465.95,80084.63 58645.56,80084.63 58657.53,79784.63 58693.46,79604.63 58956.88,79544.63 59100.56,79424.63 59208.33,79388.63 59124.51,79232.63 59076.62,79064.63 58789.25,79088.63 58789.25,79220.63 58789.25,79352.63 58585.69,79508.63 58382.14,79652.63 58382.14,79820.63' },
      { Min: 'Young', Max: 'Alpha', Maturity: 'mid', Density: 'Low', DensitySize: 25.7, Mix: '', Notes: '', Id: 16, Pos: '78923.11,98036.63 79270.35,98060.63 79557.71,98192.63 79988.77,98156.63 80264.17,97964.63 80060.61,97280.63 79928.9,96932.63 79461.93,96800.63 79138.63,96704.63 79030.87,96812.63 79174.56,96968.63 79138.63,97208.63 79378.11,97184.63 79725.35,97280.63 79689.43,97676.63 79390.08,97652.63 78982.98,97688.63 78875.21,97736.63' },
      { Min: 'Young', Max: 'Alpha', Maturity: 'mid', Density: 'Low', DensitySize: 25.6, Mix: '', Notes: '', Id: 17, Pos: '76995.33,97148.63 77234.81,97052.63 77390.47,96944.63 77294.68,96740.63 77162.96,96428.63 76971.39,96260.63 76648.09,96140.63 76504.41,96284.63 76468.49,96440.63 76612.17,96404.63 76719.94,96560.63 76719.94,96716.63 76887.57,96836.63 76839.67,97028.63' },
      { Min: 'Alpha', Max: 'Prowler', Maturity: 'big', Density: 'Low', DensitySize: 25.5, Mix: '', Notes: '', Id: 18, Pos: '55263.46,69332.22 55378.4,69543.42 55608.3,69562.62 55493.35,69293.82' },
      { Min: 'Provider', Max: 'Dominant', Maturity: 'mid', Density: 'Low', DensitySize: 25, Mix: '', Notes: '', Id: 21, Pos: '36991.51,60227.22 37259.72,60342.42 37451.3,60208.02 37393.83,59996.82 37240.57,60016.02 37068.14,59996.82' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Low', DensitySize: 25, Mix: '', Notes: '', Id: 22, Pos: '36617.93,59914.62 36943.62,60202.62 37039.41,59933.82 37000.3,59799.42 36675.41,59799.42' },
      { Min: 'Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Low', DensitySize: 25, Mix: '', Notes: '', Id: 19, Pos: '36708.93,59677.32 36938.83,59638.92 37015.46,59446.92 37245.36,59408.52 37159.15,59279.52 36977.14,59331.72 36632.3,59523.72' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Low', DensitySize: 25, Mix: '', Notes: '', Id: 20, Pos: '37460.88,60058.62 37537.52,60001.02 37690.78,60077.82 37920.68,59962.62 37920.68,59751.42 37748.25,59828.22 37652.46,59578.62 37537.52,59617.02 37575.83,59828.22 37403.41,59962.62' },
      { Min: 'Old Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 834, Pos: '26790,40890 27606,40874 28406,40618 28982,40042 28790,39354 28438,38986 27558,38746 26758,38698 25574,39146' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'mid', Density: 'Big', DensitySize: 70, Mix: '', Notes: '', Id: 926, Pos: '40310.96,28902.4 40856.96,28854.4 40809.06,28268.8 40780.33,28028.8 40550.43,28028.8 40215.17,28086.4 40081.06,28240 40081.06,28595.2 40186.43,28806.4' },
    ]
  },
  { // Ambulimax 
    Name: 'Ambulimax',
    id: '3',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 23, Pos: '69780.33,72693.22 70058.12,73029.22 70240.12,73010.02 70463.65,72924 70584.96,72712.42 70709.49,72501.22 70268.86,72290.02 69971.91,72453.22' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 88, Mix: '', Notes: '', Id: 24, Pos: '69318.18,73290.78 69212.82,73405.98 69231.97,73530.78 69375.66,73617.18 69548.08,73636.38 69845.03,73617.18 69940.82,73482.78 70074.93,73319.58 70323.98,73290.78 70419.77,73204.38 70419.77,73089.18 70276.08,73060.38 70046.19,73079.58 69768.4,72753.18 69615.13,72820.38 69452.29,72820.38 69337.34,72829.98 69260.71,72925.98 69251.13,73098.78 69327.76,73261.98' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Big', DensitySize: 85, Mix: '', Notes: '', Id: 25, Pos: '80120.48,76272 80407.85,76356 80922.72,76344 81162.2,76488 81210.09,76380 81341.8,76368 81485.49,76308 81784.83,76236 81784.83,75840 81605.23,75612 80012.72,75612' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 26, Pos: '79144.62,90852 80162.39,90876 80665.29,91848 80916.74,91752 80916.74,91656 81060.42,91524 81347.79,91452 81347.79,91260 81311.87,91056 81587.27,90900 81814.77,91008 81814.77,90204 79156.59,90216' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Big', DensitySize: 75, Mix: 'Feffoid', Notes: '', Id: 27, Pos: '86981.44,93620.63 87628.02,93812.63 87735.79,94004.63 87927.37,94112.63 88262.63,94136.63 88238.68,93656.63 88550,93584.63 88873.29,93632.63 88801.45,93884.63 88921.19,93968.63 88945.14,94112.63 89124.74,94088.63 89364.22,93968.63 89340.27,93584.63 89460.01,92996.63 88957.11,93032.63 88993.03,92480.63 89005,92300.63 88885.27,92144.63 88669.74,92108.63 88334.47,92216.63 87687.89,92264.63 87532.23,92168.63 87400.52,92060.63 87364.6,92204.63 87029.34,92348.63 86873.68,92516.63 86813.81,92672.63 86598.28,92756.63 86873.68,93344.63' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 760, Pos: '28872,59885 29784,59885 30312,59517 30296,59213 29688,58429 29064,58733 28744,59133 28712,59725' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 760, Pos: '29254,36842 30102,36842 29862,35546 29174,35194 28566,35082 27830,34762 27782,35562 29110,36042' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 836, Pos: '31046,40858 32454,40874 32470,40714 32214,40634 32198,40410 32326,40298 32326,39754 32070,39530 32070,39274 32342,39114 32358,38810 31062,38618 31062,38858 31590,39194 31446,39498 31094,39498 30918,39962' },
      { Min: 'Old Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 838, Pos: '31078,36106 31606,36282 32310,36362 32710,36122 32678,35098 32006,35162 31910,35306 31734,35114 31094,35130' },
    ]
  },
  { // Araneatrox
    Name: 'Araneatrox',
    id: '4',
    notes: '',
    spawns: [
      { Min: 'Prowler', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 85, Mix: '', Notes: '', Id: 28, Pos: '78454.11,70110.82 78530.75,70216.42 78684.01,70216.42 78894.75,70110.82 79019.27,70082.02 79201.28,70101.22 79459.91,70091.62 79057.59,69784.42 78779.8,69669.22 78703.17,69602.02 78559.48,69602.02 78415.8,69832.42' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 29, Pos: '83203.72,75840 83299.51,76272 83598.86,76344 83634.78,76656 83946.09,76764 83958.07,76596 84041.88,76260 84197.54,76200 84221.49,76080 84185.57,75984 84185.57,75816' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Low', DensitySize: 35, Mix: '', Notes: '', Id: 30, Pos: '64476.78,81324 64728.23,81204 64931.78,81156 64764.15,80892 64369.01,80784 64201.38,80952 64153.49,81108 64297.17,81276' },
      { Min: 'Dominant', Max: 'Stalker', Maturity: 'big', Density: 'Low', DensitySize: 25, Mix: 'Atrax, Gokibusagi', Notes: '', Id: 31, Pos: '89036.14,85756.8 89333.08,85814.4 89553.4,85612.8 89515.09,85382.4 89438.45,85104 89630.03,84864 89697.09,84681.6 89668.35,84547.2 89495.93,84422.4 89400.14,84230.4 89400.14,84028.8 89093.61,83808 88959.5,83808 88796.66,83932.8 88748.77,84057.6 88576.34,84076.8 88317.71,84163.2 87723.81,84028.8 87675.92,84124.8 87848.34,84499.2 88049.5,84470.4 88164.45,84460.8 88241.08,84566.4 88375.18,84480 88557.19,84518.4 88700.87,84921.6 88662.55,85065.6 88710.45,85305.6 88815.82,85401.6 88834.98,85641.6 88959.5,85804.8' },
      { Min: 'Dominant', Max: 'Stalker', Maturity: 'big', Density: 'Very Low', DensitySize: 10, Mix: '', Notes: '', Id: 3, Pos: '88940.35,86736 89103.19,86918.4 89304.35,87033.6 89390.56,86995.2 89390.56,86832 89256.45,86745.6 89208.56,86640 89218.14,86419.2 89333.08,86198.4 89313.93,86006.4 89189.4,86016 88978.66,86016 88777.5,86073.6 88748.77,86390.4 88921.19,86620.8 88930.77,86726.4' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 5, Mix: 'Bristlehog, Itumatrox', Notes: '', Id: 784, Pos: '16800,39780 17296,40084 18400,40500 19200,40452 19440,40644 20016,40724 20112,38628 20048,38516 19456,38500 19424,38564 19168,38564 19232,38452 19216,38132 19584,37924 19472,37300 18976,36644 18720,36740 18704,36948 18880,37156 18720,37236 17760,36804 17680,36868 17600,37060 17392,37092 17360,37428 17216,37348 17200,36980 16992,36820 16688,36532 16480,37028 16544,37364 16976,38100 17264,38244 17376,38548 17600,38740 17712,38980 17920,39252 17840,39412 17472,39332 17168,39508 16992,39540' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 819, Pos: '37254,32218 38774,32682 39686,32570 40006,32314 39002.81,30760.62 38726,30682 38278,30682 38054,30330 37702,30218 37446,30218 37190,30458' },
    ]
  },
  { // Argonaut
    Name: 'Argonaut',
    id: '5',
    notes: '',
    spawns: [
      { Min: 'Leader', Max: 'Raider Elite', Maturity: 'big', Density: 'Big', DensitySize: 100, Mix: '', Notes: '', Id: 32, Pos: '81697,89457 81688,89644 81470,89781 81279,89786 81287,89669 81361,89546' },
      { Min: 'Scavenger Elite', Max: '', Maturity: 'big', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 33, Pos: '80831.72,69254.4 81071.2,69244.8 81205.3,69196.8 81262.78,68841.6 81157.41,68851.2 81128.67,68736 80841.3,68822.4 80783.83,68976' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 34, Pos: '77522.18,77016 77594.02,77028 77677.84,77100 77737.71,77064 77665.86,76836 77570.07,76740 77510.2,76836' },
      { Min: 'Young', Max: 'Scout', Maturity: 'small', Density: 'Big', DensitySize: 88, Mix: '', Notes: '', Id: 35, Pos: '75965.59,77916 76049.41,78048 76181.12,78180 76384.67,78348 76564.28,78564 76971.39,78780 77186.91,78744 77582.05,78420 77641.92,78204 78252.58,78432 78719.55,78624 78875.21,78768 79006.92,78876 79126.66,79116 79282.32,79020 79509.82,78972 79665.48,78804 79713.37,78588 79916.93,78528 79833.11,78168 79617.58,77940 79701.4,77772 79593.64,77568 79449.95,77508 79186.53,77520 78982.98,77328 78887.18,77124 78815.34,76908 78731.53,76872 78551.92,77220 78492.05,77424 78061,77484 77929.29,77664 77737.71,77592 77665.86,77256 77605.99,77160 77510.2,77100 77019.28,77112 76959.41,77184 76779.81,77172 76540.33,77172 76300.86,77292 76217.04,77364 76061.38,77280 75869.8,77280 75833.88,77856' },
      { Min: 'Gatherer', Max: 'Hunter', Maturity: 'mid', Density: 'Big', DensitySize: 85, Mix: '', Notes: '', Id: 36, Pos: '85246.04,75654.4 85715.42,75712 85686.68,76096 85887.84,76240 85897.42,76844.8 86194.37,76758.4 86625.42,76556.8 86807.42,76355.2 86797.84,76124.8 86970.26,76057.6 87046.9,76192 87152.27,76163.2 87382.16,76355.2 87468.37,76355.2 87391.74,75808 87506.69,75635.2 87497.11,75126.4 87267.21,75059.2 86797.84,75049.6 86539.21,75059.2 86443.42,74924.8 86453,74713.6 86203.94,74675.2 85935.73,74809.6 85859.1,74780.8 85849.52,74713.6 85753.73,74665.6 85514.26,74665.6 85274.78,75280' },
      { Min: 'Scout', Max: 'Warrior', Maturity: 'mid', Density: 'Big', DensitySize: 80.5, Mix: '', Notes: '', Id: 37, Pos: '85472.75,79104 85779.28,79152 85951.7,79056 86488.12,78950.4 86440.23,78796.8 86306.12,78777.6 86248.65,78633.6 86239.07,78566.4 86085.8,78547.2 86095.38,78364.8 85942.12,78240 85798.43,78316.8 85664.33,78268.8 85558.96,78278.4 85444.01,78124.8 85194.96,78240 85194.96,78355.2 85233.27,78470.4 85185.38,78556.8 85089.59,78662.4 85156.64,78748.8 85319.48,78700.8 85444.01,78835.2' },
      { Min: 'Adult', Max: 'Scavanger', Maturity: 'small', Density: 'Big', DensitySize: 80.3, Mix: '', Notes: '', Id: 38, Pos: '84227.48,93332.63 84347.22,93368.63 84646.56,93572.63 84969.85,93368.63 85125.51,93392.63 85233.27,93392.63 85293.14,93308.63 85197.35,93152.63 84981.82,92840.63 84909.98,92720.63 84850.11,92396.63 84778.27,92288.63 84155.64,92420.63 83796.42,92612.63 83892.21,92852.63 83964.06,92960.63 83976.03,93104.63 84191.56,93212.63' },
      { Min: 'Guardian', Max: 'Leader', Maturity: 'big', Density: 'Big', DensitySize: 80.2, Mix: '', Notes: '', Id: 39, Pos: '84323.27,94676.63 84550.77,94676.63 84778.27,94712.63 84933.93,94472.63 85041.69,94352.63 84933.93,94184.63 84957.88,94076.63 85077.61,93668.63 85005.77,93368.63 84658.53,93608.63 84490.9,93488.63 84287.35,93368.63 84143.66,93368.63 84023.92,93404.63 83976.03,93524.63 84095.77,93692.63 84227.48,93812.63 84239.45,93944.63 84323.27,93968.63 84431.03,94040.63 84454.98,94124.63 84371.16,94220.63 84395.11,94436.63 84263.4,94544.63 84215.5,94652.63' },
      { Min: 'Scout', Max: 'Hunter', Maturity: 'mid', Density: 'Big', DensitySize: 80.1, Mix: '', Notes: '', Id: 40, Pos: '83038.72,95286.21 83115.36,95094.21 83297.36,94959.81 83661.36,94739.01 83527.25,94614.21 83651.78,94489.41 83939.15,94374.21 84255.26,94220.61 84370.2,94134.21 84408.52,94067.01 84312.73,93990.21 84197.78,93990.21 84207.36,93855.81 84073.26,93779.01 83939.15,93798.21 83843.36,93663.81 83718.83,93673.41 83383.57,93779.01 82904.62,93836.61 82799.25,94086.21 82789.67,94220.61 82856.72,94355.01 82808.83,94508.61 82904.62,94643.01 82837.56,94691.01 82856.72,94854.21 82904.62,95123.01 82952.51,95219.01' },
      { Min: 'Scavanger', Max: 'Warrior', Maturity: 'big', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 41, Pos: '54163.79,61031.4 54393.68,61069.8 54182.94,60762.6 54125.47,60513 54259.58,60378.8 54163.79,60321 54106.31,60359.4 53953.05,60858.6' },
      { Min: 'Young', Max: 'Gatherer', Maturity: 'small', Density: 'Medium', DensitySize: 51.2, Mix: '', Notes: '', Id: 42, Pos: '78324.42,76620 78492.05,76572 78635.74,76560 78587.84,75996 78719.55,75888 78623.76,75816 78671.66,75564 78587.84,75552 78408.24,75780 78132.84,75792 78001.13,75876 77989.15,76056 78216.65,76212 78336.39,76320 78156.79,76572' },
      { Min: 'Young', Max: 'Scout', Maturity: 'small', Density: 'Medium', DensitySize: 51.1, Mix: 'Molisk', Notes: '', Id: 43, Pos: '77603.6,75523.2 77938.86,75513.6 78053.81,75350.4 78283.71,75292.8 78436.97,75120 78389.08,75024 78312.45,74956.8 78197.5,75004.8 77958.02,74908.8 77488.65,74918.4 77488.65,75014.4 77622.76,75081.6 77555.7,75254.4 77661.07,75379.2' },
      { Min: 'Guardian', Max: 'Leader', Maturity: 'mid', Density: 'Medium', DensitySize: 51, Mix: '', Notes: '', Id: 44, Pos: '66763.76,69564 67087.05,69660 67518.11,69024 66991.26,68940' },
      { Min: 'Young', Max: 'Gatherer', Maturity: 'small', Density: 'Medium', DensitySize: 50.9, Mix: 'Molisk', Notes: '', Id: 45, Pos: '78049.02,75492 78252.58,75444 78456.13,75504 78647.71,75396 78791.4,75528 79018.9,75636 79306.27,75624 79533.77,75636 79581.66,75408 79557.71,75120 79294.29,75300 79246.4,75216 79054.82,75060 78923.11,75120 78803.37,75264 78731.53,75204 78516,75180 78312.45,75360 78072.97,75396' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Medium', DensitySize: 50.8, Mix: '', Notes: '', Id: 46, Pos: '73870.18,75636 74097.68,75684 74265.32,75636 74205.45,75504 74301.24,75336 74061.76,75168 74013.87,75312 73846.24,75516' },
      { Min: 'Adult', Max: 'Gatherer', Maturity: 'small', Density: 'Medium', DensitySize: 50.7, Mix: '', Notes: '', Id: 47, Pos: '69601.54,79424.63 69948.78,79484.63 70224.18,79304.63 70212.2,79040.63 69948.78,78956.63 69733.25,78944.63 69589.57,78980.63' },
      { Min: 'Leader', Max: '', Maturity: 'big', Density: 'Medium', DensitySize: 50.6, Mix: '', Notes: '', Id: 48, Pos: '67482.19,78044.63 67637.85,78044.63 67745.61,78164.63 67889.3,78104.63 68104.82,78488.63 68332.32,78284.63 68308.38,77948.63 68296.4,77792.63 68032.98,77648.63 67877.32,77660.63 67685.74,77768.63 67494.16,77792.63' },
      { Min: 'Adult', Max: 'Gatherer', Maturity: 'small', Density: 'Medium', DensitySize: 50.5, Mix: '', Notes: '', Id: 49, Pos: '65805.86,79244.63 65949.55,79340.63 66141.13,79304.63 66260.87,79280.63 66248.89,79184.63 66224.94,79016.63 66105.21,78956.63 65769.94,79050.63' },
      { Min: 'Adult', Max: 'Gatherer', Maturity: 'small', Density: 'Medium', DensitySize: 50.4, Mix: '', Notes: '', Id: 50, Pos: '66458.1,80564.63 66835.61,80792.63 67111,80732.63 67278.63,80480.63 67314.56,80204.63 67158.9,80048.63 66955.34,79964.63 66703.89,80000.63 66452.31,80276.63' },
      { Min: 'Scout', Max: 'Gatherer', Maturity: 'small', Density: 'Medium', DensitySize: 50.3, Mix: 'Foul, Molisk', Notes: '', Id: 51, Pos: '65548.43,83732.63 66165.08,83744.63 66392.58,83708.63 66943.37,83624.63 67266.66,83216.63 67254.69,82940.63 67446.27,82688.63 67266.66,82232.63 67290.61,81944.63 65548.43,81968.63' },
      { Min: 'Grunt', Max: 'Outrunner', Maturity: 'small', Density: 'Medium', DensitySize: 50.2, Mix: '', Notes: '', Id: 52, Pos: '63045.91,76412.63 63345.26,76424.63 63333.28,76520.63 63369.21,76580.63 63608.68,76556.63 63764.34,76676.63 63716.44,76808.63 63812.23,76916.63 63931.97,76952.63 64087.63,76724.63 64063.68,76568.63 64123.55,76436.63 64231.32,76424.63 64291.18,76148.63 64123.55,76148.63 63979.87,76088.63 63920,75968.63 63967.89,75884.63 63896.05,75848.63 63512.89,75932.63 63237.49,75908.63 63261.44,75860.63 63273.42,75764.63 63213.55,75680.63 63153.68,75716.63 63081.84,75896.63 63045.91,76100.63 63069.86,76256.63' },
      { Min: 'Grunt', Max: 'Outrunner', Maturity: 'small', Density: 'Medium', DensitySize: 50.1, Mix: '', Notes: '', Id: 53, Pos: '62471.17,75116.63 62674.73,75152.63 62554.99,75020.63 62614.86,74708.63 62339.46,74708.63 62195.78,74792.63 62135.91,74902.63 62339.46,75092.63' },
      { Min: 'Grunt', Max: 'Outrunner', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 54, Pos: '62638.81,76040.63 62830.39,76028.63 62986.05,76040.63 63009.99,75752.63 62950.12,75584.63 62854.33,75572.63 62698.68,75740.63 62638.81,75956.63' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Medium', DensitySize: 40, Mix: '', Notes: '', Id: 55, Pos: '77007.31,77040 77486.26,77028 77462.31,76872 77390.47,76848 77414.41,76716 77186.91,76740 77019.28,76896' },
      { Min: 'Adult', Max: 'Hunter', Maturity: 'mid', Density: 'Low', DensitySize: 30.1, Mix: 'Hiryuu', Notes: '', Id: 56, Pos: '73714.52,84240 74325.19,84240 74580.66,83556 73918.08,83556 73846.24,83448 73714.52,83448' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'mid', Density: 'Low', DensitySize: 30, Mix: '', Notes: '', Id: 57, Pos: '64392.96,81888 65542.44,81888 65552.44,81156 65386.78,81130 65279.02,81204 65267.04,81456 65195.2,81684 64955.73,81696 64668.36,81660 64560.59,81708' },
      { Min: 'Young', Max: 'Scout', Maturity: 'small', Density: 'Big', DensitySize: 99, Mix: '', Notes: '', Id: 758, Pos: '26008,63725 27784,60573 27688,60541 27384,60653 27160,60781 27112,60717 26952,60589 26840,60557 26264,60717 25944,61021 25960,62205 25800,62333 25832,62477 25784,62589 25880,62909 25464,63005 25384,63213 25528,63293 25576,63421 25640,63485 25640,63565 25704,63613 25768,63613' },
      { Min: 'Young', Max: 'Scout', Maturity: 'small', Density: 'Very Low', DensitySize: 10, Mix: '', Notes: '', Id: 759, Pos: '26028,63725 26472,63725 27400,63357 27576,63437 27816,63341 27896,63277 28408,63325 28728,63245 27000,61997' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: 'Fungoid, Furor', Notes: '', Id: 762, Pos: '29064,58733 29720,58429 29768,58253 30120,58061 30392,58605 31096,58253 30664,57437 29064,57437' },
      { Min: 'Scout', Max: 'Guardian', Maturity: 'small', Density: 'Very Low', DensitySize: 5, Mix: 'Molisk', Notes: 'North side', Id: 770, Pos: '25944,54925 26632,54925 27272,54173 27720,53053 27000,52269 26664,52061 25480,52045 24808,52093 24584,52461 24616,52589 24904,52797 25336,53261' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Low', DensitySize: 20, Mix: '', Notes: '', Id: 844, Pos: '33862,40266 34134,40074 34518,39610 34790,38986 35734,38970 36022,38170 35590,37898 35126,38122 34838,38394 34262,38490 33654,39530 33766,40106' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Low', DensitySize: 20, Mix: 'Exarosaur, Hiryuu, Molisk', Notes: '', Id: 910, Pos: '28597.51,65408.77 28637.84,65135.93 28970.58,65055.09 29010.92,64873.19 29162.16,64792.35 29353.74,64943.93 29605.82,65004.56 29857.9,64974.24 29938.57,65216.77 29888.15,65398.66 29656.24,65398.66 29646.16,65297.61 29373.91,65317.82 29192.41,65439.09' },
      { Min: 'Adult', Max: 'Leader', Maturity: 'big', Density: 'Medium', DensitySize: 40, Mix: 'Molisk', Notes: '', Id: 915, Pos: '30575.97,65327.53 30662.18,65135.53 30623.86,64377.13 30892.07,64271.53 31026.18,63993.13 31275.23,63820.33 31409.34,63887.53 31179.44,64108.33 31198.6,64386.73 31217.76,64626.73 31074.08,64914.73 31054.92,65433.13 30777.13,65471.53 30614.28,65385.13' },
      { Min: 'Young', Max: 'Gatherer', Maturity: 'small', Density: 'Low', DensitySize: 25, Mix: 'Molisk', Notes: '', Id: 918, Pos: '32280.56,63494.4 32577.51,63408 32452.98,63206.4 32510.46,62812.8 32443.4,62486.4 32261.4,62419.2 32108.14,62592 32232.67,62841.6 32223.09,63216 32280.56,63379.2' },
      { Min: 'Young', Max: 'Gatherer', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 919, Pos: '25927.81,43539.48 26225.83,43571.48 26491.91,43507.48 26438.69,43230.14 26023.6,43240.81 25810.74,43038.14 25810.74,42739.48 25959.74,42558.14 26151.32,42536.81 26321.62,42654.14 26247.11,42291.48 26545.13,42174.14 26491.91,41971.48 26364.19,41811.48 26044.89,41694.14 25746.88,41704.81 25161.49,42003.48 25065.7,42163.48 25182.78,42494.14 25502.08,43176.81 25810.74,43358.14' },
      { Min: 'Young', Max: 'Gatherer', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 920, Pos: '26636.32,40994.22 26722.53,41243.82 26856.64,41455.02 27000.32,41589.42 27239.8,41589.42 27622.96,41464.62 27651.69,41003.82' },
    ]
  },
  { // Armax Bull
    Name: 'Armax Bull',
    id: '6',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Big', DensitySize: 90, Mix: 'Armax Cow', Notes: '', Id: 58, Pos: '72648.86,84308.63 72984.13,84428.63 73223.6,84392.63 73307.42,84500.63 73487.02,84524.63 73570.84,84392.63 73702.55,84392.63 73702.55,82556.63 73510.97,82436.63 73187.68,82496.63 73091.89,82928.63 73091.89,83024.63 73175.71,83120.63 73043.99,83444.63 72984.13,83708.63 72912.28,83720.63 72912.28,84008.63 72804.52,84152.63 72636.89,84080.63 72600.97,84152.63' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Big', DensitySize: 85, Mix: 'Armax Cow', Notes: '', Id: 59, Pos: '72133.99,83972.63 72241.75,83936.63 72277.68,83828.63 72409.39,83804.63 72529.12,83936.63 72744.65,83804.63 72744.65,83480.63 72864.39,83432.63 72936.23,83348.63 72708.73,82832.63 72421.36,82964.63 72086.1,82868.63 72062.15,83012.63 71918.46,83012.63 72038.2,83264.63 72086.1,83372.63 72002.28,83432.63 71882.54,83420.63 71798.73,83600.63 72050.17,83876.63' },
      { Min: 'Old', Max: 'Alpha', Maturity: 'mid', Density: 'Big', DensitySize: 80, Mix: 'Armax Cow, Atrox', Notes: '', Id: 60, Pos: '84551.15,81950.22 84838.52,82072.42 85010.94,82370.02 86313.68,82331.62 86399.89,82504.42 89436.44,82475.62 89493.91,82370.02 89302.33,82283.62 89101.17,82379.62 88880.85,82331.62 88976.64,82110.82 89005.38,81950.22' },
      { Min: 'Mature', Max: 'Old Alpha', Maturity: 'mid', Density: 'Big', DensitySize: 75, Mix: 'Armax Cow, Atrox', Notes: '', Id: 61, Pos: '84688.47,81864 88005.2,81888 87921.38,81792 87933.35,81432 88089.01,81324 87969.27,81204 87897.43,81024 86819.79,81024 86688.08,80892 86520.45,80952 86412.69,81036 86017.55,81024 85849.92,80904 85251.23,80892 84604.65,81132 84401.1,81096 84250.57,81156' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50.7, Mix: 'Armax Cow, Atrax, Prancer', Notes: '', Id: 62, Pos: '58286.35,81596.63 58801.22,81596.63 59136.48,81680.63 59555.57,81656.63 59459.78,81428.63 59363.99,81068.63 59184.38,80948.63 59124.51,80696.63 59160.43,80288.63 58837.14,80156.63 58633.59,80132.63 58477.93,80144.63 58465.95,80312.63 58322.27,80492.63 58286.35,80648.63 58418.06,80840.63 58274.37,80972.63 58190.56,80852.63 57927.14,80864.63 57867.27,81044.63 57807.4,81140.63 58130.69,81260.63 58166.61,81416.63' },
      { Min: 'Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Medium', DensitySize: 50.6, Mix: 'Armax Cow', Notes: '', Id: 63, Pos: '72783.77,71267.2 72774.19,71392 72869.98,71459.2 73004.08,71459.2 73004.08,71545.6 73109.45,71593.6 73205.24,71440 73281.87,71392 73387.24,71459.2 73559.66,71411.2 73597.98,71296 73492.61,71180.8 73483.03,70940.8 73435.14,70432 73521.35,70086.4 73358.51,69913.6 73281.87,69760 73061.56,69750.4 72908.29,69606.4 72563.45,69577.6 72477.24,69932.8 72525.13,70364.8 72764.61,70470.4 72755.03,70595.2 72640.08,70787.2 72716.71,70940.8 72898.71,71219.2' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Medium', DensitySize: 50.5, Mix: 'Armax Cow', Notes: '', Id: 64, Pos: '69579.17,70062.82 69627.06,70158.82 69818.64,70226.02 69933.59,70139.62 70106.01,70168.42 70173.07,70216.42 70259.28,70360.42 70335.91,70389.22 70422.12,70254.82 70690.33,70235.62 70680.75,70101.22 70757.39,70053.22 70738.23,69928.42 70498.75,69765.22 70335.91,69746.02 70316.75,69669.22 70470.02,69410.02 70489.17,69256.42 70230.54,69150.82 70096.44,69285.22 70000.65,69467.62 69914.43,69448.42 69780.33,69486.82 69713.28,69726.82 69636.64,69803.62' },
      { Min: 'Old', Max: 'Guardian', Maturity: 'small', Density: 'Medium', DensitySize: 50.4, Mix: 'Armax Cow', Notes: '', Id: 65, Pos: '70699.91,70293.22 70814.86,70350.82 70939.39,70475.62 71121.39,70485.22 71274.65,70494.82 71389.6,70446.82 71418.34,70322.02 71274.65,70178.02 71006.44,69995.62 70766.97,69957.22 70786.12,70101.22 70709.49,70139.62' },
      { Min: 'Old', Max: 'Alpa', Maturity: 'mid', Density: 'Medium', DensitySize: 50.3, Mix: 'Armax Cow, Feffoid, Molisk', Notes: '', Id: 66, Pos: '87336.28,79377.18 87709.86,78906.78 87824.81,78599.58 88361.24,78417.18 88964.71,78138.78 88897.66,77937.18 88734.82,77908.38 88782.71,77533.98 88897.66,77274.78 88782.71,77063.58 88610.29,77207.58 88504.92,77101.98 88437.87,77130.78 88399.55,77341.98 88140.92,77495.58 88006.81,77476.38 87930.18,77409.18 87939.76,77553.18 87843.97,77716.38 87882.29,77879.58 87729.02,77956.38 87633.23,78033.18 87623.65,78157.98 87671.55,78273.18 87719.44,78330.78 87451.23,78532.38 87135.12,78714.78 86943.54,78676.38 86771.12,78666.78 86751.96,78762.78 86799.86,78897.18 87058.49,79098.78 87087.23,79300.38 87202.18,79396.38' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Medium', DensitySize: 50.2, Mix: 'Armax Cow, Exarosaur', Notes: '', Id: 67, Pos: '85424.85,86313.6 85990.01,86361.6 86459.38,86448 86842.54,86572.8 86928.76,86563.2 87388.55,86851.2 87666.34,86774.4 87972.87,86400 87896.23,86227.2 87972.87,86083.2 87848.34,85651.2 87714.23,85449.6 87484.34,85353.6 87359.81,85488 87206.55,85555.2 87062.86,85564.8 86976.65,85507.2 86947.91,85372.8 86756.33,85286.4 86536.02,85267.2 86152.86,85344 85731.38,85430.4 85434.43,85507.2 85348.22,85804.8 85338.64,86092.8' },
      { Min: 'Old', Max: 'Alpha', Maturity: 'mid', Density: 'Medium', DensitySize: 50.1, Mix: 'Atrax, Atrox, Caudatergus, Hiryuu, Molisk', Notes: '', Id: 68, Pos: '81899.78,78192 82206.31,78192 82369.15,78086.4 82369.15,75350.4 82254.2,75158.4 82263.78,74928 81918.94,74918.4' },
      { Min: 'Old', Max: 'Stalker', Maturity: 'big', Density: 'Medium', DensitySize: 50, Mix: 'Atrox', Notes: '', Id: 69, Pos: '84380.74,78748.8 84553.16,78748.8 84658.53,78499.2 84640.57,78252 84460.97,78168 84430.37,78009.6 84284.95,77875.2 84045.48,77884.8 83892.21,77760 83873.05,77904 84102.95,78105.6 83941.79,78432 84189.16,78528' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: '', Notes: '', Id: 70, Pos: '75455.89,73038.82 75609.15,73067.62 75791.15,72971.62 75771.99,72875.62 75676.2,72894.82 75570.83,72846.82 75417.57,72914.02' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 10, Mix: 'Armax Cow', Notes: '', Id: 766, Pos: '27992,57245 28552,57197 29656,57261 30488,57213 30536,56781 30744,56557 30552,56125 30184,56029 29720,55981 29416,56093 28952,56269 28344,56205 27816,56125 27656,56605 27704,56957' },
    ]
  },
  { // Armax Cow
    Name: 'Armax Cow',
    id: '7',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Big', DensitySize: 90, Mix: 'Armax Bull', Notes: '', Id: 71, Pos: '72648.86,84308.63 72984.13,84428.63 73223.6,84392.63 73307.42,84500.63 73487.02,84524.63 73570.84,84392.63 73702.55,84392.63 73702.55,82556.63 73510.97,82436.63 73187.68,82496.63 73091.89,82928.63 73091.89,83024.63 73175.71,83120.63 73043.99,83444.63 72984.13,83708.63 72912.28,83720.63 72912.28,84008.63 72804.52,84152.63 72636.89,84080.63 72600.97,84152.63' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Big', DensitySize: 85, Mix: 'Armax Bull', Notes: '', Id: 72, Pos: '72133.99,83972.63 72241.75,83936.63 72277.68,83828.63 72409.39,83804.63 72529.12,83936.63 72744.65,83804.63 72744.65,83480.63 72864.39,83432.63 72936.23,83348.63 72708.73,82832.63 72421.36,82964.63 72086.1,82868.63 72062.15,83012.63 71918.46,83012.63 72038.2,83264.63 72086.1,83372.63 72002.28,83432.63 71882.54,83420.63 71798.73,83600.63 72050.17,83876.63' },
      { Min: 'Old', Max: 'Alpha', Maturity: 'mid', Density: 'Big', DensitySize: 80, Mix: 'Armax Bull, Atrox', Notes: '', Id: 73, Pos: '84551.15,81950.22 84838.52,82072.42 85010.94,82370.02 86313.68,82331.62 86399.89,82504.42 89436.44,82475.62 89493.91,82370.02 89302.33,82283.62 89101.17,82379.62 88880.85,82331.62 88976.64,82110.82 89005.38,81950.22' },
      { Min: 'Mature', Max: 'Old Alpha', Maturity: 'mid', Density: 'Big', DensitySize: 75, Mix: 'Armax Bull, Atrox', Notes: '', Id: 74, Pos: '84688.47,81864 88005.2,81888 87921.38,81792 87933.35,81432 88089.01,81324 87969.27,81204 87897.43,81024 86819.79,81024 86688.08,80892 86520.45,80952 86412.69,81036 86017.55,81024 85849.92,80904 85251.23,80892 84604.65,81132 84401.1,81096 84250.57,81156' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50.5, Mix: 'Armax Bull, Atrax, Prancer', Notes: '', Id: 75, Pos: '58286.35,81596.63 58801.22,81596.63 59136.48,81680.63 59555.57,81656.63 59459.78,81428.63 59363.99,81068.63 59184.38,80948.63 59124.51,80696.63 59160.43,80288.63 58837.14,80156.63 58633.59,80132.63 58477.93,80144.63 58465.95,80312.63 58322.27,80492.63 58286.35,80648.63 58418.06,80840.63 58274.37,80972.63 58190.56,80852.63 57927.14,80864.63 57867.27,81044.63 57807.4,81140.63 58130.69,81260.63 58166.61,81416.63' },
      { Min: 'Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Medium', DensitySize: 50.4, Mix: 'Armax Bull', Notes: '', Id: 76, Pos: '72783.77,71267.2 72774.19,71392 72869.98,71459.2 73004.08,71459.2 73004.08,71545.6 73109.45,71593.6 73205.24,71440 73281.87,71392 73387.24,71459.2 73559.66,71411.2 73597.98,71296 73492.61,71180.8 73483.03,70940.8 73435.14,70432 73521.35,70086.4 73358.51,69913.6 73281.87,69760 73061.56,69750.4 72908.29,69606.4 72563.45,69577.6 72477.24,69932.8 72525.13,70364.8 72764.61,70470.4 72755.03,70595.2 72640.08,70787.2 72716.71,70940.8 72898.71,71219.2' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 50.3, Mix: 'Armax Bull', Notes: '', Id: 77, Pos: '69579.17,70062.82 69627.06,70158.82 69818.64,70226.02 69933.59,70139.62 70106.01,70168.42 70173.07,70216.42 70259.28,70360.42 70335.91,70389.22 70422.12,70254.82 70690.33,70235.62 70680.75,70101.22 70757.39,70053.22 70738.23,69928.42 70498.75,69765.22 70335.91,69746.02 70316.75,69669.22 70470.02,69410.02 70489.17,69256.42 70230.54,69150.82 70096.44,69285.22 70000.65,69467.62 69914.43,69448.42 69780.33,69486.82 69713.28,69726.82 69636.64,69803.62' },
      { Min: 'Mature', Max: 'Alpha', Maturity: 'mid', Density: 'Medium', DensitySize: 50.2, Mix: 'Armax Bull', Notes: '', Id: 78, Pos: '70699.91,70293.22 70814.86,70350.82 70939.39,70475.62 71121.39,70485.22 71274.65,70494.82 71389.6,70446.82 71418.34,70322.02 71274.65,70178.02 71006.44,69995.62 70766.97,69957.22 70786.12,70101.22 70709.49,70139.62' },
      { Min: 'Old', Max: 'Dominant', Maturity: 'mid', Density: 'Medium', DensitySize: 50.1, Mix: 'Armax Bull, Feffoid, Molisk', Notes: '', Id: 79, Pos: '87336.28,79377.18 87709.86,78906.78 87824.81,78599.58 88361.24,78417.18 88964.71,78138.78 88897.66,77937.18 88734.82,77908.38 88782.71,77533.98 88897.66,77274.78 88782.71,77063.58 88610.29,77207.58 88504.92,77101.98 88437.87,77130.78 88399.55,77341.98 88140.92,77495.58 88006.81,77476.38 87930.18,77409.18 87939.76,77553.18 87843.97,77716.38 87882.29,77879.58 87729.02,77956.38 87633.23,78033.18 87623.65,78157.98 87671.55,78273.18 87719.44,78330.78 87451.23,78532.38 87135.12,78714.78 86943.54,78676.38 86771.12,78666.78 86751.96,78762.78 86799.86,78897.18 87058.49,79098.78 87087.23,79300.38 87202.18,79396.38' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: 'Armax Bull, Exarosaur', Notes: '', Id: 80, Pos: '85424.85,86313.6 85990.01,86361.6 86459.38,86448 86842.54,86572.8 86928.76,86563.2 87388.55,86851.2 87666.34,86774.4 87972.87,86400 87896.23,86227.2 87972.87,86083.2 87848.34,85651.2 87714.23,85449.6 87484.34,85353.6 87359.81,85488 87206.55,85555.2 87062.86,85564.8 86976.65,85507.2 86947.91,85372.8 86756.33,85286.4 86536.02,85267.2 86152.86,85344 85731.38,85430.4 85434.43,85507.2 85348.22,85804.8 85338.64,86092.8' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 10, Mix: 'Armax Bull', Notes: '', Id: 767, Pos: '27992,57245 28552,57197 29656,57261 30488,57213 30536,56781 30744,56557 30552,56125 30184,56029 29720,55981 29416,56093 28952,56269 28344,56205 27816,56125 27656,56605 27704,56957' },
    ]
  },
  { // Atrax
    Name: 'Atrax',
    id: '8',
    notes: '',
    spawns: [
      { Min: 'Stalker', Max: '', Maturity: 'big', Density: 'Big', DensitySize: 100, Mix: '', Notes: '', Id: 85, Pos: '81539,89380 81456,89364 81410,89292 81241,89291 81225,89465 81383,89514 81535,89475' },
      { Min: 'Prowler', Max: '', Maturity: 'big', Density: 'Big', DensitySize: 100, Mix: '', Notes: '', Id: 84, Pos: '81535,89083 81738,89011 81734,89328 81694,89441 81567,89473 81548,89365' },
      { Min: 'Old', Max: 'Provider', Maturity: 'small', Density: 'Big', DensitySize: 80.5, Mix: 'Cornundacauda', Notes: '', Id: 81, Pos: '84179.58,90104.63 88538.03,90080.63 88514.08,89708.63 88250.66,89408.63 87460.39,89072.63 87352.63,88868.63 87029.34,88556.63 86837.76,88208.63 86885.65,87980.63 87376.57,87680.63 87328.68,87008.63 86861.7,86624.63 86706.04,86672.63 86454.6,86552.63 86191.17,86624.63 85819.99,86504.63 85388.93,86420.63 85221.3,86516.63 84790.24,86552.63 84335.24,86792.63 84335.24,87188.63 83856.29,87680.63 83700.63,88028.63 83880.24,88316.63 83832.34,88544.63 83964.06,88736.63 84131.69,89432.63 84143.66,89852.63' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 80.4, Mix: '', Notes: '', Id: 82, Pos: '72416.57,77097.6 72828.47,76790.4 72847.63,76704 72933.84,76608 72981.73,76368 73106.26,76195.2 73135,76012.8 73230.79,75830.4 73393.63,75715.2 73681,75734.4 73671.42,77817.6 73546.89,77836.8 73441.52,78096 73288.26,78105.6 73230.79,77875.2 73364.89,77587.2 73125.42,77356.8 72809.31,77404.8 72598.57,77577.6 72521.94,77395.2 72387.83,77328 72454.89,77212.8 72426.15,77088' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Big', DensitySize: 80.3, Mix: 'Atrox, Feffoid', Notes: '', Id: 83, Pos: '69523.71,89732.63 69787.14,89828.63 70158.32,89660.63 70170.3,89576.63 70457.67,89408.63 70481.61,89588.63 70637.27,89540.63 70912.67,89228.63 70960.56,89060.63 71223.99,89024.63 71331.75,88928.63 71439.51,89048.63 71870.57,88904.63 71966.36,88676.63 72373.46,88460.63 72409.39,88256.63 72289.65,87968.63 71810.7,87416.63 71583.2,87404.63 71451.49,87284.63 71355.7,87440.63 71247.93,87368.63 71128.2,87512.63 70900.69,87596.63 70685.17,87548.63 70661.22,87764.63 70493.59,87860.63 70385.82,88040.63 70266.09,88076.63 70134.37,87968.63 70050.56,88028.63 69811.08,88124.63 69571.61,88076.63 69511.74,87872.63 69308.18,87992.63 69224.37,88256.63 69104.63,88496.63 68913.05,88796.63' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 86, Pos: '64264.84,81974.4 64552.21,82291.2 64734.21,82560 64983.27,82627.2 65012,82425.6 64868.32,82204.8 64753.37,82080 64734.21,81974.4' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Medium', DensitySize: 50.9, Mix: 'Berycled', Notes: '', Id: 87, Pos: '73750.45,78156 74361.11,78060 74444.92,77772 74756.24,77472 74983.74,77220 75031.64,77160 75019.66,77052 74780.19,77064 74672.43,76956 74660.45,76812 74516.77,76524 74624.53,76320 74492.82,76176 74205.45,76236 73726.5,76236' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'small', Density: 'Medium', DensitySize: 50.8, Mix: '', Notes: '', Id: 88, Pos: '80336.01,75588 81114.3,75588 81246.01,75432 81150.22,75108 81234.04,75000 81353.78,74964 81317.86,74808 81174.17,74760 81150.22,74640 81018.51,74496 80802.99,74532 80740.99,74772 80515.62,74916 80276.14,75204' },
      { Min: 'Old Alpha', Max: '', Maturity: 'big', Density: 'Medium', DensitySize: 50.7, Mix: 'Atrox', Notes: '', Id: 89, Pos: '70631.28,72708 70798.92,72480 71146.16,72528 71361.68,72588 71457.47,72696 71421.55,72792 71253.92,72876 71086.29,72864 70870.76,72780 70679.18,72792' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50.6, Mix: '', Notes: '', Id: 90, Pos: '80544.35,74150.4 80898.77,74044.8 81004.14,74121.6 81224.46,74006.4 81329.83,74054.4 81416.04,74054.4 81463.94,73987.2 81569.31,73987.2 81444.78,73862.4 81281.93,73891.2 81253.2,73785.6 80956.25,73785.6 80774.25,73824 80515.62,73929.6 80506.04,74092.8' },
      { Min: 'Mature', Max: 'Dominant', Maturity: 'small', Density: 'Medium', DensitySize: 50.5, Mix: 'Gokibusagi', Notes: '', Id: 91, Pos: '87963.29,86505.6 88097.39,86534.4 88183.6,86457.6 88336.87,86476.8 88432.66,86601.6 88585.92,86640 88806.24,86764.8 88902.03,86736 88892.45,86630.4 88710.45,86409.6 88748.77,86064 88959.5,85996.8 89428.87,86025.6 89505.51,85987.2 89428.87,85920 89026.56,85843.2 88892.45,85833.6 88796.66,85689.6 88787.08,85440 88662.55,85353.6 88614.66,85084.8 88672.13,84931.2 88547.61,84864 88413.5,84864 88116.55,85094.4 87790.87,85257.6 87685.5,85401.6 87896.23,85660.8 88011.18,86092.8 87953.71,86256 88011.18,86419.2' },
      { Min: 'Provider', Max: 'Dominant', Maturity: 'mid', Density: 'Medium', DensitySize: 50.4, Mix: '', Notes: '', Id: 92, Pos: '76899.54,72393.6 76928.28,72508.8 77052.81,72576 77244.39,72489.6 77311.44,72576 77598.81,72595.2 77780.81,72528 77799.97,72297.6 77790.39,72153.6 77263.54,72163.2 77177.33,72230.4 76976.17,72297.6' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50.3, Mix: '', Notes: '', Id: 93, Pos: '64608.49,84048 64812.04,84120 64979.67,84096 65027.57,83988 65015.6,83904 64895.86,83760 64812.04,83664 64644.41,83616 64512.7,83640 64488.75,83796 64524.67,83952' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Medium', DensitySize: 50.2, Mix: 'Berycled, Faucervix, Gokibusagi, Molisk', Notes: '', Id: 94, Pos: '81898.58,87204 82796.61,87180 83099.13,86885.65 83290.71,86490.35 83263.59,86124 83395.3,85716 83443.2,84972 83395.3,84576 83263.59,84348 83131.88,84504 82856.48,84624 81898.58,84612' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Medium', DensitySize: 50.1, Mix: 'Caudatergus, Molisk, Snablesnot Female, Snablesnot Male', Notes: '', Id: 95, Pos: '81899.78,84585.6 82838.52,84595.2 83116.31,84480 83231.26,84316.8 83250.42,84057.6 83164.21,83865.6 82953.47,83577.6 83030.1,83270.4 83192.95,83088 83240.84,82896 83374.95,82771.2 83231.26,82560 83384.53,82368 83844.32,82252.8 84074.21,82166.4 84227.48,82080 84304.11,82147.2 84390.32,82137.6 84438.22,82080 84428.64,81955.2 81899.78,81955.2' },
      { Min: 'Mature', Max: 'Alpha', Maturity: 'mid', Density: 'Medium', DensitySize: 50, Mix: 'Armax Bull, Atrox, Caudatergus, Hiryuu, Molisk', Notes: '', Id: 96, Pos: '81899.78,78192 82206.31,78192 82369.15,78086.4 82369.15,75350.4 82254.2,75158.4 82263.78,74928 81918.94,74918.4' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Low', DensitySize: 30.2, Mix: 'Merp, Tripudion', Notes: '', Id: 97, Pos: '72502.78,68304 72630.9,68376 73109.85,68652 73277.48,68568 73349.33,68724 73504.98,68844 73696.56,68892 73696.64,67812 73481.04,67908 73325.38,67704 73145.77,67812 72990.11,67716 72774.59,67704 72606.95,67656 72295.64,67752 72319.58,67908 72523.14,68004 72642.87,68172' },
      { Min: 'Mature', Max: 'Dominant', Maturity: 'small', Density: 'Low', DensitySize: 30.1, Mix: 'Araneatrox, Gokibusagi', Notes: '', Id: 98, Pos: '89036.14,85756.8 89333.08,85814.4 89553.4,85612.8 89515.09,85382.4 89438.45,85104 89630.03,84864 89697.09,84681.6 89668.35,84547.2 89495.93,84422.4 89400.14,84230.4 89400.14,84028.8 89093.61,83808 88959.5,83808 88796.66,83932.8 88748.77,84057.6 88576.34,84076.8 88317.71,84163.2 87723.81,84028.8 87675.92,84124.8 87848.34,84499.2 88049.5,84470.4 88164.45,84460.8 88241.08,84566.4 88375.18,84480 88557.19,84518.4 88700.87,84921.6 88662.55,85065.6 88710.45,85305.6 88815.82,85401.6 88834.98,85641.6 88959.5,85804.8' },
      { Min: 'Mature', Max: 'Guardian', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: 'Armax Bull, Armax Cow, Prancer', Notes: '', Id: 99, Pos: '58286.35,81596.63 58801.22,81596.63 59136.48,81680.63 59555.57,81656.63 59459.78,81428.63 59363.99,81068.63 59184.38,80948.63 59124.51,80696.63 59160.43,80288.63 58837.14,80156.63 58633.59,80132.63 58477.93,80144.63 58465.95,80312.63 58322.27,80492.63 58286.35,80648.63 58418.06,80840.63 58274.37,80972.63 58190.56,80852.63 57927.14,80864.63 57867.27,81044.63 57807.4,81140.63 58130.69,81260.63 58166.61,81416.63' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 15, Mix: '', Notes: '', Id: 753, Pos: '25659,47225 26955,47273 26923,47161 26923,46985 26859,46905 26843,46761 26891,46697 26875,46409 26811,46345 26795,46233 26859,46025 26811,45913 26667,45897 26683,45769 26555,45577 26507,45417 26411,45273 26251,45273 25579,46121 25467,46841' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 768, Pos: '27416,56173 28008,56029 28472,55997 28920,56141 29432,56013 29928,55341 29880,55197 28360,55213 27912,55117 27624,54877 27240,54861 27016,54989 26808,55373 26920,55805' },
      { Min: 'Provider', Max: 'Guardian', Maturity: 'small', Density: 'Very Low', DensitySize: 5, Mix: 'Longu, Trilomite', Notes: '', Id: 809, Pos: '33926,29770 35206,29882 36374,29402 36134,27642 34838,27626 33958,28234 33606,29050' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 15, Mix: 'Fungoid, Merp, Neconu', Notes: '', Id: 823, Pos: '36198,35530 36550,35546 37206,35642 37542,35514 37558,35146 37350,34682 36902,34474 36454,34506 36182,34714' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Very Low', DensitySize: 5, Mix: 'Chirpy, Mermoth', Notes: '', Id: 908, Pos: '53309.34,62305.02 53673.34,62228.22 54094.82,62343.42 54286.4,62247.42 54420.5,62093.82 54631.24,62113.02 54822.82,61921.02 54497.14,61729.02 54324.71,61805.82 54075.66,61633.02 53769.13,61709.82 53443.45,61575.42 53232.71,61575.42 53232.71,61767.42 53405.13,62074.62' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Very Low', DensitySize: 10, Mix: 'Cornundacauda, Gibnib, Snalesnot Female, Snablesnot Male', Notes: '', Id: 955, Pos: '72972.98,75973.57 73116.67,76011.97 73222.04,75800.77 73394.46,75666.37 73691.41,75685.57 73633.93,74936.77 73174.14,74965.57 73097.51,74879.17 72723.93,74869.57 72695.19,75013.57 72829.3,75080.77 72982.56,75243.97 73030.46,75560.77 72915.51,75570.37 72848.45,75714.37 72963.4,75839.17' },
    ]
  },
  { // Atrox
    Name: 'Atrox',
    id: '9',
    notes: '',
    spawns: [
      { Min: 'Provider', Max: 'Dominant', Maturity: 'mid', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 103, Pos: '40229.21,60029.82 40439.95,60087.42 40478.27,59991.42 40650.69,59857.02 40631.53,59684.22 40325,59703.42 40190.9,59837.82' },
      { Min: 'Alpha', Max: 'Old Alpha', Maturity: 'big', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 108, Pos: '80726,87088 80751,86872 80977,86865 81139,87094 80949,87286 80811,87307 80801,87599 80507,87479' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Big', DensitySize: 81, Mix: '', Notes: '', Id: 100, Pos: '67500.15,89924.63 67727.65,89828.63 67595.94,89540.63 67667.78,89300.63 67859.36,89204.63 67799.49,89636.63 67883.31,89792.63 68446.08,89960.63 68960.95,89972.63 69092.66,89852.63 68937,89456.63 69332.13,89840.63 69499.77,89732.63 68877.13,88796.63 68721.47,88736.63 68661.6,88664.63 68721.47,88460.63 68362.26,88484.63 68170.68,88388.63 68086.86,88268.63 68062.92,88100.63 67943.18,88112.63 67722.86,88214.4 67646.23,88089.6 67387.6,88060.8 67320.54,87820.8 66946.96,87907.2 66841.59,87956.63 66697.91,87812.63 66506.33,87908.63 66338.7,88196.63 66446.46,88256.63 66350.67,88568.63 66171.06,88820.63 66302.77,88976.63 66494.35,88952.63 66614.09,89108.63 66626.07,89372.63 66769.75,89552.63 67128.96,89768.63 67248.7,89648.63 67296.6,89852.63' },
      { Min: 'Young', Max: 'Old Alpha', Maturity: 'mid', Density: 'Big', DensitySize: 80.9, Mix: '', Notes: '', Id: 101, Pos: '69835.03,88076.63 70038.58,88004.63 70230.16,87464.63 70433.72,87392.63 70589.38,87416.63 70685.17,87488.63 70900.69,87536.63 71116.22,87476.63 71235.96,87308.63 71247.93,87128.63 71080.3,86912.63 71080.3,86684.63 70900.69,86420.63 70589.38,86312.63 70014.64,86240.63 69835.03,86588.63 69823.06,86900.63 69847,87020.63 69739.24,87056.63 69763.19,87212.63 69835.03,87320.63 69643.45,87692.63 69571.61,87848.63 69595.55,88028.63' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Big', DensitySize: 80.8, Mix: 'Atrax, Feffoid', Notes: '', Id: 102, Pos: '69523.71,89732.63 69787.14,89828.63 70158.32,89660.63 70170.3,89576.63 70457.67,89408.63 70481.61,89588.63 70637.27,89540.63 70912.67,89228.63 70960.56,89060.63 71223.99,89024.63 71331.75,88928.63 71439.51,89048.63 71870.57,88904.63 71966.36,88676.63 72373.46,88460.63 72409.39,88256.63 72289.65,87968.63 71810.7,87416.63 71583.2,87404.63 71451.49,87284.63 71355.7,87440.63 71247.93,87368.63 71128.2,87512.63 70900.69,87596.63 70685.17,87548.63 70661.22,87764.63 70493.59,87860.63 70385.82,88040.63 70266.09,88076.63 70134.37,87968.63 70050.56,88028.63 69811.08,88124.63 69571.61,88076.63 69511.74,87872.63 69308.18,87992.63 69224.37,88256.63 69104.63,88496.63 68913.05,88796.63' },
      { Min: 'Dominant', Max: 'Prowler', Maturity: 'big', Density: 'Big', DensitySize: 80.6, Mix: '', Notes: '', Id: 104, Pos: '81246.01,75588 81617.2,75600 81820.75,75720 81892.6,75576 81892.6,74556 81796.81,74556 81712.99,74652 81473.52,74688 81317.86,74580 81210.09,74616 81210.09,74712 81341.8,74760 81401.67,74976 81269.96,75012 81198.12,75108 81269.96,75456 81174.17,75540' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Big', DensitySize: 80.5, Mix: '', Notes: '', Id: 105, Pos: '68883.12,71448 69050.75,71568 69278.25,71580 69433.91,71568 69517.73,71628 69589.57,71724 69589.57,71952 69661.41,72024 69769.17,72060 69948.78,71952 70020.62,71796 70104.44,71664 70248.12,71532 70415.76,71436 70487.6,71280 70427.73,71220 70499.57,71100 70367.86,71004 70343.91,70848 70224.18,70728 69996.68,70716 69709.31,70692 69326.15,70752 69266.28,70968 69254.3,71208 69026.8,71328 68799.3,71304' },
      { Min: 'Old Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 80.4, Mix: '', Notes: '', Id: 106, Pos: '71724.87,71416.42 71820.66,71502.82 71868.55,71790.82 71945.18,71867.62 72165.5,71829.22 72309.18,71829.22 72385.82,71522.02 72462.45,71339.62 72462.45,71157.22 72539.08,71176.42 72663.61,71138.02 72606.13,70946.02 72491.19,70821.22 72357.08,70802.02 72347.5,70725.22 72491.19,70725.22 72472.03,70581.22 72347.5,70398.82 72203.82,70360.42 72079.29,70360.42 71916.45,70475.62 71868.55,70715.62 71945.18,70792.42 71935.6,70888.42 71868.55,70974.82 71772.76,70936.42 71638.65,71070.82 71686.55,71234.02' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Big', DensitySize: 80.3, Mix: '', Notes: '', Id: 107, Pos: '80601.83,74304 80630.56,74428.8 80755.09,74467.2 81071.2,74352 81262.78,74380.8 81358.57,74505.6 81502.25,74640 81684.25,74640 81760.89,74524.8 81837.52,74534.4 81837.52,74380.8 81684.25,74352 81569.31,74160 81483.09,74016 81463.94,74102.4 81320.25,74112 81262.78,74035.2 81119.09,74102.4 81013.72,74208 80879.62,74092.8 80553.93,74198.4' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Big', DensitySize: 80.1, Mix: 'Armax Bull, Armax Cow', Notes: '', Id: 109, Pos: '84551.15,81950.22 84838.52,82072.42 85010.94,82370.02 86313.68,82331.62 86399.89,82504.42 89436.44,82475.62 89493.91,82370.02 89302.33,82283.62 89101.17,82379.62 88880.85,82331.62 88976.64,82110.82 89005.38,81950.22' },
      { Min: 'Old', Max: 'Guardian', Maturity: 'small', Density: 'Big', DensitySize: 80, Mix: 'Armax Bull, Armax Cow', Notes: '', Id: 110, Pos: '84688.47,81864 88005.2,81888 87921.38,81792 87933.35,81432 88089.01,81324 87969.27,81204 87897.43,81024 86819.79,81024 86688.08,80892 86520.45,80952 86412.69,81036 86017.55,81024 85849.92,80904 85251.23,80892 84604.65,81132 84401.1,81096 84250.57,81156' },
      { Min: 'Dominant', Max: '', Maturity: 'mid', Density: 'Medium', DensitySize: 50.2, Mix: 'Atrax', Notes: '', Id: 111, Pos: '70631.28,72708 70798.92,72480 71146.16,72528 71361.68,72588 71457.47,72696 71421.55,72792 71253.92,72876 71086.29,72864 70870.76,72780 70679.18,72792' },
      { Min: 'Old', Max: 'Dominant', Maturity: 'mid', Density: 'Medium', DensitySize: 50.1, Mix: 'Armax Bull, Atrax, Caudatergus, Hiryuu, Molisk', Notes: '', Id: 112, Pos: '81899.78,78192 82206.31,78192 82369.15,78086.4 82369.15,75350.4 82254.2,75158.4 82263.78,74928 81918.94,74918.4' },
      { Min: 'Old', Max: 'Stalker', Maturity: 'big', Density: 'Medium', DensitySize: 50, Mix: 'Armax Bull', Notes: '', Id: 113, Pos: '84380.74,78748.8 84553.16,78748.8 84658.53,78499.2 84640.57,78252 84460.97,78168 84430.37,78009.6 84284.95,77875.2 84045.48,77884.8 83892.21,77760 83873.05,77904 84102.95,78105.6 83941.79,78432 84189.16,78528' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: 'Feffoid, Merp', Notes: '', Id: 114, Pos: '67997.06,74096.63 68368.25,74144.63 68440.09,74240.63 68511.93,74240.63 68667.59,74144.63 68799.3,74240.63 68835.22,74348.63 68919.04,74372.63 68978.91,74276.63 69134.57,74324.63 69302.2,74408.63 69469.83,74204.63 69565.62,73952.63 69565.62,73760.63 67745.61,73760.63' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Very Low', DensitySize: 10, Mix: 'Hogglo', Notes: '', Id: 751, Pos: '22923,46665 23691,46633 24203,46713 24491,44617 24363,43977 23579,43769 22875,43881 22939,46009' },
      { Min: 'Old', Max: 'Dominant', Maturity: 'mid', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 761, Pos: '28056,59533 28360,59485 28440,59421 28392,59181 28760,59117 29064,58733 29064,57437 27768,57437 27640,58381 28120,58413 28216,58909' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Low', DensitySize: 20, Mix: 'Exarosaur, Itumatrox, Mermoth', Notes: '', Id: 791, Pos: '16848,28832 18928,29168 19648,28592 20464,28704 20624,28384 20448,27920 20928,27008 20912,26768 20560,26592 20048,25712 18784,25648 18128,25808 17952,25904 17552,26720 17632,27216 17808,27568' },
      { Min: 'Guardian', Max: 'Old Alpha', Maturity: 'mid', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 795, Pos: '22368,28448 23952,29360 24320,29376 24400,29248 24448,28192 24368,27456 23824,27328 23152,27280 22560,27280 22176,27680 22096,28032' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 5, Mix: 'Gokibusagi, Longu', Notes: '', Id: 802, Pos: '28160,32000 28896,32080 29168,31664 29296,30864 29152,30832 29120,30368 28464,30000 28400,30768 27872,31536' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 813, Pos: '38438,30154 39174,30410 39462,29578 39142,29146 38406,29290 38262,29738' },
      { Min: 'Provider', Max: 'Dominant', Maturity: 'mid', Density: 'Low', DensitySize: 20, Mix: 'Exarosaur, Gradivore', Notes: '', Id: 815, Pos: '34198,32634 34774,32666 35510,31930 35478,31770 35142,31642 35446,31306 35542,30986 35206,30458 33878,30666 33750,31034 33398,31210 33350,31418 33158,31514 33158,31770 33510,31850' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Very Low', DensitySize: 15, Mix: 'Caudatergus, Falxangius', Notes: '', Id: 821, Pos: '39286,35338 40022,35466 40886,35290 40886,33162 40838,32874 39846,32874 39334,32938 38182,32858 37238,33322 37526,34058 37814,33818 39110,33882 39302,34010 39638,34026 39670,34698' },
      { Min: 'Guardian', Max: 'Alpha', Maturity: 'mid', Density: 'Big', DensitySize: 15, Mix: '', Notes: '', Id: 827, Pos: '34934,35130 35318,35466 35846,35610 36118,35578 36150,35354 36134,34586 36550,34346 36566,34010 36214,33898 35862,34042 35382,34122 35062,34282 34950,34858' },
      { Min: 'Guardian', Max: 'Dominant', Maturity: 'small', Density: 'Low', DensitySize: 15, Mix: 'Fungoid, Gradivore', Notes: '', Id: 862, Pos: '40407,37826 40855,37762 40887,36418 40791,35650 39671,35618 39063,35858 39159,36274 40071,37666' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Low', DensitySize: 35, Mix: 'Exarosaur', Notes: '', Id: 927, Pos: '40537.66,32726.4 40968.71,32716.8 40949.56,31286.4 39685.13,31305.6 39838.39,31420.8 40097.02,31478.4 40106.6,31776 40279.03,32006.4 40470.61,32246.4 40432.29,32323.2 40537.66,32486.4 40480.18,32630.4' },
      { Min: 'Old', Max: 'Alpha', Maturity: 'mid', Density: 'Low', DensitySize: 25, Mix: '', Notes: '', Id: 981, Pos: '33395.53,30327.38 33405.11,30826.58 33759.53,30903.38 33730.8,30720.98 33979.85,30375.38' },
    ]
  },
  { // Berycled
    Name: 'Berycled',
    id: '11',
    notes: '',
    spawns: [
      { Min: 'Old', Max: 'Alpha', Maturity: 'mid', Density: 'Big', DensitySize: 100, Mix: 'Turp', Notes: '', Id: 115, Pos: '64628.84,87283.2 64504.32,87120 64427.68,86851.2 64466,86630.4 64552.21,86400 64705.48,86371.2 64782.11,86553.6 64849.16,86774.4 64992.85,86976 65021.58,87177.6 64906.63,87244.8 64782.11,87216 64734.21,87292.8' },
      { Min: 'Mature', Max: 'Guardian', Maturity: 'small', Density: 'Big', DensitySize: 80.3, Mix: 'Caudatergus', Notes: '', Id: 116, Pos: '88837.37,95672.63 88945.14,95744.63 89112.77,95732.63 89268.43,95576.63 89328.29,95156.63 89400.14,94784.63 89471.98,94472.63 89352.24,94064.63 89088.82,94184.63 88921.19,94172.63 88945.14,94556.63 88777.5,94688.63 88657.76,94988.63 88693.69,95396.63 88801.45,95588.63' },
      { Min: 'Old', Max: 'Guardian', Maturity: 'mid', Density: 'Big', DensitySize: 80.2, Mix: 'Plumatergus', Notes: '', Id: 117, Pos: '80563.51,97568.63 80826.93,97316.63 81832.73,97112.63 81796.81,94112.63 81257.99,94340.63 80982.59,94820.63 80635.35,95240.63 80503.64,95576.63 80300.09,95804.63 80336.01,95984.63 80072.59,96656.63 80084.56,97076.63 80312.06,97376.63' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Big', DensitySize: 80.1, Mix: '', Notes: '', Id: 118, Pos: '60956.49,84312 61028.34,84060 60968.47,83844 60657.15,83808 60501.49,83748 60537.41,83532 60489.52,83388 60417.68,83424 60345.83,83712 60369.78,83916 60561.36,84120 60776.89,84240' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'small', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 119, Pos: '73744.46,89232 74007.88,89208 74211.44,88944 74534.73,88872 74905.91,88908 74426.96,86652 74307.23,86604 73971.96,86688 73744.46,86664' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Medium', DensitySize: 51, Mix: 'Cornundos', Notes: '', Id: 120, Pos: '65919.61,77408.63 66135.14,77420.63 66386.59,77408.63 66530.27,77540.63 66721.86,77480.63 66913.43,77336.63 67200.8,77072.63 66769.75,76964.63 66685.93,77060.63 66650.01,77228.63 66518.3,77276.63 66266.85,77240.63 66111.19,77252.63 65943.56,77156.63' },
      { Min: 'Old Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Medium', DensitySize: 50.9, Mix: 'Snarg', Notes: '', Id: 121, Pos: '72816.49,88484.63 73067.94,88508.63 73187.68,88676.63 73702.55,88712.63 73702.55,86300.63 73079.92,86408.63 72529.12,86540.63 72193.86,86744.63 72086.1,86984.63 72110.04,87080.63 72409.39,87020.63 72648.86,87128.63 72828.47,87560.63 72852.42,87776.63 72624.91,87872.63 72433.33,87872.63 72433.33,87956.63 72684.78,88184.63 72720.7,88352.63' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Medium', DensitySize: 50.8, Mix: 'Cornundos', Notes: '', Id: 122, Pos: '65542.44,78860.63 65949.55,78932.63 66129.15,78932.63 66105.21,78668.63 66236.92,78596.63 66272.84,78452.63 66141.13,78404.63 66033.36,78368.63 65973.5,78104.63 65889.68,77996.63 65554.41,77948.63' },
      { Min: 'Old Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Medium', DensitySize: 50.7, Mix: 'Foul', Notes: '', Id: 123, Pos: '77400.55,88809.6 78020.29,88819.2 78077.76,88886.4 78077.76,89059.2 78269.34,89116.8 78317.23,89395.2 78441.76,89548.8 78796.18,89424 78642.92,89126.4 78671.66,88924.8 78135.23,88454.4 78039.44,88214.4 77905.34,88012.8 77838.28,87715.2' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50.6, Mix: 'Sabakuma', Notes: '', Id: 124, Pos: '79102.71,81924 80168.38,81924 80156.4,80748 80395.88,80424 80563.51,80280 80563.51,79848 79797.19,79848 79653.51,79644 79521.79,79692 79437.98,79464 79210.48,79464 78719.55,79956 78911.13,80148 78791.4,80484 78994.95,80640 78959.03,80820 79078.77,81096' },
      { Min: 'Old', Max: 'Provider', Maturity: 'small', Density: 'Medium', DensitySize: 50.5, Mix: 'Atrax', Notes: '', Id: 125, Pos: '73750.45,78156 74361.11,78060 74444.92,77772 74756.24,77472 74983.74,77220 75031.64,77160 75019.66,77052 74780.19,77064 74672.43,76956 74660.45,76812 74516.77,76524 74624.53,76320 74492.82,76176 74205.45,76236 73726.5,76236' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Medium', DensitySize: 50.4, Mix: 'Morsusilex', Notes: '', Id: 126, Pos: '87517.86,76416 87680.71,76627.2 87862.71,76771.2 87891.45,76982.4 87738.18,77155.2 87766.92,77280 88035.13,77443.2 88140.5,77443.2 88389.55,77299.2 88418.29,77088 88514.08,77049.6 88619.45,77155.2 88791.87,77011.2 88791.87,76809.6 88657.76,76492.8 88676.92,76252.8 88763.13,76089.6 88897.24,76032 89165.45,76099.2 89213.35,75916.8 89213.35,75648 88533.24,75004.8 88169.24,74928 87987.24,74956.8 87728.6,75129.6 87556.18,75129.6 87537.02,75638.4 87431.65,75811.2 87460.39,76089.6' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'small', Density: 'Medium', DensitySize: 50.3, Mix: 'Fugabarba, Snablesnot Female, Snablesnot Male', Notes: '', Id: 127, Pos: '83802.41,77652 83874.25,77712 84017.94,77664 84137.67,77724 84125.7,77868 84341.23,77880 84496.89,78012 84772.28,78036 84915.97,78432 85023.73,78528 85095.57,78504 85011.76,78168 85047.68,78000 85179.39,78000 85227.29,78132 85406.89,77928 85394.92,77808 85502.68,77580 85658.34,77700 86245.05,77988 86388.74,77748 86328.87,77616 86376.77,77496 86532.42,77376 86376.77,77112 86053.47,77148 85993.61,76872 85742.16,76956 85742.16,77232 85311.1,77520 85167.42,77688 84939.92,77760 84796.23,77736 84652.55,77640 84508.86,77544 84365.18,77556 84245.44,77616 83993.99,77568 83874.25,77460 83790.44,77508' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 50.2, Mix: '', Notes: '', Id: 128, Pos: '64659.83,85082.54 64918.47,85063.34 65023.84,84890.54 65014.26,84660.14 64822.68,84468.14 64650.26,84343.34 64496.99,84362.54 64334.15,84496.94 64200.04,84688.94 64094.67,84852.14 64037.2,85111.34 63941.41,85312.94 63883.94,85447.34 63970.15,85600.94 64295.83,85687.34 64755.62,85668.14 64947.2,85581.74 65004.68,85351.34 64928.05,85226.54 64774.78,85226.54 64659.83,85178.54 64650.26,85072.94' },
      { Min: 'Old', Max: 'Dominant', Maturity: 'small', Density: 'Medium', DensitySize: 50.1, Mix: 'Atrax, Faucervix, Gokibusagi, Molisk', Notes: '', Id: 129, Pos: '81898.58,87204 82796.61,87180 83099.13,86885.65 83290.71,86490.35 83263.59,86124 83395.3,85716 83443.2,84972 83395.3,84576 83263.59,84348 83131.88,84504 82856.48,84624 81898.58,84612' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: 'Faucervix, Prototype Drone', Notes: '', Id: 130, Pos: '63159.66,78324 63327.3,78648 63638.61,78648 63674.54,78828 63566.77,79020 63638.61,79080 63878.09,79212 64045.72,79260 64237.3,79296 64321.12,79524 64309.14,79764 64440.86,79932 64716.25,79896 64764.15,79776 64764.15,79476 64907.83,79284 65015.6,79248 65482.57,79260 65470.6,78024 64955.73,77748 64991.65,78048 64835.99,78060 64752.17,77940 64536.65,78240 64572.57,78336 64297.17,78480 64045.72,78420 63925.98,78372 63902.04,78240 63650.59,78144 63542.82,78132 63303.35,78216' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Low', DensitySize: 31.1, Mix: '', Notes: '', Id: 131, Pos: '60411.69,75080.63 60700.11,75188.63 60675.11,74990.63 60567.35,74984.63 60471.56,75008.63' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Low', DensitySize: 31, Mix: 'Caudatergus, Daikiba', Notes: '', Id: 132, Pos: '64698.29,77084.63 64925.79,77072.63 65021.58,76952.63 65332.9,76940.63 65524.48,76868.63 65512.51,74504.63 65392.77,74564.63 65296.98,74408.63 65045.53,74396.63 64961.71,74324.63 64794.08,74228.63 64794.08,74384.63 64614.48,74360.63 64506.71,74492.63 64506.71,75320.63 64638.42,75296.63 64758.16,75356.63 64818.03,75428.63 64985.66,75428.63 64973.69,75560.63 64806.06,75668.63 64758.16,75824.63 64602.5,75884.63 64506.71,75764.63 64470.79,75956.63 64327.11,76160.63 64267.24,76400.63 64446.84,76676.63 64482.76,76808.63 64602.5,76988.63' },
      { Min: 'Puny', Max: 'Juvenile', Maturity: 'small', Density: 'Low', DensitySize: 30.9, Mix: '', Notes: '', Id: 133, Pos: '63441.05,74624.63 63584.73,74660.63 63584.73,74432.63 63524.86,74396.63 63429.07,74468.63' },
      { Min: 'Mature', Max: 'Guardian', Maturity: 'mid', Density: 'Low', DensitySize: 30.8, Mix: 'Merp, Sabakuma', Notes: '', Id: 134, Pos: '72493.2,82832.63 72696.76,82760.63 72660.84,82568.63 72588.99,82472.63 72421.36,82412.63 72285.68,82470.63 72340.75,82556.63 72400.49,82712.63' },
      { Min: 'Mature', Max: 'Provider', Maturity: 'small', Density: 'Low', DensitySize: 30.7, Mix: 'Cornundos', Notes: '', Id: 135, Pos: '57998.98,84500.63 58262.4,84716.63 58489.9,84692.63 58717.4,84908.63 58980.83,84920.63 59184.38,84704.63 59411.88,84728.63 59387.93,84584.63 59507.67,84488.63 59328.06,84188.63 59076.62,84212.63 58741.35,84164.63 58453.98,84476.63' },
      { Min: 'Puny', Max: '', Maturity: 'small', Density: 'Low', DensitySize: 30.6, Mix: '', Notes: '', Id: 136, Pos: '62728.61,90012 62968.08,89700 62752.56,89568 62596.9,89760' },
      { Min: 'Provider', Max: 'Dominant', Maturity: 'mid', Density: 'Low', DensitySize: 30.5, Mix: 'Cornundacauda, Merp, Prancer', Notes: '', Id: 137, Pos: '73744.46,86580 74007.88,86628 74163.54,86592 74331.17,86556 74438.94,86604 74400.05,86040 74097.68,84864 74313.21,84264 73714.52,84264' },
      { Min: 'Old', Max: 'Dominant', Maturity: 'mid', Density: 'Low', DensitySize: 30.4, Mix: 'Cornundacauda, Merp, Prancer, Sabakuma', Notes: '', Id: 138, Pos: '73714.52,83424 73858.21,83424 73930.05,83544 74588.61,83544 74971.77,82548 74971.77,81948 73714.52,81948' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Low', DensitySize: 30.3, Mix: '', Notes: '', Id: 139, Pos: '65098.21,87638.4 65376.01,87571.2 65356.85,87427.2 65126.95,87465.6 65002.42,87532.8' },
      { Min: 'Old', Max: 'Dominant', Maturity: 'mid', Density: 'Low', DensitySize: 30.2, Mix: 'Cornundacauda, Merp, Prancer', Notes: '', Id: 140, Pos: '75927.27,82550.4 78408.24,82540.8 78446.55,81993.6 75927.27,81993.6' },
      { Min: 'Juvenile', Max: '', Maturity: 'small', Density: 'Low', DensitySize: 30.1, Mix: 'Daikiba, Snablesnot Female, Snablesnot Male', Notes: '', Id: 141, Pos: '60028.91,79000.42 60354.59,79077.22 60584.49,78952.42 60756.91,78971.62 60776.07,78837.22 60881.44,78856.42 60910.17,79019.62 61073.02,79086.82 61197.54,79077.22 61456.18,79125.22 61743.55,79067.62 61963.86,78856.42 61973.44,78779.62 61868.07,78779.62 61935.13,78539.62 61963.86,78299.62 62117.13,78174.82 62270.39,78174.82 62356.6,78203.62 62471.55,78136.42 62557.76,77934.82 62596.08,77733.22 62682.29,77570.02 62758.92,77406.82 62787.66,77109.22 62787.66,76802.02 62452.39,76840.42 62145.87,76782.82 61935.13,76946.02 61695.65,77099.62 61465.76,77157.22 61274.18,77157.22 61226.28,77310.82 60986.81,77426.02 60766.49,77454.82 60565.33,77378.02 60584.49,77224.42 60661.12,77109.22 61053.86,77013.22 60958.07,76888.42 60843.12,76734.82 60843.12,76533.22 60689.86,76389.22 60622.8,76206.82 60479.12,76475.62 60201.33,76581.22 60134.28,76811.62 60230.07,76869.22 60239.64,77032.42 60402.49,77176.42 60316.28,77416.42 60115.12,77435.62 60105.54,77550.82 59952.27,77666.02 60000.17,77906.02 59875.64,77915.62 59760.69,77954.02 59760.69,78088.42 59866.06,78232.42 59799.01,78453.22 59856.48,78683.62 59971.43,78904.42' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: '', Notes: '', Id: 142, Pos: '62256.02,82562.02 62313.5,82763.62 62399.71,82715.62 62524.24,82725.22 62466.76,82610.02 62418.87,82581.22 62313.5,82552.42' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 15, Mix: 'Hispidus, Shinkiba', Notes: '', Id: 732, Pos: '21915,56223 22779,56223 23019,55593 23019,55049 22779,54569 22619,54521 22635,54233 22363,54105 21963,54153 22091,54553 21931,54697 21771,55273' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 40, Mix: '', Notes: '', Id: 765, Pos: '24872,57165 26280,57245 26920,57197 27400,57085 27496,56733 27352,56301 27080,55997 26792,55773 26344,55725 25624,55789 25544,55997 25384,56093 25400,56317 25224,56509 25048,56653 25000,56941' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 10, Mix: '', Notes: '', Id: 769, Pos: '30808,55037 31784,55101 32632,54829 32728,53773 32568,52813 31960,52701 30568,52701 30440,52861 30216,54125 30392,54509 30472,54909' },
      { Min: 'Prowler', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 90, Mix: 'Combibo, Mourner', Notes: '', Id: 772, Pos: '28216,51549 28568,51597 29416,51597 29432,51261 29544,50893 30152,50029 30040,49597 29688,49245 29112,49325 28696,49453 28392,49741 28184,50125 28024,50733 28040,51165' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Very Low', DensitySize: 15, Mix: 'Shinkiba, Trilomite', Notes: '', Id: 805, Pos: '29408,30608 31232,30800 31856,30224 31184,29024 30880,29344 30800,29728 29472,29328 29056,30192' },
      { Min: 'Old Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 835, Pos: '29670,40842 30198,40890 30806,40842 30902,40522 30822,39914 30854,39562 30694,39066 30918,38874 30230,38666 29622,38826 29430,39386 29670,39978 29462,40394' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: 'Plumatergus', Notes: '', Id: 878, Pos: '84754.32,97208.63 84957.88,97316.63 85065.64,97256.63 85041.69,97124.63 85221.3,97112.63 85496.7,97280.63 85999.59,97004.63 86143.28,96344.63 86059.46,96068.63 86131.3,95744.63 85808.01,95816.63 85293.14,95720.63 85233.27,95504.63 84898.01,95480.63 84802.22,95660.63 84826.17,95804.63 84646.56,96068.63 84131.69,96308.63 84227.48,96524.63 84131.69,96812.63 84502.87,97040.63 84766.3,97148.63' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Very Low', DensitySize: 15, Mix: 'Molisk', Notes: '', Id: 894, Pos: '24687.47,63162.51 24687.47,61338.51 24862.31,61367.31 24929.36,61136.91 25053.89,61021.71 25168.84,60983.31 25274.21,61021.71 25590.31,60877.71 25647.79,60810.51 25714.84,60829.71 25772.31,60877.71 25753.16,60973.71 25494.52,61098.51 25322.1,61223.31 25216.73,61453.71 25235.89,61607.31 25341.26,61760.91 25398.73,62068.11 25427.47,62384.91 25168.84,62567.31 25140.1,62778.51 25015.57,62893.71 24967.68,63018.51 24814.41,63152.91' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Very Low', DensitySize: 15, Mix: 'Molisk', Notes: '', Id: 900, Pos: '24693.99,58166.4 24703.57,57513.6 24828.1,57465.6 24885.57,57417.6 25115.47,57504 25259.15,57465.6 25105.89,57398.4 25096.31,57360 27222.85,57417.6 27069.58,57638.4 27088.74,57820.8 27242.01,57993.6 27462.32,58204.8 27452.74,58483.2 27548.53,58742.4 27701.8,58867.2 27606.01,58972.8 27481.48,58992 27289.9,58800 27031.27,58838.4 26830.11,58876.8 26418.21,59577.6 26274.53,59548.8 26188.32,59788.8 26226.63,59904 26159.58,60000 26035.05,60096 25939.26,60259.2 25833.89,60316.8 25728.52,60240 25671.05,60297.6 25565.68,60259.2 25556.1,60172.8 25402.84,60067.2 25431.58,59961.6 25642.31,59875.2 25680.63,59750.4 25345.36,59596.8 25153.78,59078.4 25278.31,58972.8 25326.21,58752 25479.47,58627.2 25872.21,58905.6 25776.42,58550.4 25584.84,58358.4 25297.47,58339.2 25163.36,58147.2 25067.57,58185.6 24981.36,58176 24923.89,58108.8 24799.36,58080' },
    ]
  },
  { // Big Bulk
    Name: 'Big Bulk',
    id: '80',
    notes: '',
    spawns: [
      { Min: 'Gen 01', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 50.2, Mix: '', Notes: '', Id: 143, Pos: '68068.9,75116.63 68200.61,75104.63 68356.27,74996.63 68320.35,74888.63 68212.59,74888.63 68080.88,74972.63' },
      { Min: 'Gen 02', Max: 'Gen 05', Maturity: 'small', Density: 'Medium', DensitySize: 50.1, Mix: '', Notes: '', Id: 144, Pos: '68392.19,75200.63 68428.11,75332.63 68607.72,75320.63 68763.38,75284.63 68775.35,75056.63 68487.98,75128.63' },
      { Min: 'Gen 09', Max: 'Gen 10', Maturity: 'big', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 145, Pos: '68452.06,75080.63 68715.48,74972.63 68691.54,74840.63 68452.06,74888.63' },
    ]
  },
  { // Boorum Female
    Name: 'Boorum Female',
    id: '100',
    notes: '',
    spawns: [
      { Min: 'Guard', Max: 'Champion', Maturity: 'mid', Density: 'Big', DensitySize: 70, Mix: 'Boorum Male', Notes: '', Id: 923, Pos: '30673.35,44205.94 31219.35,44167.54 31228.93,43581.94 31353.46,43409.14 31583.35,43370.74 32043.15,43581.94 32129.36,43572.34 32129.36,43447.54 31870.72,43207.54 31688.72,43178.74 31985.67,42977.14 31861.14,42823.54 31928.2,42631.54 31822.83,42449.14 31104.4,42401.14 30491.35,42410.74 30127.35,42506.74 29830.4,42794.74 30012.4,43044.34 30213.56,43159.54 30136.92,43332.34 30213.56,43821.94 30136.92,43946.74 30184.82,44013.94 30318.93,44013.94 30424.29,43879.54 30711.66,43985.14' },
    ]
  },
  { // Boorum Male
    Name: 'Boorum Male',
    id: '99',
    notes: '',
    spawns: [
      { Min: 'Guard', Max: 'Warlord', Maturity: 'mid', Density: 'Big', DensitySize: 70, Mix: 'Boorum Female', Notes: '', Id: 924, Pos: '30673.35,44205.94 31219.35,44167.54 31228.93,43581.94 31353.46,43409.14 31583.35,43370.74 32043.15,43581.94 32129.36,43572.34 32129.36,43447.54 31870.72,43207.54 31688.72,43178.74 31985.67,42977.14 31861.14,42823.54 31928.2,42631.54 31822.83,42449.14 31104.4,42401.14 30491.35,42410.74 30127.35,42506.74 29830.4,42794.74 30012.4,43044.34 30213.56,43159.54 30136.92,43332.34 30213.56,43821.94 30136.92,43946.74 30184.82,44013.94 30318.93,44013.94 30424.29,43879.54 30711.66,43985.14' },
    ]
  },
  { // Bristlehog
    Name: 'Bristlehog',
    id: '12',
    notes: '',
    spawns: [
      { Min: 'Pup', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 146, Pos: '36945.53,54268.8 37826.96,54297.6 37826.8,53836.8 37558.59,53779.2 37204.17,53913.6 36907.22,53904' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'mid', Density: 'Big', DensitySize: 85, Mix: '', Notes: '', Id: 147, Pos: '37855.54,54307.2 38344.07,54355.2 38344.07,53846.4 38200.38,53740.8 38142.91,53817.6 38142.91,53884.8 37932.17,53913.6 37884.28,53827.2 37845.96,53827.2' },
      { Min: 'Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 148, Pos: '38391.96,53280 38641.02,53289.6 38688.91,53155.2 38660.17,53020.8 38583.54,52953.6 38478.17,52800 38411.12,52646.4 38171.65,52502.4 37989.64,52704 38066.28,52857.6 38190.8,52915.2 38238.7,53011.2 38219.54,53088 38315.33,53126.4' },
      { Min: 'Pup', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 70, Mix: '', Notes: '', Id: 149, Pos: '61752.75,75188.63 61872.49,75236.63 61932.36,75152.63 62064.07,75488.63 62147.88,75428.63 62004.2,75068.63 61968.28,75068.63 61884.46,75116.63 61728.8,75080.63' },
      { Min: 'Pup', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 50.2, Mix: '', Notes: '', Id: 150, Pos: '63297.36,74384.63 63369.21,74408.63 63524.86,74336.63 63465,74216.63 63393.15,74180.63 63309.34,74252.63 63261.44,74312.63' },
      { Min: 'Pup', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 50.1, Mix: '', Notes: '', Id: 151, Pos: '68697.52,88664.63 68877.13,88760.63 69104.63,88436.63 69200.42,88232.63 69270.18,87968.63 69188.45,87620.63 68960.95,87416.63 68781.34,87320.63 68685.55,87560.63 68948.97,87800.63 68996.87,87968.63 68960.95,88256.63 68757.39,88472.63' },
      { Min: 'Pup', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 152, Pos: '62010.18,88092 62117.95,88236 62309.53,88320 62357.42,88128 62309.53,87924 62237.69,87732 61974.26,87840' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: 'Exarosaur, Hiryuu, Trilomite', Notes: '', Id: 153, Pos: '32875.65,49119.46 34306.87,49119.46 34374.48,48927.46 34182.9,48814.52 34002.59,48656.4 33811.01,48656.4 33506.74,48227.23 33258.81,47831.93 32988.35,47447.93 33055.96,47323.7 33010.89,47165.58 32875.65,47041.34' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 10, Mix: 'Throifoid Shaman, Trilomite', Notes: '', Id: 722, Pos: '36936,47024 37432,47152 37640,46720 38200,46720 38270,46208 38200,45616 37480,45312 37208,45392 37096,45648 36792,45712 36792,46592' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'small', Density: 'Low', DensitySize: 35, Mix: 'Araneatrox, Itumatrox', Notes: '', Id: 785, Pos: '16800,39780 17296,40084 18400,40500 19200,40452 19440,40644 20016,40724 20112,38628 20048,38516 19456,38500 19424,38564 19168,38564 19232,38452 19216,38132 19584,37924 19472,37300 18976,36644 18720,36740 18704,36948 18880,37156 18720,37236 17760,36804 17680,36868 17600,37060 17392,37092 17360,37428 17216,37348 17200,36980 16992,36820 16688,36532 16480,37028 16544,37364 16976,38100 17264,38244 17376,38548 17600,38740 17712,38980 17920,39252 17840,39412 17472,39332 17168,39508 16992,39540' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'small', Density: 'Medium', DensitySize: 40, Mix: 'Cersumon, Mermoth', Notes: '', Id: 788, Pos: '18080,36548 18288,36324 18256,35812 18064,35284 18240,35124 18336,34852 18832,34804 18992,34932 19536,34580 19888,34292 20112,34036 19920,33860 19632,34068 19408,33908 18688,34180 18224,34116 17968,34212 17648,34068 17296,34084 17056,34260 17008,34516 16672,34740 16576,35236 16784,35860 17376,36388' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Very Low', DensitySize: 15, Mix: '', Notes: '', Id: 929, Pos: '24712.62,46730.67 25542.8,46805.33 25670.52,46090.67 25830.17,45856 25596.01,45653.33 25127.71,45610.67 24712.62,45632' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Very Low', DensitySize: 3, Mix: 'Exarosaur, Hiryuu, Trilomite', Notes: '', Id: 966, Pos: '35725.9,49104.67 39193.49,49123.87 39193.49,49027.87 38235.59,48663.07 38149.38,47789.47 37814.12,47683.87 37785.38,47770.27 37794.96,47760.67 38024.86,47991.07 37852.43,48451.87 37086.11,48864.67 36367.69,48691.87 35955.79,48663.07 35725.9,48807.07 35697.16,48960.67' },
    ]
  },
  { // Calamusoid
    Name: 'Calamusoid',
    id: '810',
    notes: '',
    spawns: [
      { Min: '', Max: '', Maturity: 'mid', Density: 'Big', DensitySize: 80.2, Mix: '', Notes: '', Id: 154, Pos: '65548.43,75800.63 66195.01,75812.63 66410.54,75692.63 66494.35,75500.63 66494.35,75248.63 66506.33,74924.63 66506.33,74732.63 66470.41,74552.63 66266.85,74432.63 65548.43,74432.63' },
      { Min: 'Outcast', Max: 'Bandit', Maturity: 'small', Density: 'Big', DensitySize: 80.1, Mix: '', Notes: '', Id: 155, Pos: '68811.27,85040.63 69110.62,85028.63 69230.36,84920.63 69158.51,84812.63 68942.99,84764.63 68883.12,84584.63 68655.62,84584.63 68655.62,84452.63 68499.96,84524.63 68368.25,84440.63 68200.61,84560.63 68571.8,84860.63 68835.22,85052.63' },
      { Min: 'Bandit', Max: 'Elite Warlord', Maturity: 'mid', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 156, Pos: '67302.58,85196.63 67458.24,85376.63 67661.79,85376.63 67769.56,85292.63 67769.56,85172.63 67949.16,85028.63 68068.9,84584.63 68392.19,84380.63 68607.72,83924.63 68871.14,83804.63 68871.14,83528.63 68547.85,83540.63 68487.98,83648.63 67506.14,83708.63 67326.53,83888.63 67362.45,84248.63 67372.24,84824.63' },
    ]
  },
  { // Calypsocod
    Name: 'Calypsocod',
    id: '134',
    notes: '',
    spawns: [
      // Rippersnapper spawn event in same lake
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Low', DensitySize: 30.2, Mix: '', Notes: '', Id: 157, Pos: '60609.26,77336.63 60800.84,77408.63 61028.34,77372.63 61207.94,77276.63 61172.02,77120.63 61016.36,77048.63 60681.1,77120.63 60609.26,77228.63' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: 'Razortooth, Rippersnapper', Notes: '', Id: 159, Pos: '57759.5,70092 58154.64,69888 58334.24,69816 58621.61,69888 58477.93,69444 58729.38,69204 58609.64,68820 58825.17,68676 59028.72,68856 59316.09,68880 59531.62,68616 59735.17,68808 59699.25,69036 59986.62,69384 60178.2,69240 60429.65,69216 60717.02,69312 61195.97,69048 61339.65,68712 61387.55,68292 61243.86,67860 60968.47,67536 60788.86,67380 60824.78,67200 61112.15,67236 60800.84,66864 61064.26,66732 61555.18,67164 61615.05,67344 61830.58,67260 61914.39,67056 61734.79,66924 61686.89,66612 61986.24,66300 62417.29,65964 62728.61,65844 62513.08,65628 62070.05,65664 61698.87,65964 61375.58,66228 61100.18,66372 60657.15,66432 60477.54,66324 60489.52,66012 60250.04,66048 60190.17,66384 60010.57,66456 59603.46,66384 59543.59,66564 59914.78,66780 60250.04,66732 60321.89,66984 60226.1,67716 60058.46,67764 59890.83,67656 59723.2,67812 59807.01,68016 59699.25,68256 59411.88,68448 59064.64,68352 58789.25,68388 58813.19,68172 58669.51,68112 58406.09,68184 58202.53,68388 58082.79,68688 58118.72,69072 57915.16,69312 57579.9,69720 57448.19,69924' },
      { Min: 'Provider', Max: 'Old Alpha', Maturity: 'mid', Density: 'Low', DensitySize: 20, Mix: 'Razortooth, Rippersnapper, Sonic Squid', Notes: '', Id: 945, Pos: '40730.98,53892.65 40970.46,53883.05 40932.14,49630.25 39571.92,50215.85 39456.97,50379.05 39524.03,50475.05 39495.29,50619.05 39437.82,50686.25 39638.97,50955.05 40070.03,50763.05 40204.14,50830.25 40213.71,50964.65 40156.24,51079.85 40261.61,51483.05 40242.45,51732.65 40309.5,51828.65 40290.35,52039.85 40395.72,52212.65 40386.14,52395.05 40299.93,52519.85 40165.82,52635.05 40175.4,52702.25 40242.45,52875.05 40165.82,53019.05 40242.45,53086.25 40376.56,53086.25 40395.72,53163.05 40405.29,53278.25 40510.66,53508.65 40491.51,53643.05 40548.98,53719.85 40711.82,53911.85 40740.56,54075.05' },
    ]
  },
  { // Caudatergus
    Name: 'Caudatergus',
    id: '15',
    notes: '',
    spawns: [
      { Min: 'Mature', Max: 'Prowler', Maturity: 'mid', Density: 'Big', DensitySize: 85, Mix: '', Notes: '', Id: 160, Pos: '74672.43,81900 75235.19,81852 75259.14,81492 75414.8,81396 75295.06,81144 75450.72,80760 75953.62,80376 76097.3,80172 76312.83,80136 76456.51,80232 76516.38,80412 76767.83,80400 77007.31,80364 77150.99,80076 77079.15,79896 76815.73,79752 76588.23,79788 76576.25,79644 76468.49,79656 76300.86,79800 75881.77,79740 75797.96,79572 75594.4,79584 75426.77,79740 75259.14,79788 75378.88,80076 75223.22,80184 75091.51,80040 74947.82,80220 75043.61,80376 75127.43,80544 75007.69,80724 74828.08,80784 74732.29,80580 74564.66,80328 74385.05,80340 74253.34,80448 74193.48,80748 74301.24,80964 74301.24,81144 74468.87,81216 74444.92,81444 74576.64,81672' },
      { Min: 'Mature', Max: 'Guardian', Maturity: 'small', Density: 'Big', DensitySize: 80, Mix: 'Berycled', Notes: '', Id: 161, Pos: '88837.37,95672.63 88945.14,95744.63 89112.77,95732.63 89268.43,95576.63 89328.29,95156.63 89400.14,94784.63 89471.98,94472.63 89352.24,94064.63 89088.82,94184.63 88921.19,94172.63 88945.14,94556.63 88777.5,94688.63 88657.76,94988.63 88693.69,95396.63 88801.45,95588.63' },
      { Min: 'Old', Max: 'Alpha', Maturity: 'mid', Density: 'Big', DensitySize: 78, Mix: 'Merp', Notes: '', Id: 162, Pos: '74490.42,78345.6 75582.43,78854.4 75716.54,79008 75927.27,79046.4 76300.86,79200 76358.33,79372.8 76607.38,79488 76961.81,78998.4 76664.86,78864 76339.17,78566.4 76147.59,78201.6 75984.75,78067.2 75754.85,78115.2 75639.9,78297.6 75371.69,78288 75189.69,78201.6 75160.95,77942.4 75189.69,77740.8 75390.85,77616 75228.01,77481.6 75036.43,77433.6 74758.64,77596.8 74710.74,77779.2 74710.74,77932.8 74634.11,78028.8 74500,78009.6 74423.37,78153.6' },
      { Min: 'Guardian', Max: 'Alpha', Maturity: 'mid', Density: 'Medium', DensitySize: 50.6, Mix: 'Cornundacauda', Notes: '', Id: 163, Pos: '68224.56,86840.63 68356.27,87068.63 68487.98,86864.63 68751.41,86696.63 68931.01,86408.63 69038.78,86216.63 68476.01,86216.63 68344.3,86516.63 68248.51,86612.63 68080.88,86696.63 68176.67,86792.63' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50.5, Mix: 'Cornundos', Notes: '', Id: 164, Pos: '67458.24,87728.63 67625.87,87752.63 67805.48,87548.63 67889.3,87344.63 68104.82,87260.63 68248.51,87080.63 68009.03,86792.63 68009.03,86648.63 68176.67,86552.63 68032.98,86360.63 67661.79,86516.63 67146.92,86996.63 67362.45,86984.63 67446.27,87116.63 67350.48,87344.63 67506.14,87452.63 67470.21,87656.63' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Medium', DensitySize: 50.4, Mix: '', Notes: '', Id: 165, Pos: '69314.17,76124.63 69505.75,76328.63 69984.7,76520.63 70020.62,76712.63 70152.33,76808.63 70355.89,76712.63 70272.07,76316.63 70403.78,76088.63 70523.52,75560.63 70679.18,75644.63 70727.07,75440.63 70631.28,75248.63 70607.34,75008.63 70739.05,74840.63 70798.92,75176.63 71014.44,75044.63 71182.08,75020.63 71301.81,74384.63 71289.84,73760.63 69721.28,73760.63 69733.25,74096.63 70104.44,74108.63 70331.94,74288.63 70355.89,74564.63 70236.15,74660.63 70331.94,74732.63 70355.89,75008.63 70248.12,75044.63 70056.54,74912.63 69829.04,74888.63 69374.04,74840.63 69254.3,74792.63 69218.38,74912.63 69374.04,75056.63 69637.46,75188.63 69829.04,75272.63 69841.02,75380.63 69948.78,75488.63 69996.68,75704.63 69852.99,75896.63 69781.15,75848.63 69709.31,75620.63 69314.17,75656.63 69314.17,75920.63' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Medium', DensitySize: 50.3, Mix: 'Cornundos', Notes: '', Id: 166, Pos: '72068.13,73692 72726.69,73716 72738.66,73620 72630.9,73584 72738.66,73428 72918.27,73404 73133.8,73572 73097.88,73332 73157.75,73224 73289.46,73368 73433.14,73512 73576.83,73548 73696.56,73296 73660.64,72312 73576.83,72216 73433.14,72216 73397.22,72372 73421.17,72564 73289.46,72636 73193.67,72552 73049.98,72576 73157.75,72804 73361.3,72948 73540.91,72996 73600.77,73056 73504.98,73212 73337.35,73092 73157.75,72960 73097.88,72792 72870.38,72768 72618.93,72732 72547.08,72792 72571.03,72936 72415.37,73104 72151.95,73020 72128,73200 72020.24,73536' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Medium', DensitySize: 50.2, Mix: 'Merp', Notes: '', Id: 167, Pos: '71396.83,70506.78 71444.72,70573.98 71550.09,70564.38 71588.41,70449.18 71684.2,70420.38 71684.2,70468.38 71770.41,70477.98 72038.62,70324.38 72086.52,70170.78 71971.57,70151.58 72048.2,69940.38 72096.09,69805.98 72230.2,69892.38 72316.41,69777.18 72249.36,69623.58 72086.52,69585.18 72019.46,69594.78 71914.09,69748.38 71894.94,69834.78 71779.99,69921.18 71655.46,69930.78 71760.83,70093.98 71722.51,70199.58 71435.14,70333.98' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50.1, Mix: 'Cornundos, Merp', Notes: '', Id: 168, Pos: '73750.45,80412 74169.53,80388 74528.74,80136 74576.64,79788 74720.32,79608 74708.35,79236 74636.5,78972 74816.11,78588 74432.95,78384 74373.08,78156 73750.45,78240' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: 'Atrax, Molisk, Snablesnot Female, Snablesnot Male', Notes: '', Id: 169, Pos: '81899.78,84585.6 82838.52,84595.2 83116.31,84480 83231.26,84316.8 83250.42,84057.6 83164.21,83865.6 82953.47,83577.6 83030.1,83270.4 83192.95,83088 83240.84,82896 83374.95,82771.2 83231.26,82560 83384.53,82368 83844.32,82252.8 84074.21,82166.4 84227.48,82080 84304.11,82147.2 84390.32,82137.6 84438.22,82080 84428.64,81955.2 81899.78,81955.2' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Low', DensitySize: 30.2, Mix: 'Berycled, Daikiba', Notes: '', Id: 170, Pos: '64698.29,77084.63 64925.79,77072.63 65021.58,76952.63 65332.9,76940.63 65524.48,76868.63 65512.51,74504.63 65392.77,74564.63 65296.98,74408.63 65045.53,74396.63 64961.71,74324.63 64794.08,74228.63 64794.08,74384.63 64614.48,74360.63 64506.71,74492.63 64506.71,75320.63 64638.42,75296.63 64758.16,75356.63 64818.03,75428.63 64985.66,75428.63 64973.69,75560.63 64806.06,75668.63 64758.16,75824.63 64602.5,75884.63 64506.71,75764.63 64470.79,75956.63 64327.11,76160.63 64267.24,76400.63 64446.84,76676.63 64482.76,76808.63 64602.5,76988.63' },
      { Min: 'Puny', Max: '', Maturity: 'small', Density: 'Low', DensitySize: 30.1, Mix: '', Notes: '', Id: 171, Pos: '63201.57,74696.63 63453.02,74816.63 63620.65,74924.63 63740.39,75068.63 63884.08,75032.63 64015.79,74972.63 64003.81,74828.63 63848.16,74804.63 63740.39,74816.63 63608.68,74696.63 63405.13,74648.63 63393.15,74468.63 63249.47,74456.63 63177.63,74576.63' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: 'Armax Bull, Atrax, Atrox, Hiryuu, Molisk', Notes: '', Id: 172, Pos: '81899.78,78192 82206.31,78192 82369.15,78086.4 82369.15,75350.4 82254.2,75158.4 82263.78,74928 81918.94,74918.4' },
      { Min: 'Old', Max: 'Guardian', Maturity: 'small', Density: 'Very Low', DensitySize: 5, Mix: 'Atrox, Falxangius', Notes: '', Id: 822, Pos: '39286,35338 40022,35466 40886,35290 40886,33162 40838,32874 39846,32874 39334,32938 38182,32858 37238,33322 37526,34058 37814,33818 39110,33882 39302,34010 39638,34026 39670,34698' },
      { Min: 'Dominant', Max: 'Stalker', Maturity: 'small', Density: 'Big', DensitySize: 95, Mix: 'Formidon, Neconu, Tantillion', Notes: 'Nort-East side', Id: 850, Pos: '38742,43562 39910,43306 40054,42474 40166,42394 40118,42138 39974,42026 39926,41626 39686,40986 38182,41050 37798,41498 37862,42554 38342,43370' },
    ]
  },
  { // Caperon
    Name: 'Caperon',
    id: '90',
    notes: '',
    spawns: [
      { Min: 'Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Medium', DensitySize: 95, Mix: '', Notes: '', Id: 173, Pos: '79312.25,69672 79455.94,69876 79731.33,69912 79970.81,69828 79994.76,69672 79946.86,69468 79803.18,69312 79563.7,69300 79312.25,69612' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 174, Pos: '71493.39,72564 71852.61,72864 71972.34,72816 71936.42,72708 72259.71,72540 72427.35,72252 72187.87,71952 71972.34,71988 71684.97,72216 71553.26,72456' },
      { Min: 'Guardian', Max: 'Dominant', Maturity: 'mid', Density: 'Big', DensitySize: 85, Mix: '', Notes: '', Id: 175, Pos: '79563.7,70224 79875.02,70344 80270.15,70584 80473.71,70560 80569.5,70320 80557.52,69720 80258.18,69408 80066.6,69252 79719.36,69180 79396.07,69144 79168.57,69072 79084.75,68904 78581.85,68988 78569.88,69156 78486.06,69456 78545.93,69552 78749.49,69588 79264.36,69588 79539.75,69276 79827.12,69288 79958.84,69444 80030.68,69684 79994.76,69852 79731.33,69960 79431.99,69924' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 176, Pos: '66644.03,81884.63 68452.06,81824.63 68631.67,81548.63 68655.62,81320.63 68356.27,81296.63 68320.35,81176.63 68200.61,81176.63 68164.69,81416.63 67925.22,81500.63 67805.48,81548.63 67721.66,81524.63 67446.27,81380.63 67314.56,81416.63 67302.58,81008.63 67542.06,80900.63 67805.48,81056.63 67973.11,80960.63 68260.48,81056.63 68500.22,81020.63 68523.9,80708.63 68487.98,80516.63 68332.32,80456.63 67961.14,80576.63 67757.58,80528.63 67685.74,80696.63 67506.14,80696.63 67518.11,80456.63 67314.56,80372.63 67314.56,80528.63 67146.92,80756.63 66931.4,80888.63 66775.74,81524.63' },
      { Min: 'Mature', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 70, Mix: '', Notes: '', Id: 935, Pos: '71334.14,74947.2 71621.51,74947.2 71736.46,74860.8 71880.15,74947.2 71832.25,75187.2 71947.2,75254.4 72129.2,75158.4 72416.57,75177.6 72579.41,75081.6 72675.2,74851.2 72100.46,74774.4 71774.78,74611.2 71592.78,74409.6 71496.99,74524.8 71516.14,74668.8 71391.62,74764.8' },
      { Min: 'Old', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 70, Mix: '', Notes: '', Id: 936, Pos: '72234.57,74745.6 72665.62,74793.6 72962.57,74640 73115.84,74649.6 73355.31,74774.4 73518.16,74812.8 73537.31,74630.4 73125.42,74515.2 72790.15,74505.6 72790.15,74323.2 73010.47,74236.8 73412.79,74332.8 73556.47,74313.6 73575.63,74054.4 73345.73,73958.4 73087.1,74140.8 72876.36,74131.2 72541.1,74208 72464.47,74419.2 72483.62,74563.2 72339.94,74630.4' },
    ]
  },
  { // Cersumon
    Name: 'Cersumon',
    id: '16',
    notes: '',
    spawns: [
      { Min: 'Old Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 178, Pos: '38955.21,61777.02 39376.68,61777.02 39664.05,61681.02 40143,61239.42 40066.37,61181.82 39836.48,61277.82 39644.9,61047.42 39549.11,61085.82 39280.89,61258.62 39472.47,61316.22 39529.95,61412.22 39012.68,61700.22 38878.58,61661.82' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 177, Pos: '39980.16,60884.22 40248.37,60903.42 40420.79,60538.62 40478.27,60173.82 40248.37,60116.22 40229.21,60269.82 40210.06,60404.22 40018.48,60500.22 39941.84,60596.22' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Low', DensitySize: 25, Mix: 'Bristlehog, Mermoth', Notes: '', Id: 787, Pos: '18080,36548 18288,36324 18256,35812 18064,35284 18240,35124 18336,34852 18832,34804 18992,34932 19536,34580 19888,34292 20112,34036 19920,33860 19632,34068 19408,33908 18688,34180 18224,34116 17968,34212 17648,34068 17296,34084 17056,34260 17008,34516 16672,34740 16576,35236 16784,35860 17376,36388' },
      { Min: 'Prowler', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 812, Pos: '36422,28282 38358,29114 39926,28938 39526,27354 39094,27610 38838,27370 37318,27002 37318,26778 36886,26586 36502,27082' },
    ]
  },
  { // Chirpy
    Name: 'Chirpy',
    id: '17',
    notes: '',
    spawns: [
      { Min: 'Old Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Medium', DensitySize: 50, Mix: 'Fugabarba, Gibnib, Turp', Notes: '', Id: 179, Pos: '77838.28,72748.8 78106.5,72777.6 78336.39,72777.6 78422.6,72672 78422.6,72518.4 78403.45,72403.2 78288.5,72297.6 78068.18,72249.6 78049.02,72307.2 77924.5,72403.2 77838.28,72345.6 77809.55,72547.2 77780.81,72652.8' },
      { Min: 'Provider', Max: 'Old Alpha', Maturity: 'mid', Density: 'Low', DensitySize: 30.1, Mix: 'Fugabarba, Gibnib, Turp', Notes: '', Id: 180, Pos: '79132.65,68892 79192.52,69036 79408.04,69120 80114.49,69252 80605.42,69744 80617.39,70320 80749.1,70332 80808.97,70200 81000.55,70008 80964.63,69624 80773.05,69252 80737.13,69024 80737.13,68808 80246.21,69084 79982.78,69048 79994.76,68784 80066.6,68652 80006.73,68556 79755.28,68688 79707.39,68640 79527.78,68592 79264.36,68652 79084.75,68772' },
      { Min: 'Dominant', Max: 'Stalker', Maturity: 'big', Density: 'Low', DensitySize: 30, Mix: 'Gibnib, Prancer', Notes: '', Id: 181, Pos: '52353.36,60458.67 52651.37,60608 52811.02,60586.67 52960.03,60117.33 52874.88,59978.67 52534.29,60117.33 52257.57,60224' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Very Low', DensitySize: 15.1, Mix: 'Fugabarba, Gibnib, Turp', Notes: '', Id: 182, Pos: '79428.4,68534.4 79591.24,68534.4 79773.24,68620.8 80031.88,68486.4 80098.93,68572.8 80654.51,68409.6 80587.46,68342.4 80405.46,68371.2 80405.46,68227.2 80529.98,68217.6 80510.83,68064 80347.98,67881.6 80156.4,67939.2 80070.19,67881.6 79888.19,67939.2 79687.03,67910.4 79562.5,67824 79447.56,67977.6 79524.19,68044.8 79495.45,68150.4 79514.61,68265.6 79409.24,68284.8' },
      { Min: 'Guardian', Max: 'Alpha', Maturity: 'mid', Density: 'Very Low', DensitySize: 15, Mix: 'Cornundacauda, Sabakuma', Notes: '', Id: 183, Pos: '62904.62,81974.4 62923.78,82742.4 62665.15,82876.8 62674.73,83020.8 63000.41,83107.2 63278.2,83107.2 63498.52,83203.2 63977.47,83328 64638.42,83568 64839.58,83635.2 65050.32,83894.4 65500.53,83875.2 65490.95,81964.8 64772.53,81993.6 64782.11,82080 65050.32,82454.4 64992.85,82684.8 64695.9,82617.6 64216.95,81993.6' },
      { Min: 'Mature', Max: 'Dominant', Maturity: 'small', Density: 'Very Low', DensitySize: 15, Mix: 'Daikiba, Hiryuu, Thorio Female, Thorio Male', Notes: '', Id: 361, Pos: '55088.69,64746.67 55461.21,64586.67 56163.67,64586.67 56397.82,64405.33 56461.68,64277.33 56387.18,63968 56238.17,63904 56238.17,63797.33 56355.25,63605.33 56142.38,63552 55929.51,63584 55950.8,63253.33 55940.16,63093.33 55801.8,63146.67 55684.72,63413.33 55578.29,63808 55663.43,63936 55663.43,64181.33 55780.51,64266.67 55695.36,64384 55610.22,64384 55546.36,64277.33 55290.92,64234.67 55280.27,64021.33 55322.85,63850.67 55493.14,63808 55525.07,63562.67 55407.99,63509.33 55205.77,63573.33 55109.98,63680 54929.04,64181.33 54939.69,64533.33' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Very Low', DensitySize: 5, Mix: 'Atrax, Mermoth', Notes: '', Id: 909, Pos: '53309.34,62305.02 53673.34,62228.22 54094.82,62343.42 54286.4,62247.42 54420.5,62093.82 54631.24,62113.02 54822.82,61921.02 54497.14,61729.02 54324.71,61805.82 54075.66,61633.02 53769.13,61709.82 53443.45,61575.42 53232.71,61575.42 53232.71,61767.42 53405.13,62074.62' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Low', DensitySize: 15, Mix: '', Notes: '', Id: 974, Pos: '28169.88,29447.16 28595.61,29937.83 29035.86,30204.38 29127.78,30172.49 29532.22,29276.49 29510.94,29041.83 29404.5,28913.83 29234.21,28935.16 28808.48,29041.83 28382.74,29297.83' },
    ]
  },
  { // Chirin Dragon
    Name: 'Chirin Dragon',
    id: '551',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Sapphire', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 184, Pos: '59974.65,70236 60501.49,70008 60860.7,70308 61028.34,70512 60872.68,70620 60944.52,70836 60645.18,70980 60453.6,70980 60202.15,70836 60238.07,70548 60034.52,70392' },
    ]
  },
  { // Chomper
    Name: 'Chomper',
    id: '96',
    notes: '',
    spawns: [
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 5, Mix: '', Notes: '', Id: 728, Pos: '22091,64169 22235,64169 22891,63689 23067,63049 23067,62921 22971,62777 23163,62617 23035,62489 22715,62441 22603,62233 22379,62313 22203,62265 22139,61993 21755,61993 21435,62377 21195,62553 21195,63065 21291,63481 21435,63689 21755,63785' },
    ]
  },
  { // Corinthian Kanin
    Name: 'Corinthian Kanin',
    id: '548',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Silver', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 185, Pos: '59938.73,70212 60262.02,70092 60501.49,69948 60645.18,69636 60645.18,69372 60453.6,69276 60202.15,69288 60034.52,69420 59974.65,69648 59902.8,69924 59783.07,70020' },
    ]
  },
  { // Cornoanterion
    Name: 'Cornoanterion',
    id: '85',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 186, Pos: '36466.58,52483.2 36945.53,52483.2 37031.74,52329.6 37223.32,52195.2 37223.32,51734.4 36945.53,51744 36811.43,51667.2 36495.32,51753.6 36399.53,51936 36313.32,52099.2 36342.06,52204.8' },
      { Min: 'Prowler', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 187, Pos: '35853.53,52656 35987.63,52694.4 36207.95,52713.6 36361.21,52876.8 36437.85,52876.8 36457,52800 36418.69,52723.2 36457,52608 36246.27,52099.2 36246.27,52022.4 36169.63,51955.2 35949.32,51936 35796.05,51984 35681.11,52080 35805.63,52320 35843.95,52521.6' },
      { Min: 'Provider', Max: 'Alpha', Maturity: 'mid', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 188, Pos: '35786.47,51926.4 36083.42,51936 36275,51984 36389.95,51792 36409.11,51619.2 36342.06,51427.2 36236.69,51340.8 36102.58,51302.4 35958.9,51292.8 35843.95,51283.2 35786.47,51340.8' },
    ]
  },
  { // Combibo
    Name: 'Combibo',
    id: '18',
    notes: '',
    spawns: [
      { Min: 'Old', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 80, Mix: 'Exarosaur', Notes: '', Id: 189, Pos: '66596.13,74828.63 66931.4,74816.63 66955.34,74804.63 67111,74816.63 67134.95,74876.63 67422.32,74840.63 67613.9,74864.63 67757.58,74804.63 67589.95,74528.63 67482.19,74348.63 67230.74,74276.63 67122.98,74432.63 66907.45,74504.63 66763.76,74456.63 66560.21,74552.63' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'small', Density: 'Medium', DensitySize: 50.4, Mix: '', Notes: '', Id: 190, Pos: '60908.6,78548.63 61100.18,78452.63 61243.86,78320.63 61243.86,78164.63 61219.92,78068.63 61052.28,78068.63 60920.57,78164.63 60872.68,78284.63 60872.68,78440.63' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50.3, Mix: 'Shinkiba', Notes: '', Id: 191, Pos: '77426.39,96000 77689.81,96000 78156.79,96024 78216.65,95652 78120.87,95436 77965.21,95220 77893.36,95016 77845.47,94728 77773.63,94620 77402.44,94884 77294.68,94860 77258.76,94728 77270.73,94368 77115.07,94464 77043.23,94512 76851.65,94476 76803.75,94536 76923.49,94608 77091.12,94692 77186.91,94932 77043.23,95172 76959.41,95496 76791.78,95640 76923.49,95700 77198.89,95640 77234.81,95844' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Medium', DensitySize: 50.2, Mix: 'Exarosaur', Notes: '', Id: 192, Pos: '76612.17,75096 76803.75,75072 76899.54,75144 76995.33,75144 77103.1,74916 77390.47,74868 77390.47,74484 77390.47,73836 76264.93,73836 76276.91,74784 76372.7,74880 76468.49,75000' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Medium', DensitySize: 50.1, Mix: 'Exarosaur', Notes: '', Id: 193, Pos: '67495.74,73336.42 67601.11,73384.42 67783.11,73499.62 67888.48,73432.42 67917.21,73278.82 68185.43,73019.62 68041.74,72837.22 68070.48,72530.02 68051.32,72328.42 67878.9,72318.82 67696.9,72376.42 67629.84,72568.42 67514.9,72683.62 67217.95,72856.42 67285,72971.62 67467,73115.62' },
      { Min: 'Young', Max: 'Brood Mother', Maturity: 'mid', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 194, Pos: '87051.85,77367.56 87175.81,77378.85 87288.5,77322.38 87322.31,77107.79 87311.04,76994.85 87265.97,76904.5 87153.27,76915.79 87142,77000.5 86995.5,77113.44 87006.77,77260.26' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Low', DensitySize: 30.2, Mix: 'Exarosaur', Notes: '', Id: 195, Pos: '78575.87,97100.63 78731.53,97136.63 78839.29,96992.63 78851.26,96824.63 78755.47,96704.63 78587.84,96632.63 78527.97,96884.63' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Low', DensitySize: 30.1, Mix: 'Feffoid', Notes: '', Id: 196, Pos: '80048.64,78528 81497.46,78528 81497.46,76344 81353.78,76392 81234.04,76392 81174.17,76572 80958.64,76440 80575.48,76416 80324.04,76428 80096.53,76500 80048.64,76956' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: 'Maffoid', Notes: '', Id: 197, Pos: '75283.09,89952 78025.08,89844 78108.89,89676 78240.6,89568 78204.68,89196 78061,89124 77773.63,89136 77605.99,89004 75330.98,89076 75283.09,89820' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 15, Mix: 'Cornundos, Repesilex', Notes: '', Id: 734, Pos: '19259,55545 20907,55577 21307,54089 21179,53721 19595,53721 19035,54137 18891,54297 18891,54841 19243,55161' },
      { Min: 'Prowler', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 90, Mix: 'Berycled, Mourner', Notes: '', Id: 773, Pos: '28216,51549 28568,51597 29416,51597 29432,51261 29544,50893 30152,50029 30040,49597 29688,49245 29112,49325 28696,49453 28392,49741 28184,50125 28024,50733 28040,51165' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: 'Exarosaur, Merp, Snablesnot Female, Snablesnot Male', Notes: '', Id: 937, Pos: '71774.78,76963.2 71975.94,76896 72368.68,77088 72694.36,76732.8 72838.05,76569.6 72857.2,76224 72818.89,75964.8 72723.1,75888 72770.99,75696 72742.26,75494.4 72579.41,75312 72445.31,75177.6 72148.36,75177.6 72071.73,75244.8 72090.88,75369.6 71851.41,75600 71688.57,75676.8 71449.09,75504 71190.46,75532.8 71027.62,75753.6 71027.62,75955.2 71161.72,76118.4 71382.04,76032 71449.09,75801.6 71544.88,75849.6 71535.3,76089.6 71592.78,76281.6 71573.62,76425.6 71391.62,76406.4 71190.46,76569.6 71276.67,76656 71573.62,76579.2 71573.62,76761.6 71602.36,76934.4' },
    ]
  },
  { // Cornundos
    Name: 'Cornundos',
    id: '20',
    notes: '',
    spawns: [
      { Min: 'Mature', Max: 'Dominant', Maturity: 'small', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 198, Pos: '57424.24,83852.63 57651.74,83864.63 57795.42,83732.63 58010.95,83612.63 57951.08,83480.63 57891.21,83288.63 57627.79,83096.63 57555.95,83276.63 57579.9,83492.63 57448.19,83576.63' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50.6, Mix: 'Berycled', Notes: '', Id: 199, Pos: '65919.61,77408.63 66135.14,77420.63 66386.59,77408.63 66530.27,77540.63 66721.86,77480.63 66913.43,77336.63 67200.8,77072.63 66769.75,76964.63 66685.93,77060.63 66650.01,77228.63 66518.3,77276.63 66266.85,77240.63 66111.19,77252.63 65943.56,77156.63' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50.5, Mix: 'Caudatergus', Notes: '', Id: 200, Pos: '67458.24,87728.63 67625.87,87752.63 67805.48,87548.63 67889.3,87344.63 68104.82,87260.63 68248.51,87080.63 68009.03,86792.63 68009.03,86648.63 68176.67,86552.63 68032.98,86360.63 67661.79,86516.63 67146.92,86996.63 67362.45,86984.63 67446.27,87116.63 67350.48,87344.63 67506.14,87452.63 67470.21,87656.63' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50.4, Mix: 'Berycled', Notes: '', Id: 201, Pos: '65542.44,78860.63 65949.55,78932.63 66129.15,78932.63 66105.21,78668.63 66236.92,78596.63 66272.84,78452.63 66141.13,78404.63 66033.36,78368.63 65973.5,78104.63 65889.68,77996.63 65554.41,77948.63' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50.2, Mix: 'Molisk', Notes: '', Id: 202, Pos: '67027.19,76076.63 67338.5,76136.63 67422.32,76052.63 67613.9,76112.63 67721.66,76304.63 67937.19,76196.63 68068.9,76028.63 68152.72,75728.63 68416.14,75608.63 68404.17,75344.63 68176.67,75224.63 67757.58,75152.63 67302.58,75260.63 67218.77,75644.63' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Medium', DensitySize: 50.1, Mix: 'Caudatergus', Notes: '', Id: 203, Pos: '72068.13,73692 72726.69,73716 72738.66,73620 72630.9,73584 72738.66,73428 72918.27,73404 73133.8,73572 73097.88,73332 73157.75,73224 73289.46,73368 73433.14,73512 73576.83,73548 73696.56,73296 73660.64,72312 73576.83,72216 73433.14,72216 73397.22,72372 73421.17,72564 73289.46,72636 73193.67,72552 73049.98,72576 73157.75,72804 73361.3,72948 73540.91,72996 73600.77,73056 73504.98,73212 73337.35,73092 73157.75,72960 73097.88,72792 72870.38,72768 72618.93,72732 72547.08,72792 72571.03,72936 72415.37,73104 72151.95,73020 72128,73200 72020.24,73536' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: 'Caudatergus, Merp', Notes: '', Id: 204, Pos: '73750.45,80412 74169.53,80388 74528.74,80136 74576.64,79788 74720.32,79608 74708.35,79236 74636.5,78972 74816.11,78588 74432.95,78384 74373.08,78156 73750.45,78240' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Medium', DensitySize: 50.3, Mix: 'Molisk', Notes: '', Id: 205, Pos: '68404.17,76388.63 68643.64,76400.63 68919.04,76244.63 68907.06,76004.63 69254.3,75956.63 69218.38,75692.63 69002.85,75644.63 68942.99,75500.63 68799.3,75404.63 68703.51,75464.63 68715.48,75692.63 68655.62,75788.63 68464.04,75884.63 68200.61,75920.63 68212.59,76016.63 68308.38,76088.63 68356.27,76232.63' },
      { Min: 'Mature', Max: 'Dominant', Maturity: 'small', Density: 'Low', DensitySize: 30.2, Mix: 'Mourner', Notes: '', Id: 206, Pos: '57687.66,82892.63 57879.24,82976.63 58118.72,82808.63 58334.24,82592.63 58645.56,82592.63 59004.77,82436.63 59304.12,82448.63 59747.15,82364.63 60022.54,82328.63 60046.49,82112.63 59938.73,81932.63 58286.35,81944.63 57903.19,82208.63 57903.19,82484.63' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Low', DensitySize: 30.1, Mix: 'Berycled', Notes: '', Id: 207, Pos: '57998.98,84500.63 58262.4,84716.63 58489.9,84692.63 58717.4,84908.63 58980.83,84920.63 59184.38,84704.63 59411.88,84728.63 59387.93,84584.63 59507.67,84488.63 59328.06,84188.63 59076.62,84212.63 58741.35,84164.63 58453.98,84476.63' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: '', Notes: '', Id: 208, Pos: '59615.43,84372 59926.75,84264 60309.91,84408 60525.44,84444 60657.15,84360 60585.31,84288 60273.99,84168 60130.31,83856 60190.17,83352 60297.94,83076 60429.65,82908 60597.28,82812 60573.33,82668 60333.86,82692 60142.28,83004 59986.62,83280 59938.73,83580 59890.83,83832 59735.17,84012 59615.43,84108 59531.62,84192' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Very Low', DensitySize: 15, Mix: 'Combibo, Repesilex', Notes: '', Id: 735, Pos: '19259,55545 20907,55577 21307,54089 21179,53721 19595,53721 19035,54137 18891,54297 18891,54841 19243,55161' },
    ]
  },
  { // Cornundacauda
    Name: 'Cornundacauda',
    id: '19',
    notes: '',
    spawns: [
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 216, Pos: '36332.48,54268.8 36840.16,54240 36821.01,53942.4 36342.06,53952' },
      { Min: 'Provider', Max: 'Alpha', Maturity: 'mid', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 215, Pos: '36351.64,53913.6 36792.27,53913.6 36782.69,53846.4 36696.48,53721.6 36658.16,53539.2 36686.9,53395.2 36629.43,53241.6 36581.53,53116.8 36361.21,53174.4' },
      { Min: 'Dominant', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 217, Pos: '36610.27,52963.2 36706.06,52992 36792.27,52896 36907.22,52905.6 36993.43,52790.4 36935.95,52636.8 36868.9,52560 36524.06,52560 36514.48,52656 36495.32,52752 36543.22,52886.4' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 214, Pos: '81224,87370 81263,87079 81302,86998 81324,86685 81865,86465 81858,87958 81680,88082 81462,88115 81244,87917' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Big', DensitySize: 81, Mix: 'Prancer', Notes: '', Id: 209, Pos: '81868.65,97112.63 82191.94,97148.63 82527.21,97052.63 82982.21,97052.63 83257.6,96932.63 83449.18,96716.63 83425.24,96272.63 83221.68,95804.63 83221.68,95684.63 83101.95,95516.63 82958.26,95504.63 82694.84,95264.63 82766.68,94904.63 82694.84,94808.63 82766.68,94688.63 82742.73,94364.63 82634.97,94280.63 82646.94,94148.63 82718.79,94052.63 82718.79,93848.63 82634.97,93740.63 82563.13,93848.63 82323.65,93824.63 82072.2,93896.63 82060.23,94028.63 81844.7,94172.63' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Big', DensitySize: 80.9, Mix: '', Notes: '', Id: 210, Pos: '85508.67,95672.63 85808.01,95756.63 86143.28,95696.63 86430.65,95456.63 86406.7,95336.63 86286.96,95252.63 86071.43,94844.63 86071.43,94508.63 85879.86,94304.63 85748.14,94136.63 85113.54,94340.63 84862.09,94868.63 85197.35,95396.63 85496.7,95672.63' },
      { Min: 'Old', Max: 'Provider', Maturity: 'small', Density: 'Big', DensitySize: 80.8, Mix: 'Atrax', Notes: '', Id: 211, Pos: '84179.58,90104.63 88538.03,90080.63 88514.08,89708.63 88250.66,89408.63 87460.39,89072.63 87352.63,88868.63 87029.34,88556.63 86837.76,88208.63 86885.65,87980.63 87376.57,87680.63 87328.68,87008.63 86861.7,86624.63 86706.04,86672.63 86454.6,86552.63 86191.17,86624.63 85819.99,86504.63 85388.93,86420.63 85221.3,86516.63 84790.24,86552.63 84335.24,86792.63 84335.24,87188.63 83856.29,87680.63 83700.63,88028.63 83880.24,88316.63 83832.34,88544.63 83964.06,88736.63 84131.69,89432.63 84143.66,89852.63' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Big', DensitySize: 80.7, Mix: '', Notes: '', Id: 212, Pos: '78180.73,90056.63 79749.3,90056.63 79749.3,89744.63 79533.77,89670.63 79282.32,89552.63 79018.9,89528.63 78719.55,89576.63 78372.31,89648.63 78216.65,89648.63 78144.81,89768.63' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Big', DensitySize: 80.6, Mix: '', Notes: '', Id: 213, Pos: '71655.04,89840.63 72768.6,89564.63 72864.39,89708.63 73403.21,89912.63 73702.55,89516.63 73702.55,88772.63 73115.84,88712.63 72936.23,88880.63 72050.17,89036.63 71678.99,89264.63 71487.41,89744.63' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Big', DensitySize: 80.1, Mix: '', Notes: '', Id: 218, Pos: '64130.74,86515.2 64245.68,86592 64437.26,86611.2 64523.47,86390.4 64724.63,86332.8 64830,86592 64868.32,86774.4 65059.9,86995.2 65059.9,87168 65203.58,87177.6 65337.69,87139.2 65299.37,85574.4 65088.64,85593.6 64973.69,85766.4 65098.21,85843.2 65117.37,86112 64954.53,86313.6 64743.79,86284.8 64580.95,86188.8 64466,85833.6 64236.1,85862.4 64082.84,85900.8 64034.95,86064 64149.89,86236.8 64159.47,86361.6' },
      { Min: 'Old', Max: 'Dominant', Maturity: 'mid', Density: 'Big', DensitySize: 80, Mix: 'Chirpy, Sabakuma', Notes: '', Id: 219, Pos: '62904.62,81974.4 62923.78,82742.4 62665.15,82876.8 62674.73,83020.8 63000.41,83107.2 63278.2,83107.2 63498.52,83203.2 63977.47,83328 64638.42,83568 64839.58,83635.2 65050.32,83894.4 65500.53,83875.2 65490.95,81964.8 64772.53,81993.6 64782.11,82080 65050.32,82454.4 64992.85,82684.8 64695.9,82617.6 64216.95,81993.6' },
      { Min: 'Mature', Max: 'Prowler', Maturity: 'mid', Density: 'Medium', DensitySize: 50.3, Mix: 'Caudatergus', Notes: '', Id: 220, Pos: '68224.56,86840.63 68356.27,87068.63 68487.98,86864.63 68751.41,86696.63 68931.01,86408.63 69038.78,86216.63 68476.01,86216.63 68344.3,86516.63 68248.51,86612.63 68080.88,86696.63 68176.67,86792.63' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Medium', DensitySize: 50.2, Mix: '', Notes: '', Id: 221, Pos: '61207.94,77864.63 61447.42,77924.63 61639,77924.63 61854.53,77720.63 62010.18,77564.63 62022.16,77396.63 61902.42,77288.63 61639,77252.63 61459.39,77240.63 61387.55,77336.63 61423.47,77492.63 61219.92,77588.63 61172.02,77732.63' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Medium', DensitySize: 50.1, Mix: 'Gibnib', Notes: '', Id: 222, Pos: '80641.34,87048 81850.69,87048 81850.61,86004 81156.21,86016' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 223, Pos: '78863.24,96456 79006.92,96576 79916.93,96708 79928.9,96432 80060.61,96240 80192.32,95952 80216.27,95688 80515.62,95424 80491.67,95136 80312.06,94956 80228.24,94980 80144.43,95208 80012.72,95340 79928.9,95340 79761.27,95148 78336.39,95328 78480.08,95616 78647.71,95712 78899.16,95868 78935.08,96060 78935.08,96384' },
      { Min: 'Old', Max: 'Dominant', Maturity: 'mid', Density: 'Low', DensitySize: 30.2, Mix: 'Berycled, Merp, Prancer', Notes: '', Id: 224, Pos: '73744.46,86580 74007.88,86628 74163.54,86592 74331.17,86556 74438.94,86604 74400.05,86040 74097.68,84864 74313.21,84264 73714.52,84264' },
      { Min: 'Old', Max: 'Dominant', Maturity: 'mid', Density: 'Low', DensitySize: 30.1, Mix: 'Berycled, Merp, Prancer, Sabakuma', Notes: '', Id: 225, Pos: '73714.52,83424 73858.21,83424 73930.05,83544 74588.61,83544 74971.77,82548 74971.77,81948 73714.52,81948' },
      { Min: 'Old', Max: 'Dominant', Maturity: 'mid', Density: 'Low', DensitySize: 30, Mix: 'Berycled, Merp, Prancer', Notes: '', Id: 226, Pos: '75927.27,82550.4 78408.24,82540.8 78446.55,81993.6 75927.27,81993.6' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Very Low', DensitySize: 15, Mix: '', Notes: '', Id: 227, Pos: '61228.3,82675.2 61678.51,82723.2 62234.09,82713.6 62253.25,82617.6 62167.04,82464 61793.46,82387.2 61544.41,82396.8 61189.98,82406.4 61084.61,82483.2' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 15, Mix: 'Phasm', Notes: '', Id: 754, Pos: '16731,44137 16923,44217 17211,44153 18347,44265 19179,43865 19387,43065 19307,42137 18843,41625 18059,41801 17755,42073 17499,41977 17355,41977 16875,42361 16715,42809 16763,43161 16683,43337 16683,43481 16779,43657' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Very Low', DensitySize: 10, Mix: 'Merp', Notes: '', Id: 903, Pos: '23146.41,55451.19 23529.57,55410.77 23862.31,55334.98 23942.98,55153.08 24013.56,54870.14 23932.89,54572.03 23827.02,54531.61 23796.77,54380.03 23585.03,54349.71 23524.53,54400.24 23413.61,54319.4 23232.11,54268.87 23080.87,54299.19 22929.62,54430.56 22748.12,54420.45 22748.12,54491.19 22909.45,54521.5 23151.45,55046.98' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Very Low', DensitySize: 15, Mix: 'Merp', Notes: '', Id: 904, Pos: '19978.91,56123.82 20256.7,56315.82 20563.23,56296.62 20716.5,56123.82 21109.24,56392.62 21205.03,56392.62 21300.82,56133.42 21396.61,56018.22 21511.55,56047.02 21655.24,56239.02 21760.61,56306.22 21818.08,56267.82 21808.5,56123.82 21626.5,55586.22 21770.19,55413.42 21789.34,55144.62 21789.34,54837.42 21731.87,54760.62 21233.76,54818.22 21023.02,55576.62 19978.91,55557.42' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Very Low', DensitySize: 10, Mix: 'Merp', Notes: '', Id: 907, Pos: '21406.03,54194.37 21540.14,54242.37 21712.56,54059.97 21741.3,53963.97 21722.14,53733.57 22057.4,53752.77 22421.41,53733.57 22536.35,53560.77 22680.04,53503.17 22823.72,53618.37 23168.57,53666.37 23369.73,53512.77 23436.78,53291.97 23283.52,53311.17 23072.78,53426.37 22862.04,53339.97 23091.94,53243.97 23091.94,53119.17 22986.57,53090.37 22862.04,53128.77 22804.57,52927.17 22871.62,52850.37 23044.04,52965.57 23197.3,52927.17 23340.99,53080.77 23542.15,53109.57 23570.89,52955.97 23455.94,52811.97 23484.67,52706.37 23360.15,52437.57 23187.73,52466.37 23111.09,52591.17 22890.78,52562.37 22747.09,52715.97 22699.2,52696.77 22766.25,52408.77 22909.93,52389.57 22976.99,52159.17 23091.94,51813.57 23398.46,51602.37 23398.46,51352.77 23302.67,51323.97 23302.67,51112.77 23082.36,51112.77 22938.67,50997.57 22919.51,50853.57 22794.99,50747.97 22545.93,50834.37 22430.98,51055.17 22632.14,51160.77 22823.72,51189.57 22881.2,51352.77 22976.99,51477.57 23005.72,51621.57 22909.93,51890.37 22699.2,52226.37 22469.3,52389.57 22430.98,52581.57 22296.88,52706.37 22162.77,52619.97 21923.3,52715.97 21616.77,52725.57 21616.77,52859.97 21520.98,52936.77 21501.82,52840.77 21185.71,52226.37 20974.98,52255.17 21338.98,52955.97 21319.82,53051.97 21367.72,53128.77 21520.98,53080.77 21635.93,52955.97 21578.45,53157.57 21616.77,53243.97 21789.19,53339.97 21597.61,53522.37 21425.19,53474.37 21214.45,53531.97 21099.5,53695.17 21137.82,53675.97 21310.24,53685.57 21434.77,54050.37' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Low', DensitySize: 20, Mix: 'Atrax, Gibnib, Snalesnot Female, Snablesnot Male', Notes: '', Id: 954, Pos: '72972.98,75973.57 73116.67,76011.97 73222.04,75800.77 73394.46,75666.37 73691.41,75685.57 73633.93,74936.77 73174.14,74965.57 73097.51,74879.17 72723.93,74869.57 72695.19,75013.57 72829.3,75080.77 72982.56,75243.97 73030.46,75560.77 72915.51,75570.37 72848.45,75714.37 72963.4,75839.17' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'small', Density: 'Low', DensitySize: 20, Mix: 'Exarosaur', Notes: '', Id: 976, Pos: '26710.37,29433.6 26739.11,29673.6 26911.53,29932.8 27256.38,30086.4 27476.69,30048 27716.17,29894.4 27859.85,29923.2 27993.96,30115.2 28080.17,30316.8 28128.06,30422.4 28377.12,30576 28492.07,30585.6 28539.96,29923.2 28118.49,29443.2 27840.69,29635.2 27668.27,29577.6 27572.48,29443.2 27582.06,29308.8' },
    ]
  },
  { // Daikiba
    Name: 'Daikiba',
    id: '21',
    notes: '',
    spawns: [
      { Min: 'Provider', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 95, Mix: 'Exarosaur', Notes: '', Id: 229, Pos: '79557.71,67728 79677.45,67824 79845.09,67848 79988.77,67836 80072.59,67776 80156.4,67584 80180.35,67404 80264.17,67224 80144.43,66864 79988.77,66912 79773.24,67032 79294.29,67332 79330.21,67428' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Big', DensitySize: 80.3, Mix: '', Notes: '', Id: 228, Pos: '60447.61,75776.63 60579.32,75776.63 60711.03,75728.63 60734.98,75608.63 60711.03,75488.63 60723.01,75344.63 60699.06,75224.63 60387.74,75104.63 60268,75284.63 60291.95,75416.63 60232.08,75488.63 60232.08,75548.63 60363.79,75656.63 60423.66,75776.63' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Big', DensitySize: 80.1, Mix: '', Notes: '', Id: 230, Pos: '76384.67,76524 76540.33,76776 76719.94,76788 76875.6,77136 76959.41,77076 76947.44,76884 77139.02,76656 77091.12,76548 76995.33,76488 76899.54,76500 76743.88,76392 76648.09,76440 76528.36,76368 76456.51,76476' },
      { Min: 'Young', Max: 'Raging Daikiba Bull', Maturity: 'mid', Density: 'Big', DensitySize: 80, Mix: '', Notes: 'Event', Id: 231, Pos: '55386.71,64149.33 55439.92,64170.67 55546.36,64138.67 55546.36,64021.33 55525.07,63936 55376.06,63936 55376.06,64053.33' },
      { Min: 'Guardian', Max: 'Prowler', Maturity: 'big', Density: 'Medium', DensitySize: 50, Mix: 'Chirpy, Hiryuu, Thorio Female, Thorio Male', Notes: '', Id: 232, Pos: '55088.69,64746.67 55461.21,64586.67 56163.67,64586.67 56397.82,64405.33 56461.68,64277.33 56387.18,63968 56238.17,63904 56238.17,63797.33 56355.25,63605.33 56142.38,63552 55929.51,63584 55950.8,63253.33 55940.16,63093.33 55801.8,63146.67 55684.72,63413.33 55578.29,63808 55663.43,63936 55663.43,64181.33 55780.51,64266.67 55695.36,64384 55610.22,64384 55546.36,64277.33 55290.92,64234.67 55280.27,64021.33 55322.85,63850.67 55493.14,63808 55525.07,63562.67 55407.99,63509.33 55205.77,63573.33 55109.98,63680 54929.04,64181.33 54939.69,64533.33' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Low', DensitySize: 30.2, Mix: 'Berycled, Caudatergus', Notes: '', Id: 233, Pos: '64698.29,77084.63 64925.79,77072.63 65021.58,76952.63 65332.9,76940.63 65524.48,76868.63 65512.51,74504.63 65392.77,74564.63 65296.98,74408.63 65045.53,74396.63 64961.71,74324.63 64794.08,74228.63 64794.08,74384.63 64614.48,74360.63 64506.71,74492.63 64506.71,75320.63 64638.42,75296.63 64758.16,75356.63 64818.03,75428.63 64985.66,75428.63 64973.69,75560.63 64806.06,75668.63 64758.16,75824.63 64602.5,75884.63 64506.71,75764.63 64470.79,75956.63 64327.11,76160.63 64267.24,76400.63 64446.84,76676.63 64482.76,76808.63 64602.5,76988.63' },
      { Min: 'Pup', Max: 'Juvenile', Maturity: 'small', Density: 'Low', DensitySize: 30.1, Mix: '', Notes: '', Id: 234, Pos: '63285.39,75884.63 63512.89,75908.63 63872.1,75824.63 63860.13,75704.63 63967.89,75476.63 63812.23,75284.63 63704.47,75092.63 63548.81,74924.63 63297.36,74792.63 63189.6,74744.63 63201.57,74996.63 63069.86,75140.63 62998.02,75020.63 62998.02,74912.63 62866.31,74900.63 62734.6,74792.63 62626.83,74768.63 62578.94,75008.63 62794.47,75212.63 62830.39,75416.63 62938.15,75404.63 63177.63,75572.63 63297.36,75764.63' },
      { Min: 'Juvenile', Max: '', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: 'Berycled, Snablesnot Female, Snablesnot Male', Notes: '', Id: 235, Pos: '60028.91,79000.42 60354.59,79077.22 60584.49,78952.42 60756.91,78971.62 60776.07,78837.22 60881.44,78856.42 60910.17,79019.62 61073.02,79086.82 61197.54,79077.22 61456.18,79125.22 61743.55,79067.62 61963.86,78856.42 61973.44,78779.62 61868.07,78779.62 61935.13,78539.62 61963.86,78299.62 62117.13,78174.82 62270.39,78174.82 62356.6,78203.62 62471.55,78136.42 62557.76,77934.82 62596.08,77733.22 62682.29,77570.02 62758.92,77406.82 62787.66,77109.22 62787.66,76802.02 62452.39,76840.42 62145.87,76782.82 61935.13,76946.02 61695.65,77099.62 61465.76,77157.22 61274.18,77157.22 61226.28,77310.82 60986.81,77426.02 60766.49,77454.82 60565.33,77378.02 60584.49,77224.42 60661.12,77109.22 61053.86,77013.22 60958.07,76888.42 60843.12,76734.82 60843.12,76533.22 60689.86,76389.22 60622.8,76206.82 60479.12,76475.62 60201.33,76581.22 60134.28,76811.62 60230.07,76869.22 60239.64,77032.42 60402.49,77176.42 60316.28,77416.42 60115.12,77435.62 60105.54,77550.82 59952.27,77666.02 60000.17,77906.02 59875.64,77915.62 59760.69,77954.02 59760.69,78088.42 59866.06,78232.42 59799.01,78453.22 59856.48,78683.62 59971.43,78904.42' },
    ]
  },
  { // Defender
    Name: 'Defender',
    id: '88',
    notes: '',
    spawns: [
      // { Min: 'Gen 02', Max: 'Gen 03', Maturity: 'small', Density: 'Medium', DensitySize : 50, Mix: '', Notes: '', Id: 236, Pos: '79569.69,68532 79749.3,68580 79904.95,68484 79881.01,68292 79749.3,68184 79629.56,68268' },
    ]
  },
  { // Diripi
    Name: 'Diripi',
    id: '22',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 237, Pos: '67859.36,77288.63 68254.49,77336.63 68398.18,77456.63 68565.81,77408.63 68625.68,77240.63 68649.63,77084.63 68745.42,77000.63 68541.87,76724.63 68374.23,76700.63 68266.47,76724.63 68146.73,76808.63 68086.86,76940.63 67991.07,76988.63 67907.26,76880.63 67727.65,76964.63' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: 'Allophyl, Gokibusagi, Tripudion', Notes: '', Id: 238, Pos: '52640.73,64554.67 52853.59,64458.67 53087.75,64277.33 53162.25,64512 53364.47,64597.33 53417.69,64341.33 53438.97,64149.33 53215.47,64010.67 53268.68,63914.67 53492.19,64021.33 53715.7,64021.33 54056.29,64192 53928.57,64437.33 53960.5,64618.67 54130.79,64586.67 54375.59,64074.67 54652.32,63957.33 54535.24,63658.67 54811.97,63541.33 54780.04,63360 54258.51,63434.67 54173.37,63712 53726.35,63530.67 53854.07,63424 53875.35,63178.67 54226.58,63018.67 54247.87,62858.67 53992.43,62901.33 53683.77,63061.33 53438.97,62997.33 53247.4,63114.67 52757.8,63146.67 52555.58,63637.33 52906.81,63850.67 52811.02,64192 52640.73,64320' },
    ]
  },
  { // Droka
    Name: 'Droka',
    id: '121',
    notes: '',
    spawns: [
      { Min: 'Gen 01', Max: 'Gen 03', Maturity: 'small', Density: 'Medium', DensitySize: 50.3, Mix: '', Notes: '', Id: 239, Pos: '68871.14,72492 68942.99,72600 69074.7,72660 69182.46,72660 69529.7,72360 69350.09,72120' },
      { Min: 'Gen 01', Max: 'Gen 05', Maturity: 'small', Density: 'Medium', DensitySize: 50.2, Mix: 'Legionnaire, Trooper', Notes: '', Id: 240, Pos: '76626.54,79756.8 76846.86,79728 77115.07,79920 77268.33,80121.6 77431.18,80179.2 77689.81,80496 77890.97,80419.2 78159.18,80649.6 78389.08,80601.6 78436.97,80428.8 78762.66,80438.4 78868.03,80140.8 78580.66,79872 78647.71,79488 78484.87,79363.2 78647.71,79008 78676.45,78633.6 78283.71,78460.8 77852.65,78950.4 77546.13,79008 77009.7,79017.6 76636.12,79497.6' },
      { Min: 'Gen 04', Max: 'Gen 10', Maturity: 'big', Density: 'Medium', DensitySize: 50.1, Mix: 'Legionnaire, Trooper', Notes: '', Id: 241, Pos: '82378.73,75158.4 83097.16,75148.8 83202.52,75206.4 83250.42,75292.8 83231.26,75657.6 83307.89,75744 83863.48,75753.6 83595.26,75465.6 83327.05,75033.6 82982.21,74697.6 82857.68,74736 82666.1,74908.8 82541.57,74928 82330.84,74918.4 82273.36,75024 82292.52,75139.2' },
      { Min: 'Gen 01', Max: 'Gen 03', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 242, Pos: '61237.88,83318.4 61850.93,83308.8 61917.99,83203.2 61582.72,82972.8 61496.51,82963.2 61266.61,83155.2' },
      { Min: 'C1-TF', Max: '', Maturity: 'small', Density: 'Small', DensitySize: 5, Mix: 'Drone, Warrior', Notes: '', Id: 880, Pos: '35767.32,53548.8 36322.9,53539.2 36322.9,53107.2 35882.26,53088 35671.53,53136 35700.26,53241.6 35757.74,53260.8' },
    ]
  },
  { // Drone
    Name: 'Drone',
    id: '23',
    notes: '',
    spawns: [
      { Min: 'Gen 01', Max: 'Gen 03', Maturity: 'small', Density: 'Big', DensitySize: 85, Mix: 'Droka, Warrior', Notes: '', Id: 243, Pos: '35767.32,53548.8 36322.9,53539.2 36322.9,53107.2 35882.26,53088 35671.53,53136 35700.26,53241.6 35757.74,53260.8' },
      { Min: 'Gen 01', Max: 'Gen 03', Maturity: 'small', Density: 'Big', DensitySize: 80.2, Mix: '', Notes: '', Id: 244, Pos: '38382.38,53952 39005.02,53952 39005.02,52905.6 38784.7,52905.6 38717.65,53030.4 38717.65,53251.2 38803.86,53404.8 38727.23,53491.2 38641.02,53510.4 38478.17,53702.4' },
      { Min: 'Gen 01', Max: 'Gen 03', Maturity: 'small', Density: 'Big', DensitySize: 80.1, Mix: '', Notes: '', Id: 245, Pos: '75774.01,81696 75869.8,81828 76037.43,81888 76492.43,81840 76552.3,81684 77007.31,81276 77007.31,81132 76887.57,80808 76336.78,80928 76145.2,80820 76121.25,80712 76276.91,80652 76240.99,80556 76061.38,80580 75977.56,80424 75498.61,80772 75618.35,81084 75582.43,81132 75702.17,81180 75857.83,81252 75869.8,81444 75762.04,81636' },
      { Min: 'Gen 01', Max: 'Gen 07', Maturity: 'mid', Density: 'Big', DensitySize: 80, Mix: 'Warrior', Notes: '', Id: 246, Pos: '83024.12,74688 83359.38,75024 83658.72,75468 83946.09,75720 84245.44,75720 85203.34,75696 85239.26,75240 85478.73,74628 84987.81,74400 84880.05,74400 84760.31,74436 83934.12,74448 83467.14,74496 83227.67,74532' },
      { Min: 'Gen 01', Max: 'Gen 03', Maturity: 'small', Density: 'Medium', DensitySize: 50.8, Mix: '', Notes: '', Id: 247, Pos: '72828.47,78584.63 72924.26,78536.63 73139.79,78164.63 73247.55,78116.63 73199.65,77876.63 73331.37,77588.63 73091.89,77396.63 72816.49,77420.63 72768.6,77480.63 72756.63,77624.63 72612.94,77816.63 72660.84,78116.63 72804.52,78296.63 72792.55,78572.63' },
      { Min: 'Gen 01', Max: 'Gen 03', Maturity: 'small', Density: 'Medium', DensitySize: 50.7, Mix: 'Trooper', Notes: '', Id: 248, Pos: '74133.61,76140 74911.9,76116 75462.69,76128 75821.9,76008 76049.41,75684 75965.59,75528 75151.38,75804 74804.14,75792 74672.43,75720 74420.98,75720 74025.84,75744 74025.84,76008' },
      { Min: 'Gen 03', Max: 'Gen 08', Maturity: 'mid', Density: 'Medium', DensitySize: 50.6, Mix: 'Warrior', Notes: '', Id: 249, Pos: '74510.78,68292 74642.49,68352 74618.54,68544 74750.25,68544 75001.7,68508 75061.57,68376 75037.62,68232 75121.44,68076 75265.13,67944 75265.13,67764 75348.94,67656 75564.47,67644 75552.5,67500 75372.89,67380 75145.39,67368 75109.47,67500 74858.02,67824 74582.62,68088' },
      { Min: 'Gen 01', Max: 'Gen 04', Maturity: 'small', Density: 'Medium', DensitySize: 50.5, Mix: 'Warrior', Notes: '', Id: 250, Pos: '76923.49,76348.8 77143.81,76329.6 77239.6,76204.8 77335.39,76272 77536.55,76070.4 77383.28,75936 77383.28,75715.2 77469.49,75571.2 77622.76,75350.4 77402.44,75158.4 77412.02,74985.6 77344.97,74899.2 77134.23,74937.6 77038.44,75168 76904.33,75177.6 76798.96,75120 76722.33,75206.4 76693.59,75331.2 76597.8,75436.8 76636.12,75542.4 76770.23,75600 76875.6,76022.4 76789.38,76032 76856.44,76214.4' },
      { Min: 'Gen 01', Max: 'Gen 03', Maturity: 'small', Density: 'Medium', DensitySize: 50.4, Mix: '', Notes: '', Id: 251, Pos: '80880.04,69325.98 81014.14,69585.18 81119.51,69642.78 81282.36,69613.98 81244.04,69441.18 81205.72,69268.38 81052.46,69306.78' },
      { Min: 'Gen 01', Max: 'Gen 03', Maturity: 'small', Density: 'Medium', DensitySize: 50.3, Mix: '', Notes: '', Id: 252, Pos: '61515.67,82924.8 61965.88,83212.8 62128.72,83049.6 62099.99,82934.4 61745.56,82800 61601.88,82790.4' },
      { Min: 'Gen 01', Max: 'Gen 05', Maturity: 'small', Density: 'Medium', DensitySize: 50.2, Mix: '', Notes: '', Id: 253, Pos: '62042.51,83241.6 62147.88,83328 62387.36,83280 62569.36,83260.8 62588.52,83097.6 62569.36,82886.4 62521.46,82752 62406.52,82732.8 62320.3,82809.6 62387.36,83001.6 62186.2,83001.6' },
      { Min: 'Gen 03', Max: 'Gen 06', Maturity: 'mid', Density: 'Medium', DensitySize: 50.1, Mix: 'Legionnaire, Trooper, Warrior', Notes: '', Id: 254, Pos: '50331.12,59754.67 50607.85,59648 50916.51,59690.67 51246.45,59200 51118.73,58549.33 50767.5,58400 50277.9,58261.33 49990.54,58421.33 50001.18,58709.33 50224.69,59061.33 50245.98,59210.67 50384.34,59381.33' },
      { Min: 'Gen 02', Max: 'Gen 05', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 255, Pos: '50895.22,64800 51022.94,64746.67 51363.53,64768 51544.46,64949.33 51757.33,64906.67 51736.04,64597.33 51618.97,64437.33 51352.88,64330.67 51097.44,64266.67 50842,64245.33 50735.57,64309.33 50820.72,64757.33' },
      { Min: 'Gen 03', Max: 'Gen 06', Maturity: 'small', Density: 'Low', DensitySize: 30.4, Mix: '', Notes: '', Id: 256, Pos: '86131.3,90644.63 86310.91,90836.63 86789.86,90740.63 86873.68,90812.63 87053.28,90716.63 87220.92,90776.63 87412.5,90704.63 87448.42,90500.63 87436.44,90128.63 86322.88,90128.63 86286.96,90308.63 86143.28,90416.63' },
      { Min: 'Gen 03', Max: 'Gen 06', Maturity: 'small', Density: 'Low', DensitySize: 30.3, Mix: '', Notes: '', Id: 257, Pos: '86729.99,91028.63 87113.15,90992.63 87484.34,91004.63 87735.79,90956.63 87580.13,90848.63 87388.55,90764.63 87268.81,90836.63 87149.07,90788.63 86897.62,90860.63 86837.76,90848.63 86753.94,90788.63 86538.41,90956.63' },
      { Min: 'Gen 05', Max: 'Gen 07', Maturity: 'mid', Density: 'Low', DensitySize: 30.2, Mix: '', Notes: '', Id: 258, Pos: '87556.18,90740.63 87831.58,90836.63 88238.68,90740.63 88382.37,90524.63 88550,90128.63 87448.42,90128.63 87460.39,90620.63' },
      { Min: 'Gen 01', Max: '', Maturity: 'mid', Density: 'Low', DensitySize: 30.1, Mix: '', Notes: '', Id: 259, Pos: '65763.96,74276.63 66230.93,74240.63 66159.09,74168.63 66051.33,74108.63 65943.56,74036.63 65835.8,74024.63 65740.01,74120.63' },
      { Min: 'Elite', Max: '', Maturity: 'big', Density: 'Low', DensitySize: 30, Mix: '', Notes: '', Id: 260, Pos: '68757.39,77540.63 68877.13,77576.63 68984.89,77528.63 68960.95,77312.63 68937,77132.63 68805.29,77084.63 68757.39,77180.63' },
      { Min: 'Gen 01', Max: 'Gen 03', Maturity: 'small', Density: 'Very Low', DensitySize: 5, Mix: 'Thorio Female, Thorio Male', Notes: 'East side', Id: 781, Pos: '32120,50893 32664,50781 32728,49773 32632,49261 32200,49165 30376,49197 30552,49581 31784,50717' },
    ]
  },
  { // Daspletor
    Name: 'Daspletor',
    id: '86',
    notes: '',
    spawns: [
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 261, Pos: '36868.9,55488 37137.11,55862.4 37309.54,55910.4 37520.27,55929.6 37625.64,55814.4 37625.64,55257.6 36849.74,55248' },
      { Min: 'Mature', Max: 'Provider', Maturity: 'small', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 262, Pos: '38047.12,55910.4 38219.54,56150.4 38353.65,56188.8 38535.65,56112 38641.02,55939.2 38641.02,55776 38593.12,55622.4 38037.54,55622.4 38075.86,55756.8' },
      { Min: 'Guardian', Max: 'Old Alpha', Maturity: 'mid', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 263, Pos: '39934.18,53942.4 40231.13,53856 40413.13,53692.8 40441.87,53548.8 40326.92,53289.6 40346.08,53193.6 40116.18,53136 39991.66,53136 39962.92,53203.2 39761.76,53577.6 39771.34,53664' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 92, Mix: '', Notes: '', Id: 264, Pos: '39033.76,53529.6 39675.55,53539.2 39905.44,53155.2 39750.86,52752 39369.02,52867.2 39033.76,52924.8' },
      { Min: 'Old Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 265, Pos: '39225.34,52857.6 39388.18,52838.4 39723.44,52723.2 39704.29,52406.4 39704.29,52166.4 39225.34,52195.2' },
    ]
  },
  { // Estophyl
    Name: 'Estophyl',
    id: '25',
    notes: '',
    spawns: [
      { Min: 'Dominant', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 269, Pos: '34991.42,52012.8 35115.94,52291.2 35441.63,52435.2 35681.11,52646.4 35824.79,52684.8 35796.05,52416 35748.16,52262.4 35614.05,52032 35393.74,51955.2' },
      { Min: 'Old', Max: 'Dominant', Maturity: 'mid', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 270, Pos: '34972.26,51993.6 35435.47,51974.4 35435.47,51772.8 34876.47,51792' },
      { Min: 'Young', Max: 'Alpha', Maturity: 'mid', Density: 'Big', DensitySize: 80.5, Mix: 'Allophyl', Notes: '', Id: 266, Pos: '71176.09,81632.63 71283.85,81560.63 71762.8,81572.63 72565.05,81392.63 72684.78,81104.63 72062.15,80852.63 71918.46,80996.63 71678.99,80996.63 71571.22,80888.63 71559.25,79808.63 71188.06,79640.63 71200.04,79424.63 70757.01,79340.63 70685.17,79892.63 70816.88,80384.63 70828.85,80996.63 70565.43,81380.63 70733.06,81488.63 70864.77,81392.63 71044.38,81428.63' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Big', DensitySize: 80.4, Mix: 'Allophyl', Notes: '', Id: 267, Pos: '66823.63,86108.63 67122.98,86396.63 67362.45,86168.63 68128.77,85892.63 67589.95,85772.63 67278.63,85244.63 66955.34,85400.63 66955.34,85640.63 66823.63,85892.63' },
      { Min: 'Young', Max: 'Old Alpha', Maturity: 'mid', Density: 'Big', DensitySize: 80.3, Mix: 'Allophyl', Notes: '', Id: 268, Pos: '70164.31,81212.63 70391.81,81404.63 70619.31,81104.63 70655.23,80720.63 70715.1,80312.63 70571.42,80036.63 70619.31,79784.63 70583.39,79484.63 70511.55,79364.63 70487.6,79148.63 70535.49,78932.63 70284.05,78788.63 70415.76,78752.63 70379.84,78488.63 70487.6,78224.63 70679.18,78152.63 70451.68,78080.63 70248.12,78080.63 70152.33,77852.63 69709.31,78200.63 69541.67,78248.63 69625.49,78380.63 69541.67,78584.63 70128.39,78560.63 70212.2,78704.63 69912.86,78764.63 69649.44,78764.63 69481.8,78968.63 69769.17,78908.63 70248.12,79028.63 70260.1,79340.63 69948.78,79520.63 69649.44,79484.63 69864.96,79664.63 70224.18,79532.63 70367.86,79460.63 70415.76,79676.63 70415.76,79940.63 70391.81,80108.63 70487.6,80348.63 70559.44,80504.63 70415.76,80900.63 70296.02,81080.63' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Big', DensitySize: 80, Mix: 'Allophyl', Notes: '', Id: 271, Pos: '65572.31,85062.07 66290.73,84990.07 66649.94,84966.07 67009.16,84618.07 67069.02,84234.07 67057.05,83898.07 66374.55,83910.07 65572.31,83946.07' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 60, Mix: '', Notes: '', Id: 275, Pos: '35460.79,51964.8 35652.37,52041.6 35757.74,51926.4 35757.74,51552 35460.79,51571.2' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50.3, Mix: 'Allophyl', Notes: '', Id: 272, Pos: '70948.59,78872.63 71343.72,78860.63 71583.2,78668.63 71918.46,78524.63 72205.83,78488.63 72313.6,78332.63 72553.07,78296.63 72481.23,77912.63 72337.54,77984.63 72289.65,77792.63 72421.36,77468.63 72289.65,77396.63 72325.57,77300.63 71990.3,76988.63 71834.65,77012.63 71810.7,77288.63 71583.2,77576.63 71415.57,77624.63 71068.33,77912.63 71188.06,78260.63 71116.22,78320.63 71020.43,78272.63 70984.51,78344.63 70948.59,78488.63 70840.83,78728.63' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50.2, Mix: '', Notes: '', Id: 273, Pos: '81463.94,69628.8 81617.2,69427.2 81636.36,69264 81483.09,69033.6 81444.78,68822.4 81454.36,68467.2 81358.57,68083.2 81195.72,67756.8 80975.41,67574.4 80793.41,67440 80438.98,67430.4 80592.25,67680 80735.93,67814.4 80860.46,67939.2 80870.04,68227.2 81147.83,68380.8 81320.25,68563.2 81205.3,68716.8 81214.88,68793.6 81320.25,68793.6 81320.25,68908.8 81291.51,69033.6 81291.51,69254.4 81310.67,69504' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'small', Density: 'Medium', DensitySize: 50.1, Mix: 'Allophyl', Notes: '', Id: 274, Pos: '78365.13,72787.2 78451.34,72825.6 78623.76,72768 78997.34,72739.2 78978.19,72672 79370.92,72547.2 79591.24,72412.8 79572.08,72326.4 79485.87,72278.4 79399.66,72288 79294.29,72374.4 79188.92,72384 79102.71,72451.2 79121.87,72518.4 79035.66,72576 79016.5,72441.6 78844.08,72374.4 78777.03,72278.4 78623.76,72288 78537.55,72259.2 78508.81,72326.4 78518.39,72384 78441.76,72432 78451.34,72691.2' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Low', DensitySize: 30.1, Mix: 'Allophyl', Notes: '', Id: 276, Pos: '69931.24,68010.78 70237.77,68193.18 70304.82,68135.58 70228.19,68058.78 70276.08,67914.78 70333.56,67962.78 70314.4,67847.58 70247.35,67645.98 70151.56,67482.78 70036.61,67482.78 69902.5,67338.78 69816.29,67127.58 69691.77,67146.78 69615.13,66925.98 69433.13,66762.78 69413.97,66628.38 69318.18,66628.38 69260.71,66733.98 69203.24,66877.98 68973.34,66945.18 69107.45,67021.98 69327.76,67377.18 69442.71,67492.38 69663.03,67482.78 69979.14,67732.38 69883.35,67885.98' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: 'Allophyl', Notes: '', Id: 277, Pos: '69912.86,67032 70140.36,67188 70200.23,67344 70260.1,67500 70379.84,67548 70367.86,67692 70391.81,67776 70487.6,67668 70523.52,67416 70499.57,67164 70679.18,67080 70763,66924 70667.21,66792 70451.68,66756 70487.6,66624 70583.39,66612 70571.42,66432 70367.86,66252 70212.2,66168 70212.2,66072 70116.41,66036 70032.6,65880 70104.44,65676 69996.68,65676 69852.99,65832 69829.04,65916 69673.38,65928 69697.33,66084 69613.52,66144 69529.7,66156 69374.04,66432 69577.59,66588 69577.59,66768 69769.17,66864' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Low', DensitySize: 25, Mix: 'Shinkiba', Notes: '', Id: 798, Pos: '26720,32656 27936,32512 27984,31824 27840,31552 27344,31120 26608,30752 26480,31584' },
    ]
  },
  { // Eviscerator
    Name: 'Eviscerator',
    id: '120',
    notes: '',
    spawns: [
      { Min: 'The Eviscerator', Max: '', Maturity: 'big', Density: 'Medium', DensitySize: 50.8, Mix: '', Notes: '', Id: 278, Pos: '69439.9,77744.63 69583.58,77744.63 69685.42,77672.63 69631.48,77516.63 69487.79,77528.63 69392,77708.63' },
      { Min: 'Gen 02', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 50.7, Mix: '', Notes: '', Id: 279, Pos: '80022.3,71356.8 80003.14,71520 80070.19,71596.8 80194.72,71606.4 80261.77,71548.8 80319.25,71433.6 80520.4,71404.8 80635.35,71404.8 80635.35,71347.2 80529.98,71251.2 80395.88,71241.6 80261.77,71280 80185.14,71328' },
      { Min: 'Gen 01', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 50.6, Mix: '', Notes: '', Id: 280, Pos: '80031.88,70972.8 80213.88,71088 80271.35,71174.4 80625.77,71174.4 80644.93,71088 80616.19,70944 80482.09,70838.4 80376.72,70771.2 80118.09,70790.4 80003.14,70857.6' },
      { Min: 'Gen 08', Max: '', Maturity: 'big', Density: 'Medium', DensitySize: 50.5, Mix: '', Notes: '', Id: 281, Pos: '79351.77,71404.8 79399.66,71539.2 79591.24,71587.2 79725.35,71558.4 79725.35,71443.2 79639.14,71366.4 79610.4,71251.2 79533.77,71222.4 79457.14,71251.2' },
      { Min: 'Gen 10', Max: '', Maturity: 'big', Density: 'Medium', DensitySize: 50.4, Mix: '', Notes: '', Id: 282, Pos: '79131.45,71241.6 79323.03,71376 79437.98,71241.6 79342.19,71145.6 79437.98,71068.8 79505.03,70992 79495.45,70857.6 79380.5,70876.8 79255.98,70915.2 79217.66,71088' },
      { Min: 'Gen 06', Max: '', Maturity: 'mid', Density: 'Medium', DensitySize: 50.3, Mix: '', Notes: '', Id: 283, Pos: '79524.19,70992 79667.87,71001.6 79754.08,70915.2 79715.77,70838.4 79533.77,70819.2' },
      { Min: 'Gen 07', Max: '', Maturity: 'mid', Density: 'Medium', DensitySize: 50.2, Mix: '', Notes: '', Id: 284, Pos: '79505.03,71212.8 79658.29,71260.8 79715.77,71232 79744.51,71184 79667.87,71049.6 79524.19,71049.6 79505.03,71107.2' },
      { Min: 'Gen 03', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 50.1, Mix: '', Notes: '', Id: 285, Pos: '79706.19,71059.2 79773.24,71193.6 80070.19,71222.4 80146.82,71193.6 80108.51,71107.2 79974.4,71011.2 79869.03,70953.6 79782.82,70953.6' },
      { Min: 'Gen 04', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 286, Pos: '79658.29,71376 79763.66,71452.8 79859.45,71481.6 79974.4,71491.2 79983.98,71356.8 80060.61,71308.8 80031.88,71251.2 79754.08,71241.6 79687.03,71299.2 79639.14,71299.2' },
      { Min: 'Gen 01', Max: 'Gen 03', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: '', Notes: '', Id: 287, Pos: '75474.67,71328 75618.35,71196 75750.06,71136 75833.88,71004 76085.33,71160 76181.12,71028 76336.78,71052 76660.07,71040 76899.54,70860 76887.57,70668 76707.96,70644 76516.38,70512 76360.72,70656 76217.04,70656 76085.33,70548 75917.7,70596 75738.09,70908 75678.22,71076 75402.82,71256' },
      { Min: 'Mulciber', Max: '', Maturity: 'big', Density: 'Very Low', DensitySize: 15, Mix: 'Warlock, Warrior', Notes: '', Id: 943, Pos: '69763.19,77672.63 70194.24,77684.63 70170.3,77588.63 70050.56,77432.63 70230.16,77336.63 70110.43,77216.63 69870.95,77132.63 69643.45,77176.63 69679.37,77456.63' },
    ]
  },
  { // Equus
    Name: 'Equus',
    id: '24',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Low', DensitySize: 39, Mix: 'Plumatergus', Notes: '', Id: 737, Pos: '17627,54105 18379,54297 19499,52857 19515,52361 18619,52105 18619,51257 18347,50841 17083,51321 16971,51481 16827,51849 17371,51961' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Low', DensitySize: 10, Mix: 'Molisk, Traeskeron', Notes: '', Id: 841, Pos: '34438,36522 34822,36618 35318,36602 35382,36090 35318,35626 35142,35610 34598,35210 34214,35306 34246,36058' },
    ]
  },
  { // Exarosaur
    Name: 'Exarosaur',
    id: '26',
    notes: '',
    spawns: [
      { Min: 'Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 95, Mix: 'Daikiba', Notes: '', Id: 290, Pos: '79557.71,67728 79677.45,67824 79845.09,67848 79988.77,67836 80072.59,67776 80156.4,67584 80180.35,67404 80264.17,67224 80144.43,66864 79988.77,66912 79773.24,67032 79294.29,67332 79330.21,67428' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Big', DensitySize: 80.3, Mix: '', Notes: '', Id: 288, Pos: '62147.88,76736.63 62471.17,76796.63 62794.47,76772.63 62782.49,76676.63 62626.83,76472.63 62459.2,76268.63 62375.38,76100.63 62195.78,76184.63 62016.17,76184.63 61896.43,76148.63 61788.67,76184.63 61788.67,76328.63 61884.46,76412.63 61980.25,76520.63 62000.25,76640.63' },
      { Min: 'Old', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 80.2, Mix: 'Combibo', Notes: '', Id: 289, Pos: '66596.13,74828.63 66931.4,74816.63 66955.34,74804.63 67111,74816.63 67134.95,74876.63 67422.32,74840.63 67613.9,74864.63 67757.58,74804.63 67589.95,74528.63 67482.19,74348.63 67230.74,74276.63 67122.98,74432.63 66907.45,74504.63 66763.76,74456.63 66560.21,74552.63' },
      { Min: 'Old Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 80, Mix: 'Snablesnot Female, Snablesnot Male, Snarksnot', Notes: '', Id: 291, Pos: '74563,88881 74428,88934 74241,88962 74197,89090 74155,89942 75191,89947 75241,89823 75293,89034' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Medium', DensitySize: 50.6, Mix: 'Shinkiba', Notes: '', Id: 292, Pos: '66688.33,87753.6 66860.75,87916.8 66985.28,87868.8 67339.7,87792 67416.33,88032 67655.81,88070.4 67521.7,87734.4 67492.96,87360 67416.33,87264 67454.65,87129.6 67378.02,87024 67109.8,87024 66908.65,87244.8 66774.54,87504' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Medium', DensitySize: 50.5, Mix: 'Merp, Snablesnot Female, Snablesnot Male, Snarksnot', Notes: '', Id: 293, Pos: '74301.24,91284 74624.53,91380 74624.53,91488 74744.27,91572 75127.43,91428 75139.4,91104 75582.43,90780 75558.48,90444 75402.82,90084 73726.5,90108 73894.13,90264 74133.61,90360 74025.84,90624 74073.74,90936 74085.71,91104 74133.61,91200 74241.37,91200' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'small', Density: 'Medium', DensitySize: 50.4, Mix: 'Faucervix', Notes: '', Id: 294, Pos: '73965.97,74712 74253.34,74712 74373.08,74616 74420.98,74676 74768.21,74676 74768.21,73836 73822.29,73836 73954,74052 73870.18,74184 73954,74412 73918.08,74664' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'small', Density: 'Medium', DensitySize: 50.3, Mix: 'Combibo', Notes: '', Id: 295, Pos: '76612.17,75096 76803.75,75072 76899.54,75144 76995.33,75144 77103.1,74916 77390.47,74868 77390.47,74484 77390.47,73836 76264.93,73836 76276.91,74784 76372.7,74880 76468.49,75000' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Medium', DensitySize: 50.2, Mix: '', Notes: '', Id: 296, Pos: '70559.44,73140 70834.84,73368 71170.1,73332 71206.02,73116 70942.6,72924 70679.18,72972' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Medium', DensitySize: 50.1, Mix: 'Combibo', Notes: '', Id: 297, Pos: '67495.74,73336.42 67601.11,73384.42 67783.11,73499.62 67888.48,73432.42 67917.21,73278.82 68185.43,73019.62 68041.74,72837.22 68070.48,72530.02 68051.32,72328.42 67878.9,72318.82 67696.9,72376.42 67629.84,72568.42 67514.9,72683.62 67217.95,72856.42 67285,72971.62 67467,73115.62' },
      { Min: 'Young', Max: 'Alpha', Maturity: 'mid', Density: 'Medium', DensitySize: 50, Mix: 'Armax Bull, Armax Cow', Notes: '', Id: 298, Pos: '85424.85,86313.6 85990.01,86361.6 86459.38,86448 86842.54,86572.8 86928.76,86563.2 87388.55,86851.2 87666.34,86774.4 87972.87,86400 87896.23,86227.2 87972.87,86083.2 87848.34,85651.2 87714.23,85449.6 87484.34,85353.6 87359.81,85488 87206.55,85555.2 87062.86,85564.8 86976.65,85507.2 86947.91,85372.8 86756.33,85286.4 86536.02,85267.2 86152.86,85344 85731.38,85430.4 85434.43,85507.2 85348.22,85804.8 85338.64,86092.8' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Low', DensitySize: 30.2, Mix: 'Combibo', Notes: '', Id: 299, Pos: '78575.87,97100.63 78731.53,97136.63 78839.29,96992.63 78851.26,96824.63 78755.47,96704.63 78587.84,96632.63 78527.97,96884.63' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Low', DensitySize: 30.1, Mix: 'Shinkiba', Notes: '', Id: 300, Pos: '70922.25,67142.4 70874.35,67248 70931.83,67334.4 71065.93,67305.6 71065.93,67420.8 71132.98,67516.8 71228.77,67392 71362.88,67401.6 71429.93,67507.2 71439.51,67612.8 71525.72,67651.2 71669.41,67593.6 71784.36,67670.4 71995.09,67708.8 72052.57,67612.8 72157.94,67612.8 72081.31,67353.6 71880.15,67180.8 71822.67,66979.2 71755.62,67017.6 71659.83,66988.8 71621.51,67104 71487.41,67171.2 71353.3,67123.2 71314.99,67017.6 71353.3,66864 71410.78,66787.2 71573.62,66816 71640.67,66883.2 71832.25,66796.8 71746.04,66748.8 71755.62,66624 71698.15,66528 71611.93,66566.4 71640.67,66672 71391.62,66700.8 71314.99,66691.2 71276.67,66585.6 71161.72,66691.2 71065.93,66720 70950.98,66883.2 71046.77,67056 70941.4,67152' },
      { Min: 'Old', Max: 'Dominant', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: 'Bristlehog, Hiryuu, Trilomite', Notes: '', Id: 301, Pos: '32875.65,49119.46 34306.87,49119.46 34374.48,48927.46 34182.9,48814.52 34002.59,48656.4 33811.01,48656.4 33506.74,48227.23 33258.81,47831.93 32988.35,47447.93 33055.96,47323.7 33010.89,47165.58 32875.65,47041.34' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'small', Density: 'Low', DensitySize: 25, Mix: 'Scaboreas', Notes: '', Id: 725, Pos: '36376,48672 37080,48848 37848,48432 38008,48000 37352,47328 36760,47600 36424,48160' },
      { Min: 'Stalker', Max: '', Maturity: 'big', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 730, Pos: '19915,61337 20971,61193 20843,60473 20523,59705 20123,59545 19243,59721 19131,60569 19307,61129' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 100, Mix: 'Fugabarba, Hiryuu, Snablesnot Female, Snablesnot Male, Snarksnot', Notes: '', Id: 745, Pos: '16587,46281 19803,46169 19947,45561 19899,44777 18987,44633 18219,44681 18187,44809 17435,44985 16891,44601 16635,44873 16571,44985 16699,45641' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'small', Density: 'Low', DensitySize: 15, Mix: 'Atrox, Itumatrox, Mermoth', Notes: '', Id: 794, Pos: '16848,28832 18928,29168 19648,28592 20464,28704 20624,28384 20448,27920 20928,27008 20912,26768 20560,26592 20048,25712 18784,25648 18128,25808 17952,25904 17552,26720 17632,27216 17808,27568' },
      { Min: 'Mature', Max: 'Dominant', Maturity: 'small', Density: 'Very Low', DensitySize: 5, Mix: 'Atrox, Gradivore', Notes: '', Id: 816, Pos: '34198,32634 34774,32666 35510,31930 35478,31770 35142,31642 35446,31306 35542,30986 35206,30458 33878,30666 33750,31034 33398,31210 33350,31418 33158,31514 33158,31770 33510,31850' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: 'Merp', Notes: '', Id: 858, Pos: '29494,48858 30022,48970 31206,49002 32262,48810 32502,48538 32518,47594 30726,47322 30342,46954 30006,47450 29286,47754 29222,48426' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 5, Mix: 'Sumima', Notes: '', Id: 864, Pos: '16851.98,63241.6 17168.09,63452.8 17273.46,63568 17541.67,63760 17761.99,63712 17656.62,63366.4 17714.09,63193.6 17532.09,62944 17330.93,62723.2 17215.99,62742.4 17120.2,62713.6 16938.2,62848 16880.72,62963.2 16871.14,63145.6' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 5, Mix: 'Sumima', Notes: '', Id: 865, Pos: '18030.66,63598.4 19563.3,63598.4 19457.93,63387.2 19400.45,63243.2 18193.5,63243.2 18145.6,63454.4 18001.92,63492.8' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'small', Density: 'Very Low', DensitySize: 10, Mix: 'Argonaut, Hiryuu, Molisk', Notes: '', Id: 913, Pos: '28597.51,65408.77 28637.84,65135.93 28970.58,65055.09 29010.92,64873.19 29162.16,64792.35 29353.74,64943.93 29605.82,65004.56 29857.9,64974.24 29938.57,65216.77 29888.15,65398.66 29656.24,65398.66 29646.16,65297.61 29373.91,65317.82 29192.41,65439.09' },
      { Min: 'Provider', Max: 'Prowler', Maturity: 'mid', Density: 'Low', DensitySize: 35, Mix: 'Atrox', Notes: '', Id: 928, Pos: '40537.66,32726.4 40968.71,32716.8 40949.56,31286.4 39685.13,31305.6 39838.39,31420.8 40097.02,31478.4 40106.6,31776 40279.03,32006.4 40470.61,32246.4 40432.29,32323.2 40537.66,32486.4 40480.18,32630.4' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: 'Combibo, Merp, Snablesnot Female, Snablesnot Male', Notes: '', Id: 938, Pos: '71774.78,76963.2 71975.94,76896 72368.68,77088 72694.36,76732.8 72838.05,76569.6 72857.2,76224 72818.89,75964.8 72723.1,75888 72770.99,75696 72742.26,75494.4 72579.41,75312 72445.31,75177.6 72148.36,75177.6 72071.73,75244.8 72090.88,75369.6 71851.41,75600 71688.57,75676.8 71449.09,75504 71190.46,75532.8 71027.62,75753.6 71027.62,75955.2 71161.72,76118.4 71382.04,76032 71449.09,75801.6 71544.88,75849.6 71535.3,76089.6 71592.78,76281.6 71573.62,76425.6 71391.62,76406.4 71190.46,76569.6 71276.67,76656 71573.62,76579.2 71573.62,76761.6 71602.36,76934.4' },
      { Min: 'Old', Max: 'Old Alpha', Maturity: 'mid', Density: 'Low', DensitySize: 20, Mix: 'Foul', Notes: '', Id: 962, Pos: '17068.94,28887.12 17009.07,29199.12 16925.25,29127.12 16913.28,29031.12 16781.57,29031.12 16733.67,29175.12 16793.54,29403.12 16913.28,29595.12 16901.31,29739.12 16709.73,29931.12 16697.75,30231.12 16673.81,30483.12 16901.31,31047.12 17021.04,31191.12 16937.23,31335.12 17188.68,31599.12 17547.89,31815.12 17943.02,31935.12 18266.31,32367.12 19200.27,32439.12 19236.19,32343.12 19367.9,32355.12 19607.37,32571.12 20170.14,32631.12 20146.19,32523.12 19595.4,31971.12 19224.21,31923.12 19188.29,31779.12 19284.08,31611.12 18996.71,31287.12 18745.26,31215.12 18565.66,31275.12 18410,31059.12 18649.47,31107.12 18553.68,30867.12 18625.53,30243.12 18805.13,30123.12 18805.13,29931.12 18529.74,29919.12 18433.95,30087.12 17895.13,29943.12 17895.13,29823.12 18266.31,29547.12 18577.63,29547.12 18829.08,29403.12 18865,29211.12 18661.45,29247.12 18529.74,29115.12' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'small', Density: 'Very Low', DensitySize: 3, Mix: 'Bristlehog, Hiryuu, Trilomite', Notes: '', Id: 967, Pos: '35725.9,49104.67 39193.49,49123.87 39193.49,49027.87 38235.59,48663.07 38149.38,47789.47 37814.12,47683.87 37785.38,47770.27 37794.96,47760.67 38024.86,47991.07 37852.43,48451.87 37086.11,48864.67 36367.69,48691.87 35955.79,48663.07 35725.9,48807.07 35697.16,48960.67' },
      { Min: 'Young', Max: 'Alpha', Maturity: 'mid', Density: 'Low', DensitySize: 25, Mix: 'Cornundacauda', Notes: '', Id: 975, Pos: '26710.37,29433.6 26739.11,29673.6 26911.53,29932.8 27256.38,30086.4 27476.69,30048 27716.17,29894.4 27859.85,29923.2 27993.96,30115.2 28080.17,30316.8 28128.06,30422.4 28377.12,30576 28492.07,30585.6 28539.96,29923.2 28118.49,29443.2 27840.69,29635.2 27668.27,29577.6 27572.48,29443.2 27582.06,29308.8' },
    ]
  },
  { // Eudorian Devil
    Name: 'Eudorian Devil',
    id: '550',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Sapphire', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 302, Pos: '60980.44,70836 61279.79,70896 61495.31,70824 61734.79,70860 61878.47,71160 62070.05,71232 62333.48,70884 62321.5,70716 61818.6,70536 61674.92,70320 61662.95,69996 61411.5,69948 61124.13,69828 60681.1,70092 61076.23,70536 60920.57,70632' },
    ]
  },
  { // Falxangius
    Name: 'Falxangius',
    id: '27',
    notes: '',
    spawns: [
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 15, Mix: 'Atrox, Caudatergus', Notes: '', Id: 820, Pos: '39286,35338 40022,35466 40886,35290 40886,33162 40838,32874 39846,32874 39334,32938 38182,32858 37238,33322 37526,34058 37814,33818 39110,33882 39302,34010 39638,34026 39670,34698' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 853, Pos: '32790,46666 33094,46986 33142,46778 33222,46698 33542,46906 33750,46970 33734,47098 33846,47114 33974,47242 34070,47578 34406,47818 35238,47978 35782,48186 36342,48026 36646,47466 36662,45610 36518,44762 36118,44378 36038,44090 35750,43994 34790,43962 32806,44026 32822,46282' },
    ]
  },
  { // Faucervix
    Name: 'Faucervix',
    id: '28',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 318, Pos: '64740.2,80220 64907.83,80316 65554.41,80316 65554.57,79320 64943.75,79308 64847.96,79464 64871.91,79824 64752.17,80028 64752.17,80208' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Medium', DensitySize: 45, Mix: '', Notes: '', Id: 320, Pos: '69864.19,68941.98 69912.08,69028.38 70065.35,69028.38 70247.35,68961.18 70630.51,68807.58 70525.14,68730.78 70630.51,68721.18 70659.24,68653.98 70410.19,68596.38 70314.4,68596.38 70266.51,68740.38 70228.19,68788.38 70209.03,68884.38 70122.82,68922.78 70103.66,68817.18 70103.66,68673.18 69959.98,68730.78 69873.77,68845.98' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Medium', DensitySize: 35, Mix: 'Exarosaur', Notes: '', Id: 319, Pos: '73965.97,74712 74253.34,74712 74373.08,74616 74420.98,74676 74768.21,74676 74768.21,73836 73822.29,73836 73954,74052 73870.18,74184 73954,74412 73918.08,74664' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Medium', DensitySize: 30, Mix: 'Atrax, Berycled, Gokibusagi, Molisk', Notes: '', Id: 321, Pos: '81898.58,87204 82796.61,87180 83099.13,86885.65 83290.71,86490.35 83263.59,86124 83395.3,85716 83443.2,84972 83395.3,84576 83263.59,84348 83131.88,84504 82856.48,84624 81898.58,84612' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Medium', DensitySize: 25, Mix: 'Berycled, Prototype Drone', Notes: '', Id: 322, Pos: '63159.66,78324 63327.3,78648 63638.61,78648 63674.54,78828 63566.77,79020 63638.61,79080 63878.09,79212 64045.72,79260 64237.3,79296 64321.12,79524 64309.14,79764 64440.86,79932 64716.25,79896 64764.15,79776 64764.15,79476 64907.83,79284 65015.6,79248 65482.57,79260 65470.6,78024 64955.73,77748 64991.65,78048 64835.99,78060 64752.17,77940 64536.65,78240 64572.57,78336 64297.17,78480 64045.72,78420 63925.98,78372 63902.04,78240 63650.59,78144 63542.82,78132 63303.35,78216' },
      { Min: 'Provider', Max: 'Domninant', Maturity: 'mid', Density: 'Low', DensitySize: 20, Mix: 'Molisk', Notes: '', Id: 323, Pos: '57520.03,84024 57998.98,83904 58681.48,83760 58777.27,83904 58645.56,84096 58322.27,84216 58010.95,84264 57723.58,84300 57496.08,84168' },
    ]
  },
  { // Feffoid
    Name: 'Feffoid',
    id: '29',
    notes: '',
    spawns: [
      { Min: 'Berserker', Max: 'Clan Warlord', Maturity: 'mid', Density: 'Big', DensitySize: 80.4, Mix: '', Notes: '', Id: 303, Pos: '71870.57,86624.63 72265.7,86624.63 72529.12,86492.63 73702.55,86240.63 73702.55,84704.63 73463.08,84704.63 73343.34,84656.63 72648.86,84620.63 72648.86,84704.63 72732.68,84812.63 72636.89,85088.63 72517.15,85076.63 72445.31,84824.63 72193.86,85004.63 72253.73,85316.63 71894.52,85952.63 71834.65,86264.63 71714.91,86252.63 71726.88,86444.63' },
      { Min: 'Outcast', Max: 'Warrior', Maturity: 'small', Density: 'Big', DensitySize: 80.3, Mix: 'Atrax, Atrox', Notes: '', Id: 304, Pos: '69523.71,89732.63 69787.14,89828.63 70158.32,89660.63 70170.3,89576.63 70457.67,89408.63 70481.61,89588.63 70637.27,89540.63 70912.67,89228.63 70960.56,89060.63 71223.99,89024.63 71331.75,88928.63 71439.51,89048.63 71870.57,88904.63 71966.36,88676.63 72373.46,88460.63 72409.39,88256.63 72289.65,87968.63 71810.7,87416.63 71583.2,87404.63 71451.49,87284.63 71355.7,87440.63 71247.93,87368.63 71128.2,87512.63 70900.69,87596.63 70685.17,87548.63 70661.22,87764.63 70493.59,87860.63 70385.82,88040.63 70266.09,88076.63 70134.37,87968.63 70050.56,88028.63 69811.08,88124.63 69571.61,88076.63 69511.74,87872.63 69308.18,87992.63 69224.37,88256.63 69104.63,88496.63 68913.05,88796.63' },
      { Min: 'Warlord', Max: 'Champion Elite', Maturity: 'big', Density: 'Big', DensitySize: 80.2, Mix: '', Notes: '', Id: 305, Pos: '69074.7,84392.63 69254.3,84476.63 69242.33,84620.63 69529.7,84620.63 69589.57,84368.63 69541.67,83948.63 69421.94,83684.63 69254.3,83504.63 69038.78,83480.63 68907.06,83612.63 68931.01,83888.63 69014.83,84044.63' },
      { Min: 'Bandit', Max: 'Warrior', Maturity: 'small', Density: 'Big', DensitySize: 80.1, Mix: '', Notes: '', Id: 306, Pos: '67781.53,79796.63 68332.32,79916.63 68511.93,79856.63 68667.59,79652.63 68811.27,79352.63 68595.75,78968.63 68368.25,79088.63 68236.53,79244.63 68032.98,79232.63 67997.06,79124.63 68092.85,78920.63 68320.35,78728.63 68224.56,78536.63 68056.93,78524.63 67865.35,78164.63 67709.69,78260.63 67625.87,78080.63 67374.42,78092.63 67290.61,78248.63 67302.58,78464.63 67242.71,78536.63 67230.74,78728.63 67099.03,78812.63 67254.69,79076.63 67338.5,79292.63 67589.95,79604.63 67769.56,79808.63' },
      { Min: 'Outcast', Max: 'Bandit', Maturity: 'small', Density: 'Big', DensitySize: 80, Mix: 'Combibo', Notes: '', Id: 307, Pos: '80048.64,78528 81497.46,78528 81497.46,76344 81353.78,76392 81234.04,76392 81174.17,76572 80958.64,76440 80575.48,76416 80324.04,76428 80096.53,76500 80048.64,76956' },
      { Min: 'Berserker', Max: 'Clan Warlord', Maturity: 'big', Density: 'Medium', DensitySize: 50.2, Mix: '', Notes: '', Id: 308, Pos: '87713.28,92185.41 87991.07,92156.61 88316.75,92156.61 88508.33,92079.81 88680.75,92003.01 88709.49,91772.61 88613.7,91523.01 88613.7,91302.21 88527.49,91158.21 88345.49,91033.41 88192.23,90947.01 87962.33,91014.21 87703.7,91033.41 87425.91,91052.61 87435.48,91955.01 87579.17,92127.81' },
      { Min: 'Bandit', Max: 'Warlord', Maturity: 'small', Density: 'Medium', DensitySize: 50.1, Mix: 'Ambulimax', Notes: '', Id: 309, Pos: '86981.44,93620.63 87628.02,93812.63 87735.79,94004.63 87927.37,94112.63 88262.63,94136.63 88238.68,93656.63 88550,93584.63 88873.29,93632.63 88801.45,93884.63 88921.19,93968.63 88945.14,94112.63 89124.74,94088.63 89364.22,93968.63 89340.27,93584.63 89460.01,92996.63 88957.11,93032.63 88993.03,92480.63 89005,92300.63 88885.27,92144.63 88669.74,92108.63 88334.47,92216.63 87687.89,92264.63 87532.23,92168.63 87400.52,92060.63 87364.6,92204.63 87029.34,92348.63 86873.68,92516.63 86813.81,92672.63 86598.28,92756.63 86873.68,93344.63' },
      { Min: 'Guard', Max: 'Hunter', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: 'Armax Bull, Armax Cow, Molisk', Notes: '', Id: 310, Pos: '87336.28,79377.18 87709.86,78906.78 87824.81,78599.58 88361.24,78417.18 88964.71,78138.78 88897.66,77937.18 88734.82,77908.38 88782.71,77533.98 88897.66,77274.78 88782.71,77063.58 88610.29,77207.58 88504.92,77101.98 88437.87,77130.78 88399.55,77341.98 88140.92,77495.58 88006.81,77476.38 87930.18,77409.18 87939.76,77553.18 87843.97,77716.38 87882.29,77879.58 87729.02,77956.38 87633.23,78033.18 87623.65,78157.98 87671.55,78273.18 87719.44,78330.78 87451.23,78532.38 87135.12,78714.78 86943.54,78676.38 86771.12,78666.78 86751.96,78762.78 86799.86,78897.18 87058.49,79098.78 87087.23,79300.38 87202.18,79396.38' },
      { Min: 'Guard', Max: 'Berserker', Maturity: 'small', Density: 'Very Low', DensitySize: 15, Mix: '', Notes: '', Id: 931, Pos: '22500.4,42748.8 22806.93,42556.8 22787.77,42374.4 22826.09,42316.8 22768.61,42220.8 22509.98,42105.6 22452.51,42028.8 22356.72,42057.6 22289.66,42134.4 22385.45,42259.2 22356.72,42384 22423.77,42652.8 22423.77,42768' },
    ]
  },
  { // Feffox
    Name: 'Feffox',
    id: '445',
    notes: '',
    spawns: [
      { Min: 'Old Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Medium', DensitySize: 50.4, Mix: '', Notes: '', Id: 311, Pos: '83964.06,90884.63 84203.53,90740.63 84538.79,90788.63 85137.48,90632.63 85113.54,90572.63 84874.06,90620.63 84754.32,90596.63 84622.61,90488.63 84335.24,90464.63 84131.69,90608.63 83988,90644.63 83940.11,90884.63' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Medium', DensitySize: 50.3, Mix: '', Notes: '', Id: 312, Pos: '89010.56,92960.63 89471.98,92960.63 89615.67,92684.63 89747.38,92192.63 89735.4,92132.63 89567.77,92048.63 89507.9,91748.63 89388.16,91628.63 88765.53,91748.63 88717.63,92000.63 88945.14,92108.63 89052.9,92264.63' },
      { Min: 'Guardian', Max: 'Old Alpha', Maturity: 'mid', Density: 'Medium', DensitySize: 50.2, Mix: '', Notes: '', Id: 313, Pos: '85317.09,91700.63 85388.93,91820.63 85736.17,91820.63 86059.46,91620.63 86167.23,91620.63 86298.94,91550.63 86334.86,91148.63 86155.25,91040.63 85796.04,90740.63 85496.7,90740.63 85424.85,90776.63 85281.17,90668.63 85185.38,90704.63 85161.43,90872.63 85353.01,91064.63 85460.77,91352.63 85472.75,91460.63 85376.96,91544.63' },
      { Min: 'Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Medium', DensitySize: 50.1, Mix: '', Notes: '', Id: 314, Pos: '83796.42,92444.63 84167.61,92372.63 84790.24,92252.63 84993.8,91832.63 85197.35,91676.63 85376.96,91364.63 85305.12,91088.63 85113.54,90896.63 84993.8,90860.63 84898.01,90968.63 84718.4,90884.63 84443.01,90884.63 84059.85,91076.63 83820.37,91016.63 83652.74,91220.63 83401.29,91424.63 83676.68,91952.63' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 315, Pos: '86586.31,92684.63 86777.89,92612.63 86873.68,92396.63 87053.28,92264.63 87328.68,92132.63 87364.6,91988.63 87376.57,91076.63 87029.34,91052.63 86706.04,91100.63 86586.31,91196.63 86370.78,91220.63 86322.88,91580.63 86167.23,91676.63 86047.49,91676.63 85796.04,91856.63 85891.83,92024.63 86119.33,92072.63 86454.6,92540.63' },
    ]
  },
  { // Formicacida
    Name: 'Formicacida',
    id: '30',
    notes: '',
    spawns: [
      { Min: 'Ravager', Max: 'Watcher', Maturity: 'small', Density: 'Medium', DensitySize: 50.1, Mix: '', Notes: '', Id: 316, Pos: '534,310 533,308 535,307 540,307 543.5,312 543,313 540,314 538,317 536,318 535,318 532,314' },
      { Min: 'Weak', Max: 'Ravager', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 317, Pos: '77970.1,94596 78420.21,94332 78408.24,94116 78276.52,93948 78061,93816 77893.36,93804 77857.44,93900 77701.78,93996' },
      { Min: 'Weak', Max: 'Strong', Maturity: 'small', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 720, Pos: '38245,48650 39205,49018 39397,48458 39381,48266 39589,47802 40005,47338 39221,46314 38773,47006 38293,47162 38133,47610' },
    ]
  },
  { // Formidon
    Name: 'Formidon',
    id: '31',
    notes: '',
    spawns: [
      { Min: 'Old Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 806, Pos: '29392,32640 30736,32608 32592,32672 32720,31984 32384,31472 32416,30896 32000,30400 31232,30816 29440,30848 29216,31600' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Big', DensitySize: 95, Mix: 'Cadatergus, Neconu, Tantillion', Notes: 'South/South-West side', Id: 850, Pos: '38742,43562 39910,43306 40054,42474 40166,42394 40118,42138 39974,42026 39926,41626 39686,40986 38182,41050 37798,41498 37862,42554 38342,43370' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Low', DensitySize: 25, Mix: '', Notes: '', Id: 833, Pos: '28726,38346 29270,38442 29798,38330 29974,37850 29670,37354 29206,37338 28678,37466 28406,37946' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Medium', DensitySize: 40, Mix: 'Scipulor', Notes: '', Id: 873, Pos: '18086.74,60266.54 18105.89,60420.14 17962.21,60525.74 18172.95,60621.74 18249.58,60727.34 18786,60967.34 18805.16,61140.14 18900.95,61312.94 19159.58,61370.54 19284.11,61312.94 19389.48,61120.94 19207.48,60535.34 19322.43,59680.94 19753.48,59584.94 19485.27,59287.34' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: 'Snarksnot', Notes: '', Id: 885, Pos: '19770.39,59078.4 19779.97,59328 19856.6,59472 20057.76,59520 20249.34,59491.2 20527.13,59587.2 20632.5,59299.2 20757.02,59116.8 21053.97,58790.4 21274.29,58560 21312.61,58387.2 21168.92,58224 21092.29,58262.4 20948.6,57984 20977.34,57888 20852.81,57772.8 20661.23,57820.8 20469.65,58003.2 20450.5,58118.4 20201.44,58262.4 20096.07,58348.8 19961.97,58368 19799.12,58636.8' },
    ]
  },
  { // Frescoquda
    Name: 'Frescoquda',
    id: '130',
    notes: '',
    spawns: [
      { Min: 'Old Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 324, Pos: '38468.59,52156.8 39167.86,52118.4 39167.86,51456 38717.65,51408 38669.75,51532.8 38545.23,51638.4 38468.59,51763.2' },
      { Min: 'Old', Max: 'Dominant', Maturity: 'mid', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 325, Pos: '38746.39,52886.4 39043.33,52896 39177.44,52848 39167.86,52195.2 38468.59,52214.4 38487.75,52732.8 38679.33,52780.8' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 326, Pos: '37539.43,52608 38171.65,52396.8 38411.12,52396.8 38411.12,51830.4 38277.01,51849.6 38123.75,51792 37932.17,51811.2 37932.17,52137.6 37817.22,52185.6 37826.8,52329.6 37529.85,52329.6' },
      { Min: 'Guardian', Max: 'Dominant', Maturity: 'mid', Density: 'Medium', DensitySize: 50.2, Mix: '', Notes: '', Id: 327, Pos: '65925.2,70297.6 66049.73,70249.6 66356.26,70355.2 66519.1,70326.4 66566.99,70230.4 66519.1,70057.6 66432.89,69971.2 66423.31,69884.8 66212.57,69856 66068.89,69798.4' },
      { Min: 'Alpha', Max: 'Prowler', Maturity: 'big', Density: 'Medium', DensitySize: 50.1, Mix: '', Notes: '', Id: 328, Pos: '65973.5,69552 66368.63,69576 66608.1,69588 66703.89,69624 66967.32,68916 66368.63,68832 66236.92,69048 66045.34,69180 66021.39,69336 66057.31,69456 65961.52,69432' },
      { Min: 'Ravager', Max: 'Watcher', Maturity: 'mid', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 329, Pos: '68739.43,67764 68871.14,67884 68931.01,68028 69194.43,68136 69649.44,68040 69733.25,67836 69781.15,67716 69733.25,67560 69613.52,67500 69433.91,67524 69302.2,67392 69158.51,67152 69038.78,66996 68919.04,66984 68739.43,67140 68751.41,67320 68583.77,67392 68727.46,67464' }, // TODO: Wrong maturities, check again
    ]
  },
  { // Fungoid
    Name: 'Fungoid',
    id: '34',
    notes: '',
    spawns: [
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 80, Mix: 'Argonaut, Furor', Notes: '', Id: 764, Pos: '29064,58733 29720,58429 29768,58253 30120,58061 30392,58605 31096,58253 30664,57437 29064,57437' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 5, Mix: 'Atrax, Merp, Neconu', Notes: '', Id: 824, Pos: '36198,35530 36550,35546 37206,35642 37542,35514 37558,35146 37350,34682 36902,34474 36454,34506 36182,34714' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: 'Atrox, Gradivore', Notes: '', Id: 860, Pos: '40407,37826 40855,37762 40887,36418 40791,35650 39671,35618 39063,35858 39159,36274 40071,37666' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'small', Density: 'Mid', DensitySize: 60, Mix: '', Notes: '', Id: 925, Pos: '36446.49,39284.14 36647.65,39370.54 37222.39,39408.94 37222.39,39236.14 37069.12,39168.94 37145.75,38976.94 37308.6,38775.34 37231.96,38602.54 37049.96,38496.94 36666.8,38458.54 36446.49,38660.14 36341.12,38919.34' },
    ]
  },
  { // Fugabarba
    Name: 'Fugabarba',
    id: '33',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Dominant', Maturity: 'small', Density: 'Medium', DensitySize: 50.4, Mix: 'Berycled, Snablesnot Female, Snablesnot Male', Notes: '', Id: 330, Pos: '83802.41,77652 83874.25,77712 84017.94,77664 84137.67,77724 84125.7,77868 84341.23,77880 84496.89,78012 84772.28,78036 84915.97,78432 85023.73,78528 85095.57,78504 85011.76,78168 85047.68,78000 85179.39,78000 85227.29,78132 85406.89,77928 85394.92,77808 85502.68,77580 85658.34,77700 86245.05,77988 86388.74,77748 86328.87,77616 86376.77,77496 86532.42,77376 86376.77,77112 86053.47,77148 85993.61,76872 85742.16,76956 85742.16,77232 85311.1,77520 85167.42,77688 84939.92,77760 84796.23,77736 84652.55,77640 84508.86,77544 84365.18,77556 84245.44,77616 83993.99,77568 83874.25,77460 83790.44,77508' },
      { Min: 'Old Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Medium', DensitySize: 50.3, Mix: 'Chirpy, Gibnib, Turp', Notes: '', Id: 331, Pos: '77838.28,72748.8 78106.5,72777.6 78336.39,72777.6 78422.6,72672 78422.6,72518.4 78403.45,72403.2 78288.5,72297.6 78068.18,72249.6 78049.02,72307.2 77924.5,72403.2 77838.28,72345.6 77809.55,72547.2 77780.81,72652.8' },
      { Min: 'Mature', Max: 'Alpha', Maturity: 'small', Density: 'Medium', DensitySize: 50.1, Mix: 'Aetherex, Gibnib', Notes: '', Id: 333, Pos: '73724.1,81897.6 74576.64,81888 74547.9,81734.4 74413.79,81456 74413.79,81244.8 74222.21,81206.4 74250.95,80956.8 74155.16,80774.4 74001.9,80755.2 73724.1,80764.8' },
      { Min: 'Prowler', Max: 'Stalker', Maturity: 'big', Density: 'Medium', DensitySize: 45, Mix: '', Notes: '', Id: 332, Pos: '78001.13,87974.4 78298.08,87916.8 78384.29,87964.8 78805.76,87936 79093.13,87849.6 79313.45,87840 79428.4,87571.2 79562.5,87388.8 79447.56,87110.4 78413.02,87129.6 78393.87,87408 78173.55,87676.8 77953.23,87792' },
      { Min: 'Provider', Max: 'Old Alpha', Maturity: 'mid', Density: 'Low', DensitySize: 30, Mix: 'Gibnib, Chirpy, Turp', Notes: '', Id: 334, Pos: '79132.65,68892 79192.52,69036 79408.04,69120 80114.49,69252 80605.42,69744 80617.39,70320 80749.1,70332 80808.97,70200 81000.55,70008 80964.63,69624 80773.05,69252 80737.13,69024 80737.13,68808 80246.21,69084 79982.78,69048 79994.76,68784 80066.6,68652 80006.73,68556 79755.28,68688 79707.39,68640 79527.78,68592 79264.36,68652 79084.75,68772' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Very Low', DensitySize: 15, Mix: 'Chirpy, Gibnib, Turp', Notes: '', Id: 335, Pos: '79428.4,68534.4 79591.24,68534.4 79773.24,68620.8 80031.88,68486.4 80098.93,68572.8 80654.51,68409.6 80587.46,68342.4 80405.46,68371.2 80405.46,68227.2 80529.98,68217.6 80510.83,68064 80347.98,67881.6 80156.4,67939.2 80070.19,67881.6 79888.19,67939.2 79687.03,67910.4 79562.5,67824 79447.56,67977.6 79524.19,68044.8 79495.45,68150.4 79514.61,68265.6 79409.24,68284.8' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 5, Mix: 'Exarosaur, Hiryuu, Snablesnot Female, Snablesnot Male, Snarksnot', Notes: '', Id: 746, Pos: '16587,46281 19803,46169 19947,45561 19899,44777 18987,44633 18219,44681 18187,44809 17435,44985 16891,44601 16635,44873 16571,44985 16699,45641' },
    ]
  },
  { // Furor
    Name: 'Furor',
    id: '125',
    notes: '',
    spawns: [
      { Min: 'Patron', Max: 'Looter', Maturity: 'mid', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 336, Pos: '37932.17,51734.4 38142.91,51705.6 38353.65,51753.6 38506.91,51552 38631.44,51465.6 38717.65,51331.2 38717.65,51244.8 38602.7,51244.8 38573.96,51110.4 38190.8,51043.2 38066.28,50995.2 37769.33,50928 37711.85,51340.8 37817.22,51552' },
      { Min: 'Rogue', Max: 'Clan Leader', Maturity: 'big', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 337, Pos: '37261.64,51321.6 37692.7,51321.6 37750.17,50928 37663.96,50908.8 37568.17,50947.2 37309.54,50899.2 37089.22,50940.8' },
      { Min: 'Adept', Max: 'Gatherer', Maturity: 'small', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 338, Pos: '36562.37,50812.8 36696.48,50870.4 37022.17,50880 37290.38,50832 37501.12,50870.4 37472.38,50793.6 37117.96,50640 37003.01,50505.6 36782.69,50496 36619.85,50400 36562.37,50371.2' },
      { Min: 'Adept', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 80, Mix: 'Argonaut, Fungoid', Notes: '', Id: 763, Pos: '29064,58733 29720,58429 29768,58253 30120,58061 30392,58605 31096,58253 30664,57437 29064,57437' },
    ]
  },
  { // Foul
    Name: 'Foul',
    id: '32',
    notes: '',
    spawns: [
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 100, Mix: '', Notes: '', Id: 340, Pos: '78899.16,67848 78875.21,68016 78935.08,68088 79030.87,68112 79318.24,68040 79354.16,67896 79533.77,67704 79294.29,67440 79138.63,67524 78959.03,67440 78743.5,67632 78887.18,67704' },
      { Min: 'Scout', Max: 'Raider', Maturity: 'mid', Density: 'Big', DensitySize: 85, Mix: '', Notes: '', Id: 341, Pos: '35173.42,50851.2 35317.1,50745.6 35585.32,50774.4 35681.11,50928 35720.16,49785.6 35594.89,49852.8 35269.21,50131.2 35202.16,50150.4 35115.94,50284.8 35010.58,50409.6 35039.31,50572.8 35135.1,50822.4' },
      { Min: 'Raider', Max: 'Leader', Maturity: 'big', Density: 'Big', DensitySize: 85, Mix: '', Notes: '', Id: 339, Pos: '67973.11,85724.63 68188.64,85796.63 68464.04,85844.63 68715.48,85844.63 68871.14,85820.63 69026.8,85892.63 69206.41,85676.63 69026.8,85436.63 68823.25,85328.63 68487.98,85316.63 68212.59,85448.63 67997.06,85568.63 67925.22,85688.63' },
      { Min: 'Hunter', Max: 'Leader', Maturity: 'big', Density: 'Medium', DensitySize: 60, Mix: '', Notes: '', Id: 347, Pos: '35470.37,51552 35757.74,51532.8 35757.74,51331.2 35681.11,51254.4 35690.68,51139.2 35547,50841.6 35355.42,50822.4 35393.74,50908.8 35432.05,51148.8 35403.31,51475.2 35451.21,51484.8' },
      { Min: 'Scout', Max: 'Guardian', Maturity: 'small', Density: 'Medium', DensitySize: 50.5, Mix: 'Argonaut, Molisk', Notes: '', Id: 342, Pos: '65548.43,83732.63 66165.08,83744.63 66392.58,83708.63 66943.37,83624.63 67266.66,83216.63 67254.69,82940.63 67446.27,82688.63 67266.66,82232.63 67290.61,81944.63 65548.43,81968.63' },
      { Min: 'Young', Max: 'Leader', Maturity: 'mid', Density: 'Medium', DensitySize: 50.4, Mix: 'Berycled', Notes: '', Id: 343, Pos: '77400.55,88809.6 78020.29,88819.2 78077.76,88886.4 78077.76,89059.2 78269.34,89116.8 78317.23,89395.2 78441.76,89548.8 78796.18,89424 78642.92,89126.4 78671.66,88924.8 78135.23,88454.4 78039.44,88214.4 77905.34,88012.8 77838.28,87715.2' },
      { Min: 'Adult', Max: 'Guardian', Maturity: 'small', Density: 'Medium', DensitySize: 50.3, Mix: '', Notes: '', Id: 344, Pos: '67913.24,68352 68140.74,68448 68320.35,68376 68428.11,68064 68583.77,67980 68440.09,67740 68452.06,67584 68308.38,67584 68272.46,67428 68140.74,67440 68080.88,67776 67961.14,67944 67877.32,67968 67937.19,68196' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 50.2, Mix: '', Notes: '', Id: 345, Pos: '80443.77,66585.6 80731.14,67056 80769.46,67084.8 80798.2,67036.8 80750.3,66854.4 80779.04,66710.4 80855.67,66633.6 80846.09,66537.6 80922.72,66499.2 80855.67,66374.4 80759.88,66384 80750.3,66105.6 80759.88,66009.6 80654.51,66038.4 80597.04,65961.6 80491.67,65971.2 80424.61,66067.2 80357.56,66105.6 80395.88,66211.2 80501.25,66451.2' },
      { Min: 'Young', Max: 'Scout', Maturity: 'small', Density: 'Medium', DensitySize: 50.1, Mix: '', Notes: '', Id: 346, Pos: '78264.55,69672 78444.16,69360 78527.97,69048 78492.05,68652 78408.24,68580 78228.63,68760 78144.81,68952 77977.18,69120 77941.26,69324 78037.05,69504' },
      { Min: 'Young', Max: 'Adult', Maturity: 'small', Density: 'Low', DensitySize: 15, Mix: '', Notes: '', Id: 348, Pos: '35843.95,49420.8 36083.42,49497.6 36533.64,49728 37098.8,49785.6 37481.96,49910.4 37616.06,50073.6 38363.23,50131.2 38957.12,50073.6 39234.91,50054.4 39560.6,49948.8 39713.86,49977.6 39800.08,49900.8 39742.6,49651.2 39445.65,49209.6 35690.68,49219.2' },
      { Min: 'Scout', Max: 'Warrior', Maturity: 'mid', Density: 'Low', DensitySize: 15, Mix: 'Exarosaur', Notes: '', Id: 963, Pos: '17068.94,28887.12 17009.07,29199.12 16925.25,29127.12 16913.28,29031.12 16781.57,29031.12 16733.67,29175.12 16793.54,29403.12 16913.28,29595.12 16901.31,29739.12 16709.73,29931.12 16697.75,30231.12 16673.81,30483.12 16901.31,31047.12 17021.04,31191.12 16937.23,31335.12 17188.68,31599.12 17547.89,31815.12 17943.02,31935.12 18266.31,32367.12 19200.27,32439.12 19236.19,32343.12 19367.9,32355.12 19607.37,32571.12 20170.14,32631.12 20146.19,32523.12 19595.4,31971.12 19224.21,31923.12 19188.29,31779.12 19284.08,31611.12 18996.71,31287.12 18745.26,31215.12 18565.66,31275.12 18410,31059.12 18649.47,31107.12 18553.68,30867.12 18625.53,30243.12 18805.13,30123.12 18805.13,29931.12 18529.74,29919.12 18433.95,30087.12 17895.13,29943.12 17895.13,29823.12 18266.31,29547.12 18577.63,29547.12 18829.08,29403.12 18865,29211.12 18661.45,29247.12 18529.74,29115.12' },
    ]
  },
  { // Globster
    Name: 'Globster',
    id: '92',
    notes: '',
    spawns: [
      { Min: 'Old', Max: 'Dominant', Maturity: 'mid', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 349, Pos: '37654.38,54806.4 38545.23,54835.2 38516.49,54758.4 38439.86,54700.8 38353.65,54537.6 38305.75,54432 37663.96,54384' },
      { Min: 'Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 350, Pos: '37654.38,55440 37999.22,55584 38583.54,55584 38583.54,54864 37654.38,54844.8' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Big', DensitySize: 88, Mix: '', Notes: '', Id: 351, Pos: '38602.7,55574.4 39072.07,55574.4 39206.18,55488 39225.34,55056 39167.86,55036.8 39024.18,54758.4 38602.7,54758.4' },
    ]
  },
  { // Gibnib
    Name: 'Gibnib',
    id: '35',
    notes: '',
    spawns: [
      { Min: 'Old Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Medium', DensitySize: 50.2, Mix: 'Chirpy, Fugabarba, Turp', Notes: '', Id: 352, Pos: '77838.28,72748.8 78106.5,72777.6 78336.39,72777.6 78422.6,72672 78422.6,72518.4 78403.45,72403.2 78288.5,72297.6 78068.18,72249.6 78049.02,72307.2 77924.5,72403.2 77838.28,72345.6 77809.55,72547.2 77780.81,72652.8' },
      { Min: 'Mature', Max: 'Dominant', Maturity: 'small', Density: 'Medium', DensitySize: 50.1, Mix: 'Aetherex, Fugabarba', Notes: '', Id: 353, Pos: '73724.1,81897.6 74576.64,81888 74547.9,81734.4 74413.79,81456 74413.79,81244.8 74222.21,81206.4 74250.95,80956.8 74155.16,80774.4 74001.9,80755.2 73724.1,80764.8' },
      { Min: 'Provider', Max: 'Alpha', Maturity: 'mid', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 354, Pos: '77850.86,90204.13 77909.81,90440.44 78042.44,90661.98 78234.02,90839.21 79088.77,90853.98 79088.77,90204.13' },
      { Min: 'Young', Max: 'Old Alpha', Maturity: 'mid', Density: 'Low', DensitySize: 30.4, Mix: '', Notes: '', Id: 355, Pos: '61183,74263 61374,74283 61528,74129 61374,73803 61164,73956' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Low', DensitySize: 30.3, Mix: 'Cornundacauda', Notes: '', Id: 356, Pos: '80641.34,87048 81850.69,87048 81850.61,86004 81156.21,86016' },
      { Min: 'Mature', Max: 'Dominant', Maturity: 'small', Density: 'Low', DensitySize: 30.2, Mix: '', Notes: '', Id: 357, Pos: '76031.45,90048 76103.29,90336 76462.5,90468 76797.77,90468 77252.77,90456 77324.61,90372 77324.61,90036' },
      { Min: 'Provider', Max: 'Old Alpha', Maturity: 'mid', Density: 'Low', DensitySize: 30.1, Mix: 'Chirpy, Fugabarba, Turp', Notes: '', Id: 358, Pos: '79132.65,68892 79192.52,69036 79408.04,69120 80114.49,69252 80605.42,69744 80617.39,70320 80749.1,70332 80808.97,70200 81000.55,70008 80964.63,69624 80773.05,69252 80737.13,69024 80737.13,68808 80246.21,69084 79982.78,69048 79994.76,68784 80066.6,68652 80006.73,68556 79755.28,68688 79707.39,68640 79527.78,68592 79264.36,68652 79084.75,68772' },
      { Min: 'Dominant', Max: 'Stalker', Maturity: 'big', Density: 'Low', DensitySize: 30, Mix: 'Chirpy, Prancer', Notes: '', Id: 359, Pos: '52353.36,60458.67 52651.37,60608 52811.02,60586.67 52960.03,60117.33 52874.88,59978.67 52534.29,60117.33 52257.57,60224' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Very Low', DensitySize: 15.1, Mix: 'Chirpy, Fugabarba, Turp', Notes: '', Id: 360, Pos: '79428.4,68534.4 79591.24,68534.4 79773.24,68620.8 80031.88,68486.4 80098.93,68572.8 80654.51,68409.6 80587.46,68342.4 80405.46,68371.2 80405.46,68227.2 80529.98,68217.6 80510.83,68064 80347.98,67881.6 80156.4,67939.2 80070.19,67881.6 79888.19,67939.2 79687.03,67910.4 79562.5,67824 79447.56,67977.6 79524.19,68044.8 79495.45,68150.4 79514.61,68265.6 79409.24,68284.8' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Very Low', DensitySize: 14, Mix: 'Atrax, Cornundacauda, Snalesnot Female, Snablesnot Male', Notes: '', Id: 956, Pos: '72972.98,75973.57 73116.67,76011.97 73222.04,75800.77 73394.46,75666.37 73691.41,75685.57 73633.93,74936.77 73174.14,74965.57 73097.51,74879.17 72723.93,74869.57 72695.19,75013.57 72829.3,75080.77 72982.56,75243.97 73030.46,75560.77 72915.51,75570.37 72848.45,75714.37 72963.4,75839.17' },
      { Min: 'Young', Max: 'Alpha', Maturity: 'small', Density: 'Low', DensitySize: 20, Mix: 'Hiryuu', Notes: '', Id: 978, Pos: '28872.39,27218.84 28853.23,26959.64 28862.81,26825.24 29083.13,26777.24 29293.87,26834.84 29801.56,27314.84 30213.45,27670.04 30472.09,27900.44 30625.35,28159.64 30701.98,28447.64 30663.67,28601.24 30500.82,28783.64 30060.19,28706.84 29887.77,28639.64 29696.19,28486.04 29427.97,28025.24 29351.34,27574.04 28910.71,27334.04' },
    ]
  },
  { // Gokibusagi
    Name: 'Gokibusagi',
    id: '36',
    notes: '',
    spawns: [
      { Min: 'Guardian', Max: 'Prowler', Maturity: 'mid', Density: 'Medium', DensitySize: 50.3, Mix: '', Notes: '', Id: 362, Pos: '49937.53,69101.82 50378.17,69217.02 50493.11,69101.82 50033.32,68621.82' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Medium', DensitySize: 50.2, Mix: 'Araneatrox, Atrax', Notes: '', Id: 363, Pos: '89036.14,85756.8 89333.08,85814.4 89553.4,85612.8 89515.09,85382.4 89438.45,85104 89630.03,84864 89697.09,84681.6 89668.35,84547.2 89495.93,84422.4 89400.14,84230.4 89400.14,84028.8 89093.61,83808 88959.5,83808 88796.66,83932.8 88748.77,84057.6 88576.34,84076.8 88317.71,84163.2 87723.81,84028.8 87675.92,84124.8 87848.34,84499.2 88049.5,84470.4 88164.45,84460.8 88241.08,84566.4 88375.18,84480 88557.19,84518.4 88700.87,84921.6 88662.55,85065.6 88710.45,85305.6 88815.82,85401.6 88834.98,85641.6 88959.5,85804.8' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Medium', DensitySize: 50.1, Mix: 'Atrax', Notes: '', Id: 364, Pos: '87963.29,86505.6 88097.39,86534.4 88183.6,86457.6 88336.87,86476.8 88432.66,86601.6 88585.92,86640 88806.24,86764.8 88902.03,86736 88892.45,86630.4 88710.45,86409.6 88748.77,86064 88959.5,85996.8 89428.87,86025.6 89505.51,85987.2 89428.87,85920 89026.56,85843.2 88892.45,85833.6 88796.66,85689.6 88787.08,85440 88662.55,85353.6 88614.66,85084.8 88672.13,84931.2 88547.61,84864 88413.5,84864 88116.55,85094.4 87790.87,85257.6 87685.5,85401.6 87896.23,85660.8 88011.18,86092.8 87953.71,86256 88011.18,86419.2' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: '', Notes: 'Event', Id: 365, Pos: '55280.27,63136 55535.71,63178.67 55812.44,62858.67 55918.87,62624 55461.21,62453.33 55290.92,62474.67 55227.06,62592 55322.85,62965.33' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Low', DensitySize: 30.2, Mix: 'Atrax, Berycled, Faucervix, Molisk', Notes: '', Id: 366, Pos: '81898.58,87204 82796.61,87180 83099.13,86885.65 83290.71,86490.35 83263.59,86124 83395.3,85716 83443.2,84972 83395.3,84576 83263.59,84348 83131.88,84504 82856.48,84624 81898.58,84612' },
      { Min: 'Mature', Max: 'Guardian', Maturity: 'small', Density: 'Low', DensitySize: 30.1, Mix: '', Notes: '', Id: 367, Pos: '50611.57,63603.2 50985.15,63334.4 51215.05,63334.4 51751.47,63536 51828.11,63881.6 52182.53,63929.6 52469.9,63584 52536.95,63248 52268.74,63027.2 51856.84,62940.8 51694,63027.2 51464.1,62979.2 51234.21,62720 51023.47,62854.4 50554.1,62499.2 50190.1,62547.2 50132.62,62633.6 50209.26,62748.8 50448.73,62787.2 50592.42,62787.2 50716.94,63142.4 50439.15,63430.4 50439.15,63564.8' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: 'Allophyl, Diripi, Tripudion', Notes: '', Id: 368, Pos: '52640.73,64554.67 52853.59,64458.67 53087.75,64277.33 53162.25,64512 53364.47,64597.33 53417.69,64341.33 53438.97,64149.33 53215.47,64010.67 53268.68,63914.67 53492.19,64021.33 53715.7,64021.33 54056.29,64192 53928.57,64437.33 53960.5,64618.67 54130.79,64586.67 54375.59,64074.67 54652.32,63957.33 54535.24,63658.67 54811.97,63541.33 54780.04,63360 54258.51,63434.67 54173.37,63712 53726.35,63530.67 53854.07,63424 53875.35,63178.67 54226.58,63018.67 54247.87,62858.67 53992.43,62901.33 53683.77,63061.33 53438.97,62997.33 53247.4,63114.67 52757.8,63146.67 52555.58,63637.33 52906.81,63850.67 52811.02,64192 52640.73,64320' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 40, Mix: 'Atrox, Gokibusagi, Longu', Notes: '', Id: 800, Pos: '28160,32000 28896,32080 29168,31664 29296,30864 29152,30832 29120,30368 28464,30000 28400,30768 27872,31536' },
    ]
  },
  { // Gradivore
    Name: 'Gradivore',
    id: '37',
    notes: '',
    spawns: [
      { Min: 'Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 99, Mix: 'Atrox, Exarosaur', Notes: '', Id: 814, Pos: '34198,32634 34774,32666 35510,31930 35478,31770 35142,31642 35446,31306 35542,30986 35206,30458 33878,30666 33750,31034 33398,31210 33350,31418 33158,31514 33158,31770 33510,31850' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 5, Mix: 'Grand Formidon', Notes: '', Id: 829, Pos: '33494,34522 33686,34570 33798,34506 33958,34634 34166,34538 34230,34410 34646,34074 34790,33834 34870,33338 34614,33258 34486,33306 34390,33370 33654,33434 33350,33178 32982,33146 32886,33578 32966,33738 33494,33722 33558,34298' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: 'Atrox, Fungoid', Notes: '', Id: 861, Pos: '40407,37826 40855,37762 40887,36418 40791,35650 39671,35618 39063,35858 39159,36274 40071,37666' },
    ]
  },
  { // Grand Formidon
    Name: 'Grand Formidon',
    id: '131',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Low', DensitySize: 25, Mix: 'Gradivore', Notes: '', Id: 828, Pos: '33494,34522 33686,34570 33798,34506 33958,34634 34166,34538 34230,34410 34646,34074 34790,33834 34870,33338 34614,33258 34486,33306 34390,33370 33654,33434 33350,33178 32982,33146 32886,33578 32966,33738 33494,33722 33558,34298' },
    ]
  },
  { // Harbinger
    Name: 'Harbinger',
    id: '176',
    notes: '',
    spawns: [
      { Min: 'Digamma', Max: 'Iota', Maturity: 'big', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 369, Pos: '60273.99,85680 60800.84,85524 60920.57,85368 61136.1,85404 60980.44,85248 60681.1,85224 60465.57,84864 59926.75,84912 59890.83,85152 60082.41,85512' },
      { Min: 'Delta', Max: 'Zeta', Maturity: 'mid', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 370, Pos: '60693.07,85212 60992.42,85224 61207.94,85452 61555.18,85380 61351.63,85140 61230.6,85056 60980.36,84840 60669.12,84648 60489.52,84852' },
      { Min: 'Alpha', Max: 'Digamma', Maturity: 'small', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 371, Pos: '61567.16,85356 61734.79,85224 61686.89,85128 62034.13,85104 62022.16,85020 61818.6,84972 61639,84732 61447.42,84780 61351.63,84708 61219.92,84456 61004.39,84492 60932.55,84600 60848.73,84720 61124.13,84996 61375.58,85140' },
    ]
  },
  { // Hogglo
    Name: 'Hogglo',
    id: '40',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 375, Pos: '37252.06,52166.4 37395.75,52291.2 37807.64,52291.2 37807.64,52166.4 37903.43,52108.8 37903.43,51811.2 37807.64,51676.8 37644.8,51379.2 37271.22,51408' },
      { Min: 'Prowler', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 85, Mix: '', Notes: '', Id: 374, Pos: '36485.74,51705.6 36706.06,51657.6 36821.01,51628.8 36974.27,51705.6 37175.43,51705.6 37242.48,51619.2 37242.48,51408 37175.43,51350.4 36993.43,50947.2 36715.64,50956.8 36571.95,50899.2 36342.06,51302.4 36457,51523.2' },
      { Min: 'Guardian', Max: 'Old Alpha', Maturity: 'mid', Density: 'Big', DensitySize: 85, Mix: '', Notes: '', Id: 373, Pos: '35776.9,51254.4 35853.53,51225.6 36179.21,51244.8 36284.58,51273.6 36389.95,51100.8 36476.16,50870.4 36543.22,50812.8 36533.64,50380.8 36389.95,50380.8 35730.68,49980.4 35690.68,50937.6 35767.32,51120' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 376, Pos: '79761.27,93840 81042.46,93948 81150.22,93816 81246.01,93240 81162.2,93048 80982.59,93072 80695.22,93492 80683.25,93660 79809.16,93636' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 729, Pos: '17163,60505 17435,60297 17995,60281 19355,59289 19099,58665 18059,57705 17419,57705 17243,57769 16955,58009 16795,58249 16667,58601 16699,59481 16891,60345 16987,60473' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 10, Mix: 'Atrox', Notes: '', Id: 752, Pos: '22923,46665 23691,46633 24203,46713 24491,44617 24363,43977 23579,43769 22875,43881 22939,46009' },
    ]
  },
  { // Hiryuu
    Name: 'Hiryuu',
    id: '38',
    notes: '',
    spawns: [
      { Min: 'Old Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 378, Pos: '37663.96,55804.8 37826.8,55804.8 38027.96,55891.2 38056.7,55747.2 38008.8,55610.4 37663.96,55455.6' },
      { Min: 'Provider', Max: 'Prowler', Maturity: 'mid', Density: 'Big', DensitySize: 80.1, Mix: '', Notes: '', Id: 377, Pos: '78345.97,70588.8 78317.23,70742.4 78365.13,70857.6 78556.71,70896 78604.6,70771.2 79026.08,70713.6 79351.77,70656 80012.72,70742.4 80223.46,70742.4 80223.46,70608 79983.98,70464 79782.82,70348.8 79552.93,70320 79208.08,70377.6 78882.4,70531.2 78777.03,70368 78700.39,70329.6 78566.29,70358.4 78518.39,70473.6 78345.97,70540.8' },
      { Min: 'Mature', Max: 'Dominant', Maturity: 'mid', Density: 'Medium', DensitySize: 50.5, Mix: 'Mermoth, Neconu', Notes: '', Id: 379, Pos: '54592.93,68141.82 55205.98,67949.82 57006.83,66375.42 56144.72,66260.22 55320.93,66644.22 54439.66,67642.62' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Medium', DensitySize: 50.4, Mix: 'Armax Bull, Atrax, Atrox, Caudatergus, Molisk', Notes: '', Id: 380, Pos: '81899.78,78192 82206.31,78192 82369.15,78086.4 82369.15,75350.4 82254.2,75158.4 82263.78,74928 81918.94,74918.4' },
      { Min: 'Provider', Max: 'Dominant', Maturity: 'small', Density: 'Medium', DensitySize: 50.3, Mix: '', Notes: '', Id: 381, Pos: '75420.78,69816 75480.65,69972 75780,69828 76043.42,69576 76067.37,69444 75791.97,69264 75540.52,69540' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Medium', DensitySize: 50.2, Mix: 'Rippersnapper', Notes: '', Id: 382, Pos: '86095.38,77064 86586.31,77040 86634.2,77196 86538.41,77268 86682.1,77712 86801.83,77892 86646.18,78144 86765.91,78324 86945.52,78480 87196.97,78264 87328.68,78168 87184.99,78012 87244.86,77952 87663.94,77856 87711.84,77220 87843.55,76968 87819.6,76776 87125.13,76200 87029.34,76224 86945.52,76092 86825.78,76140 86837.76,76380 86682.1,76572 86741.97,76884 86586.31,76848 86634.2,76680 86574.33,76644 86358.81,76740 86059.46,76860' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Low & Spread out', DensitySize: 50.1, Mix: '', Notes: '', Id: 383, Pos: '85032.11,80755.2 86727.6,80755.2 86909.6,80553.6 88490.13,80534.4 88681.71,80409.6 88672.13,80294.4 88806.24,80208 88739.19,80044.8 88767.92,79814.4 88767.92,79516.8 88787.08,79180.8 88825.4,78960 88710.45,78748.8 88691.29,78316.8 87857.92,78643.2 87723.81,78950.4 87350.23,79430.4 87187.39,79468.8 87072.44,79411.2 86947.91,79228.8 86718.02,78998.4 86507.28,78988.8 85942.12,79084.8 85779.28,79200 85444.01,79171.2 85444.01,79286.4 85654.75,79593.6 85309.9,79516.8 85223.69,79564.8 85022.53,79516.8 85089.59,79660.8 85137.48,79852.8 85147.06,80121.6 85271.59,80352' },
      { Min: 'Guardian', Max: 'Old Alpha', Maturity: 'mid', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 384, Pos: '53236.75,60970.67 53258.04,61109.33 53375.12,61120 53492.19,61066.67 53566.7,60789.33 53449.62,60640 53375.12,60384 53226.11,60320 53140.96,60586.67 53151.61,60736 53109.03,60832 53130.32,60949.33 53215.47,60981.33' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'mid', Density: 'Low', DensitySize: 30.5, Mix: 'Argonaut', Notes: '', Id: 385, Pos: '73714.52,84240 74325.19,84240 74580.66,83556 73918.08,83556 73846.24,83448 73714.52,83448' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Low', DensitySize: 30.4, Mix: '', Notes: '', Id: 386, Pos: '62442.81,87611.62 62490.71,87822.82 62653.55,87861.22 62806.82,87592.42 62960.08,87304.42 63295.35,87064.42 63170.82,86930.02 63017.55,86853.22 62979.24,86709.22 62797.24,86786.02 62624.82,87006.82 62596.08,87189.22 62538.6,87486.82' },
      { Min: 'Guardian', Max: 'Alpha', Maturity: 'mid', Density: 'Low', DensitySize: 30.3, Mix: '', Notes: '', Id: 387, Pos: '56041.27,63277.8 56194.53,63297 56271.17,63143.4 56060.43,63162.6' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Low', DensitySize: 25, Mix: '', Notes: '', Id: 388, Pos: '32943.63,56239.47 33441.74,56239.47 33614.16,56546.67 33920.69,56527.47 34150.59,56431.47 34246.38,56143.47 34131.43,55490.67 34054.8,55125.87 34227.22,54837.87 34073.96,53801.07 34265.54,53090.67 33978.17,52514.67 33901.53,51823.47 33882.38,51381.87 33537.53,51017.07 32981.95,50633.07' },
      { Min: 'Mature', Max: 'Guardian', Maturity: 'small', Density: 'Low', DensitySize: 30.1, Mix: 'Bristlehog, Exarosaur, Trilomite', Notes: '', Id: 389, Pos: '32875.65,49119.46 34306.87,49119.46 34374.48,48927.46 34182.9,48814.52 34002.59,48656.4 33811.01,48656.4 33506.74,48227.23 33258.81,47831.93 32988.35,47447.93 33055.96,47323.7 33010.89,47165.58 32875.65,47041.34' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Very Low', DensitySize: 15, Mix: 'Chirpy, Daikiba, Thorio Female, Thorio Male', Notes: '', Id: 390, Pos: '55088.69,64746.67 55461.21,64586.67 56163.67,64586.67 56397.82,64405.33 56461.68,64277.33 56387.18,63968 56238.17,63904 56238.17,63797.33 56355.25,63605.33 56142.38,63552 55929.51,63584 55950.8,63253.33 55940.16,63093.33 55801.8,63146.67 55684.72,63413.33 55578.29,63808 55663.43,63936 55663.43,64181.33 55780.51,64266.67 55695.36,64384 55610.22,64384 55546.36,64277.33 55290.92,64234.67 55280.27,64021.33 55322.85,63850.67 55493.14,63808 55525.07,63562.67 55407.99,63509.33 55205.77,63573.33 55109.98,63680 54929.04,64181.33 54939.69,64533.33' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 10, Mix: 'Exarosaur, Fugabarba, Snablesnot Female, Snablesnot Male, Snarksnot', Notes: '', Id: 747, Pos: '16587,46281 19803,46169 19947,45561 19899,44777 18987,44633 18219,44681 18187,44809 17435,44985 16891,44601 16635,44873 16571,44985 16699,45641' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'small', Density: 'Low', DensitySize: 20, Mix: 'Molisk', Notes: '', Id: 896, Pos: '25462.59,62349.71 25385.96,61725.71 25271.01,61572.11 25251.86,61466.51 25357.22,61236.11 25539.23,61111.31 25788.28,60986.51 25941.54,60948.11 25951.12,62186.51 25788.28,62186.51 25635.02,62301.71' },
      { Min: 'Old', Max: 'Guardian', Maturity: 'small', Density: 'Low', DensitySize: 20, Mix: 'Argonaut, Exarosaur, Molisk', Notes: '', Id: 912, Pos: '28597.51,65408.77 28637.84,65135.93 28970.58,65055.09 29010.92,64873.19 29162.16,64792.35 29353.74,64943.93 29605.82,65004.56 29857.9,64974.24 29938.57,65216.77 29888.15,65398.66 29656.24,65398.66 29646.16,65297.61 29373.91,65317.82 29192.41,65439.09' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'small', Density: 'Very Low', DensitySize: 5, Mix: 'Bristlehog, Exarosaur, Trilomite', Notes: '', Id: 968, Pos: '35725.9,49104.67 39193.49,49123.87 39193.49,49027.87 38235.59,48663.07 38149.38,47789.47 37814.12,47683.87 37785.38,47770.27 37794.96,47760.67 38024.86,47991.07 37852.43,48451.87 37086.11,48864.67 36367.69,48691.87 35955.79,48663.07 35725.9,48807.07 35697.16,48960.67' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Low', DensitySize: 25, Mix: 'Gibnib', Notes: '', Id: 977, Pos: '28872.39,27218.84 28853.23,26959.64 28862.81,26825.24 29083.13,26777.24 29293.87,26834.84 29801.56,27314.84 30213.45,27670.04 30472.09,27900.44 30625.35,28159.64 30701.98,28447.64 30663.67,28601.24 30500.82,28783.64 30060.19,28706.84 29887.77,28639.64 29696.19,28486.04 29427.97,28025.24 29351.34,27574.04 28910.71,27334.04' },
    ]
  },
  { // Hispidus
    Name: 'Hispidus',
    id: '39',
    notes: '',
    spawns: [
      { Min: 'Prowler', Max: 'Stalker', Maturity: 'big', Density: 'Medium', DensitySize: 95, Mix: '', Notes: '', Id: 391, Pos: '79890,87075 80399,87077 80401,87221 80278,87402 80043,87499 79867,87328' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 5, Mix: 'Berycled, Shinkiba', Notes: '', Id: 733, Pos: '21915,56223 22779,56223 23019,55593 23019,55049 22779,54569 22619,54521 22635,54233 22363,54105 21963,54153 22091,54553 21931,54697 21771,55273' },
    ]
  },
  { // Igni
    Name: 'Igni',
    id: '41',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Stalker', Maturity: 'mid', Density: 'Big', DensitySize: 80.1, Mix: '', Notes: '', Id: 392, Pos: '54393.68,64372.2 54527.79,64410.6 54700.21,64180.2 54585.26,64007.4 54393.68,64103.4 54317.05,64276.2' },
      { Min: 'Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 393, Pos: '69278.25,69588 69469.83,69624 69551.54,69540 69480.86,69420 69469.83,69180 69314.17,69096' },
    ]
  },
  { // Itumatrox
    Name: 'Itumatrox',
    id: '42',
    notes: '',
    spawns: [
      { Min: 'Alpha', Max: 'Old Alpha', Maturity: 'big', Density: 'Big', DensitySize: 100, Mix: '', Notes: '', Id: 397, Pos: '80616,89853 80599,89641 80458,89521 80351,89523 80343,89748' },
      { Min: 'Alpha', Max: 'Old Alpha', Maturity: 'big', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 395, Pos: '81378,89042 81265,89115 81303,89261 81570,89108 81532,88975' },
      { Min: 'Prowler', Max: 'Stalker', Maturity: 'big', Density: 'BIg', DensitySize: 95, Mix: '', Notes: '', Id: 396, Pos: '81217,89682 81212,89467 81037,89501 81015,89782' },
      { Min: 'Young', Max: 'Old Alpha', Maturity: 'mid', Density: 'Medium', DensitySize: 50.4, Mix: '', Notes: '', Id: 394, Pos: '80216.27,81924 81868.65,81924 81868.65,80628 80300.09,80628 80216.27,80748 80204.3,80940' },
      { Min: 'Old Alpha', Max: 'Prowler', Maturity: 'big', Density: 'Very Low', DensitySize: 15, Mix: '', Notes: '', Id: 398, Pos: '55838.2,69946.62 56317.15,70215.42 56240.51,69735.42' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 15, Mix: '', Notes: '', Id: 756, Pos: '18923,44553 20427,44505 20651,43625 20715,42281 19499,42041 19499,43065 19307,43833' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Low', DensitySize: 25, Mix: 'Araneatrox, Bristlehog', Notes: '', Id: 786, Pos: '16800,39780 17296,40084 18400,40500 19200,40452 19440,40644 20016,40724 20112,38628 20048,38516 19456,38500 19424,38564 19168,38564 19232,38452 19216,38132 19584,37924 19472,37300 18976,36644 18720,36740 18704,36948 18880,37156 18720,37236 17760,36804 17680,36868 17600,37060 17392,37092 17360,37428 17216,37348 17200,36980 16992,36820 16688,36532 16480,37028 16544,37364 16976,38100 17264,38244 17376,38548 17600,38740 17712,38980 17920,39252 17840,39412 17472,39332 17168,39508 16992,39540' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Low', DensitySize: 25, Mix: 'Atrox, Exarosaur, Mermoth', Notes: '', Id: 793, Pos: '16848,28832 18928,29168 19648,28592 20464,28704 20624,28384 20448,27920 20928,27008 20912,26768 20560,26592 20048,25712 18784,25648 18128,25808 17952,25904 17552,26720 17632,27216 17808,27568' },
    ]
  },
  { // Kerberos
    Name: 'Kerberos',
    id: '114',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Big', DensitySize: 100, Mix: '', Notes: '', Id: 413, Pos: '80099.35,67809.18 80175.98,67837.98 80319.67,67799.58 80405.88,67665.18 80463.35,67530.78 80271.77,67261.98 80204.72,67540.38 80099.35,67799.58' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 100, Mix: '', Notes: '', Id: 414, Pos: '80030.68,69012 80246.21,69048 81012.53,68640 80988.58,68364 80844.89,68256 80832.92,68100 80593.44,68124 80605.42,68244 80701.21,68400 80198.31,68568 80042.65,68760' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Big', DensitySize: 100, Mix: '', Notes: '', Id: 405, Pos: '80455.75,73572 80958.64,73644 81030.49,73380 81174.17,73392 81269.96,73224 81066.41,73080 81138.25,72900 81281.93,72936 81485.49,72888 81736.94,72516 81772.86,72252 81677.07,71964 81677.07,71220 81736.94,70860 81605.23,70452 81413.65,70164 81198.12,70188 81269.96,70368 81497.46,70596 81533.38,70884 81413.65,70968 81126.28,70788 80791.01,71076 80814.96,71316 80898.77,71352 80982.59,71196 81138.25,71244 81162.2,71508 81257.99,71676 81257.99,71808 81413.65,71928 81246.01,72072 81222.07,72216 80910.75,72312 80814.96,72276 80623.38,72516 80731.14,72648 80635.35,73008 80431.8,73248 80515.62,73368' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 407, Pos: '36868.9,54816 37635.22,54816 37635.22,54374.4 36878.48,54345.6' },
      { Min: 'Stalker', Max: '', Maturity: 'big', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 408, Pos: '39876.71,52848 40106.6,52800 40077.87,52512 40135.34,52502.4 40192.81,52550.4 40307.76,52387.2 40317.34,52243.2 40183.24,52118.4 40192.81,52012.8 39838.39,52041.6 39790.5,52233.6 39780.92,52550.4' },
      { Min: 'Old', Max: 'Guardian', Maturity: 'mid', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 402, Pos: '39941.84,58858.62 40152.58,58781.82 40037.63,58513.02 39807.74,58493.82 39788.58,58589.82' },
      { Min: 'Provider', Max: 'Old Alpha', Maturity: 'mid', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 410, Pos: '39244.49,52118.4 39713.86,52089.6 39800.08,51820.8 39857.55,51446.4 39800.08,51312 39656.39,51273.6 39340.28,51302.4 39234.91,51427.2' },
      { Min: 'Provider', Max: 'Stalker', Maturity: 'mid', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 406, Pos: '79852.65,73182.82 80082.54,73144.42 80235.81,73163.62 80398.65,73221.22 80532.76,73058.02 80446.55,72837.22 80350.76,72750.82 80264.54,72702.82 80350.76,72587.62 80274.12,72453.22 80072.96,72434.02 80044.23,72587.62 79996.33,72770.02 79785.59,72808.42 79900.54,72894.82 79804.75,72981.22' },
      { Min: 'Old Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 88, Mix: '', Notes: '', Id: 401, Pos: '38845.05,58953.12 38940.84,59087.52 39438.95,59125.92 39534.74,59049.12 39726.32,59087.52 39937.06,58895.52 39764.63,58607.52 39573.05,58665.12 39477.26,58818.72 39266.53,58972.32 39036.63,58991.52 38921.68,58876.32' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Big', DensitySize: 85, Mix: '', Notes: '', Id: 409, Pos: '39397.76,51225.6 39723.44,51206.4 39838.39,51254.4 39886.29,51283.2 40087.45,51216 40068.29,51062.4 40135.34,50985.6 40106.6,50851.2 39733.02,51004.8 39388.18,51148.8' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Big', DensitySize: 85, Mix: '', Notes: '', Id: 411, Pos: '37070.06,52732.8 37299.96,52684.8 37472.38,52608 37491.54,52329.6 37357.43,52320 37223.32,52214.4 37060.48,52339.2 36955.11,52512' },
      { Min: 'Provider', Max: 'Old Alpha', Maturity: 'mid', Density: 'Big', DensitySize: 81.3, Mix: '', Notes: '', Id: 399, Pos: '68499.96,84260.63 68751.41,84260.63 68895.09,84224.63 69026.8,84212.63 69002.85,84068.63 68907.06,83912.63 68871.14,83828.63 68655.62,83900.63' },
      { Min: 'Provider', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 81.2, Mix: '', Notes: '', Id: 400, Pos: '66201,75980.63 66392.58,76016.63 66572.18,75932.63 66859.55,75596.63 66931.4,75320.63 67087.05,75140.63 67470.21,75056.63 67422.32,74864.63 67027.19,74924.63 66955.34,74864.63 66584.16,74876.63 66536.26,75188.63 66536.26,75512.63 66440.47,75752.63 66201,75836.63' },
      { Min: 'Old Alpha', Max: 'Stalker + Old Elite', Maturity: 'big', Density: 'Big', DensitySize: 80.9, Mix: '', Notes: '', Id: 403, Pos: '66069.29,69768 66224.94,69840 66404.55,69852 66620.08,69900 66703.89,69720 66632.05,69624 66033.36,69564 66009.42,69624' },
      { Min: 'Dominant', Max: 'Alpha', Maturity: 'mid', Density: 'Big', DensitySize: 80.8, Mix: '', Notes: '', Id: 404, Pos: '70774.97,71568 70918.65,71592 71134.18,71736 71194.05,71544 71313.79,71448 71397.6,71328 71445.5,71208 71397.6,71160 71277.87,71208 71265.89,71100 71373.66,71112 71421.55,71052 71349.71,70980 71265.89,70956 71265.89,70776 71158.13,70776 70763,70992 70751.02,71112 70786.94,71172 70691.15,71280 70751.02,71460' },
      { Min: 'Young', Max: 'Alpha', Maturity: 'mid', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 412, Pos: '73728.89,68880 73843.84,68947.2 73987.53,68937.6 74073.74,68745.6 74121.63,68505.6 74188.69,68294.4 74428.16,68265.6 74485.63,68208 74543.11,68054.4 74725.11,67852.8 74945.43,67622.4 75089.11,67430.4 75137.01,67267.2 75108.27,67161.6 75002.9,67084.8 74897.53,67075.2 74629.32,67286.4 74456.9,67353.6 74284.48,67372.8 73997.11,67334.4 73843.84,67382.4 73728.89,67536' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 415, Pos: '77881.39,73593.6 78810.55,73584 78810.55,73353.6 78772.24,73161.6 78551.92,73027.2 78187.92,73008 78005.92,73084.8 77958.02,73296 77938.86,73430.4' },
    ]
  },
  { // Kingfisher
    Name: 'Kingfisher',
    id: '44',
    notes: '',
    spawns: [
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Low', DensitySize: 15, Mix: '', Notes: '', Id: 797, Pos: '24704,32688 26608,32688 26592,32400 26464,31632 26000,30720 24928,30544 24624,30656 24608,31776' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: 'Kingfisher, Oculus', Notes: '', Id: 817, Pos: '36118,32314 37190,31978 37094,31018 36870,31018 36694,30858 36438,30298 35846,30170 35686,31306 36038,31514 35878,31786 35702,31786 35702,32010' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 845, Pos: '32966,43754 34262,43786 34550,43530 34774,43050 34838,41786 34678,41162 33926,41034 33046,41242 32822,41898 32870,43258' },
    ]
  },
  { // Letomie
    Name: 'Letomie',
    id: '129',
    notes: '',
    spawns: [
      { Min: 'Prowler', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 417, Pos: '40106.6,54969.6 40643.03,54950.4 40643.03,54739.2 40556.82,54633.6 40652.61,54441.6 40643.03,54278.4 40729.24,54163.2 40652.61,53971.2 40422.71,53721.6 40259.87,53856 40087.45,53923.2 39943.76,53971.2 39943.76,54048 39656.39,54432 39800.08,54662.4 39905.44,54787.2 40010.81,54787.2 40116.18,54969.6' },
      { Min: 'Provider', Max: 'Dominant', Maturity: 'mid', Density: 'Big', DensitySize: 92, Mix: '', Notes: '', Id: 419, Pos: '39062.49,54537.6 39455.23,54537.6 39867.13,53980.8 39665.97,53558.4 39043.33,53558.4 39043.33,54268.8' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Big', DensitySize: 80.3, Mix: '', Notes: '', Id: 416, Pos: '78324.42,95292 78899.16,95220 78899.16,95004 78923.11,94620 78755.47,94632 78432.18,94392 78001.13,94632' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Big', DensitySize: 80.1, Mix: '', Notes: '', Id: 418, Pos: '38564.38,54672 39072.07,54672 39024.18,54508.8 39014.6,54240 39005.02,53980.8 38382.38,53980.8 38382.38,54384' },
    ]
  },
  { // Longu
    Name: 'Longu',
    id: '46',
    notes: '',
    spawns: [
      { Min: 'Old Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 100, Mix: 'Sabakuma', Notes: '', Id: 420, Pos: '80916,77543 80899,77456 80964,77321 81032,77311 81125,77536' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'small', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 839, Pos: '37382,39658 37766,39850 38070,39802 38150,39578 38054,39306 38278,39066 38502,39226 38742,39210 39014,38858 39046,37914 38566,37898 38006,38426 37910,38698 37558,38858 37382,39002' },
      { Min: 'Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Medium', DensitySize: 50.3, Mix: '', Notes: '', Id: 421, Pos: '66662.78,70624 66902.26,70739.2 67017.21,70624 67055.52,70480 66902.26,70451.2 66796.89,70489.6 66681.94,70403.2 66624.47,70499.2' },
      { Min: 'Mature', Max: 'Stalker', Maturity: 'mid', Density: 'Medium', DensitySize: 50.2, Mix: '', Notes: '', Id: 422, Pos: '80814.96,79824 81856.68,79824 81856.68,78588 80814.96,78588' },
      { Min: 'Old', Max: 'Provider', Maturity: 'small', Density: 'Medium', DensitySize: 50.1, Mix: '', Notes: '', Id: 423, Pos: '66636.06,70295.58 66818.07,70439.58 66904.28,70429.98 67057.54,70439.58 67162.91,69988.38 67028.8,69921.18 67076.7,69690.78 66741.43,69594.78 66636.06,69940.38 66521.12,69969.18' },
      { Min: 'Old', Max: 'Dominant', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 424, Pos: '77263.54,72105.6 77857.44,72067.2 77828.71,71961.6 77771.23,71894.4 77838.28,71721.6 77819.13,71587.2 77560.49,71500.8 77445.55,71587.2 77311.44,71616 77158.18,71990.4 77215.65,72086.4' },
      { Min: 'Old', Max: 'Guardian', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: 'Sabakuma', Notes: '', Id: 425, Pos: '76944.59,81809.25 77164.91,81886.05 79061.55,81876.45 79051.97,81118.05 78908.29,80849.25 78323.97,80676.45 78122.81,80666.85 77883.34,80455.65 77701.34,80542.05 77519.33,80340.45 77260.7,80647.65 77212.81,81070.05 77069.12,81290.85 76982.91,81578.85' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Low', DensitySize: 25, Mix: 'Atrox, Gokibusagi', Notes: '', Id: 801, Pos: '28160,32000 28896,32080 29168,31664 29296,30864 29152,30832 29120,30368 28464,30000 28400,30768 27872,31536' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: 'Atrax, Trilomite', Notes: '', Id: 807, Pos: '33926,29770 35206,29882 36374,29402 36134,27642 34838,27626 33958,28234 33606,29050' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: '', Notes: '', Id: 839, Pos: '33590,36682 34118,36602 34166,35962 34086,35482 33638,35418 33110,35546 33046,36090 33174,36490' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 40, Mix: '', Notes: '', Id: 839, Pos: '36246,37130 36854,37114 36918,36890 37062,36842 37142,36762 37270,36762 37414,36202 37142,35914 36678,35786 36374,35802 36166,36058 36166,36618' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Medium', DensitySize: 40, Mix: 'TskTsk', Notes: '', Id: 889, Pos: '22371.77,64151.31 23013.56,63680.91 23128.51,63421.71 23463.78,63690.51 23684.09,63690.51 23751.15,63968.91 23674.51,64007.31 23626.62,64103.31 23377.56,64256.91 23147.67,64266.51 22936.93,64362.51 22841.14,64352.91 22687.88,64458.51 22611.24,64228.11 22448.4,64199.31' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Low', DensitySize: 20, Mix: 'Sabakuma', Notes: '', Id: 898, Pos: '23908.94,58245.44 23919.58,58117.44 23855.72,57957.44 23919.58,57808.11 24057.94,57754.78 24324.03,57968.11 24409.17,57978.78 24441.1,58170.78 24355.96,58277.44 24260.17,58352.11 24036.66,58245.44 23951.51,58298.78' },
    ]
  },
  { // Legionnaire
    Name: 'Legionnaire',
    id: '122',
    notes: '',
    spawns: [
      { Min: 'Gen 05', Max: 'Gen 06', Maturity: 'mid', Density: 'Big', DensitySize: 80, Mix: 'Trooper', Notes: '', Id: 426, Pos: '67613.9,71196 67769.56,71424 68044.95,71616 68272.46,71472 68487.98,71232 69014.83,71280 69182.46,71160 69194.43,70824 69050.75,70704 68966.93,70692 69230.36,70344 69326.15,69900 69230.36,69636 69074.7,69732 69050.75,70044 68990.88,70308 68535.88,70644 68404.17,70464 67865.35,70332 67817.45,70116 67853.37,69900 68044.95,69540 68787.33,69120 69002.85,69144 69146.54,69108 69050.75,68880 68895.09,68616 68667.59,68568 68128.77,68640 67518.11,68748 67601.93,69024 67482.19,69204 67146.92,69720 67087.05,69888 67374.42,70044 67506.14,70296 67458.24,70500 67518.11,70752' },
      { Min: 'Gen 03', Max: 'Gen 04', Maturity: 'mid', Density: 'Medium', DensitySize: 50.1, Mix: '', Notes: '', Id: 427, Pos: '68142.74,73225.6 68257.69,73408 68449.27,73350.4 68698.32,73408 68909.06,73484.8 69100.64,73379.2 69110.22,73129.6 69071.9,72966.4 68813.27,72918.4 68296,72956.8 68238.53,73100.8' },
      { Min: 'Gen 01', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 51, Mix: 'Trooper', Notes: '', Id: 428, Pos: '68225.76,72940.8 68311.97,72902.4 68743.02,72873.6 68877.13,72816 68896.29,72652.8 68723.87,72556.8 68493.97,72460.8 68139.55,72508.8 68101.23,72796.8' },
      { Min: 'Gen 02', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 50.9, Mix: '', Notes: '', Id: 429, Pos: '69200.42,72684 69380.03,72768 69547.66,72684 69715.29,72540 69571.61,72384' },
      { Min: 'Gen 01', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 50.8, Mix: '', Notes: '', Id: 430, Pos: '69763.19,72516 70074.51,72336 70254.11,72204 70290.03,71952 70218.19,71808 70110.43,71844 69990.69,72036 69835.03,72192 69655.42,72192 69619.5,72312 69595.55,72372' },
      { Min: 'Gen 09', Max: 'Gen 10', Maturity: 'big', Density: 'Medium', DensitySize: 50.7, Mix: '', Notes: '', Id: 431, Pos: '78585.45,70953.6 78824.92,71088 79102.71,71164.8 79188.92,71078.4 79208.08,70896 79093.13,70848 78633.34,70838.4' },
      { Min: 'Gen 05', Max: 'Gen 06', Maturity: 'mid', Density: 'Medium', DensitySize: 50.6, Mix: '', Notes: '', Id: 432, Pos: '78595.03,71376 78662.08,71520 78815.34,71539.2 79131.45,71548.8 79236.82,71356.8 78968.61,71174.4 78872.82,71145.6' },
      { Min: 'Gen 07', Max: 'Gen 08', Maturity: 'big', Density: 'Medium', DensitySize: 50.5, Mix: '', Notes: '', Id: 433, Pos: '78345.97,71932.8 78527.97,72105.6 78729.13,72096 78968.61,72153.6 78968.61,72038.4 78872.82,71980.8 78681.24,71942.4 78441.76,71731.2 78345.97,71740.8 78326.81,71884.8' },
      { Min: 'Gen 01', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 50.4, Mix: 'Trooper, Warrior', Notes: '', Id: 434, Pos: '78556.71,71779.2 78719.55,71923.2 78911.13,71980.8 78987.76,72048 79035.66,71990.4 79198.5,71721.6 79121.87,71644.8 79035.66,71644.8 79016.5,71577.6 78652.5,71577.6 78527.97,71654.4' },
      { Min: 'Gen 01', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 50.3, Mix: 'Trooper, Warrior', Notes: '', Id: 435, Pos: '35125.52,55939.2 35230.89,56169.6 35432.05,56361.6 35633.21,56486.4 35796.05,56572.8 35930.16,56630.4 36054.69,56496 36121.74,56188.8 36236.69,55968 36265.42,55814.4 36121.74,55737.6 35901.42,55708.8 35566.16,55699.2 35460.79,55795.2' },
      { Min: 'Gen 01', Max: 'Gen 02', Maturity: 'small', Density: 'Medium', DensitySize: 50.2, Mix: 'Droka, Trooper', Notes: '', Id: 436, Pos: '76626.54,79756.8 76846.86,79728 77115.07,79920 77268.33,80121.6 77431.18,80179.2 77689.81,80496 77890.97,80419.2 78159.18,80649.6 78389.08,80601.6 78436.97,80428.8 78762.66,80438.4 78868.03,80140.8 78580.66,79872 78647.71,79488 78484.87,79363.2 78647.71,79008 78676.45,78633.6 78283.71,78460.8 77852.65,78950.4 77546.13,79008 77009.7,79017.6 76636.12,79497.6' },
      { Min: 'Gen 04', Max: 'Gen 10', Maturity: 'big', Density: 'Medium', DensitySize: 50.1, Mix: 'Droka, Trooper', Notes: '', Id: 437, Pos: '82378.73,75158.4 83097.16,75148.8 83202.52,75206.4 83250.42,75292.8 83231.26,75657.6 83307.89,75744 83863.48,75753.6 83595.26,75465.6 83327.05,75033.6 82982.21,74697.6 82857.68,74736 82666.1,74908.8 82541.57,74928 82330.84,74918.4 82273.36,75024 82292.52,75139.2' },
      { Min: 'Gen 01', Max: 'Gen 02', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: 'Drone, Trooper, Warrior', Notes: '', Id: 438, Pos: '50331.12,59754.67 50607.85,59648 50916.51,59690.67 51246.45,59200 51118.73,58549.33 50767.5,58400 50277.9,58261.33 49990.54,58421.33 50001.18,58709.33 50224.69,59061.33 50245.98,59210.67 50384.34,59381.33' },
      { Min: 'Gen 01', Max: '', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: '', Notes: '', Id: 439, Pos: '50522.7,64618.67 50756.86,64746.67 50682.35,64309.33 50469.49,64330.67 50501.42,64437.33 50586.56,64544' },
    ]
  },
  { // Leviathan
    Name: 'Leviathan',
    id: '133',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Alpha', Maturity: 'mid', Density: 'Big', DensitySize: 85, Mix: 'Razortooth, Rippersnapper', Notes: '', Id: 440, Pos: '52971.2,61536 53239.41,61584 53373.52,61536 53593.84,61593.6 53794.99,61680 54101.52,61612.8 54350.58,61756.8 54523,61680 54484.68,61593.6 54590.05,61468.8 54570.89,61401.6 54331.42,61257.6 54283.52,61094.4 54139.84,61017.6 54034.47,60864 53823.73,60883.2 53632.15,60720 53517.2,60595.2 53507.62,60432 53411.83,60240 53258.57,60163.2 53258.57,60009.6 53191.52,59942.4 52990.36,59913.6 52913.73,60000 53028.67,60240 52923.31,60451.2 52846.67,60576 52674.25,60652.8 52319.83,60499.2 52214.46,60489.6 52233.62,60691.2 52377.3,60864 52473.09,60960 52559.3,61238.4 52712.57,61084.8 52942.46,61075.2 53009.52,61171.2 53057.41,61401.6' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Big', DensitySize: 75, Mix: '', Notes: '', Id: 441, Pos: '59654,78936 59360,78950 58876,78916 58629,78880 58693.46,77568 59174,77561 59712,77609' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 949, Pos: '35764.12,49913.6 36051.49,50115.2 36377.18,50288 36635.81,50316.8 36846.55,50403.2 36999.81,50403.2 37009.39,50048 36875.29,50028.8 36702.87,49875.2 36453.81,49798.4 36290.97,49798.4 36099.39,49644.8 35926.97,49740.8 35783.28,49808' },
      { Min: 'Old', Max: 'Old Alpha', Maturity: 'mid', Density: 'Big', DensitySize: 80, Mix: '', Notes: 'Mostly Old - Dominant', Id: 950, Pos: '37041.32,50409.6 37232.9,50572.8 37453.22,50649.6 37568.17,50764.8 37663.96,50784 37702.27,50832 38602.7,51062.4 38660.17,51177.6 39244.49,51235.2 39646.81,50976 39416.92,50697.6 39263.65,50745.6 38842.18,50716.8 38621.86,50419.2 38382.38,50380.8 38123.75,50438.4 37999.22,50534.4 37568.17,50380.8 37414.9,50169.6 37376.59,50035.2 37252.06,50016 37041.32,50083.2' },
    ]
  },
  { // Malcruentor
    Name: 'Malcruentor',
    id: '109',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Stalker', Maturity: 'mid', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 887, Pos: '21070.92,59291.82 21166.71,59339.82 21367.87,59311.02 21396.61,59157.42 21243.34,59051.82 21118.81,59109.42 21080.5,59186.22' },
    ]
  },
  { // Marcimex
    Name: 'Marcimex',
    id: '451',
    notes: '',
    spawns: [
      { Min: 'Weak', Max: 'Queen', Maturity: 'small', Density: 'Big', DensitySize: 80, Mix: '', Notes: 'Cyclop\'s Depths', Id: 958, Pos: '76741.49,71020.8 76827.7,71088 76990.54,71040 77009.7,70934.4 76933.07,70867.2' },
    ]
  },
  { // Maffoid
    Name: 'Maffoid',
    id: '47',
    notes: '',
    spawns: [
      { Min: 'Bandit', Max: 'Raider', Maturity: 'small', Density: 'Medium', DensitySize: 50.4, Mix: '', Notes: '', Id: 445, Pos: '66548.24,79304.63 66931.4,79352.63 67087.05,79640.63 67266.66,79664.63 67398.37,79496.63 67146.92,79208.63 66871.53,78896.63 66679.95,79100.63 66476.39,78992.63 66368.63,79136.63' },
      { Min: 'Outcast', Max: 'Raider', Maturity: 'small', Density: 'Medium', DensitySize: 50.3, Mix: '', Notes: '', Id: 446, Pos: '79258.37,79392 79485.87,79416 79557.71,79608 79677.45,79608 79809.16,79824 80791.01,79824 80791.01,78600 79773.24,78600 79713.37,78828 79545.74,79008 79330.21,79032 79126.66,79188 79234.42,79404 79461.93,79428' },
      { Min: 'Bandit', Max: 'Berserker', Maturity: 'small', Density: 'Medium', DensitySize: 50.2, Mix: 'Combibo', Notes: '', Id: 447, Pos: '75283.09,89952 78025.08,89844 78108.89,89676 78240.6,89568 78204.68,89196 78061,89124 77773.63,89136 77605.99,89004 75330.98,89076 75283.09,89820' },
      { Min: 'Warrior', Max: 'Warlord', Maturity: 'mid', Density: 'Medium', DensitySize: 50.1, Mix: '', Notes: '', Id: 448, Pos: '62080.83,86256 62119.15,86313.6 62243.67,86294.4 62368.2,86140.8 62483.15,86064 62406.52,85958.4 62310.73,85891.2 62186.2,86054.4' },
      { Min: 'Outcast', Max: 'Raider', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 449, Pos: '61429.46,86736 61611.46,86851.2 61860.51,87004.8 62052.09,86908.8 62262.83,86899.2 62406.52,86956.8 62760.94,86707.2 63191.99,86457.6 63565.57,86265.6 63623.05,85910.4 63527.26,85680 63057.89,85660.8 62808.83,85497.6 62636.41,85497.6 62511.88,85555.2 62416.09,85699.2 62387.36,85862.4 62511.88,85987.2 62674.73,85977.6 62789.68,86169.6 62827.99,86409.6 62483.15,86611.2 62425.67,86524.8 62195.78,86582.4 61975.46,86505.6 61898.83,86448 61755.14,86438.4 61678.51,86227.2 61486.93,86256 61400.72,86553.6' },
      { Min: 'Bandit', Max: 'Warrior', Maturity: 'small', Density: 'Low', DensitySize: 30.2, Mix: 'Atrox, Merp', Notes: '', Id: 450, Pos: '67997.06,74096.63 68368.25,74144.63 68440.09,74240.63 68511.93,74240.63 68667.59,74144.63 68799.3,74240.63 68835.22,74348.63 68919.04,74372.63 68978.91,74276.63 69134.57,74324.63 69302.2,74408.63 69469.83,74204.63 69565.62,73952.63 69565.62,73760.63 67745.61,73760.63' },
      { Min: 'Outrunner', Max: 'Grunt', Maturity: 'small', Density: 'Low', DensitySize: 30.1, Mix: '', Notes: '', Id: 451, Pos: '59208.33,85628.63 59232.27,85316.63 59363.99,85112.63 59040.69,84968.63 58861.09,85100.63 59004.77,85244.63 59100.56,85460.63' },
      { Min: 'Warlord', Max: 'Clan Warlord', Maturity: 'big', Density: 'Low', DensitySize: 30, Mix: '', Notes: '', Id: 452, Pos: '62273.61,86460 62417.29,86460 62537.03,86412 62501.11,86328 62393.34,86340 62261.63,86340' },
      { Min: 'Bandit', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 10, Mix: '', Notes: '', Id: 757, Pos: '20555,44505 21195,44521 21323,44441 21339,44249 21499,44153 21627,44217 21691,44377 21579,44585 21691,44713 21883,44521 22043,44537 22091,44665 22395,44665 22315,44489 22427,43977 22395,43465 22187,42937 21659,42521 20971,42425 20779,42617 20779,43289' },
      { Min: 'Raider', Max: 'Warrior', Maturity: 'mid', Density: 'Very Low', DensitySize: 15, Mix: 'Molisk', Notes: '', Id: 933, Pos: '22910.09,43855.02 23724.3,43730.22 23772.2,43567.02 23714.72,43413.42 23561.46,43307.82 23599.78,43135.02 23647.67,43106.22 23436.93,42981.42 23207.04,42866.22 22843.03,42568.62 22507.77,42779.82 22670.61,42895.02 22661.03,43202.22 22804.72,43413.42 22871.77,43547.82 22843.03,43730.22' },
    ]
  },
  { // Miner Bot
    Name: 'Miner Bot',
    id: '110',
    notes: '',
    spawns: [
      { Min: 'Gen 01', Max: 'Gen 05', Maturity: 'small', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 442, Pos: '35633.21,54672 35901.42,54681.6 35997.21,54624 36121.74,54681.6 36188.79,54489.6 36313.32,54259.2 36322.9,53568 35767.32,53568.8 35748.16,54038.4 35633.21,54048' },
      { Min: 'Gen 07', Max: 'Gen 10', Maturity: 'big', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 444, Pos: '36188.79,55584 36399.53,55612.8 36686.9,55478.4 36821.01,55478.4 36821.01,54854.4 36246.27,54873.6 36399.53,55084.8 36313.32,55161.6 36169.63,55190.4' },
      { Min: 'Gen 03', Max: 'Gen 07', Maturity: 'mid', Density: 'Big', DensitySize: 85, Mix: '', Notes: '', Id: 443, Pos: '36227.11,54835.2 36830.59,54825.6 36849.74,54336 36342.06,54364.8 36246.27,54604.8' },
    ]
  },
  { // Mind Reaver
    Name: 'Mind Reaver',
    id: '449',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Stalker', Maturity: 'small', Density: 'Big', DensitySize: 80, Mix: '', Notes: 'Cyclop\'s Depths', Id: 957, Pos: '76741.49,71020.8 76827.7,71088 76990.54,71040 77009.7,70934.4 76933.07,70867.2' },
    ]
  },
  { // Molisk
    Name: 'Molisk',
    id: '50',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Medium', DensitySize: 51.7, Mix: '', Notes: '', Id: 453, Pos: '84359.19,90392.63 84562.74,90404.63 84874.06,90536.63 85077.61,90452.63 85424.85,90620.63 85604.46,90476.63 85879.86,90524.63 86107.36,90632.63 86131.3,90380.63 86263.01,90284.63 86298.94,90128.63 84167.61,90128.63' },
      { Min: 'Young', Max: 'Scavanger', Maturity: 'small', Density: 'Medium', DensitySize: 51.6, Mix: '', Notes: '', Id: 454, Pos: '82898.39,93788.63 83389.32,93740.63 83772.48,93620.63 83772.48,93524.63 83940.11,93416.63 84023.92,93284.63 83916.16,93188.63 83928.13,92948.63 83820.37,92816.63 83736.55,92576.63 83365.37,92576.63 83233.66,92660.63 83209.71,92768.63 82766.68,92780.63 82826.55,93020.63 82862.47,93224.63 82766.68,93452.63 82814.57,93548.63 82718.79,93608.63' },
      { Min: 'Adult', Max: 'Hunter', Maturity: 'mid', Density: 'Medium', DensitySize: 51.5, Mix: '', Notes: '', Id: 455, Pos: '83856.29,96176.63 84131.69,96272.63 84622.61,96056.63 84778.27,95816.63 84766.3,95672.63 84874.06,95456.63 85173.4,95456.63 84838.14,94952.63 84634.59,94712.63 84419.06,94712.63 84287.35,94736.63 84155.64,94676.63 84227.48,94520.63 84359.19,94424.63 84335.24,94220.63 83916.16,94436.63 83640.76,94568.63 83568.92,94640.63 83700.63,94760.63 83209.71,95096.63 83137.87,95168.63 83089.97,95360.63 83293.53,95480.63 83544.97,95756.63 83748.53,96008.63' },
      { Min: 'Young', Max: 'Scout', Maturity: 'small', Density: 'Medium', DensitySize: 51.4, Mix: '', Notes: '', Id: 456, Pos: '72110.04,84356.63 72301.62,84392.63 72361.49,84380.63 72325.57,84248.63 72241.75,83948.63 72144.12,84008.63 72163.99,84212.63' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Medium', DensitySize: 51.3, Mix: 'Argonaut, Foul', Notes: '', Id: 457, Pos: '65548.43,83732.63 66165.08,83744.63 66392.58,83708.63 66943.37,83624.63 67266.66,83216.63 67254.69,82940.63 67446.27,82688.63 67266.66,82232.63 67290.61,81944.63 65548.43,81968.63' },
      { Min: 'Young', Max: 'Adult', Maturity: 'small', Density: 'Medium', DensitySize: 51.2, Mix: 'Cornundos', Notes: '', Id: 458, Pos: '68404.17,76388.63 68643.64,76400.63 68919.04,76244.63 68907.06,76004.63 69254.3,75956.63 69218.38,75692.63 69002.85,75644.63 68942.99,75500.63 68799.3,75404.63 68703.51,75464.63 68715.48,75692.63 68655.62,75788.63 68464.04,75884.63 68200.61,75920.63 68212.59,76016.63 68308.38,76088.63 68356.27,76232.63' },
      { Min: 'Young', Max: 'Gatherer', Maturity: 'small', Density: 'Medium', DensitySize: 51.1, Mix: 'Cornundos', Notes: '', Id: 459, Pos: '67027.19,76076.63 67338.5,76136.63 67422.32,76052.63 67613.9,76112.63 67721.66,76304.63 67937.19,76196.63 68068.9,76028.63 68152.72,75728.63 68416.14,75608.63 68404.17,75344.63 68176.67,75224.63 67757.58,75152.63 67302.58,75260.63 67218.77,75644.63' },
      { Min: 'Young', Max: 'Scout', Maturity: 'small', Density: 'Medium', DensitySize: 51, Mix: '', Notes: '', Id: 460, Pos: '62920.19,81360 63111.77,81312 63387.17,81324 63626.64,81420 63770.33,81324 63638.61,81180 63470.98,81144 63315.32,81120 63207.56,81096 62860.32,81204' },
      { Min: 'Young', Max: 'Scout', Maturity: 'small', Density: 'Medium', DensitySize: 50.9, Mix: 'Argonaut', Notes: '', Id: 461, Pos: '78049.02,75492 78252.58,75444 78456.13,75504 78647.71,75396 78791.4,75528 79018.9,75636 79306.27,75624 79533.77,75636 79581.66,75408 79557.71,75120 79294.29,75300 79246.4,75216 79054.82,75060 78923.11,75120 78803.37,75264 78731.53,75204 78516,75180 78312.45,75360 78072.97,75396' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Medium', DensitySize: 50.8, Mix: '', Notes: '', Id: 462, Pos: '72565.47,69469.98 72958.2,69479.58 73063.57,69537.18 73168.94,69681.18 73350.94,69690.78 73504.21,69575.58 73456.31,69316.38 73245.57,69018.78 73456.31,68884.38 73322.21,68778.78 73255.15,68615.58 73101.89,68711.58 72776.2,68529.18 72584.62,68452.38 72460.1,68615.58 72498.41,68884.38 72575.04,68961.18 72699.57,68980.38 72622.94,69105.18 72632.52,69258.78 72479.25,69306.78 72488.83,69441.18' },
      { Min: 'Young', Max: 'Raider', Maturity: 'small', Density: 'Medium', DensitySize: 50.7, Mix: '', Notes: '', Id: 463, Pos: '70513.54,69424 70609.33,69491.2 70743.44,69510.4 70791.33,69481.6 70762.6,69395.2 70848.81,69366.4 70906.28,69385.6 70896.7,69472 70973.33,69481.6 71049.97,69452.8 71241.55,69520 71299.02,69472 71279.86,69356.8 71212.81,69328 71097.86,69328 71030.81,69241.6 70915.86,69318.4 70877.54,69193.6 70791.33,69193.6 70714.7,69193.6 70657.23,69289.6 70532.7,69241.6' },
      { Min: 'Young', Max: 'Hunter', Maturity: 'mid', Density: 'Medium', DensitySize: 50.6, Mix: '', Notes: '', Id: 464, Pos: '71142.56,69955.2 71343.72,70099.2 71516.14,70060.8 71611.93,69916.8 71860.99,69753.6 71793.94,69676.8 71908.88,69571.2 71746.04,69475.2 71678.99,69273.6 71736.46,69139.2 71554.46,69014.4 71429.93,68995.2 71334.14,69024 71295.83,69052.8 71286.25,69196.8 71362.88,69321.6 71592.78,69369.6 71631.09,69456 71640.67,69552 71621.51,69638.4 71477.83,69686.4 71506.57,69801.6 71468.25,69840 71353.3,69782.4 71228.77,69772.8 71142.56,69830.4' },
      { Min: 'Young', Max: 'Scavanger', Maturity: 'small', Density: 'Medium', DensitySize: 50.5, Mix: 'Armax Bull, Armax Cow, Feffoid', Notes: '', Id: 465, Pos: '87336.28,79377.18 87709.86,78906.78 87824.81,78599.58 88361.24,78417.18 88964.71,78138.78 88897.66,77937.18 88734.82,77908.38 88782.71,77533.98 88897.66,77274.78 88782.71,77063.58 88610.29,77207.58 88504.92,77101.98 88437.87,77130.78 88399.55,77341.98 88140.92,77495.58 88006.81,77476.38 87930.18,77409.18 87939.76,77553.18 87843.97,77716.38 87882.29,77879.58 87729.02,77956.38 87633.23,78033.18 87623.65,78157.98 87671.55,78273.18 87719.44,78330.78 87451.23,78532.38 87135.12,78714.78 86943.54,78676.38 86771.12,78666.78 86751.96,78762.78 86799.86,78897.18 87058.49,79098.78 87087.23,79300.38 87202.18,79396.38' },
      { Min: 'Adult', Max: 'Guardian', Maturity: 'small', Density: 'high', DensitySize: 50.4, Mix: '', Notes: '', Id: 466, Pos: '58920.96,83988 59232.27,83868 59543.59,83832 59591.49,83688 59579.51,83592 59399.91,83556 59208.33,83604 58897.01,83712 58861.09,83856' },
      { Min: 'Young', Max: 'Scout', Maturity: 'small', Density: 'Medium', DensitySize: 50.3, Mix: 'Argonaut', Notes: '', Id: 467, Pos: '77603.6,75523.2 77938.86,75513.6 78053.81,75350.4 78283.71,75292.8 78436.97,75120 78389.08,75024 78312.45,74956.8 78197.5,75004.8 77958.02,74908.8 77488.65,74918.4 77488.65,75014.4 77622.76,75081.6 77555.7,75254.4 77661.07,75379.2' },
      { Min: 'Young', Max: 'Scout', Maturity: 'small', Density: 'Medium', DensitySize: 50.2, Mix: 'Atrax, Berycled, Faucervix, Gokibusagi', Notes: '', Id: 468, Pos: '81898.58,87204 82796.61,87180 83099.13,86885.65 83290.71,86490.35 83263.59,86124 83395.3,85716 83443.2,84972 83395.3,84576 83263.59,84348 83131.88,84504 82856.48,84624 81898.58,84612' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 50.1, Mix: 'Atrax, Caudatergus, Snablesnot Female, Snablesnot Male', Notes: '', Id: 469, Pos: '81899.78,84585.6 82838.52,84595.2 83116.31,84480 83231.26,84316.8 83250.42,84057.6 83164.21,83865.6 82953.47,83577.6 83030.1,83270.4 83192.95,83088 83240.84,82896 83374.95,82771.2 83231.26,82560 83384.53,82368 83844.32,82252.8 84074.21,82166.4 84227.48,82080 84304.11,82147.2 84390.32,82137.6 84438.22,82080 84428.64,81955.2 81899.78,81955.2' },
      { Min: 'Scavanger', Max: 'Warrior', Maturity: 'mid', Density: 'Medium', DensitySize: 50, Mix: 'Armax Bull, Atrax, Atrox, Caudatergus, Hiryuu', Notes: '', Id: 470, Pos: '81899.78,78192 82206.31,78192 82369.15,78086.4 82369.15,75350.4 82254.2,75158.4 82263.78,74928 81918.94,74918.4' },
      { Min: 'Young', Max: 'Gatherer', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: 'Faucervix', Notes: '', Id: 471, Pos: '57520.03,84024 57998.98,83904 58681.48,83760 58777.27,83904 58645.56,84096 58322.27,84216 58010.95,84264 57723.58,84300 57496.08,84168' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 743, Pos: '21659,48553 23083,48633 23643,47561 23739,47097 23499,46777 22075,46777 22059,47145 21627,47625' },
      { Min: 'Adult', Max: 'Scout', Maturity: 'small', Density: 'Very Low', DensitySize: 5, Mix: 'Argonaut', Notes: 'South-East side', Id: 771, Pos: '25944,54925 26632,54925 27272,54173 27720,53053 27000,52269 26664,52061 25480,52045 24808,52093 24584,52461 24616,52589 24904,52797 25336,53261' },
      { Min: 'Young', Max: 'Adult', Maturity: 'small', Density: 'Medium', DensitySize: 60, Mix: 'Shinkiba, Trooper, Warrior', Notes: '', Id: 775, Pos: '32120,50893 32664,50781 32728,49773 32632,49261 32200,49165 30376,49197 30552,49581 31784,50717' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 5, Mix: 'Equus, Traeskeron', Notes: '', Id: 842, Pos: '34438,36522 34822,36618 35318,36602 35382,36090 35318,35626 35142,35610 34598,35210 34214,35306 34246,36058' },
      { Min: 'Young', Max: 'Adult', Maturity: 'small', Density: 'Very Low', DensitySize: 10, Mix: 'Tripudion', Notes: '', Id: 855, Pos: '27350,46634 28278,46538 29126,46682 29542,46474 29686,45914 29398,43786 27974,43978 27366,43722 26886,44170 27190,44762 27078,45450' },
      { Min: 'Young', Max: 'Scout', Maturity: 'small', Density: 'Big', DensitySize: 75, Mix: 'Armax Bull, Armax Cow', Notes: '', Id: 876, Pos: '72648.86,84308.63 72984.13,84428.63 73223.6,84392.63 73307.42,84500.63 73487.02,84524.63 73570.84,84392.63 73702.55,84392.63 73702.55,82556.63 73510.97,82436.63 73187.68,82496.63 73091.89,82928.63 73091.89,83024.63 73175.71,83120.63 73043.99,83444.63 72984.13,83708.63 72912.28,83720.63 72912.28,84008.63 72804.52,84152.63 72636.89,84080.63 72600.97,84152.63' },
      { Min: 'Young', Max: 'Scout', Maturity: 'small', Density: 'Big', DensitySize: 75, Mix: 'Armax Bull, Armax Cow', Notes: '', Id: 877, Pos: '72133.99,83972.63 72241.75,83936.63 72277.68,83828.63 72409.39,83804.63 72529.12,83936.63 72744.65,83804.63 72744.65,83480.63 72864.39,83432.63 72936.23,83348.63 72708.73,82832.63 72421.36,82964.63 72086.1,82868.63 72062.15,83012.63 71918.46,83012.63 72038.2,83264.63 72086.1,83372.63 72002.28,83432.63 71882.54,83420.63 71798.73,83600.63 72050.17,83876.63' },
      { Min: 'Adult', Max: 'Guardian', Maturity: 'small', Density: 'Low', DensitySize: 20, Mix: 'Berycled', Notes: '', Id: 893, Pos: '24687.47,63162.51 24687.47,61338.51 24862.31,61367.31 24929.36,61136.91 25053.89,61021.71 25168.84,60983.31 25274.21,61021.71 25590.31,60877.71 25647.79,60810.51 25714.84,60829.71 25772.31,60877.71 25753.16,60973.71 25494.52,61098.51 25322.1,61223.31 25216.73,61453.71 25235.89,61607.31 25341.26,61760.91 25398.73,62068.11 25427.47,62384.91 25168.84,62567.31 25140.1,62778.51 25015.57,62893.71 24967.68,63018.51 24814.41,63152.91' },
      { Min: 'Adult', Max: 'Guardian', Maturity: 'small', Density: 'Low', DensitySize: 20, Mix: 'Hiryuu', Notes: '', Id: 895, Pos: '25462.59,62349.71 25385.96,61725.71 25271.01,61572.11 25251.86,61466.51 25357.22,61236.11 25539.23,61111.31 25788.28,60986.51 25941.54,60948.11 25951.12,62186.51 25788.28,62186.51 25635.02,62301.71' },
      { Min: 'Adult', Max: 'Guardian', Maturity: 'small', Density: 'Low', DensitySize: 20, Mix: 'Berycled', Notes: '', Id: 901, Pos: '24693.99,58166.4 24703.57,57513.6 24828.1,57465.6 24885.57,57417.6 25115.47,57504 25259.15,57465.6 25105.89,57398.4 25096.31,57360 27222.85,57417.6 27069.58,57638.4 27088.74,57820.8 27242.01,57993.6 27462.32,58204.8 27452.74,58483.2 27548.53,58742.4 27701.8,58867.2 27606.01,58972.8 27481.48,58992 27289.9,58800 27031.27,58838.4 26830.11,58876.8 26418.21,59577.6 26274.53,59548.8 26188.32,59788.8 26226.63,59904 26159.58,60000 26035.05,60096 25939.26,60259.2 25833.89,60316.8 25728.52,60240 25671.05,60297.6 25565.68,60259.2 25556.1,60172.8 25402.84,60067.2 25431.58,59961.6 25642.31,59875.2 25680.63,59750.4 25345.36,59596.8 25153.78,59078.4 25278.31,58972.8 25326.21,58752 25479.47,58627.2 25872.21,58905.6 25776.42,58550.4 25584.84,58358.4 25297.47,58339.2 25163.36,58147.2 25067.57,58185.6 24981.36,58176 24923.89,58108.8 24799.36,58080' },
      { Min: 'Gatherer', Max: 'Scavenger', Maturity: 'mid', Density: 'Low', DensitySize: 20, Mix: 'Argonaut, Exarosaur, Hiryuu', Notes: '', Id: 911, Pos: '28597.51,65408.77 28637.84,65135.93 28970.58,65055.09 29010.92,64873.19 29162.16,64792.35 29353.74,64943.93 29605.82,65004.56 29857.9,64974.24 29938.57,65216.77 29888.15,65398.66 29656.24,65398.66 29646.16,65297.61 29373.91,65317.82 29192.41,65439.09' },
      { Min: 'Hunter', Max: 'Leader', Maturity: 'big', Density: 'Low', DensitySize: 20, Mix: 'Argonaut', Notes: '', Id: 914, Pos: '30575.97,65327.53 30662.18,65135.53 30623.86,64377.13 30892.07,64271.53 31026.18,63993.13 31275.23,63820.33 31409.34,63887.53 31179.44,64108.33 31198.6,64386.73 31217.76,64626.73 31074.08,64914.73 31054.92,65433.13 30777.13,65471.53 30614.28,65385.13' },
      { Min: 'Young', Max: 'Adult', Maturity: 'small', Density: 'Very Low', DensitySize: 10, Mix: '', Notes: '', Id: 916, Pos: '30889.71,63093.1 31071.71,63265.9 31339.92,63265.9 31560.24,63102.7 31426.13,62949.1 31167.5,63016.3 30995.08,62987.5' },
      { Min: 'Young', Max: 'Gatherer', Maturity: 'small', Density: 'Low', DensitySize: 25, Mix: 'Argonaut', Notes: '', Id: 917, Pos: '32280.56,63494.4 32577.51,63408 32452.98,63206.4 32510.46,62812.8 32443.4,62486.4 32261.4,62419.2 32108.14,62592 32232.67,62841.6 32223.09,63216 32280.56,63379.2' },
      { Min: 'Scavenger', Max: 'Leader', Maturity: 'mid', Density: 'Medium', DensitySize: 50, Mix: 'Maffoid', Notes: '', Id: 932, Pos: '22910.09,43855.02 23724.3,43730.22 23772.2,43567.02 23714.72,43413.42 23561.46,43307.82 23599.78,43135.02 23647.67,43106.22 23436.93,42981.42 23207.04,42866.22 22843.03,42568.62 22507.77,42779.82 22670.61,42895.02 22661.03,43202.22 22804.72,43413.42 22871.77,43547.82 22843.03,43730.22' },
      { Min: 'Adult', Max: 'Scavenger', Maturity: 'small', Density: 'Low', DensitySize: 23, Mix: '', Notes: '', Id: 980, Pos: '31346.61,26640 31494.56,26904.52 31902.19,26976 32160.82,27014.4 32352.4,26976 32476.93,26601.6 32323.67,26131.2 32132.09,25795.2 31499.87,25852.8 31337.03,26294.4' },
    ]
  },
  { // Morsusilex
    Name: 'Morsusilex',
    id: '51',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Prowler', Maturity: 'mid', Density: 'Medium', DensitySize: 50.1, Mix: '', Notes: '', Id: 472, Pos: '67036.37,72236.8 67132.16,72304 67409.95,72150.4 67620.68,71939.2 67821.84,71718.4 67735.63,71564.8 67534.47,71545.6 67467.42,71459.2 67390.79,71334.4 67362.05,71142.4 67285.42,71036.8 67065.1,71027.2 66988.47,71459.2 67208.79,71766.4 67084.26,72016' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: 'Berycled', Notes: '', Id: 473, Pos: '87517.86,76416 87680.71,76627.2 87862.71,76771.2 87891.45,76982.4 87738.18,77155.2 87766.92,77280 88035.13,77443.2 88140.5,77443.2 88389.55,77299.2 88418.29,77088 88514.08,77049.6 88619.45,77155.2 88791.87,77011.2 88791.87,76809.6 88657.76,76492.8 88676.92,76252.8 88763.13,76089.6 88897.24,76032 89165.45,76099.2 89213.35,75916.8 89213.35,75648 88533.24,75004.8 88169.24,74928 87987.24,74956.8 87728.6,75129.6 87556.18,75129.6 87537.02,75638.4 87431.65,75811.2 87460.39,76089.6' },
    ]
  },
  { // Mermoth
    Name: 'Mermoth',
    id: '48',
    notes: '',
    spawns: [
      { Min: 'Mature', Max: 'Alpha', Maturity: 'small', Density: 'Medium', DensitySize: 50.2, Mix: 'Atrax, Chirpy', Notes: '', Id: 474, Pos: '53309.34,62305.02 53673.34,62228.22 54094.82,62343.42 54286.4,62247.42 54420.5,62093.82 54631.24,62113.02 54822.82,61921.02 54497.14,61729.02 54324.71,61805.82 54075.66,61633.02 53769.13,61709.82 53443.45,61575.42 53232.71,61575.42 53232.71,61767.42 53405.13,62074.62' },
      { Min: 'Guardian', Max: 'Old Alpha', Maturity: 'mid', Density: 'Medium', DensitySize: 50.1, Mix: '', Notes: '', Id: 475, Pos: '53031.55,62055.42 53108.18,61805.82 52878.28,61786.62 52878.28,61997.82' },
      { Min: 'Mature', Max: 'Dominant', Maturity: 'mid', Density: 'Medium', DensitySize: 50, Mix: 'Hiryuu, Neconu', Notes: '', Id: 476, Pos: '54592.93,68141.82 55205.98,67949.82 57006.83,66375.42 56144.72,66260.22 55320.93,66644.22 54439.66,67642.62' },
      { Min: 'Cub', Max: 'Juvenile', Maturity: 'small', Density: 'Low', DensitySize: 30.4, Mix: '', Notes: '', Id: 477, Pos: '59974.65,76760.63 60022.54,76736.63 60154.25,76400.63 60321.89,76388.63 60465.57,76340.63 60513.47,76244.63 60465.57,76112.63 60381.75,76004.63 60285.96,75848.63 60381.75,75800.63 60381.75,75716.63 60226.1,75548.63 60238.07,75416.63 60154.25,75524.63 60285.96,75764.63 60142.28,75728.63 60094.38,75812.63 60106.36,75872.63 60357.81,76004.63 60345.83,76064.63 60417.68,76160.63 60381.75,76316.63 60250.04,76340.63 60106.36,76304.63 59974.65,76412.63 59914.78,76652.63' },
      { Min: 'Cub', Max: 'Juvenile', Maturity: 'small', Density: 'Low', DensitySize: 30.3, Mix: '', Notes: '', Id: 478, Pos: '63890.06,90048 64165.46,90096 64081.64,89748 63878.09,89892' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Low', DensitySize: 30.2, Mix: '', Notes: '', Id: 479, Pos: '60070.44,77276.63 60297.94,77300.63 60321.89,77144.63 60214.12,77060.63 60190.17,76916.63 60094.38,76892.63 60118.33,77024.63 60118.33,77192.63' },
      { Min: 'Cub', Max: '', Maturity: 'small', Density: 'Low', DensitySize: 30.1, Mix: '', Notes: '', Id: 480, Pos: '62201.76,89724 62381.37,89496 62596.9,89568 62572.95,89484 62189.79,89352 62141.9,89460' },
      { Min: 'Cub', Max: 'Juvenile', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: '', Notes: '', Id: 481, Pos: '62070.05,89184 62297.55,89232 62261.63,89052 62177.82,88980 62034.13,88980 61950.32,89064' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Low', DensitySize: 15, Mix: 'Bristlehog, Cersumon', Notes: '', Id: 789, Pos: '18080,36548 18288,36324 18256,35812 18064,35284 18240,35124 18336,34852 18832,34804 18992,34932 19536,34580 19888,34292 20112,34036 19920,33860 19632,34068 19408,33908 18688,34180 18224,34116 17968,34212 17648,34068 17296,34084 17056,34260 17008,34516 16672,34740 16576,35236 16784,35860 17376,36388' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Low', DensitySize: 25, Mix: 'Atrox, Exarosaur, Itumatrox', Notes: '', Id: 792, Pos: '16848,28832 18928,29168 19648,28592 20464,28704 20624,28384 20448,27920 20928,27008 20912,26768 20560,26592 20048,25712 18784,25648 18128,25808 17952,25904 17552,26720 17632,27216 17808,27568' },
      { Min: 'Mature', Max: 'Provider', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: '', Notes: '', Id: 930, Pos: '23740.36,43742.92 23788.26,43560.52 23730.78,43397.32 23596.68,43320.52 23606.26,43157.32 23702.05,43147.72 23845.73,43243.72 23922.36,43358.92 23970.26,43502.92 24133.1,43579.72 24238.47,43598.92 24382.16,43896.52' },
    ]
  },
  { // Mourner
    Name: 'Mourner',
    id: '52',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Big', DensitySize: 80, Mix: 'Cornundos', Notes: '', Id: 482, Pos: '57687.66,82892.63 57879.24,82976.63 58118.72,82808.63 58334.24,82592.63 58645.56,82592.63 59004.77,82436.63 59304.12,82448.63 59747.15,82364.63 60022.54,82328.63 60046.49,82112.63 59938.73,81932.63 58286.35,81944.63 57903.19,82208.63 57903.19,82484.63' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 10, Mix: '', Notes: '', Id: 726, Pos: '18251,65241 19227,65337 20507,64969 20843,63785 19243,63673 17995,63721 17803,63785 17739,64057 17915,64873' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 5, Mix: 'Berycled, Combibo', Notes: '', Id: 774, Pos: '28216,51549 28568,51597 29416,51597 29432,51261 29544,50893 30152,50029 30040,49597 29688,49245 29112,49325 28696,49453 28392,49741 28184,50125 28024,50733 28040,51165' },
    ]
  },
  { // Merp
    Name: 'Merp',
    id: '49',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Big', DensitySize: 100, Mix: '', Notes: '', Id: 484, Pos: '63931.97,78212.63 63967.89,78320.63 64075.66,78320.63 64231.32,78224.63 64327.11,78104.63 64315.13,78020.63 64195.39,77936.63 64039.74,78032.63 63991.84,78176.63 63908.02,78212.63' },
      { Min: 'Provider', Max: 'Stalker', Maturity: 'mid', Density: 'Big', DensitySize: 85, Mix: '', Notes: '', Id: 487, Pos: '73720.51,70212 73720.51,71640 73864.2,71580 73888.14,71352 74103.67,71016 74139.59,70884 74331.17,70800 74522.75,70560 74666.44,70380 74666.44,70200 74486.83,70308 74307.23,70248 74379.07,70020 74522.75,69984 74666.44,69996 74798.15,69912 74810.12,69780 74774.2,69720 74630.52,69696 74271.3,69732 74115.65,69660 74055.78,69528 73888.14,69552 73720.51,69708' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 85, Mix: '', Notes: '', Id: 485, Pos: '64268.67,77430.21 64441.1,77391.81 64565.62,76969.41 64450.67,76815.81 64412.36,76681.41 64259.09,76451.01 64153.73,76460.61 64105.83,76566.21 64115.41,76739.01 63962.15,76969.41 64239.94,77324.61' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Big', DensitySize: 80.4, Mix: '', Notes: '', Id: 483, Pos: '55981.88,73258.62 56729.04,72797.82 56958.94,72145.02 56345.88,71223.42 55387.98,71415.42 54928.19,71069.82 54564.19,71895.42 55119.77,72836.22' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 80.1, Mix: '', Notes: '', Id: 486, Pos: '65548.43,76868.63 65883.69,76796.63 66111.19,77000.63 66314.75,77024.63 66434.49,76736.63 66434.49,76388.63 66506.33,76268.63 66733.83,76244.63 66829.62,76088.63 66805.67,75920.63 66709.88,75872.63 66566.2,75980.63 66386.59,76052.63 66242.9,76016.63 66159.09,76004.63 66183.04,75824.63 65548.43,75824.63' },
      { Min: 'Mature', Max: 'Guardian', Maturity: 'small', Density: 'Medium', DensitySize: 50.7, Mix: '', Notes: '', Id: 488, Pos: '81371.74,84072 81467.53,83652 81838.71,83556 81850.69,82152 81491.48,82116 80761.08,82116 80581.47,82104 80353.97,82296 80485.68,82416 80773.05,82536 80856.87,82788 80820.95,82956' },
      { Min: 'Young', Max: 'Alpha', Maturity: 'mid', Density: 'Medium', DensitySize: 50.6, Mix: '', Notes: '', Id: 489, Pos: '75358.92,91360 75563.27,91436.8 75601.59,91616 75729.31,91654.4 75729.31,91488 75767.62,91360 75805.94,91168 75690.99,91027.2 75588.82,90950.4 75384.46,91129.6' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Medium', DensitySize: 50.5, Mix: 'Exarosaur, Snablesnot Female, Snablesnot Male, Snarksnot', Notes: '', Id: 490, Pos: '74301.24,91284 74624.53,91380 74624.53,91488 74744.27,91572 75127.43,91428 75139.4,91104 75582.43,90780 75558.48,90444 75402.82,90084 73726.5,90108 73894.13,90264 74133.61,90360 74025.84,90624 74073.74,90936 74085.71,91104 74133.61,91200 74241.37,91200' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50.4, Mix: '', Notes: '', Id: 491, Pos: '69362.07,68988 69350.09,69084 69517.73,69168 69529.7,69408 69589.57,69540 69601.54,69624 69733.25,69612 69757.2,69444 69924.83,69384 70008.65,69288 69996.68,69096 69852.99,69096 69769.17,68904 69888.91,68712 69996.68,68628 70068.52,68604 70092.47,68472 70164.31,68400 70176.28,68148 69936.81,68016 69805.1,68112 69733.25,68136 69733.25,68316 69613.52,68352 69457.86,68448 69362.07,68592 69218.38,68688 69170.49,68856' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Medium', DensitySize: 50.3, Mix: '', Notes: '', Id: 492, Pos: '70261.3,68390.4 70318.77,68476.8 70510.35,68505.6 70529.51,68275.2 70749.82,67987.2 70797.72,67756.8 70826.46,67584 70673.19,67459.2 70596.56,67488 70567.82,67689.6 70567.82,67814.4 70500.77,67948.8 70567.82,68044.8 70519.93,68131.2 70366.66,68121.6' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50.2, Mix: 'Caudatergus', Notes: '', Id: 493, Pos: '71396.83,70506.78 71444.72,70573.98 71550.09,70564.38 71588.41,70449.18 71684.2,70420.38 71684.2,70468.38 71770.41,70477.98 72038.62,70324.38 72086.52,70170.78 71971.57,70151.58 72048.2,69940.38 72096.09,69805.98 72230.2,69892.38 72316.41,69777.18 72249.36,69623.58 72086.52,69585.18 72019.46,69594.78 71914.09,69748.38 71894.94,69834.78 71779.99,69921.18 71655.46,69930.78 71760.83,70093.98 71722.51,70199.58 71435.14,70333.98' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50.1, Mix: 'Caudatergus, Cornundos', Notes: '', Id: 494, Pos: '73750.45,80412 74169.53,80388 74528.74,80136 74576.64,79788 74720.32,79608 74708.35,79236 74636.5,78972 74816.11,78588 74432.95,78384 74373.08,78156 73750.45,78240' },
      { Min: 'Mature', Max: 'Guardian', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: 'Caudatergus', Notes: '', Id: 495, Pos: '74490.42,78345.6 75582.43,78854.4 75716.54,79008 75927.27,79046.4 76300.86,79200 76358.33,79372.8 76607.38,79488 76961.81,78998.4 76664.86,78864 76339.17,78566.4 76147.59,78201.6 75984.75,78067.2 75754.85,78115.2 75639.9,78297.6 75371.69,78288 75189.69,78201.6 75160.95,77942.4 75189.69,77740.8 75390.85,77616 75228.01,77481.6 75036.43,77433.6 74758.64,77596.8 74710.74,77779.2 74710.74,77932.8 74634.11,78028.8 74500,78009.6 74423.37,78153.6' },
      { Min: 'Provider', Max: 'Guardian', Maturity: 'mid', Density: 'Low', DensitySize: 30.6, Mix: 'Berycled, Sabakuma', Notes: '', Id: 496, Pos: '72493.2,82832.63 72696.76,82760.63 72660.84,82568.63 72588.99,82472.63 72421.36,82412.63 72285.68,82470.63 72340.75,82556.63 72400.49,82712.63' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Low', DensitySize: 30.5, Mix: 'Atrox, Maffoid', Notes: '', Id: 497, Pos: '67997.06,74096.63 68368.25,74144.63 68440.09,74240.63 68511.93,74240.63 68667.59,74144.63 68799.3,74240.63 68835.22,74348.63 68919.04,74372.63 68978.91,74276.63 69134.57,74324.63 69302.2,74408.63 69469.83,74204.63 69565.62,73952.63 69565.62,73760.63 67745.61,73760.63' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Low', DensitySize: 30.4, Mix: '', Notes: '', Id: 498, Pos: '58717.4,80012.63 58873.06,80084.63 59052.67,80084.63 59208.33,79904.63 59423.85,79724.63 59483.72,79592.63 59363.99,79424.63 59136.48,79424.63 58956.88,79592.63 58741.35,79736.63' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Low', DensitySize: 30.3, Mix: 'Berycled, Cornundacauda, Prancer', Notes: '', Id: 499, Pos: '73744.46,86580 74007.88,86628 74163.54,86592 74331.17,86556 74438.94,86604 74400.05,86040 74097.68,84864 74313.21,84264 73714.52,84264' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'small', Density: 'Low', DensitySize: 30.2, Mix: 'Berycled, Cornundacauda, Prancer, Sabakuma', Notes: '', Id: 500, Pos: '73714.52,83424 73858.21,83424 73930.05,83544 74588.61,83544 74971.77,82548 74971.77,81948 73714.52,81948' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Low', DensitySize: 30.1, Mix: 'Atrax, Tripudion', Notes: '', Id: 501, Pos: '72502.78,68304 72630.9,68376 73109.85,68652 73277.48,68568 73349.33,68724 73504.98,68844 73696.56,68892 73696.64,67812 73481.04,67908 73325.38,67704 73145.77,67812 72990.11,67716 72774.59,67704 72606.95,67656 72295.64,67752 72319.58,67908 72523.14,68004 72642.87,68172' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: 'Berycled, Cornundacauda, Prancer', Notes: '', Id: 502, Pos: '75927.27,82550.4 78408.24,82540.8 78446.55,81993.6 75927.27,81993.6' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 10, Mix: 'Atrax, Fungoid, Neconu', Notes: '', Id: 825, Pos: '36198,35530 36550,35546 37206,35642 37542,35514 37558,35146 37350,34682 36902,34474 36454,34506 36182,34714' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Medium', DensitySize: 45, Mix: 'Exarosaur', Notes: '', Id: 859, Pos: '29494,48858 30022,48970 31206,49002 32262,48810 32502,48538 32518,47594 30726,47322 30342,46954 30006,47450 29286,47754 29222,48426' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Very Low', DensitySize: 10, Mix: 'Cornundacauda', Notes: '', Id: 902, Pos: '23146.41,55451.19 23529.57,55410.77 23862.31,55334.98 23942.98,55153.08 24013.56,54870.14 23932.89,54572.03 23827.02,54531.61 23796.77,54380.03 23585.03,54349.71 23524.53,54400.24 23413.61,54319.4 23232.11,54268.87 23080.87,54299.19 22929.62,54430.56 22748.12,54420.45 22748.12,54491.19 22909.45,54521.5 23151.45,55046.98' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Very Low', DensitySize: 15, Mix: 'Cornundacauda', Notes: '', Id: 905, Pos: '19978.91,56123.82 20256.7,56315.82 20563.23,56296.62 20716.5,56123.82 21109.24,56392.62 21205.03,56392.62 21300.82,56133.42 21396.61,56018.22 21511.55,56047.02 21655.24,56239.02 21760.61,56306.22 21818.08,56267.82 21808.5,56123.82 21626.5,55586.22 21770.19,55413.42 21789.34,55144.62 21789.34,54837.42 21731.87,54760.62 21233.76,54818.22 21023.02,55576.62 19978.91,55557.42' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Very Low', DensitySize: 10, Mix: 'Cornundacauda', Notes: '', Id: 906, Pos: '21406.03,54194.37 21540.14,54242.37 21712.56,54059.97 21741.3,53963.97 21722.14,53733.57 22057.4,53752.77 22421.41,53733.57 22536.35,53560.77 22680.04,53503.17 22823.72,53618.37 23168.57,53666.37 23369.73,53512.77 23436.78,53291.97 23283.52,53311.17 23072.78,53426.37 22862.04,53339.97 23091.94,53243.97 23091.94,53119.17 22986.57,53090.37 22862.04,53128.77 22804.57,52927.17 22871.62,52850.37 23044.04,52965.57 23197.3,52927.17 23340.99,53080.77 23542.15,53109.57 23570.89,52955.97 23455.94,52811.97 23484.67,52706.37 23360.15,52437.57 23187.73,52466.37 23111.09,52591.17 22890.78,52562.37 22747.09,52715.97 22699.2,52696.77 22766.25,52408.77 22909.93,52389.57 22976.99,52159.17 23091.94,51813.57 23398.46,51602.37 23398.46,51352.77 23302.67,51323.97 23302.67,51112.77 23082.36,51112.77 22938.67,50997.57 22919.51,50853.57 22794.99,50747.97 22545.93,50834.37 22430.98,51055.17 22632.14,51160.77 22823.72,51189.57 22881.2,51352.77 22976.99,51477.57 23005.72,51621.57 22909.93,51890.37 22699.2,52226.37 22469.3,52389.57 22430.98,52581.57 22296.88,52706.37 22162.77,52619.97 21923.3,52715.97 21616.77,52725.57 21616.77,52859.97 21520.98,52936.77 21501.82,52840.77 21185.71,52226.37 20974.98,52255.17 21338.98,52955.97 21319.82,53051.97 21367.72,53128.77 21520.98,53080.77 21635.93,52955.97 21578.45,53157.57 21616.77,53243.97 21789.19,53339.97 21597.61,53522.37 21425.19,53474.37 21214.45,53531.97 21099.5,53695.17 21137.82,53675.97 21310.24,53685.57 21434.77,54050.37' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: 'Combibo, Exarosaur, Snablesnot Female, Snablesnot Male', Notes: '', Id: 941, Pos: '71774.78,76963.2 71975.94,76896 72368.68,77088 72694.36,76732.8 72838.05,76569.6 72857.2,76224 72818.89,75964.8 72723.1,75888 72770.99,75696 72742.26,75494.4 72579.41,75312 72445.31,75177.6 72148.36,75177.6 72071.73,75244.8 72090.88,75369.6 71851.41,75600 71688.57,75676.8 71449.09,75504 71190.46,75532.8 71027.62,75753.6 71027.62,75955.2 71161.72,76118.4 71382.04,76032 71449.09,75801.6 71544.88,75849.6 71535.3,76089.6 71592.78,76281.6 71573.62,76425.6 71391.62,76406.4 71190.46,76569.6 71276.67,76656 71573.62,76579.2 71573.62,76761.6 71602.36,76934.4' },
    ]
  },
  { // Mulmun
    Name: 'Mulmun',
    id: '102',
    notes: '',
    spawns: [
      { Min: 'Outcast', Max: 'Warrior', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: '', Notes: '', Id: 979, Pos: '29574.49,27331.2 29727.76,27273.6 29699.02,27168 29526.6,27062.4 29379.72,27065.6 29402.07,27196.8' },
    ]
  },
  { // Neconu
    Name: 'Neconu',
    id: '53',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: 'Scaboreas', Notes: '', Id: 503, Pos: '85113.54,94328.63 85772.09,94100.63 85939.72,93980.63 85879.86,93788.63 85963.67,93584.63 86011.57,93236.63 86418.67,93176.63 86430.65,93476.63 86598.28,93668.63 86610.25,93980.63 86825.78,94088.63 87017.36,94004.63 86921.57,93680.63 86837.76,93356.63 86694.07,92996.63 86394.73,92504.63 86059.46,92096.63 85891.83,92072.63 85736.17,91856.63 85376.96,91892.63 85113.54,91880.63 84981.82,92096.63 84921.96,92384.63 84981.82,92732.63 85293.14,93176.63 85341.04,93320.63 85209.33,93440.63 85065.64,93908.63 84981.82,94160.63 85053.67,94280.63' },
      { Min: 'Old', Max: '', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: 'Hiryuu, Mermoth', Notes: '', Id: 504, Pos: '54592.93,68141.82 55205.98,67949.82 57006.83,66375.42 56144.72,66260.22 55320.93,66644.22 54439.66,67642.62' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 739, Pos: '18763,51961 19723,52249 21003,52249 21547,51897 21547,50889 21467,50361 20747,49977 19835,49945 19595,50393 19307,50585 18971,50585 18763,51033' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Big', DensitySize: 96, Mix: '', Notes: '', Id: 796, Pos: '21472,27072 23312,27072 24480,27056 24480,24816 23760,24784 22656,25088 21648,25536 21184,26512' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Medium', DensitySize: 40, Mix: 'Trilomite', Notes: '', Id: 810, Pos: '33606,27594 35814,27514 36246,27290 36198,26522 35606,26154 34086,26538 33478,26810 33350,27290' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Low', DensitySize: 20, Mix: 'Atrax, Fungoid, Merp', Notes: '', Id: 826, Pos: '36198,35530 36550,35546 37206,35642 37542,35514 37558,35146 37350,34682 36902,34474 36454,34506 36182,34714' },
      { Min: 'Mature', Max: 'Guardian', Maturity: 'small', Density: 'Big', DensitySize: 80, Mix: 'Cadatergus, Formidon, Tantillion', Notes: 'Middle of Land Area', Id: 850, Pos: '38742,43562 39910,43306 40054,42474 40166,42394 40118,42138 39974,42026 39926,41626 39686,40986 38182,41050 37798,41498 37862,42554 38342,43370' },
      { Min: 'Old Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 850, Pos: '26835,43924 27331,43444 27939,43796 29251,43636 29891,42996 28483,41876 26739,42132 26179,42340 26403,43524' },
    ]
  },
  { // Nexnecis
    Name: 'Nexnecis',
    id: '95',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 505, Pos: '81296.3,88310.4 81210.09,88608 81210.09,88718 81732.73,88718 81723.15,88204.8' },
      { Min: 'Alpha', Max: 'Old Alpha', Maturity: 'big', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 507, Pos: '81478,88979 81349,88796 81194,88846 81088,88998 81092,89068 81196,89138 81371,89018' },
      { Min: 'Guardian', Max: 'Dominant', Maturity: 'mid', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 506, Pos: '81675,88707 81498,88687 81395,88782 81445,88967' },
      { Min: 'Prowler', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 508, Pos: '80970,89072 80845,89242 80907,89488 81197,89493 81231,89246 81043,89060' },
      { Min: 'Stalker', Max: '', Maturity: 'big', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 740, Pos: '16939,48953 17707,49017 17931,48905 18139,47161 18091,46409 16587,46409 16459,47929 16507,48185' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Low', DensitySize: 25, Mix: 'Tripudion', Notes: 'East side', Id: 783, Pos: '25736,51149 26104,51085 26088,51197 26376,51053 26456,51117 26952,50925 27016,50781 27160,50717 27448,50845 27880,50701 28264,49773 28456,49165 26856,49165 26504,49437 25640,49757 25656,50765 25608,50829 25688,50973' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Low', DensitySize: 25, Mix: '', Notes: '', Id: 857, Pos: '26822,49002 27926,48970 29046,49002 29110,47834 28998,47786 29126,46858 28422,46666 27686,46826 27126,47066 27110,47898 26742,48394 26342,48602' },
    ]
  },
  { // Oculus
    Name: 'Oculus',
    id: '54',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: 'Kingfisher', Notes: '', Id: 818, Pos: '36118,32314 37190,31978 37094,31018 36870,31018 36694,30858 36438,30298 35846,30170 35686,31306 36038,31514 35878,31786 35702,31786 35702,32010' },
      { Min: 'Prowler', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 837, Pos: '30374,38426 31798,38634 32390,38298 32118,37914 32342,37370 32374,36554 31494,36282 30902,36554 30134,37194' },
    ]
  },
  { // Osseocollum
    Name: 'Osseocollum',
    id: '97',
    notes: '',
    spawns: [
      { Min: 'Prowler', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 100, Mix: '', Notes: '', Id: 512, Pos: '81023,89806 80997,89606 80853,89560 80638,89685 80636,89861' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 80.3, Mix: '', Notes: '', Id: 509, Pos: '65889.68,71496 66919.42,71448 66979.29,71124 66979.29,70830 66859.55,70820 66727.84,70692 66009.42,70692 65817.84,70764 65769.94,70956 65745.99,71208 65841.78,71436' },
      { Min: 'Prowler', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 80.2, Mix: '', Notes: '', Id: 510, Pos: '65858.15,70499.2 66116.78,70672 66480.78,70662.4 66509.52,70374.4 66337.1,70374.4 66068.89,70278.4 65915.62,70326.4' },
      { Min: 'Guardian', Max: 'Prowler', Maturity: 'big', Density: 'Big', DensitySize: 80.1, Mix: '', Notes: '', Id: 511, Pos: '75094.32,66724.38 75257.16,66743.58 75534.96,66589.98 75697.8,66589.98 75803.17,66657.18 76195.91,66743.58 76377.91,66426.78 76464.12,66205.98 76406.64,66042.78 76176.75,65908.38 75975.59,65975.58 75966.01,66129.18 75649.9,66157.98 75573.27,66052.38 75736.11,66033.18 75736.11,65946.78 75352.95,65908.38 75218.85,65831.58 75103.9,65860.38 74988.95,65937.18 74893.16,66023.58 74845.27,66177.18 74902.74,66484.38' },
      { Min: 'Dominant', Max: 'Prowler', Maturity: 'big', Density: 'Low', DensitySize: 30, Mix: '', Notes: '', Id: 513, Pos: '50129.11,69697.02 50397.32,69716.22 50550.59,69543.42 50550.59,69293.82 50033.32,69293.82' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 742, Pos: '20507,48745 21003,48489 21531,48489 21499,47593 21931,47145 21931,46713 20027,46665 20027,48153' },
      { Min: 'Old', Max: 'Provider', Maturity: 'small', Density: 'Very Low', DensitySize: 10, Mix: '', Notes: '', Id: 854, Pos: '31494,47178 32662,47162 32662,45946 32310,45130 31894,45018 30950,44202 30054,45050 30790,46650' },
    ]
  },
  { // Phasm
    Name: 'Phasm',
    id: '98',
    notes: '',
    spawns: [
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 15, Mix: 'Cornundacauda', Notes: '', Id: 755, Pos: '16731,44137 16923,44217 17211,44153 18347,44265 19179,43865 19387,43065 19307,42137 18843,41625 18059,41801 17755,42073 17499,41977 17355,41977 16875,42361 16715,42809 16763,43161 16683,43337 16683,43481 16779,43657' },
    ]
  },
  { // Plumatergus
    Name: 'Plumatergus',
    id: '55',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Big', DensitySize: 80, Mix: 'Berycled', Notes: '', Id: 515, Pos: '80563.51,97568.63 80826.93,97316.63 81832.73,97112.63 81796.81,94112.63 81257.99,94340.63 80982.59,94820.63 80635.35,95240.63 80503.64,95576.63 80300.09,95804.63 80336.01,95984.63 80072.59,96656.63 80084.56,97076.63 80312.06,97376.63' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'small', Density: 'Big', DensitySize: 60, Mix: '', Notes: '', Id: 516, Pos: '81269.96,93164.63 81533.38,93188.63 81605.23,93332.63 81832.73,93260.63 81820.75,91052.63 81593.25,90932.63 81353.78,91064.63 81389.7,91328.63 81377.73,91460.63 81090.36,91544.63 80946.67,91688.63 81006.54,91940.63 81018.51,91860.63 80862.85,91924.63 80826.93,91916.63 80683.25,91976.63' },
      { Min: 'Mature', Max: 'Alpha', Maturity: 'mid', Density: 'Medium', DensitySize: 50, Mix: 'Prancer', Notes: '', Id: 517, Pos: '80515.62,70646.4 80582.67,70732.8 80678.46,70790.4 80850.88,70972.8 81099.93,70752 81071.2,70608 81090.36,70464 81138.25,70348.8 81109.51,70137.6 81042.46,70060.8 80841.3,70233.6 80793.41,70358.4 80659.3,70464 80640.14,70550.4 80544.35,70560' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Low', DensitySize: 20, Mix: 'Berycled', Notes: '', Id: 514, Pos: '84754.32,97208.63 84957.88,97316.63 85065.64,97256.63 85041.69,97124.63 85221.3,97112.63 85496.7,97280.63 85999.59,97004.63 86143.28,96344.63 86059.46,96068.63 86131.3,95744.63 85808.01,95816.63 85293.14,95720.63 85233.27,95504.63 84898.01,95480.63 84802.22,95660.63 84826.17,95804.63 84646.56,96068.63 84131.69,96308.63 84227.48,96524.63 84131.69,96812.63 84502.87,97040.63 84766.3,97148.63' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Very Low', DensitySize: 35, Mix: 'Equus', Notes: '', Id: 738, Pos: '17627,54105 18379,54297 19499,52857 19515,52361 18619,52105 18619,51257 18347,50841 17083,51321 16971,51481 16827,51849 17371,51961' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: 'Prancer', Notes: '', Id: 973, Pos: '75208.66,72720.19 75294.87,72720.19 75390.66,72816.19 75486.45,72796.99 75639.71,72614.59 75630.13,72460.99 75496.03,72451.39 75361.92,72518.59 75266.13,72614.59 75199.08,72614.59' },
    ]
  },
  { // Primordial Longu
    Name: 'Primordial Longu',
    id: '425',
    notes: '',
    spawns: [
      { Min: 'Old Alpha', Max: 'Slayer', Maturity: 'big', Density: 'Big', DensitySize: 99, Mix: '', Notes: '', Id: 790, Pos: '19696,35732 20720,36324 21264,36564 22528,36628 24192,36420 24432,35636 24368,33876 23536,33780 22384,33124 21776,33044 20576,33940 20096,34756 20112,35492' },
      { Min: 'Provider', Max: 'Old Alpha', Maturity: 'mid', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 518, Pos: '76246.97,67620 76462.5,67704 76713.95,67572 76797.77,67596 77037.24,67380 77085.14,67236 77228.82,67092 77516.19,67128 77564.09,67272 77743.69,67320 78043.04,67356 78330.41,67560 78665.67,67680 78917.12,67404 78821.33,67200 78761.46,66864 78857.25,66612 78941.07,66612 78976.99,66372 79036.86,66264 78893.17,66060 78725.54,65904 78533.96,65904 78174.75,66024 77659.88,66204 77360.53,66204 77228.82,66120 77121.06,65988 77013.29,65964 76797.77,66036 76558.29,66756 76306.84,66996 76258.95,67380 76282.89,67536' },
      { Min: 'Mature', Max: 'Dominant', Maturity: 'small', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 519, Pos: '75314.22,72345.6 75524.96,72364.8 75879.38,72211.2 75917.69,72105.6 75793.17,71942.4 75601.59,71750.4 75515.38,71846.4 75352.53,71664 75141.8,72057.6' },
      { Min: 'Old', Max: 'Provider', Maturity: 'small', Density: 'Big', DensitySize: 75, Mix: '', Notes: '', Id: 969, Pos: '75321.59,71656.62 74861.79,71685.42 74785.16,71925.42 75005.48,72175.02 75120.43,72040.62' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Big', DensitySize: 85, Mix: '', Notes: '', Id: 970, Pos: '73934.84,72710.4 74203.05,72768 74432.95,72758.4 74461.69,72864 74701.16,72796.8 74796.95,72652.8 74796.95,72364.8 74988.53,72172.8 74749.06,71932.8 74538.32,71990.4 74375.48,71932.8 74270.11,72000 74270.11,72211.2 74183.9,72297.6 74030.63,72537.6' },
      { Min: 'Mature', Max: 'Old', Maturity: 'small', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 971, Pos: '73756.57,73397.86 73775.73,73647.46 75164.69,73647.46 75222.16,73052.26 75164.69,72802.66 74992.26,72831.46 74819.84,72706.66 74704.89,72841.06 74800.68,72975.46 74657,73081.06 74609.1,73292.26 74302.58,73349.86 74101.42,73349.86 73938.57,73465.06 73814.05,73349.86' },
    ]
  },
  { // Proteron
    Name: 'Proteron',
    id: '132',
    notes: '',
    spawns: [
      { Min: 'Prowler', Max: 'Stalker', Maturity: 'big', Density: 'Medium', DensitySize: 50.1, Mix: '', Notes: '', Id: 520, Pos: '72454.89,72230.4 73240.36,71856 73096.68,71644.8 72962.57,71596.8 72962.57,71500.8 72799.73,71500.8 72732.68,71424 72732.68,71299.2 72560.26,71395.2 72474.04,71568 72378.25,72086.4' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 521, Pos: '77448.74,68569.6 77592.42,68713.6 77764.85,68790.4 78023.48,68809.6 78120.16,68617.6 78138.43,68377.6 78205.48,68252.8 78320.43,68281.6 78473.69,68377.6 78569.48,68291.2 78626.96,68099.2 78559.9,67936 78464.11,67849.6 78339.59,67792 78128.85,67772.8 77927.69,67705.6 77841.48,67619.2 77707.37,67667.2 77563.69,67859.2 77534.95,68003.2 77429.58,68089.6 77429.58,68252.8 77487.05,68406.4 77400.84,68540.8' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 727, Pos: '17803,63417 18011,63241 19371,63241 19723,62777 19643,62633 19931,62249 19531,61833 18987,61545 17819,61449 17387,61289 17259,61273 17163,61497 17323,61977 17211,62185 17435,62265 17291,62697 17707,63209' },
      { Min: 'Old', Max: 'Dominant', Maturity: 'mid', Density: 'Big', DensitySize: 85, Mix: '', Notes: '', Id: 750, Pos: '20075,46153 20299,46313 20843,46473 22427,46473 22731,45593 22203,45369 22059,45161 21659,45097 21563,45113 21371,44953 21339,45033 21339,45161 21147,45209 20939,45289 20795,45209 20683,45209 20475,44985 20219,44905 20075,45001' },
    ]
  },
  { // Prototype Defender
    Name: 'Prototype Defender',
    id: '612',
    notes: '',
    spawns: [
      { Min: 'Gen 01', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 50.2, Mix: '', Notes: '', Id: 522, Pos: '75424.38,67132.8 75501.01,67296 75577.64,67334.4 75711.75,67324.8 75932.06,67257.6 76094.91,67171.2 76142.8,66988.8 76123.64,66902.4 76123.64,66816 75769.22,66787.2 75683.01,66921.6 75539.32,66921.6' },
      { Min: 'Gen 01', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 50.1, Mix: '', Notes: '', Id: 523, Pos: '62234.09,85632 62281.99,85699.2 62387.36,85660.8 62483.15,85516.8 62358.62,85440 62214.94,85516.8 62234.09,85622.4' },
      { Min: 'Gen 03', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 524, Pos: '62061.67,85113.6 62138.3,85219.2 62329.88,85238.4 62454.41,85200 62425.67,85027.2 62167.04,84940.8 62099.99,84998.4' },
    ]
  },
  { // Prototype Drone
    Name: 'Prototype Drone',
    id: '397',
    notes: '',
    spawns: [
      { Min: 'Gen 04', Max: '', Maturity: 'mid', Density: 'Big', DensitySize: 80.1, Mix: '', Notes: '', Id: 525, Pos: '64135.53,76100.63 64327.11,76112.63 64458.82,75932.63 64506.71,75692.63 64339.08,75428.63 64267.24,75452.63 64195.39,75356.63 64063.68,75404.63 63896.05,75704.63 63920,75824.63 64003.81,75860.63 63955.92,75968.63 64015.79,76052.63' },
      { Min: 'Gen 01', Max: 'Gen 05', Maturity: 'mid', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 526, Pos: '63219.53,80340 63135.72,80748 63207.56,80892 63506.9,80892 63626.64,80544 63985.85,80400 64093.62,80268 63914.01,80160 63590.72,79836 63243.48,79608 63207.56,78660 62992.03,78684 62968.08,79356 62740.58,79812 62896.24,80100 62884.27,80244 63183.61,80412' },
      { Min: 'Gen 06', Max: '', Maturity: 'mid', Density: 'Medium', DensitySize: 50.1, Mix: '', Notes: '', Id: 527, Pos: '62357.42,85356 62381.37,85428 62489.13,85488 62608.87,85368 62608.87,85260 62501.11,85224 62381.37,85248' },
      { Min: 'Gen 02', Max: 'Gen 05', Maturity: 'mid', Density: 'Medium', DensitySize: 50, Mix: 'Berycled, Faucervix', Notes: '', Id: 528, Pos: '63159.66,78324 63327.3,78648 63638.61,78648 63674.54,78828 63566.77,79020 63638.61,79080 63878.09,79212 64045.72,79260 64237.3,79296 64321.12,79524 64309.14,79764 64440.86,79932 64716.25,79896 64764.15,79776 64764.15,79476 64907.83,79284 65015.6,79248 65482.57,79260 65470.6,78024 64955.73,77748 64991.65,78048 64835.99,78060 64752.17,77940 64536.65,78240 64572.57,78336 64297.17,78480 64045.72,78420 63925.98,78372 63902.04,78240 63650.59,78144 63542.82,78132 63303.35,78216' },
    ]
  },
  { // Prototype Droka
    Name: 'Prototype Droka',
    id: '564',
    notes: '',
    spawns: [
      { Min: 'Gen 05', Max: '', Maturity: 'mid', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 529, Pos: '62483.15,85190.4 62617.25,85200 62636.41,85065.6 62483.15,84950.4 62444.83,85017.6 62483.15,85171.2' },
    ]
  },
  { // Prancer
    Name: 'Prancer',
    id: '56',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Big', DensitySize: 80.1, Mix: 'Cornundacauda', Notes: '', Id: 530, Pos: '81868.65,97112.63 82191.94,97148.63 82527.21,97052.63 82982.21,97052.63 83257.6,96932.63 83449.18,96716.63 83425.24,96272.63 83221.68,95804.63 83221.68,95684.63 83101.95,95516.63 82958.26,95504.63 82694.84,95264.63 82766.68,94904.63 82694.84,94808.63 82766.68,94688.63 82742.73,94364.63 82634.97,94280.63 82646.94,94148.63 82718.79,94052.63 82718.79,93848.63 82634.97,93740.63 82563.13,93848.63 82323.65,93824.63 82072.2,93896.63 82060.23,94028.63 81844.7,94172.63' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'small', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 531, Pos: '76061.38,94928.63 76540.33,95132.63 76863.62,95156.63 77043.23,95108.63 77162.96,94940.63 77067.18,94740.63 76839.67,94600.63 76731.91,94496.63 76672.04,94232.63 76492.43,94004.63 76312.83,93908.63 76073.35,93896.63 75845.85,94016.63 75833.88,94160.63 76013.49,94232.63 76133.22,94364.63 76121.25,94580.63 76013.49,94772.63' },
      { Min: 'Old', Max: 'Alpha', Maturity: 'mid', Density: 'Medium', DensitySize: 50, Mix: 'Plumatergus', Notes: '', Id: 532, Pos: '80515.62,70646.4 80582.67,70732.8 80678.46,70790.4 80850.88,70972.8 81099.93,70752 81071.2,70608 81090.36,70464 81138.25,70348.8 81109.51,70137.6 81042.46,70060.8 80841.3,70233.6 80793.41,70358.4 80659.3,70464 80640.14,70550.4 80544.35,70560' },
      { Min: 'Guardian', Max: 'Alpha', Maturity: 'mid', Density: 'Low', DensitySize: 30.5, Mix: 'Berycled, Cornundacauda, Merp', Notes: '', Id: 533, Pos: '73744.46,86580 74007.88,86628 74163.54,86592 74331.17,86556 74438.94,86604 74400.05,86040 74097.68,84864 74313.21,84264 73714.52,84264' },
      { Min: 'Guardian', Max: 'Alpha', Maturity: 'mid', Density: 'Low', DensitySize: 30.4, Mix: 'Berycled, Cornundacauda, Merp, Sabakuma', Notes: '', Id: 534, Pos: '73714.52,83424 73858.21,83424 73930.05,83544 74588.61,83544 74971.77,82548 74971.77,81948 73714.52,81948' },
      { Min: 'Mature', Max: 'Guardian', Maturity: 'small', Density: 'Low', DensitySize: 30.3, Mix: '', Notes: '', Id: 535, Pos: '66367.85,68807.58 67546.07,68961.18 67459.86,68721.18 67603.54,68644.38 67967.55,68577.18 67852.6,68317.98 67890.91,68173.98 67843.02,67953.18 67689.75,67895.58 67574.81,67895.58 67431.12,68029.98 67354.49,68173.98 67134.17,68231.58 67028.8,68298.78 66808.49,68269.98 66588.17,68471.58 66434.91,68615.58' },
      { Min: 'Provider', Max: 'Alpha', Maturity: 'mid', Density: 'Low', DensitySize: 30.2, Mix: 'Berycled, Cornundacauda, Merp', Notes: '', Id: 536, Pos: '75927.27,82550.4 78408.24,82540.8 78446.55,81993.6 75927.27,81993.6' },
      { Min: 'Old', Max: 'Alpha', Maturity: 'mid', Density: 'Low', DensitySize: 30.1, Mix: 'Armax Bull, Armax Cow, Atrax', Notes: '', Id: 537, Pos: '58286.35,81596.63 58801.22,81596.63 59136.48,81680.63 59555.57,81656.63 59459.78,81428.63 59363.99,81068.63 59184.38,80948.63 59124.51,80696.63 59160.43,80288.63 58837.14,80156.63 58633.59,80132.63 58477.93,80144.63 58465.95,80312.63 58322.27,80492.63 58286.35,80648.63 58418.06,80840.63 58274.37,80972.63 58190.56,80852.63 57927.14,80864.63 57867.27,81044.63 57807.4,81140.63 58130.69,81260.63 58166.61,81416.63' },
      { Min: 'Old', Max: 'Guardian', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: 'Chirpy, Gibnib', Notes: '', Id: 538, Pos: '52353.36,60458.67 52651.37,60608 52811.02,60586.67 52960.03,60117.33 52874.88,59978.67 52534.29,60117.33 52257.57,60224' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: 'Plumatergus', Notes: '', Id: 972, Pos: '75208.66,72720.19 75294.87,72720.19 75390.66,72816.19 75486.45,72796.99 75639.71,72614.59 75630.13,72460.99 75496.03,72451.39 75361.92,72518.59 75266.13,72614.59 75199.08,72614.59' },
    ]
  },
  { // Pupugi
    Name: 'Pupugi',
    id: '57',
    notes: '',
    spawns: [
      { Min: 'Old Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 934, Pos: '71226.76,71829.22 71188.44,72021.22 71408.76,72165.22 71571.6,72213.22 71676.97,72078.82 71696.13,71934.82 71562.02,71848.42 71370.44,71838.82 71274.65,71723.62' },
    ]
  },
  { // Rextelum
    Name: 'Rextelum',
    id: '294',
    notes: '',
    spawns: [
      { Min: 'Weak', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 539, Pos: '35401.4,60423.42 35573.82,60711.42 36206.03,60826.62 36244.35,60173.82 36052.77,60039.42 35918.66,59732.22 35592.98,59693.82 35535.5,59828.22 35669.61,59962.62 35650.45,60116.22' },
      { Min: 'Watcher', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 540, Pos: '35592.98,59665.02 35899.51,59684.22 35956.98,59549.82 36148.56,59396.22 36052.77,58820.22 35607.35,58857.12' },
      { Min: 'Strong', Max: '', Maturity: 'mid', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 541, Pos: '35602.56,58806.42 36004.88,58806.42 35985.72,58576.02 36100.67,58460.82 36117.3,58172.82 36119.82,57904.02 35909.09,57884.82 35794.14,58134.42 35410.98,58480.02' },
      { Min: 'Soldier', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 542, Pos: '36229.98,60848.52 36689.77,61021.32 36708.93,60694.92 36862.2,60541.32 36910.09,60239.52 36804.72,60080.52 36593.98,59946.12 36555.67,59946.12 36498.19,60195.72 36287.46,60176.52' },
      { Min: 'Chaser', Max: '', Maturity: 'big', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 547, Pos: '38485.84,61513.62 39041.42,61439.82 39558.68,61014.42 39961,60899.22 39922.69,60592.02 39846.05,60534.42 39807.74,60380.82 39903.53,60227.22 39654.47,60035.22 39443.74,60246.42 39022.26,60419.22 38964.79,60668.82 38926.47,61014.42 38658.26,61168.02' },
      { Min: 'Ravager', Max: '', Maturity: 'mid', Density: 'Big', DensitySize: 88, Mix: '', Notes: '', Id: 545, Pos: '36177.3,59382.42 36368.88,59363.22 36579.62,59516.82 36790.35,59363.22 36675.41,59152.02 36809.51,59017.62 36349.72,58614.42 36253.93,58576.02 36081.51,58691.22' },
      { Min: 'Queen', Max: '', Maturity: 'big', Density: 'Big', DensitySize: 85, Mix: '', Notes: '', Id: 543, Pos: '36747.25,61021.32 37053.78,61059.72 38107.47,61789.32 38854.63,61750.92 38778,61501.32 38433.15,61539.72 38490.63,61040.52 37053.78,60848.52' },
      { Min: 'Majestic', Max: '', Maturity: 'big', Density: 'Big', DensitySize: 85, Mix: '', Notes: '', Id: 544, Pos: '37408.2,60853.92 38442.73,61007.52 38519.36,60661.92 38864.21,60604.32 38883.37,60066.72 38557.68,59989.92 37887.15,60066.72 37810.52,60412.32 37542.32,60239.52 37408.2,60297.12 37465.67,60450.72 37465.67,60738.72' },
      { Min: 'Devastator', Max: '', Maturity: 'mid', Density: 'Big', DensitySize: 85, Mix: '', Notes: '', Id: 546, Pos: '36392.83,58607.52 36967.57,59173.52 37063.36,59145.12 37216.62,59260.32 37350.73,59221.92 37389.04,59145.12 37350.73,59106.72 37465.67,58703.52 37178.3,58703.52 36316.19,58338.72 36297.04,58434.72 36411.98,58530.72' },
      { Min: 'Hunter', Max: '', Maturity: 'mid', Density: 'Big', DensitySize: 85, Mix: '', Notes: '', Id: 548, Pos: '38591.21,59962.62 38916.89,60039.42 38955.21,59943.42 39510.79,59751.42 39625.74,59405.82 39395.84,59156.22 38916.89,59117.82 38916.89,59367.42 38687,59713.02 38533.73,59770.62 38591.21,59905.02' },
      { Min: 'Strong', Max: 'The Sand King', Maturity: 'big', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 549, Pos: '37058.57,59977.62 37250.15,59977.62 37365.09,59958.42 37499.2,59843.22 37460.88,59593.62 37384.25,59478.42 37250.15,59440.02 37039.41,59478.42 36981.93,59632.02' },
      { Min: 'Watcher', Max: 'Ravager', Maturity: 'mid', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 741, Pos: '18155,47977 19867,48377 19915,46729 19595,46409 18299,46505' }
    ]
  },
  { // Repesilex
    Name: 'Repesilex',
    id: '58',
    notes: '',
    spawns: [
      { Min: 'Mature', Max: 'Old Alpha', Maturity: 'mid', Density: 'Big', DensitySize: 80.1, Mix: '', Notes: '', Id: 550, Pos: '65859.74,73544.63 66206.98,73544.63 66422.51,73424.63 66398.56,73172.63 66434.49,73052.63 66386.59,72908.63 66398.56,72704.63 66458.43,72536.63 66542.25,72368.63 66362.64,72284.63 66087.25,72308.63 65847.77,72296.63 65847.77,72596.63 65943.56,72860.63 65955.54,73112.63 65859.74,73052.63 65787.9,72920.63 65704.09,73388.63' },
      { Min: 'Mature', Max: 'Prowler', Maturity: 'mid', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 551, Pos: '66228.54,72096 66362.64,72028.8 66669.17,72096 66774.54,72067.2 66860.75,72096 66899.07,72201.6 67014.01,72230.4 67033.17,71952 67157.7,71750.4 66946.96,71481.6 66036.96,71529.6 65902.85,71817.6 66008.22,71875.2 66008.22,72009.6 66247.69,72076.8 66372.22,72009.6' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 20, Mix: 'Combibo, Cornundos', Notes: '', Id: 736, Pos: '19259,55545 20907,55577 21307,54089 21179,53721 19595,53721 19035,54137 18891,54297 18891,54841 19243,55161' },
    ]
  },
  { // Rippersnapper
    Name: 'Rippersnapper',
    id: '739',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Dominant', Maturity: 'mid', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 553, Pos: '51019.96,64061.82 51230.7,63965.82 51279.01,63637.82 51019.96,63542.62 50885.85,63793.02' },
      { Min: 'Young', Max: 'Alpha', Maturity: 'mid', Density: 'Medium', DensitySize: 45, Mix: '', Notes: '', Id: 552, Pos: '51807.35,60417 52018.09,60244.2 51730.72,59841 51654.09,60052.2 51711.56,60282.6' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Medium', DensitySize: 40, Mix: 'Hiryuu', Notes: '', Id: 554, Pos: '86095.38,77064 86586.31,77040 86634.2,77196 86538.41,77268 86682.1,77712 86801.83,77892 86646.18,78144 86765.91,78324 86945.52,78480 87196.97,78264 87328.68,78168 87184.99,78012 87244.86,77952 87663.94,77856 87711.84,77220 87843.55,76968 87819.6,76776 87125.13,76200 87029.34,76224 86945.52,76092 86825.78,76140 86837.76,76380 86682.1,76572 86741.97,76884 86586.31,76848 86634.2,76680 86574.33,76644 86358.81,76740 86059.46,76860' },
      { Min: 'Young', Max: 'Prowler', Maturity: 'mid', Density: 'Low', DensitySize: 30, Mix: '', Notes: '', Id: 158, Pos: '73890.14,71440 73966.77,71440 74263.72,71113.6 74369.09,70940.8 74675.62,70681.6 74598.99,70624 74512.78,70729.6 74349.93,70806.4 74282.88,70931.2 74167.93,70921.6 74139.19,71046.4 74072.14,71200 73909.3,71372.8' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Low', DensitySize: 25, Mix: 'Calypsocod, Razortooth', Notes: '', Id: 555, Pos: '57759.5,70092 58154.64,69888 58334.24,69816 58621.61,69888 58477.93,69444 58729.38,69204 58609.64,68820 58825.17,68676 59028.72,68856 59316.09,68880 59531.62,68616 59735.17,68808 59699.25,69036 59986.62,69384 60178.2,69240 60429.65,69216 60717.02,69312 61195.97,69048 61339.65,68712 61387.55,68292 61243.86,67860 60968.47,67536 60788.86,67380 60824.78,67200 61112.15,67236 60800.84,66864 61064.26,66732 61555.18,67164 61615.05,67344 61830.58,67260 61914.39,67056 61734.79,66924 61686.89,66612 61986.24,66300 62417.29,65964 62728.61,65844 62513.08,65628 62070.05,65664 61698.87,65964 61375.58,66228 61100.18,66372 60657.15,66432 60477.54,66324 60489.52,66012 60250.04,66048 60190.17,66384 60010.57,66456 59603.46,66384 59543.59,66564 59914.78,66780 60250.04,66732 60321.89,66984 60226.1,67716 60058.46,67764 59890.83,67656 59723.2,67812 59807.01,68016 59699.25,68256 59411.88,68448 59064.64,68352 58789.25,68388 58813.19,68172 58669.51,68112 58406.09,68184 58202.53,68388 58082.79,68688 58118.72,69072 57915.16,69312 57579.9,69720 57448.19,69924' },
      { Min: 'Dominant', Max: 'Old Alpha', Maturity: 'mid', Density: 'Very Low', DensitySize: 5, Mix: 'Leviathan, Razortooth', Notes: '', Id: 874, Pos: '52971.2,61536 53239.41,61584 53373.52,61536 53593.84,61593.6 53794.99,61680 54101.52,61612.8 54350.58,61756.8 54523,61680 54484.68,61593.6 54590.05,61468.8 54570.89,61401.6 54331.42,61257.6 54283.52,61094.4 54139.84,61017.6 54034.47,60864 53823.73,60883.2 53632.15,60720 53517.2,60595.2 53507.62,60432 53411.83,60240 53258.57,60163.2 53258.57,60009.6 53191.52,59942.4 52990.36,59913.6 52913.73,60000 53028.67,60240 52923.31,60451.2 52846.67,60576 52674.25,60652.8 52319.83,60499.2 52214.46,60489.6 52233.62,60691.2 52377.3,60864 52473.09,60960 52559.3,61238.4 52712.57,61084.8 52942.46,61075.2 53009.52,61171.2 53057.41,61401.6' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Very Low', DensitySize: 5, Mix: 'Calypsocod, Razortooth, Sonic Squid', Notes: '', Id: 947, Pos: '40730.98,53892.65 40970.46,53883.05 40932.14,49630.25 39571.92,50215.85 39456.97,50379.05 39524.03,50475.05 39495.29,50619.05 39437.82,50686.25 39638.97,50955.05 40070.03,50763.05 40204.14,50830.25 40213.71,50964.65 40156.24,51079.85 40261.61,51483.05 40242.45,51732.65 40309.5,51828.65 40290.35,52039.85 40395.72,52212.65 40386.14,52395.05 40299.93,52519.85 40165.82,52635.05 40175.4,52702.25 40242.45,52875.05 40165.82,53019.05 40242.45,53086.25 40376.56,53086.25 40395.72,53163.05 40405.29,53278.25 40510.66,53508.65 40491.51,53643.05 40548.98,53719.85 40711.82,53911.85 40740.56,54075.05' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Low', DensitySize: 15, Mix: '', Notes: '', Id: 951, Pos: '33416.7,47941.69 33811.13,48540.28 34442.22,48935.57 34419.68,49104.98 35636.78,49104.98 35681.86,48845.22 35569.16,48720.98 35602.97,48517.69 35355.04,48043.33 35140.92,47964.28 35107.12,48077.22 35208.54,48133.69 35422.66,48664.51 35332.5,48766.16 34836.65,48754.86 34633.8,48528.98 34419.68,48495.1 34042.16,48303.1 33906.92,47840.04 33568.84,47817.45' },
    ]
  },
  { // Razortooth
    Name: 'Razortooth',
    id: '135',
    notes: '',
    spawns: [
      // { Min: 'Dominant', Max: 'Prowler', Maturity: 'big', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 556, Pos: '387,273 391,278 394,277.5 396,285 391,290 389.5,289 391.5,285 391,284 391,282 390,281 387,282' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Low', DensitySize: 50, Mix: '', Notes: '', Id: 557, Pos: '77838.28,72288 77924.5,72355.2 78010.71,72278.4 78125.65,71942.4 78183.13,71788.8 78135.23,71769.6 78020.29,71827.2 77867.02,71827.2 77847.86,71932.8 77905.34,72076.8' },
      { Min: 'Juvenile', Max: '', Maturity: 'small', Density: 'Low', DensitySize: 30.1, Mix: '', Notes: '', Id: 558, Pos: '59762.71,84585.6 59925.55,84537.6 59944.71,84422.4 59781.87,84441.6 59714.82,84508.8' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: 'Calypsocod, Rippersnapper', Notes: '', Id: 559, Pos: '57759.5,70092 58154.64,69888 58334.24,69816 58621.61,69888 58477.93,69444 58729.38,69204 58609.64,68820 58825.17,68676 59028.72,68856 59316.09,68880 59531.62,68616 59735.17,68808 59699.25,69036 59986.62,69384 60178.2,69240 60429.65,69216 60717.02,69312 61195.97,69048 61339.65,68712 61387.55,68292 61243.86,67860 60968.47,67536 60788.86,67380 60824.78,67200 61112.15,67236 60800.84,66864 61064.26,66732 61555.18,67164 61615.05,67344 61830.58,67260 61914.39,67056 61734.79,66924 61686.89,66612 61986.24,66300 62417.29,65964 62728.61,65844 62513.08,65628 62070.05,65664 61698.87,65964 61375.58,66228 61100.18,66372 60657.15,66432 60477.54,66324 60489.52,66012 60250.04,66048 60190.17,66384 60010.57,66456 59603.46,66384 59543.59,66564 59914.78,66780 60250.04,66732 60321.89,66984 60226.1,67716 60058.46,67764 59890.83,67656 59723.2,67812 59807.01,68016 59699.25,68256 59411.88,68448 59064.64,68352 58789.25,68388 58813.19,68172 58669.51,68112 58406.09,68184 58202.53,68388 58082.79,68688 58118.72,69072 57915.16,69312 57579.9,69720 57448.19,69924' },
      { Min: 'Mature', Max: 'Old', Maturity: 'small', Density: 'Very Low', DensitySize: 5, Mix: 'Leviathan, Rippersnapper', Notes: '', Id: 875, Pos: '52971.2,61536 53239.41,61584 53373.52,61536 53593.84,61593.6 53794.99,61680 54101.52,61612.8 54350.58,61756.8 54523,61680 54484.68,61593.6 54590.05,61468.8 54570.89,61401.6 54331.42,61257.6 54283.52,61094.4 54139.84,61017.6 54034.47,60864 53823.73,60883.2 53632.15,60720 53517.2,60595.2 53507.62,60432 53411.83,60240 53258.57,60163.2 53258.57,60009.6 53191.52,59942.4 52990.36,59913.6 52913.73,60000 53028.67,60240 52923.31,60451.2 52846.67,60576 52674.25,60652.8 52319.83,60499.2 52214.46,60489.6 52233.62,60691.2 52377.3,60864 52473.09,60960 52559.3,61238.4 52712.57,61084.8 52942.46,61075.2 53009.52,61171.2 53057.41,61401.6' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'mid', Density: 'Low', DensitySize: 20, Mix: 'Calypsocod, Rippersnapper, Sonic Squid', Notes: '', Id: 946, Pos: '40730.98,53892.65 40970.46,53883.05 40932.14,49630.25 39571.92,50215.85 39456.97,50379.05 39524.03,50475.05 39495.29,50619.05 39437.82,50686.25 39638.97,50955.05 40070.03,50763.05 40204.14,50830.25 40213.71,50964.65 40156.24,51079.85 40261.61,51483.05 40242.45,51732.65 40309.5,51828.65 40290.35,52039.85 40395.72,52212.65 40386.14,52395.05 40299.93,52519.85 40165.82,52635.05 40175.4,52702.25 40242.45,52875.05 40165.82,53019.05 40242.45,53086.25 40376.56,53086.25 40395.72,53163.05 40405.29,53278.25 40510.66,53508.65 40491.51,53643.05 40548.98,53719.85 40711.82,53911.85 40740.56,54075.05' },
    ]
  },
  { // Sabakuma
    Name: 'Sabakuma',
    id: '60',
    notes: '',
    spawns: [
      { Min: 'Alpha', Max: 'Prowler', Maturity: 'big', Density: 'Big', DensitySize: 100, Mix: '', Notes: '', Id: 560, Pos: '40526.16,59540.22 40698.59,59559.42 40698.59,59021.82 40487.85,59098.62 40468.69,59405.82 40545.32,59482.62' },
      { Min: 'Stalker', Max: '', Maturity: 'big', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 561, Pos: '37776.99,59800.62 38064.36,59624.02 37987.73,59432.42 37776.99,59468.82 37681.2,59545.62' },
      { Min: 'Prowler', Max: 'Lurker', Maturity: 'big', Density: 'Big', DensitySize: 90, Mix: 'Longu', Notes: 'Low Amount', Id: 563, Pos: '80916,77543 80899,77456 80964,77321 81032,77311 81125,77536' },
      { Min: 'Old', Max: 'Dominant', Maturity: 'small', Density: 'Big', DensitySize: 80, Mix: 'Longu', Notes: '', Id: 562, Pos: '76944.59,81809.25 77164.91,81886.05 79061.55,81876.45 79051.97,81118.05 78908.29,80849.25 78323.97,80676.45 78122.81,80666.85 77883.34,80455.65 77701.34,80542.05 77519.33,80340.45 77260.7,80647.65 77212.81,81070.05 77069.12,81290.85 76982.91,81578.85' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: 'Berycled', Notes: '', Id: 564, Pos: '79102.71,81924 80168.38,81924 80156.4,80748 80395.88,80424 80563.51,80280 80563.51,79848 79797.19,79848 79653.51,79644 79521.79,79692 79437.98,79464 79210.48,79464 78719.55,79956 78911.13,80148 78791.4,80484 78994.95,80640 78959.03,80820 79078.77,81096' },
      { Min: 'Provider', Max: 'Guardian', Maturity: 'mid', Density: 'Low', DensitySize: 30.1, Mix: 'Berycled, Merp', Notes: '', Id: 565, Pos: '72493.2,82832.63 72696.76,82760.63 72660.84,82568.63 72588.99,82472.63 72421.36,82412.63 72285.68,82470.63 72340.75,82556.63 72400.49,82712.63' },
      { Min: 'Mature', Max: 'Dominant', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: 'Berycled, Cornundacauda, Merp, Prancer', Notes: '', Id: 566, Pos: '73714.52,83424 73858.21,83424 73930.05,83544 74588.61,83544 74971.77,82548 74971.77,81948 73714.52,81948' },
      { Min: 'Guardian', Max: 'Alpha', Maturity: 'mid', Density: 'Very Low', DensitySize: 15, Mix: 'Chirpy, Cornundacauda', Notes: '', Id: 567, Pos: '62904.62,81974.4 62923.78,82742.4 62665.15,82876.8 62674.73,83020.8 63000.41,83107.2 63278.2,83107.2 63498.52,83203.2 63977.47,83328 64638.42,83568 64839.58,83635.2 65050.32,83894.4 65500.53,83875.2 65490.95,81964.8 64772.53,81993.6 64782.11,82080 65050.32,82454.4 64992.85,82684.8 64695.9,82617.6 64216.95,81993.6' },
      { Min: 'Provider', Max: 'Alpha', Maturity: 'mid', Density: 'Low', DensitySize: 20, Mix: 'Longu', Notes: '', Id: 899, Pos: '23908.94,58245.44 23919.58,58117.44 23855.72,57957.44 23919.58,57808.11 24057.94,57754.78 24324.03,57968.11 24409.17,57978.78 24441.1,58170.78 24355.96,58277.44 24260.17,58352.11 24036.66,58245.44 23951.51,58298.78' },
    ]
  },
  { // Scaboreas
    Name: 'Scaboreas',
    id: '61',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Dominant', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: 'Neconu', Notes: '', Id: 568, Pos: '85113.54,94328.63 85772.09,94100.63 85939.72,93980.63 85879.86,93788.63 85963.67,93584.63 86011.57,93236.63 86418.67,93176.63 86430.65,93476.63 86598.28,93668.63 86610.25,93980.63 86825.78,94088.63 87017.36,94004.63 86921.57,93680.63 86837.76,93356.63 86694.07,92996.63 86394.73,92504.63 86059.46,92096.63 85891.83,92072.63 85736.17,91856.63 85376.96,91892.63 85113.54,91880.63 84981.82,92096.63 84921.96,92384.63 84981.82,92732.63 85293.14,93176.63 85341.04,93320.63 85209.33,93440.63 85065.64,93908.63 84981.82,94160.63 85053.67,94280.63' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: 'Exarosaur', Notes: '', Id: 724, Pos: '36376,48672 37080,48848 37848,48432 38008,48000 37352,47328 36760,47600 36424,48160' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Big', DensitySize: 99, Mix: '', Notes: '', Id: 832, Pos: '25350,37098 26086,36330 25942,35898 25542,35626 24982,35946 24678,36218 24678,36586 24822,36794' },
    ]
  },
  { // Scipulor
    Name: 'Scipulor',
    id: '107',
    notes: '',
    spawns: [
      { Min: 'Guardian', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 871, Pos: '19925.55,61303.7 19906.39,61668.5 20126.7,61985.3 20663.13,62359.7 20835.55,62599.7 20950.5,62599.7 20960.08,62388.5 20960.08,62129.3 20825.97,61860.5 20873.87,61572.5 20711.02,61466.9 20758.92,61332.5 20739.76,61226.9 20002.18,61332.5' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 569, Pos: '52907.02,69178.62 53481.76,68967.42 53443.45,68660.22 53711.66,68621.82 53654.18,67201.02 53021.97,66932.22 52523.86,67450.62 52006.6,67335.42 51815.02,67546.62 51795.86,67892.22 52064.07,68372.22 52370.6,68410.62' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Big', DensitySize: 60, Mix: 'Formidon', Notes: '', Id: 872, Pos: '18086.74,60266.54 18105.89,60420.14 17962.21,60525.74 18172.95,60621.74 18249.58,60727.34 18786,60967.34 18805.16,61140.14 18900.95,61312.94 19159.58,61370.54 19284.11,61312.94 19389.48,61120.94 19207.48,60535.34 19322.43,59680.94 19753.48,59584.94 19485.27,59287.34' },
      { Min: 'Provider', Max: 'Alpha', Maturity: 'mid', Density: 'Medium', DensitySize: 50.1, Mix: '', Notes: '', Id: 570, Pos: '55359.25,66375.42 55569.98,66164.22 55550.83,65933.82 55263.46,66049.02 55167.67,66356.22' },
      { Min: 'Provider', Max: 'Stalker', Maturity: 'big', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 571, Pos: '54439.66,66682.62 54841.98,66317.82 55014.4,65799.42 54669.56,65780.22 54401.35,66202.62 54382.19,66625.02' },
    ]
  },
  { // Shinkiba
    Name: 'Shinkiba',
    id: '222',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Dominant', Maturity: 'small', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 572, Pos: '75090.14,77197.37 75269.74,77281.37 75545.14,77269.37 75557.11,77089.37 75700.8,77089.37 75832.51,77221.37 76036.06,77245.37 76215.67,77293.37 76407.25,77053.37 76491.07,76801.37 76335.41,76549.37 76155.8,76429.37 76083.96,76585.37 75904.35,76681.37 75784.62,76621.37 75856.46,76489.37 75844.48,76165.37 75557.11,76201.37 75317.64,76285.37 74730.93,76321.37 74706.98,76573.37 74886.58,76849.37 75030.27,76909.37' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50.5, Mix: 'Exarosaur', Notes: '', Id: 573, Pos: '66688.33,87753.6 66860.75,87916.8 66985.28,87868.8 67339.7,87792 67416.33,88032 67655.81,88070.4 67521.7,87734.4 67492.96,87360 67416.33,87264 67454.65,87129.6 67378.02,87024 67109.8,87024 66908.65,87244.8 66774.54,87504' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50.4, Mix: 'Snablesnot Male', Notes: '', Id: 574, Pos: '68703.51,83180.63 69433.91,83168.63 69745.23,83336.63 70128.39,83300.63 70331.94,83180.63 70367.86,82988.63 70224.18,82844.63 70188.26,82568.63 70044.57,82520.63 70224.18,82328.63 70272.07,82124.63 70260.1,81932.63 68751.41,81932.63 68775.35,82376.63 68931.01,82352.63 69002.85,82292.63 69050.75,82352.63 68966.93,82592.63 68643.64,82628.63 68452.06,82832.63 68559.83,83072.63' },
      { Min: 'Young', Max: 'Stalker', Maturity: 'mid', Density: 'Medium', DensitySize: 50.3, Mix: 'Snarksnot', Notes: '', Id: 575, Pos: '67530.08,75032.63 67601.93,75152.63 67757.58,75128.63 68032.98,75164.63 68056.93,74936.63 67937.19,74804.63 67865.35,74600.63 67769.56,74828.63 67566,74936.63' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50.2, Mix: 'Combibo', Notes: '', Id: 576, Pos: '77426.39,96000 77689.81,96000 78156.79,96024 78216.65,95652 78120.87,95436 77965.21,95220 77893.36,95016 77845.47,94728 77773.63,94620 77402.44,94884 77294.68,94860 77258.76,94728 77270.73,94368 77115.07,94464 77043.23,94512 76851.65,94476 76803.75,94536 76923.49,94608 77091.12,94692 77186.91,94932 77043.23,95172 76959.41,95496 76791.78,95640 76923.49,95700 77198.89,95640 77234.81,95844' },
      { Min: 'Young', Max: 'Stalker', Maturity: 'mid', Density: 'Medium', DensitySize: 50.1, Mix: 'Tantillion', Notes: '', Id: 577, Pos: '74875.98,75756 75163.35,75756 76109.27,75396 76552.3,75384 76684.02,75252 76396.65,75072 76240.99,74892 76240.99,73836 74792.16,73836 74770.27,75324 74770.27,75456 74840.06,75696' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 578, Pos: '77953.23,74820 78168.76,74940 78216.65,74964 78312.45,74916 78252.58,74700 78312.45,74520 78324.42,74352 78192.71,74136 77989.15,74484 78001.13,74700' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: 'Exarosaur', Notes: '', Id: 579, Pos: '70922.25,67142.4 70874.35,67248 70931.83,67334.4 71065.93,67305.6 71065.93,67420.8 71132.98,67516.8 71228.77,67392 71362.88,67401.6 71429.93,67507.2 71439.51,67612.8 71525.72,67651.2 71669.41,67593.6 71784.36,67670.4 71995.09,67708.8 72052.57,67612.8 72157.94,67612.8 72081.31,67353.6 71880.15,67180.8 71822.67,66979.2 71755.62,67017.6 71659.83,66988.8 71621.51,67104 71487.41,67171.2 71353.3,67123.2 71314.99,67017.6 71353.3,66864 71410.78,66787.2 71573.62,66816 71640.67,66883.2 71832.25,66796.8 71746.04,66748.8 71755.62,66624 71698.15,66528 71611.93,66566.4 71640.67,66672 71391.62,66700.8 71314.99,66691.2 71276.67,66585.6 71161.72,66691.2 71065.93,66720 70950.98,66883.2 71046.77,67056 70941.4,67152' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 10, Mix: 'Berycled, Hispidus', Notes: '', Id: 731, Pos: '21915,56223 22779,56223 23019,55593 23019,55049 22779,54569 22619,54521 22635,54233 22363,54105 21963,54153 22091,54553 21931,54697 21771,55273' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Low', DensitySize: 20, Mix: 'Molisk, Trooper, Warrior', Notes: '', Id: 776, Pos: '32120,50893 32664,50781 32728,49773 32632,49261 32200,49165 30376,49197 30552,49581 31784,50717' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Low', DensitySize: 10, Mix: 'Estophyl', Notes: '', Id: 799, Pos: '26720,32656 27936,32512 27984,31824 27840,31552 27344,31120 26608,30752 26480,31584' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 14, Mix: 'Berycled, Trilomite', Notes: '', Id: 804, Pos: '29408,30608 31232,30800 31856,30224 31184,29024 30880,29344 30800,29728 29472,29328 29056,30192' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 40, Mix: '', Notes: '', Id: 852, Pos: '40150,47354 40518,47402 40662,47226 40726,47066 40726,46890 40678,46794 40854,46602 40854,45082 40902,44714 40806,44426 39814,44538 39590,44714 39542,45242 39334,45450 39430,45834 39398,45994 39622,46570' },
    ]
  },
  { // Second Entity
    Name: 'Second Entity',
    id: '62',
    notes: '',
    spawns: [
      { Min: 'Gen 09', Max: 'Gen 10', Maturity: 'big', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 580, Pos: '34512.47,55238.4 34569.94,55372.8 34732.78,55660.8 34933.94,55785.6 35096.79,55824 35432.05,55756.8 35489.53,55564.8 35489.53,55286.4 35451.21,54988.8 35211.73,54950.4 34924.36,54950.4 34732.78,55142.4' },
      { Min: 'Gen 01', Max: 'Gen 03', Maturity: 'small', Density: 'Big', DensitySize: 92, Mix: '', Notes: '', Id: 582, Pos: '34886.05,54835.2 35288.37,54825.6 35460.79,54873.6 35604.58,54700.8 35604.47,54009.6 35106.37,54019.2 35068.05,54144 34838.15,54422.4' },
      { Min: 'Gen 01', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 581, Pos: '35518.26,55584 36093,55555.2 36131.32,55161.6 36025.95,55075.2 35949.32,54902.4 35796.05,54787.2 35642.79,54777.6 35499.1,55008 35518.26,55324.8' },
      { Min: 'Gen 05', Max: 'Gen 08', Maturity: 'mid', Density: 'Big', DensitySize: 85, Mix: '', Notes: '', Id: 583, Pos: '35135.1,53980.2 35729,53980.8 35729.74,53289.6 35355.42,53337.6' },
      { Min: 'Gen 01', Max: 'Gen 04', Maturity: 'small', Density: 'Big', DensitySize: 85, Mix: '', Notes: '', Id: 584, Pos: '38937.97,56313.6 39100.81,56476.8 39263.65,56553.6 39407.34,56496 39474.39,56313.6 39771.34,55977.6 39723.44,55747.2 39541.44,55680 39340.28,55699.2 39072.07,55987.2' },
      { Min: 'Gen 01', Max: 'Gen 08', Maturity: 'mid', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 585, Pos: '68368.25,74864.63 68452.06,74888.63 68751.41,74828.63 68739.43,74732.63 68655.62,74720.63 68523.9,74792.63 68440.09,74720.63 68344.3,74720.63' },
    ]
  },
  { // Steel Bird
    Name: 'Steel Bird',
    id: '175',
    notes: '',
    spawns: [
      { Min: 'Elite', Max: '', Maturity: 'big', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 586, Pos: '68356.27,74708.63 68464.04,74720.63 68523.9,74780.63 68619.69,74720.63 68763.38,74672.63 68691.54,74564.63 68487.98,74516.63 68328.38,74604.63' },
    ]
  },
  { // Spina Drone
    Name: 'Spina Drone',
    id: '905',
    notes: '',
    spawns: [
      { Min: 'Prowler', Max: '', Maturity: 'big', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 589, Pos: '79713,88494 79887,88498 79963,88739 79697,88921 79574,88811 79593,88686' },
      { Min: 'Alpha', Max: '', Maturity: 'big', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 590, Pos: '79272,89159 79378,89052 79583,89089 79562,89210 79272,89415' },
      { Min: 'Mature', Max: 'Old', Maturity: 'small', Density: 'Big', DensitySize: 80.8, Mix: '', Notes: '', Id: 587, Pos: '76396.65,73560 76684.02,73560 76839.67,73452 76815.73,73212 76672.04,73068 76612.17,72864 76336.78,72816 76229.01,72900 76133.22,72912 76049.41,73056 76061.38,73200 76193.09,73200 76205.07,73320 76348.75,73440' },
      { Min: 'Alpha', Max: 'Noble', Maturity: 'big', Density: 'Big', DensitySize: 80.7, Mix: '', Notes: '', Id: 588, Pos: '80929,88614 80646,88444 80464,88407 80330,88019 80445,87968 80539,88005 80714,87904 80938,88090 81052,88315 80991,88463' },
      { Min: 'Old Alpha', Max: '', Maturity: 'big', Density: 'Big', DensitySize: 80.4, Mix: '', Notes: '', Id: 591, Pos: '82425.43,80832 82557.14,80820 82533.19,80568 82425.43,80580' },
      { Min: 'Alpha', Max: '', Maturity: 'big', Density: 'Big', DensitySize: 80.3, Mix: '', Notes: '', Id: 592, Pos: '82443.77,80258.02 83190.93,80248.42 83200.51,80114.02 82980.19,80085.22 82817.35,79979.62 82692.82,79845.22 82616.19,79816.42 82424.61,79854.82' },
      { Min: 'Guardian', Max: 'Dominant', Maturity: 'mid', Density: 'Big', DensitySize: 80.2, Mix: '', Notes: '', Id: 593, Pos: '83288.74,80227.2 83461.16,80236.8 83614.42,80332.8 83691.05,80342.4 83940.11,80294.4 83959.27,80227.2 83863.48,80112 83691.05,80073.6 83317.47,80112' },
      { Min: 'Old Alpha', Max: '', Maturity: 'big', Density: 'Big', DensitySize: 80.1, Mix: '', Notes: '', Id: 594, Pos: '83116.31,81139.2 83221.68,81187.2 83317.47,81168 83489.89,80966.4 83518.63,80784 83317.47,80755.2 83173.79,80899.2' },
      { Min: 'Guardian', Max: 'Dominant', Maturity: 'mid', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 595, Pos: '82736.75,81888 84640.57,81876 84173.6,81168 83814.38,81168 83646.75,81048 83538.99,80976 83263.59,81288 82928.33,81420' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 596, Pos: '76358.33,72796.8 76597.8,72854.4 76779.81,72720 76731.91,72576 76559.49,72662.4 76291.28,72691.2' },
    ]
  },
  { // Spina Worker
    Name: 'Spina Worker',
    id: '906',
    notes: '',
    spawns: [
      { Min: 'Stalker', Max: '', Maturity: 'big', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 606, Pos: '79449,88888 79293,88752 79519,88310 79680,88333 79711,88498' },
      { Min: 'Prowler', Max: '', Maturity: 'big', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 607, Pos: '79276,88328 79250,88795 78945,88795 78808,88678 78889,88402 79116,88222' },
      { Min: 'Old Alpha', Max: '', Maturity: 'big', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 608, Pos: '79095,88179 79084,88042 78913,88010 78224,88075 78189,88404 78580,88768 78792,88660' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 81.1, Mix: '', Notes: '', Id: 597, Pos: '76025.46,72372 76229.01,72444 76576.25,72372 76372.7,72108 76312.83,71772 76229.01,71676 76097.3,71712 76049.41,71964 76001.51,72108 75977.56,72312' },
      { Min: 'Mature', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 81, Mix: '', Notes: '', Id: 598, Pos: '75869.8,72672 75905.72,72768 76013.49,72756 76229.01,72684 76217.04,72504 75965.59,72384 75965.59,72540' },
      { Min: 'Old', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 80.9, Mix: '', Notes: '', Id: 599, Pos: '76264.93,72672 76516.38,72636 76672.04,72564 76564.28,72408 76252.96,72504' },
      { Min: 'Dominant', Max: '', Maturity: 'mid', Density: 'Big', DensitySize: 80.8, Mix: '', Notes: '', Id: 600, Pos: '76937.86,73344 77119.86,73449.6 77282.7,73411.2 77349.76,73257.6 77531.76,73219.2 77455.12,73094.4 77004.91,72844.8 76899.54,72988.8' },
      { Min: 'Guardian', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 80.7, Mix: '', Notes: '', Id: 601, Pos: '77033.65,72835.2 77512.6,73123.2 77522.18,72969.6 77397.65,72729.6 77206.07,72681.6 77081.54,72710.4' },
      { Min: 'Provider', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 80.6, Mix: '', Notes: '', Id: 602, Pos: '77540.49,73104 77752.07,73190.4 77809.55,73084.8 77799.97,72816 77723.34,72595.2 77598.81,72652.8 77282.7,72614.4 77225.23,72576 77158.18,72604.8 77206.07,72672 77435.97,72739.2 77550.91,72979.2' },
      //{ Min: 'Prowler', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize : 50, Mix: '', Notes: '', Id: 603, Pos: '80090.55,89484 80365.94,89436 80569.5,89424 80892.79,89496 81072.39,89568 81383.71,89412 81371.74,88860 81216.08,88728 80820.95,88788 80701.21,88836 80138.44,88872 80294.1,89172' },
      { Min: 'Prowler', Max: '', Maturity: 'big', Density: 'Big', DensitySize: 80.5, Mix: '', Notes: '', Id: 604, Pos: '80939,88609 81058,88331 81184,88518 81148,88635 80998,88670' },
      { Min: 'Prowler', Max: '', Maturity: 'big', Density: 'Big', DensitySize: 80.4, Mix: '', Notes: '', Id: 605, Pos: '80678,87875 80618,87786 80627,87671 80534,87664 80473,87799 80295,87825 80158,87760 80127,87858 80305,87979 80368,88065 80493,87998' },
      { Min: 'Alpha', Max: 'Old Alpha', Maturity: 'big', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 609, Pos: '82024.31,80256 82378.73,80227.2 82397.89,79804.8 82570.31,79785.6 82551.15,79238.4 82273.36,79248 82206.31,79344 82024.31,79344' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 50.1, Mix: '', Notes: '', Id: 610, Pos: '75314.22,73651.2 75831.48,73651.2 75831.48,73296 75898.54,73190.4 75879.38,73084.8 75812.33,73065.6 75630.33,73152 75419.59,73084.8 75314.22,73104' },
      { Min: 'Mature', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 611, Pos: '75858.2,73643.62 76375.47,73643.62 76318,73461.22 76183.89,73346.02 76126.42,73230.82 75906.1,73250.02 75848.63,73336.42' },
    ]
  },
  { // Spina Soldier
    Name: 'Spina Soldier',
    id: '907',
    notes: '',
    spawns: [
      { Min: 'Stalker', Max: '', Maturity: 'big', Density: 'Big', DensitySize: 100, Mix: '', Notes: '', Id: 614, Pos: '80407,89351 80498,89524 80611,89610 80753,89578 80835,89421 80809,89261 80536,89187' },
      { Min: 'Old Alpha', Max: '', Maturity: 'big', Density: 'Big', DensitySize: 100, Mix: '', Notes: '', Id: 615, Pos: '80531,89152 80629,89196 80816,89193 80905,89013 80694,88935 80497,88993' },
      { Min: 'Prowler', Max: '', Maturity: 'big', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 612, Pos: '80316,89746 80328,89444 79994,89385 79946,89678 80042,89778' },
      { Min: 'Noble', Max: '', Maturity: 'big', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 613, Pos: '79874,89189 79945,88992 80329,89020 80335,89228 80140,89304' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 616, Pos: '78756,89060 78858,89219 78995,89290 79328,89005 79221,88813 78946,88820' },
      { Min: 'Guardian', Max: '', Maturity: 'mid', Density: 'Big', DensitySize: 80.3, Mix: '', Notes: '', Id: 617, Pos: '81950.87,81385.6 82171.19,81366.4 82286.13,80982.4 81979.61,80992' },
      { Min: 'Provider', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 80.2, Mix: '', Notes: '', Id: 618, Pos: '81982.4,80952 82281.74,80940 82377.53,80520 81970.43,80508' },
      { Min: 'Provider', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 80.1, Mix: '', Notes: '', Id: 619, Pos: '82577.87,80642.02 82721.56,80776.42 82855.66,80776.42 83104.72,80421.22 83085.56,80296.42 82654.5,80296.42 82558.71,80363.62' },
      { Min: 'Old', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 620, Pos: '83049.26,80812.8 83135.47,80870.4 83307.89,80726.4 83470.74,80736 83585.68,80755.2 83643.16,80755.2 83652.74,80524.8 83624,80409.6 83432.42,80294.4 83250.42,80284.8 83001.37,80688' },
    ]
  },
  { // Snablesnot Female
    Name: 'Snablesnot Female',
    id: '63',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Big', DensitySize: 80.3, Mix: 'Snablesnot Male', Notes: '', Id: 621, Pos: '81868.65,93776.63 82167.99,93812.63 82551.15,93692.63 82694.84,93344.63 82623,92108.63 82802.6,91976.63 83425.24,91244.63 83568.92,90692.63 83856.29,90104.63 81868.65,90152.63' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Big', DensitySize: 80.2, Mix: 'Snablesnot Male', Notes: '', Id: 622, Pos: '81868.65,90116.63 83844.32,90080.63 83712.61,89408.63 83688.66,89192.63 83521.03,88868.63 83389.32,88496.63 83221.68,88148.63 83281.55,87728.63 83521.03,87500.63 83724.58,87392.63 83760.5,87224.63 81868.65,87224.63' },
      { Min: 'Prowler', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 80.1, Mix: 'Exarosaur, Snablesnot Male, Snarksnot', Notes: '', Id: 623, Pos: '74563,88881 74428,88934 74241,88962 74197,89090 74155,89942 75191,89947 75241,89823 75293,89034' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Big', DensitySize: 80, Mix: 'Snablesnot Male', Notes: '', Id: 624, Pos: '62198.97,82288 62294.76,82316.8 62419.29,82374.4 62467.18,82489.6 62639.6,82508.8 62668.34,82652.8 62898.24,82700.8 62859.92,82134.4 62620.45,82067.2 62198.97,81990.4' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 50.6, Mix: 'Snablesnot Male', Notes: '', Id: 625, Pos: '83287.54,84252 83431.22,84504 83562.93,84240 83562.93,83964 83467.14,83724 83263.59,83568 83191.75,83412 83215.7,83148 83060.04,83316 82988.19,83556 83155.83,83772 83287.54,84048' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50.5, Mix: 'Snablesnot Male', Notes: '', Id: 626, Pos: '60519.45,75992.63 60603.27,76052.63 60723.01,76052.63 60746.95,75908.63 60723.01,75752.63 60579.32,75812.63 60423.66,75824.63 60495.51,75920.63 60507.48,75992.63' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'small', Density: 'Medium', DensitySize: 50.4, Mix: 'Snablesnot Male', Notes: '', Id: 627, Pos: '75235.19,93864 75534.54,94032 75702.17,94008 75522.56,93288 75115.45,92784 75151.38,92616 75342.96,92724 75462.69,92616 75354.93,92376 75570.46,92352 75797.96,91872 75654.27,91800 75450.72,91848 75271.11,91956 75163.35,91908 75115.45,91740 75019.66,91692 74935.85,91716 74959.8,91872 74864.01,91980 74840.06,92124 74864.01,92268 74804.14,92412 74947.82,92424 74959.8,92616 74684.4,92892 74768.21,93348 74971.77,93720' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Medium', DensitySize: 50.3, Mix: 'Exarosaur, Merp, Snablesnot Male, Snarksnot', Notes: '', Id: 628, Pos: '74301.24,91284 74624.53,91380 74624.53,91488 74744.27,91572 75127.43,91428 75139.4,91104 75582.43,90780 75558.48,90444 75402.82,90084 73726.5,90108 73894.13,90264 74133.61,90360 74025.84,90624 74073.74,90936 74085.71,91104 74133.61,91200 74241.37,91200' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50.2, Mix: 'Snablesnot Male', Notes: '', Id: 629, Pos: '78240.6,95640 78432.18,95640 77510.2,93660 77294.68,93660 77402.44,93804 77378.49,93924 77234.81,94116 77270.73,94356 77414.41,94260 77318.62,94116 77450.34,93984 77546.13,94020 77558.1,94188 77570.07,94308 77689.81,94452 77869.42,94740 77917.31,94992 78001.13,95220 78120.87,95424' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50.1, Mix: 'Berycled, Fugabarba, Snablesnot Male', Notes: '', Id: 630, Pos: '83802.41,77652 83874.25,77712 84017.94,77664 84137.67,77724 84125.7,77868 84341.23,77880 84496.89,78012 84772.28,78036 84915.97,78432 85023.73,78528 85095.57,78504 85011.76,78168 85047.68,78000 85179.39,78000 85227.29,78132 85406.89,77928 85394.92,77808 85502.68,77580 85658.34,77700 86245.05,77988 86388.74,77748 86328.87,77616 86376.77,77496 86532.42,77376 86376.77,77112 86053.47,77148 85993.61,76872 85742.16,76956 85742.16,77232 85311.1,77520 85167.42,77688 84939.92,77760 84796.23,77736 84652.55,77640 84508.86,77544 84365.18,77556 84245.44,77616 83993.99,77568 83874.25,77460 83790.44,77508' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: 'Berycled, Daikiba, Snablesnot Male', Notes: '', Id: 631, Pos: '60028.91,79000.42 60354.59,79077.22 60584.49,78952.42 60756.91,78971.62 60776.07,78837.22 60881.44,78856.42 60910.17,79019.62 61073.02,79086.82 61197.54,79077.22 61456.18,79125.22 61743.55,79067.62 61963.86,78856.42 61973.44,78779.62 61868.07,78779.62 61935.13,78539.62 61963.86,78299.62 62117.13,78174.82 62270.39,78174.82 62356.6,78203.62 62471.55,78136.42 62557.76,77934.82 62596.08,77733.22 62682.29,77570.02 62758.92,77406.82 62787.66,77109.22 62787.66,76802.02 62452.39,76840.42 62145.87,76782.82 61935.13,76946.02 61695.65,77099.62 61465.76,77157.22 61274.18,77157.22 61226.28,77310.82 60986.81,77426.02 60766.49,77454.82 60565.33,77378.02 60584.49,77224.42 60661.12,77109.22 61053.86,77013.22 60958.07,76888.42 60843.12,76734.82 60843.12,76533.22 60689.86,76389.22 60622.8,76206.82 60479.12,76475.62 60201.33,76581.22 60134.28,76811.62 60230.07,76869.22 60239.64,77032.42 60402.49,77176.42 60316.28,77416.42 60115.12,77435.62 60105.54,77550.82 59952.27,77666.02 60000.17,77906.02 59875.64,77915.62 59760.69,77954.02 59760.69,78088.42 59866.06,78232.42 59799.01,78453.22 59856.48,78683.62 59971.43,78904.42' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: 'Atrax, Caudatergus, Molisk, Snablesnot Male', Notes: '', Id: 632, Pos: '81899.78,84585.6 82838.52,84595.2 83116.31,84480 83231.26,84316.8 83250.42,84057.6 83164.21,83865.6 82953.47,83577.6 83030.1,83270.4 83192.95,83088 83240.84,82896 83374.95,82771.2 83231.26,82560 83384.53,82368 83844.32,82252.8 84074.21,82166.4 84227.48,82080 84304.11,82147.2 84390.32,82137.6 84438.22,82080 84428.64,81955.2 81899.78,81955.2' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 5, Mix: 'Exarosaur, Fugabarba, Hiryuu, Snablesnot Male, Snarksnot', Notes: '', Id: 748, Pos: '16587,46281 19803,46169 19947,45561 19899,44777 18987,44633 18219,44681 18187,44809 17435,44985 16891,44601 16635,44873 16571,44985 16699,45641' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Big', DensitySize: 70, Mix: 'Combibo, Exarosaur, Merp, Snablesnot Male', Notes: '', Id: 939, Pos: '71774.78,76963.2 71975.94,76896 72368.68,77088 72694.36,76732.8 72838.05,76569.6 72857.2,76224 72818.89,75964.8 72723.1,75888 72770.99,75696 72742.26,75494.4 72579.41,75312 72445.31,75177.6 72148.36,75177.6 72071.73,75244.8 72090.88,75369.6 71851.41,75600 71688.57,75676.8 71449.09,75504 71190.46,75532.8 71027.62,75753.6 71027.62,75955.2 71161.72,76118.4 71382.04,76032 71449.09,75801.6 71544.88,75849.6 71535.3,76089.6 71592.78,76281.6 71573.62,76425.6 71391.62,76406.4 71190.46,76569.6 71276.67,76656 71573.62,76579.2 71573.62,76761.6 71602.36,76934.4' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'small', Density: 'Low', DensitySize: 20, Mix: 'Atrax, Cornundacauda, Gibnib, Snablesnot Male', Notes: '', Id: 952, Pos: '72972.98,75973.57 73116.67,76011.97 73222.04,75800.77 73394.46,75666.37 73691.41,75685.57 73633.93,74936.77 73174.14,74965.57 73097.51,74879.17 72723.93,74869.57 72695.19,75013.57 72829.3,75080.77 72982.56,75243.97 73030.46,75560.77 72915.51,75570.37 72848.45,75714.37 72963.4,75839.17' },
    ]
  },
  { // Snablesnot Male
    Name: 'Snablesnot Male',
    id: '64',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Big', DensitySize: 80.3, Mix: 'Snablesnot Female', Notes: '', Id: 633, Pos: '81868.65,93776.63 82167.99,93812.63 82551.15,93692.63 82694.84,93344.63 82623,92108.63 82802.6,91976.63 83425.24,91244.63 83568.92,90692.63 83856.29,90104.63 81868.65,90152.63' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Big', DensitySize: 80.2, Mix: 'Snablesnot Female', Notes: '', Id: 634, Pos: '81868.65,90116.63 83844.32,90080.63 83712.61,89408.63 83688.66,89192.63 83521.03,88868.63 83389.32,88496.63 83221.68,88148.63 83281.55,87728.63 83521.03,87500.63 83724.58,87392.63 83760.5,87224.63 81868.65,87224.63' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Big', DensitySize: 80.1, Mix: 'Snablesnot Female', Notes: '', Id: 635, Pos: '62198.97,82288 62294.76,82316.8 62419.29,82374.4 62467.18,82489.6 62639.6,82508.8 62668.34,82652.8 62898.24,82700.8 62859.92,82134.4 62620.45,82067.2 62198.97,81990.4' },
      { Min: 'Prowler', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 80, Mix: 'Exarosaur, Snablesnot Female, Snarksnot', Notes: '', Id: 636, Pos: '74563,88881 74428,88934 74241,88962 74197,89090 74155,89942 75191,89947 75241,89823 75293,89034' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50.7, Mix: 'Snablesnot Female', Notes: '', Id: 637, Pos: '60519.45,75992.63 60603.27,76052.63 60723.01,76052.63 60746.95,75908.63 60723.01,75752.63 60579.32,75812.63 60423.66,75824.63 60495.51,75920.63 60507.48,75992.63' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50.6, Mix: 'Shinkiba', Notes: '', Id: 638, Pos: '68703.51,83180.63 69433.91,83168.63 69745.23,83336.63 70128.39,83300.63 70331.94,83180.63 70367.86,82988.63 70224.18,82844.63 70188.26,82568.63 70044.57,82520.63 70224.18,82328.63 70272.07,82124.63 70260.1,81932.63 68751.41,81932.63 68775.35,82376.63 68931.01,82352.63 69002.85,82292.63 69050.75,82352.63 68966.93,82592.63 68643.64,82628.63 68452.06,82832.63 68559.83,83072.63' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'small', Density: 'Medium', DensitySize: 50.5, Mix: 'Snablesnot Female', Notes: '', Id: 639, Pos: '75235.19,93864 75534.54,94032 75702.17,94008 75522.56,93288 75115.45,92784 75151.38,92616 75342.96,92724 75462.69,92616 75354.93,92376 75570.46,92352 75797.96,91872 75654.27,91800 75450.72,91848 75271.11,91956 75163.35,91908 75115.45,91740 75019.66,91692 74935.85,91716 74959.8,91872 74864.01,91980 74840.06,92124 74864.01,92268 74804.14,92412 74947.82,92424 74959.8,92616 74684.4,92892 74768.21,93348 74971.77,93720' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Medium', DensitySize: 50.4, Mix: 'Exarosaur, Merp, Snablesnot Female, Snarksnot', Notes: '', Id: 640, Pos: '74301.24,91284 74624.53,91380 74624.53,91488 74744.27,91572 75127.43,91428 75139.4,91104 75582.43,90780 75558.48,90444 75402.82,90084 73726.5,90108 73894.13,90264 74133.61,90360 74025.84,90624 74073.74,90936 74085.71,91104 74133.61,91200 74241.37,91200' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50.3, Mix: 'Snablesnot Female', Notes: '', Id: 641, Pos: '78240.6,95640 78432.18,95640 77510.2,93660 77294.68,93660 77402.44,93804 77378.49,93924 77234.81,94116 77270.73,94356 77414.41,94260 77318.62,94116 77450.34,93984 77546.13,94020 77558.1,94188 77570.07,94308 77689.81,94452 77869.42,94740 77917.31,94992 78001.13,95220 78120.87,95424' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50.2, Mix: 'Berycled, Fugabarba, Snablesnot Female', Notes: '', Id: 642, Pos: '83802.41,77652 83874.25,77712 84017.94,77664 84137.67,77724 84125.7,77868 84341.23,77880 84496.89,78012 84772.28,78036 84915.97,78432 85023.73,78528 85095.57,78504 85011.76,78168 85047.68,78000 85179.39,78000 85227.29,78132 85406.89,77928 85394.92,77808 85502.68,77580 85658.34,77700 86245.05,77988 86388.74,77748 86328.87,77616 86376.77,77496 86532.42,77376 86376.77,77112 86053.47,77148 85993.61,76872 85742.16,76956 85742.16,77232 85311.1,77520 85167.42,77688 84939.92,77760 84796.23,77736 84652.55,77640 84508.86,77544 84365.18,77556 84245.44,77616 83993.99,77568 83874.25,77460 83790.44,77508' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 50.1, Mix: 'Snablesnot Female', Notes: '', Id: 643, Pos: '83287.54,84252 83431.22,84504 83562.93,84240 83562.93,83964 83467.14,83724 83263.59,83568 83191.75,83412 83215.7,83148 83060.04,83316 82988.19,83556 83155.83,83772 83287.54,84048' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: 'Berycled, Daikiba, Snablesnot Female', Notes: '', Id: 644, Pos: '60028.91,79000.42 60354.59,79077.22 60584.49,78952.42 60756.91,78971.62 60776.07,78837.22 60881.44,78856.42 60910.17,79019.62 61073.02,79086.82 61197.54,79077.22 61456.18,79125.22 61743.55,79067.62 61963.86,78856.42 61973.44,78779.62 61868.07,78779.62 61935.13,78539.62 61963.86,78299.62 62117.13,78174.82 62270.39,78174.82 62356.6,78203.62 62471.55,78136.42 62557.76,77934.82 62596.08,77733.22 62682.29,77570.02 62758.92,77406.82 62787.66,77109.22 62787.66,76802.02 62452.39,76840.42 62145.87,76782.82 61935.13,76946.02 61695.65,77099.62 61465.76,77157.22 61274.18,77157.22 61226.28,77310.82 60986.81,77426.02 60766.49,77454.82 60565.33,77378.02 60584.49,77224.42 60661.12,77109.22 61053.86,77013.22 60958.07,76888.42 60843.12,76734.82 60843.12,76533.22 60689.86,76389.22 60622.8,76206.82 60479.12,76475.62 60201.33,76581.22 60134.28,76811.62 60230.07,76869.22 60239.64,77032.42 60402.49,77176.42 60316.28,77416.42 60115.12,77435.62 60105.54,77550.82 59952.27,77666.02 60000.17,77906.02 59875.64,77915.62 59760.69,77954.02 59760.69,78088.42 59866.06,78232.42 59799.01,78453.22 59856.48,78683.62 59971.43,78904.42' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: 'Atrax, Caudatergus, Molisk, Snablesnot Female', Notes: '', Id: 645, Pos: '81899.78,84585.6 82838.52,84595.2 83116.31,84480 83231.26,84316.8 83250.42,84057.6 83164.21,83865.6 82953.47,83577.6 83030.1,83270.4 83192.95,83088 83240.84,82896 83374.95,82771.2 83231.26,82560 83384.53,82368 83844.32,82252.8 84074.21,82166.4 84227.48,82080 84304.11,82147.2 84390.32,82137.6 84438.22,82080 84428.64,81955.2 81899.78,81955.2' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 5, Mix: 'Exarosaur, Fugabarba, Hiryuu, Snablesnot Female, Snarksnot', Notes: '', Id: 749, Pos: '16587,46281 19803,46169 19947,45561 19899,44777 18987,44633 18219,44681 18187,44809 17435,44985 16891,44601 16635,44873 16571,44985 16699,45641' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Big', DensitySize: 70, Mix: 'Combibo, Exarosaur, Merp, Snablesnot Female', Notes: '', Id: 940, Pos: '71774.78,76963.2 71975.94,76896 72368.68,77088 72694.36,76732.8 72838.05,76569.6 72857.2,76224 72818.89,75964.8 72723.1,75888 72770.99,75696 72742.26,75494.4 72579.41,75312 72445.31,75177.6 72148.36,75177.6 72071.73,75244.8 72090.88,75369.6 71851.41,75600 71688.57,75676.8 71449.09,75504 71190.46,75532.8 71027.62,75753.6 71027.62,75955.2 71161.72,76118.4 71382.04,76032 71449.09,75801.6 71544.88,75849.6 71535.3,76089.6 71592.78,76281.6 71573.62,76425.6 71391.62,76406.4 71190.46,76569.6 71276.67,76656 71573.62,76579.2 71573.62,76761.6 71602.36,76934.4' },
      { Min: 'Young', Max: 'Dominant', Maturity: 'small', Density: 'Low', DensitySize: 20, Mix: 'Atrax, Cornundacauda, Gibnib, Snalesnot Female', Notes: '', Id: 953, Pos: '72972.98,75973.57 73116.67,76011.97 73222.04,75800.77 73394.46,75666.37 73691.41,75685.57 73633.93,74936.77 73174.14,74965.57 73097.51,74879.17 72723.93,74869.57 72695.19,75013.57 72829.3,75080.77 72982.56,75243.97 73030.46,75560.77 72915.51,75570.37 72848.45,75714.37 72963.4,75839.17' },
    ]
  },
  { // Snarg
    Name: 'Snarg',
    id: '65',
    notes: '',
    spawns: [
      { Min: 'Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 80.1, Mix: 'Berycled', Notes: '', Id: 646, Pos: '72816.49,88484.63 73067.94,88508.63 73187.68,88676.63 73702.55,88712.63 73702.55,86300.63 73079.92,86408.63 72529.12,86540.63 72193.86,86744.63 72086.1,86984.63 72110.04,87080.63 72409.39,87020.63 72648.86,87128.63 72828.47,87560.63 72852.42,87776.63 72624.91,87872.63 72433.33,87872.63 72433.33,87956.63 72684.78,88184.63 72720.7,88352.63' },
      { Min: 'Old', Max: 'Old Alpha', Maturity: 'mid', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 647, Pos: '69637.46,85292.63 69996.68,85232.63 70152.33,85064.63 70463.65,84968.63 70607.34,85004.63 70846.81,85316.63 70954.58,85568.63 71182.08,85412.63 71373.66,85256.63 71493.39,85028.63 71265.89,84884.63 71289.84,84680.63 71421.55,84752.63 71589.18,84620.63 71481.42,84464.63 71373.66,84344.63 71218,84332.63 71050.37,84152.63 71014.44,84020.63 70679.18,84080.63 70487.6,84176.63 70343.91,84068.63 70212.2,84212.63 70152.33,84176.63 70092.47,84236.63 70080.49,84308.63 69745.23,84560.63 69661.41,84932.63' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Very Low', DensitySize: 5, Mix: 'TskTsk, Sumima', Notes: '', Id: 869, Pos: '19626.7,63619.2 20488.81,63696 20460.07,63312 20335.55,63187.2 19530.91,63196.8 19454.28,63264' },
      { Min: 'Mature', Max: 'Dominant', Maturity: 'small', Density: 'Low', DensitySize: 25, Mix: 'TskTsk', Notes: '', Id: 891, Pos: '23128.51,63344.91 23492.51,63661.71 23712.83,63642.51 23827.78,63556.11 24009.78,63728.91 24191.78,63623.31 24191.78,63440.91 23990.62,63354.51 23875.67,63152.91 24019.36,62922.51 24115.15,62884.11 24134.31,62759.31 24009.78,62730.51 23904.41,62749.71 23894.83,62615.31 23789.46,62567.31 23578.72,62596.11 23310.51,62663.31 23195.56,62807.31 23214.72,62932.11 23195.56,63066.51' },
    ]
  },
  { // Snarksnot
    Name: 'Snarksnot',
    id: '66',
    notes: '',
    spawns: [
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 80.4, Mix: '', Notes: '', Id: 648, Pos: '62902.23,78092.63 63021.97,78104.63 63105.78,78164.63 63381.18,78020.63 63596.71,78068.63 63812.23,78128.63 63908.02,77936.63 64195.39,77852.63 64375,77912.63 64518.69,77588.63 64482.76,77552.63 64183.42,77672.63 64027.76,77600.63 64087.63,77360.63 64231.32,77348.63 64075.66,77180.63 63991.84,77072.63 63943.94,76988.63 63812.23,76952.63 63692.5,76808.63 63728.42,76688.63 63620.65,76580.63 63476.97,76592.63 63357.23,76604.63 63309.34,76520.63 63309.34,76436.63 63009.99,76448.63 62938.15,76808.63 62890.26,77252.63 62698.68,77660.63 62674.73,77924.63 62758.54,78044.63' },
      { Min: 'Guardian', Max: 'Stalker', Maturity: 'mid', Density: 'Big', DensitySize: 80.3, Mix: '', Notes: '', Id: 649, Pos: '69912.86,84308.63 70056.54,84224.63 70164.31,84128.63 70212.2,84176.63 70307.99,84008.63 70296.02,83936.63 70092.47,83708.63 69852.99,83936.63' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 80.2, Mix: '', Notes: '', Id: 650, Pos: '66332.71,74420.63 66775.74,74432.63 66895.47,74468.63 67099.03,74408.63 67206.79,74252.63 67494.16,74312.63 67542.06,74360.63 67757.58,74744.63 67841.4,74552.63 67961.14,74468.63 67973.11,74348.63 67566,73760.63 65781.92,73760.63 65710.07,74012.63 65853.76,73952.63 66300.73,74216.63' },
      { Min: 'Prowler', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 80, Mix: 'Exarosaur, Snablesnot Female, Snablesnot Male', Notes: '', Id: 651, Pos: '74563,88881 74428,88934 74241,88962 74197,89090 74155,89942 75191,89947 75241,89823 75293,89034' },
      { Min: 'Provider', Max: 'Dominant', Maturity: 'mid', Density: 'Medium', DensitySize: 50.1, Mix: '', Notes: '', Id: 652, Pos: '75127.43,91632 75295.06,91740 75450.72,91728 75522.56,91608 75510.59,91464 75330.98,91404 75103.48,91512' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: 'Exarosaur, Merp, Snablesnot Female, Snablesnot Male', Notes: '', Id: 653, Pos: '74301.24,91284 74624.53,91380 74624.53,91488 74744.27,91572 75127.43,91428 75139.4,91104 75582.43,90780 75558.48,90444 75402.82,90084 73726.5,90108 73894.13,90264 74133.61,90360 74025.84,90624 74073.74,90936 74085.71,91104 74133.61,91200 74241.37,91200' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: 'Shinkiba', Notes: '', Id: 654, Pos: '67530.08,75032.63 67601.93,75152.63 67757.58,75128.63 68032.98,75164.63 68056.93,74936.63 67937.19,74804.63 67865.35,74600.63 67769.56,74828.63 67566,74936.63' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Low', DensitySize: 25, Mix: 'Exarosaur, Fugabarba, Hiryuu, Snablesnot Female, Snablesnot Male', Notes: '', Id: 744, Pos: '16587,46281 19803,46169 19947,45561 19899,44777 18987,44633 18219,44681 18187,44809 17435,44985 16891,44601 16635,44873 16571,44985 16699,45641' },
      { Min: 'Old', Max: 'Alpha', Maturity: 'mid', Density: 'Low', DensitySize: 15, Mix: 'Formidon', Notes: '', Id: 886, Pos: '19770.39,59078.4 19779.97,59328 19856.6,59472 20057.76,59520 20249.34,59491.2 20527.13,59587.2 20632.5,59299.2 20757.02,59116.8 21053.97,58790.4 21274.29,58560 21312.61,58387.2 21168.92,58224 21092.29,58262.4 20948.6,57984 20977.34,57888 20852.81,57772.8 20661.23,57820.8 20469.65,58003.2 20450.5,58118.4 20201.44,58262.4 20096.07,58348.8 19961.97,58368 19799.12,58636.8' },
    ]
  },
  { // Sumima
    Name: 'Sumima',
    id: '108',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Low', DensitySize: 15, Mix: 'Exarosaur', Notes: '', Id: 863, Pos: '16851.98,63241.6 17168.09,63452.8 17273.46,63568 17541.67,63760 17761.99,63712 17656.62,63366.4 17714.09,63193.6 17532.09,62944 17330.93,62723.2 17215.99,62742.4 17120.2,62713.6 16938.2,62848 16880.72,62963.2 16871.14,63145.6' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Medium', DensitySize: 30, Mix: 'Exarosaur', Notes: '', Id: 866, Pos: '18030.66,63598.4 19563.3,63598.4 19457.93,63387.2 19400.45,63243.2 18193.5,63243.2 18145.6,63454.4 18001.92,63492.8' },
      { Min: 'Mature', Max: 'Guardian', Maturity: 'small', Density: 'Big', DensitySize: 70, Mix: '', Notes: '', Id: 867, Pos: '19533.68,63170.02 20175.48,63150.82 20357.48,63160.42 20482.01,63275.62 20520.32,63707.62 20980.11,63746.02 20903.48,63995.62 20970.53,63986.02 21056.75,63803.62 21133.38,63755.62 20980.11,63496.42 20970.53,63266.02 20874.74,63054.82 20683.16,62930.02 20558.64,62699.62 20491.58,62488.42 20319.16,62344.42 20070.11,62219.62 19782.74,62594.02 19840.21,62786.02' },
      { Min: 'Mature', Max: 'Provider', Maturity: 'small', Density: 'Medium', DensitySize: 30, Mix: 'TskTsk, Snarg', Notes: '', Id: 868, Pos: '19626.7,63619.2 20488.81,63696 20460.07,63312 20335.55,63187.2 19530.91,63196.8 19454.28,63264' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Very Low', DensitySize: 10, Mix: '', Notes: '', Id: 883, Pos: '21289.24,62452.21 21375.45,62039.41 21806.51,62001.01 21519.14,62337.01' },
    ]
  },
  { // Sonic Squid
    Name: 'Sonic Squid',
    id: '136',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Very Low', DensitySize: 10, Mix: 'Calypsocod, Razortooth, Rippersnapper', Notes: 'More South side', Id: 944, Pos: '40730.98,53892.65 40970.46,53883.05 40932.14,49630.25 39571.92,50215.85 39456.97,50379.05 39524.03,50475.05 39495.29,50619.05 39437.82,50686.25 39638.97,50955.05 40070.03,50763.05 40204.14,50830.25 40213.71,50964.65 40156.24,51079.85 40261.61,51483.05 40242.45,51732.65 40309.5,51828.65 40290.35,52039.85 40395.72,52212.65 40386.14,52395.05 40299.93,52519.85 40165.82,52635.05 40175.4,52702.25 40242.45,52875.05 40165.82,53019.05 40242.45,53086.25 40376.56,53086.25 40395.72,53163.05 40405.29,53278.25 40510.66,53508.65 40491.51,53643.05 40548.98,53719.85 40711.82,53911.85 40740.56,54075.05' },
      { Min: 'Old', Max: 'Alpha', Maturity: 'mid', Density: 'Low', DensitySize: 15, Mix: '', Notes: '', Id: 948, Pos: '40300.39,55792.98 40415.34,55975.38 40616.5,56042.58 40855.98,55898.58 40884.71,55639.38 40817.66,55456.98 40597.34,55437.78 40424.92,55572.18' },
    ]
  },
  { // Turp
    Name: 'Turp',
    id: '75',
    notes: '',
    spawns: [
      { Min: 'Old Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Medium', DensitySize: 50.1, Mix: 'Chirpy, Fugabarba, Gibnib', Notes: '', Id: 655, Pos: '77838.28,72748.8 78106.5,72777.6 78336.39,72777.6 78422.6,72672 78422.6,72518.4 78403.45,72403.2 78288.5,72297.6 78068.18,72249.6 78049.02,72307.2 77924.5,72403.2 77838.28,72345.6 77809.55,72547.2 77780.81,72652.8' },
      { Min: 'Provider', Max: 'Stalker', Maturity: 'mid', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 656, Pos: '62129.92,86448 62177.82,86496 62249.66,86472 62237.69,86376 62153.87,86376' },
      { Min: 'Provider', Max: 'Old Alpha', Maturity: 'mid', Density: 'Low', DensitySize: 30, Mix: 'Chirpy, Fugabarba, Gibnib', Notes: '', Id: 657, Pos: '79132.65,68892 79192.52,69036 79408.04,69120 80114.49,69252 80605.42,69744 80617.39,70320 80749.1,70332 80808.97,70200 81000.55,70008 80964.63,69624 80773.05,69252 80737.13,69024 80737.13,68808 80246.21,69084 79982.78,69048 79994.76,68784 80066.6,68652 80006.73,68556 79755.28,68688 79707.39,68640 79527.78,68592 79264.36,68652 79084.75,68772' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Very Low', DensitySize: 15, Mix: 'Chirpy, Fugabarba, Gibnib', Notes: '', Id: 658, Pos: '79428.4,68534.4 79591.24,68534.4 79773.24,68620.8 80031.88,68486.4 80098.93,68572.8 80654.51,68409.6 80587.46,68342.4 80405.46,68371.2 80405.46,68227.2 80529.98,68217.6 80510.83,68064 80347.98,67881.6 80156.4,67939.2 80070.19,67881.6 79888.19,67939.2 79687.03,67910.4 79562.5,67824 79447.56,67977.6 79524.19,68044.8 79495.45,68150.4 79514.61,68265.6 79409.24,68284.8' },
      { Min: 'Young', Max: 'Stalker', Maturity: 'mid', Density: 'High', DensitySize: 75, Mix: '', Notes: '', Id: 718, Pos: '73998.14,67328.57 74122.66,67184.57 74247.19,67251.77 74199.3,67376.57 74113.08,67376.57' },
      { Min: 'Young', Max: 'Stalker', Maturity: 'mid', Density: 'Very High', DensitySize: 90, Mix: '', Notes: '', Id: 719, Pos: '82380,78367 82391,78438 82424,78504 82513,78511 82590,78510 82623,78463 82624,78384 82580,78294 82570,78216 82480,78208 82426,78248' },
      { Min: 'Young', Max: 'Stalker', Maturity: 'mid', Density: 'Very Low', DensitySize: 15, Mix: 'Berycled', Notes: '', Id: 897, Pos: '64628.84,87283.2 64504.32,87120 64427.68,86851.2 64466,86630.4 64552.21,86400 64705.48,86371.2 64782.11,86553.6 64849.16,86774.4 64992.85,86976 65021.58,87177.6 64906.63,87244.8 64782.11,87216 64734.21,87292.8' },
    ]
  },
  { // Tantillion
    Name: 'Tantillion',
    id: '68',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 659, Pos: '40181.32,58753.02 40334.58,58829.82 40353.74,58983.42 40487.85,59041.02 40698.59,58964.22 40736.9,58791.42 40564.48,58541.82 40066.37,58503.42' },
      { Min: 'Provider', Max: 'Old Alpha', Maturity: 'mid', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 660, Pos: '53972.21,63277.8 54259.58,63258.6 54336.21,63181.8 54454.16,63066.6 54642.74,63239.4 55006.74,63220.2 55198.32,63105 55217.48,62951.4 55140.84,62548.2 55025.9,62567.4 54853.47,62817 54738.53,62759.4 54776.84,62452.2 54604.42,62394.6 54527.79,62529 54393.68,62529 54432,62836.2 54355.37,62989.8' },
      { Min: 'Dominant', Max: '', Maturity: 'mid', Density: 'Big', DensitySize: 60, Mix: 'Tantardion', Notes: '', Id: 881, Pos: '39644.9,60001.02 40047.21,60250.62 40200.48,60231.42 40219.64,60077.82 40181.32,59847.42 40430.37,59405.82 40449.53,59117.82 40123.85,59117.82 39644.9,59405.82 39549.11,59751.42' },
      { Min: 'Provider', Max: 'Alpha', Maturity: 'mid', Density: 'Medium', DensitySize: 50.2, Mix: 'Tantardion', Notes: '', Id: 661, Pos: '67350.48,80336.63 67625.87,80396.63 67721.66,80312.63 67949.16,80336.63 67997.06,80108.63 67841.4,80096.63 67853.37,80000.63 68021.01,79940.63 67973.11,79880.63 67685.74,79892.63 67500.29,79700.63 67422.32,79544.63 67302.58,79724.63 67350.48,80216.63' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Medium', DensitySize: 50.1, Mix: '', Notes: '', Id: 662, Pos: '68000.03,80348.63 68332.32,80372.63 68583.77,80300.63 68643.64,80156.63 68080.88,79964.63 67961.14,80012.63 67961.14,80072.63 68044.95,80132.63' },
      { Min: 'Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Medium', DensitySize: 50, Mix: 'Tantardion', Notes: '', Id: 663, Pos: '74040.21,71808 74155.16,71942.4 74365.9,71827.2 74461.69,71452.8 74614.95,71078.4 74519.16,70915.2 74327.58,71088 74222.21,71232 74088.11,71356.8 73925.26,71558.4 73934.84,71664 74107.26,71635.2 74164.74,71692.8 74059.37,71808' },
      { Min: 'Young', Max: 'Mature', Maturity: 'small', Density: 'Very Low', DensitySize: 15, Mix: 'Shinkiba', Notes: '', Id: 664, Pos: '74875.98,75756 75163.35,75756 76109.27,75396 76552.3,75384 76684.02,75252 76396.65,75072 76240.99,74892 76240.99,73836 74792.16,73836 74770.27,75324 74770.27,75456 74840.06,75696' },
      { Min: 'Dominant', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 90, Mix: 'Cadatergus, Formidon, Neconu', Notes: 'East/South-East side', Id: 851, Pos: '38742,43562 39910,43306 40054,42474 40166,42394 40118,42138 39974,42026 39926,41626 39686,40986 38182,41050 37798,41498 37862,42554 38342,43370' },
    ]
  },
  { // Tantardion
    Name: 'Tantardion',
    id: '67',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Big', DensitySize: 90, Mix: 'Tantillion', Notes: '', Id: 667, Pos: '39644.9,60001.02 40047.21,60250.62 40200.48,60231.42 40219.64,60077.82 40181.32,59847.42 40430.37,59405.82 40449.53,59117.82 40123.85,59117.82 39644.9,59405.82 39549.11,59751.42' },
      { Min: 'Guardian', Max: 'Old Alpha', Maturity: 'mid', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 666, Pos: '38088.31,59581.32 38260.73,59562.12 38528.94,59734.92 38682.21,59658.12 38758.84,59542.92 38413.99,59466.12 38260.73,59427.72 38107.47,59254.92 38011.68,59350.92' },
      { Min: 'Prowler', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 90, Mix: '', Notes: '', Id: 665, Pos: '37556.67,59329.02 37805.73,59290.62 37997.31,59309.82 38093.1,59223.02 38054.78,59117.82 37882.36,59002.62 37882.36,58868.22 38016.47,58657.02 37614.15,58733.82 37652.46,59021.82 37575.83,59137.02 37403.41,59175.42 37384.25,59252.22 37480.04,59290.62' },
      { Min: 'Old Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 668, Pos: '73756.03,73225.6 73832.67,73331.2 73938.04,73417.6 74100.88,73321.6 74311.62,73302.4 74589.41,73244.8 74637.3,73052.8 74761.83,72956.8 74656.46,72832 74445.72,72889.6 74388.25,72784 74196.67,72793.6 73985.93,72764.8 73890.14,72716.8 73736.88,72870.4 73736.88,72956.8 73813.51,73081.6' },
      { Min: 'Guardian', Max: 'Alpha', Maturity: 'mid', Density: 'Medium', DensitySize: 50, Mix: 'Tantillion', Notes: '', Id: 669, Pos: '67350.48,80336.63 67625.87,80396.63 67721.66,80312.63 67949.16,80336.63 67997.06,80108.63 67841.4,80096.63 67853.37,80000.63 68021.01,79940.63 67973.11,79880.63 67685.74,79892.63 67500.29,79700.63 67422.32,79544.63 67302.58,79724.63 67350.48,80216.63' },
      { Min: 'Dominant', Max: 'Stalker', Maturity: 'big', Density: 'Low', DensitySize: 30, Mix: 'Tantillion', Notes: '', Id: 670, Pos: '74040.21,71808 74155.16,71942.4 74365.9,71827.2 74461.69,71452.8 74614.95,71078.4 74519.16,70915.2 74327.58,71088 74222.21,71232 74088.11,71356.8 73925.26,71558.4 73934.84,71664 74107.26,71635.2 74164.74,71692.8 74059.37,71808' },
    ]
  },
  { // Tezlapod
    Name: 'Tezlapod',
    id: '69',
    notes: '',
    spawns: [
      { Min: 'Old Alpha', Max: 'Ultra Fierce', Maturity: 'big', Density: 'Medium', DensitySize: 50.2, Mix: '', Notes: '', Id: 671, Pos: '67733.64,85472.63 67997.06,85544.63 68272.46,85388.63 68128.77,85268.63 67817.45,85304.63 67733.64,85340.63' },
      { Min: 'Provider', Max: 'Alpha', Maturity: 'mid', Density: 'Medium', DensitySize: 50.1, Mix: '', Notes: '', Id: 672, Pos: '67817.45,85268.63 68152.72,85244.63 68476.01,85100.63 68392.19,84992.63 68116.8,85088.63 67805.48,85184.63' },
      { Min: 'Prowler', Max: 'Stalker', Maturity: 'big', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 673, Pos: '69254.3,84896.63 69469.83,84896.63 69493.78,84668.63 69242.33,84656.63 69170.49,84776.63' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Medium', DensitySize: 60, Mix: '', Notes: '', Id: 884, Pos: '21052,61806.78 22172.74,61768.38 22287.69,61893.18 22603.8,61605.18 22508.01,61173.18 22508.01,60673.98 22316.43,60510.78 22086.53,60529.98 21875.79,60357.18 20965.79,60405.18 21109.47,61173.18 20793.37,61249.98 20908.31,61393.98 21013.68,61480.38' },
    ]
  },
  { // Thorio Female
    Name: 'Thorio Female',
    id: '81',
    notes: '',
    spawns: [
      { Min: 'Nomad', Max: 'Defender', Maturity: 'small', Density: 'Very Low', DensitySize: 15, Mix: 'Chirpy, Daikiba, Hiryuu, Thorio Male', Notes: '', Id: 674, Pos: '55088.69,64746.67 55461.21,64586.67 56163.67,64586.67 56397.82,64405.33 56461.68,64277.33 56387.18,63968 56238.17,63904 56238.17,63797.33 56355.25,63605.33 56142.38,63552 55929.51,63584 55950.8,63253.33 55940.16,63093.33 55801.8,63146.67 55684.72,63413.33 55578.29,63808 55663.43,63936 55663.43,64181.33 55780.51,64266.67 55695.36,64384 55610.22,64384 55546.36,64277.33 55290.92,64234.67 55280.27,64021.33 55322.85,63850.67 55493.14,63808 55525.07,63562.67 55407.99,63509.33 55205.77,63573.33 55109.98,63680 54929.04,64181.33 54939.69,64533.33' },
      { Min: 'Weakling', Max: 'Farmer', Maturity: 'small', Density: 'Very Low', DensitySize: 10, Mix: 'Drone, Thorio Male', Notes: 'East side', Id: 779, Pos: '32120,50893 32664,50781 32728,49773 32632,49261 32200,49165 30376,49197 30552,49581 31784,50717' },
      { Min: 'Housecarl', Max: 'Overseer', Maturity: 'big', Density: 'Big', DensitySize: 90, Mix: 'Thorifoid Berserker, Thorifoid Shaman, Thorio Male', Notes: '', Id: 846, Pos: '35142,43674 35302,43738 35574,43674 35814,42922 36966,43146 37414,41914 37206,41146 35190,41114 35030,42170' },
      { Min: 'Weakling', Max: 'Laborer', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: 'Thorifoid Shaman', Notes: '', Id: 923, Pos: '25113.45,40274.02 25190.08,40562.02 25410.4,40734.82 26061.77,40859.62 26454.51,40648.42 26291.67,40322.02 26291.67,40139.62 26100.09,39957.22 25985.14,39928.42 25831.88,39717.22 25573.24,39659.62 25458.3,39822.82 25103.87,39736.42 24969.77,39755.62 24873.98,39966.82 24941.03,40168.42 25036.82,40283.62 25123.03,40254.82' },
    ]
  },
  { // Thorio Male
    Name: 'Thorio Male',
    id: '79',
    notes: '',
    spawns: [
      { Min: 'Nomad', Max: 'Defender', Maturity: 'small', Density: 'Very Low', DensitySize: 10, Mix: 'Chirpy, Daikiba, Hiryuu, Thorio Female', Notes: '', Id: 675, Pos: '55088.69,64746.67 55461.21,64586.67 56163.67,64586.67 56397.82,64405.33 56461.68,64277.33 56387.18,63968 56238.17,63904 56238.17,63797.33 56355.25,63605.33 56142.38,63552 55929.51,63584 55950.8,63253.33 55940.16,63093.33 55801.8,63146.67 55684.72,63413.33 55578.29,63808 55663.43,63936 55663.43,64181.33 55780.51,64266.67 55695.36,64384 55610.22,64384 55546.36,64277.33 55290.92,64234.67 55280.27,64021.33 55322.85,63850.67 55493.14,63808 55525.07,63562.67 55407.99,63509.33 55205.77,63573.33 55109.98,63680 54929.04,64181.33 54939.69,64533.33' },
      { Min: 'Weakling', Max: 'Farmer', Maturity: 'small', Density: 'Very Low', DensitySize: 10, Mix: 'Drone, Thorio Female', Notes: 'East side', Id: 780, Pos: '32120,50893 32664,50781 32728,49773 32632,49261 32200,49165 30376,49197 30552,49581 31784,50717' },
      { Min: 'Housecarl', Max: 'Overseer', Maturity: 'big', Density: 'Big', DensitySize: 90, Mix: 'Thorifoid Berserker, Thorifoid Shaman, Thorio Female', Notes: '', Id: 847, Pos: '35142,43674 35302,43738 35574,43674 35814,42922 36966,43146 37414,41914 37206,41146 35190,41114 35030,42170' },
    ]
  },
  { // Thorifoid Berserker
    Name: 'Thorifoid Berserker',
    id: '71',
    notes: '',
    spawns: [
      { Min: 'Master', Max: 'Legendary', Maturity: 'big', Density: 'Big', DensitySize: 90, Mix: 'Thorifoid Shaman, Thorio Female, Thorio Male', Notes: '', Id: 848, Pos: '35142,43674 35302,43738 35574,43674 35814,42922 36966,43146 37414,41914 37206,41146 35190,41114 35030,42170' },
      { Min: 'Inept', Max: 'Master of the Guard', Maturity: 'mid', Density: 'Big', DensitySize: 70, Mix: '', Notes: 'Cyclop\'s Depths', Id: 959, Pos: '76741.49,71020.8 76827.7,71088 76990.54,71040 77009.7,70934.4 76933.07,70867.2' },
      { Min: 'Inept', Max: 'Novice', Maturity: 'small', Density: 'Medium', DensitySize: 30, Mix: '', Notes: '', Id: 830, Pos: '30870,34650 31382,34650 31830,34890 32534,34842 32662,34666 32662,34186 32502,34154 32454,33530 32310,33386 32246,33434 32182,33370 32102,33354 32054,33306 32150,33178 32614,33130 32662,32874 31846,32858 31094,33082 30918,32874 30694,33402 30614,34298' },
      { Min: 'Superior', Max: 'Great', Maturity: 'mid', Density: 'Very Low', DensitySize: 5, Mix: '', Notes: '', Id: 921, Pos: '25055.98,38968.42 25075.14,39170.02 25257.14,39496.42 25439.14,39563.62 25573.24,39467.62 25525.35,39208.42 25554.09,39054.82 25439.14,38872.42 25266.72,38795.62 25075.14,38814.82' },
      { Min: 'Neophyte', Max: 'Novice', Maturity: 'small', Density: 'Low', DensitySize: 15, Mix: 'Thorifoid Shaman', Notes: '', Id: 964, Pos: '24711.13,33429.22 24845.24,33371.62 25439.14,33515.62 25870.19,33544.42 26100.09,33544.42 26090.51,33198.82 26052.19,33064.42 25831.88,32997.22 25142.19,32949.22 24691.98,32939.62' },
    ]
  },
  { // Thorifoid Shaman
    Name: 'Thorifoid Shaman',
    id: '70',
    notes: '',
    spawns: [
      { Min: 'Master', Max: 'Legendary', Maturity: 'big', Density: 'Big', DensitySize: 90, Mix: 'Thorifoid Berserker, Thorio Female, Thorio Male', Notes: '', Id: 849, Pos: '35142,43674 35302,43738 35574,43674 35814,42922 36966,43146 37414,41914 37206,41146 35190,41114 35030,42170' },
      { Min: 'Inept', Max: '', Maturity: 'small', Density: 'Low', DensitySize: 25, Mix: 'Bristlehog, Trilomite', Notes: '', Id: 721, Pos: '36936,47024 37432,47152 37640,46720 38200,46720 38270,46208 38200,45616 37480,45312 37208,45392 37096,45648 36792,45712 36792,46592' },
      { Min: 'Inept', Max: 'Neophyte', Maturity: 'small', Density: 'Low', DensitySize: 20, Mix: '', Notes: '', Id: 831, Pos: '28118,34442 28742,34522 29798,34170 29990,33610 29782,33290 29846,32922 29286,32890 28598,33386 28406,33210 28246,33338 27942,32842 26534,32906 26710,33386' },
      { Min: 'Superior', Max: 'Pyromancer Chalte', Maturity: 'mid', Density: 'Medium', DensitySize: 50, Mix: '', Notes: 'Cyclop\'s Depths', Id: 960, Pos: '76741.49,71020.8 76827.7,71088 76990.54,71040 77009.7,70934.4 76933.07,70867.2' },
      { Min: 'Inept', Max: 'Apprentice', Maturity: 'small', Density: 'Low', DensitySize: 25, Mix: 'Thorio Female', Notes: '', Id: 922, Pos: '25113.45,40274.02 25190.08,40562.02 25410.4,40734.82 26061.77,40859.62 26454.51,40648.42 26291.67,40322.02 26291.67,40139.62 26100.09,39957.22 25985.14,39928.42 25831.88,39717.22 25573.24,39659.62 25458.3,39822.82 25103.87,39736.42 24969.77,39755.62 24873.98,39966.82 24941.03,40168.42 25036.82,40283.62 25123.03,40254.82' },
      { Min: 'Neophyte', Max: 'Apprentice', Maturity: 'small', Density: 'Low', DensitySize: 15, Mix: 'Thorifoid Berserker', Notes: '', Id: 965, Pos: '24711.13,33429.22 24845.24,33371.62 25439.14,33515.62 25870.19,33544.42 26100.09,33544.42 26090.51,33198.82 26052.19,33064.42 25831.88,32997.22 25142.19,32949.22 24691.98,32939.62' },
    ]
  },
  { // Thorifoid Waywatcher
    Name: 'Thorifoid Waywatcher',
    id: '736',
    notes: '',
    spawns: [
      // { Min: 'Nomad', Max: 'Laborer', Maturity: 'small', Density: 'Very Low', DensitySize: 10, Mix: 'Atrax, Daikiba, Thorio Female', Notes: '', Id: 675, Pos: '55088.69,64746.67 55461.21,64586.67 56163.67,64586.67 56397.82,64405.33 56461.68,64277.33 56387.18,63968 56238.17,63904 56238.17,63797.33 56355.25,63605.33 56142.38,63552 55929.51,63584 55950.8,63253.33 55940.16,63093.33 55801.8,63146.67 55684.72,63413.33 55578.29,63808 55663.43,63936 55663.43,64181.33 55780.51,64266.67 55695.36,64384 55610.22,64384 55546.36,64277.33 55290.92,64234.67 55280.27,64021.33 55322.85,63850.67 55493.14,63808 55525.07,63562.67 55407.99,63509.33 55205.77,63573.33 55109.98,63680 54929.04,64181.33 54939.69,64533.33' },
      { Min: 'Novice', Max: 'Master', Maturity: 'mid', Density: 'Medium', DensitySize: 40, Mix: '', Notes: 'Cyclop\'s Depths', Id: 961, Pos: '76741.49,71020.8 76827.7,71088 76990.54,71040 77009.7,70934.4 76933.07,70867.2' },
    ]
  },
  { // Trilomite
    Name: 'Trilomite',
    id: '72',
    notes: '',
    spawns: [
      { Min: 'Mature', Max: '', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: 'Bristlehog, Exarosaur, Hiryuu', Notes: '', Id: 676, Pos: '32875.65,49119.46 34306.87,49119.46 34374.48,48927.46 34182.9,48814.52 34002.59,48656.4 33811.01,48656.4 33506.74,48227.23 33258.81,47831.93 32988.35,47447.93 33055.96,47323.7 33010.89,47165.58 32875.65,47041.34' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Very Low', DensitySize: 10, Mix: 'Bristlehog, Thorifoid Shaman', Notes: '', Id: 723, Pos: '36936,47024 37432,47152 37640,46720 38200,46720 38270,46208 38200,45616 37480,45312 37208,45392 37096,45648 36792,45712 36792,46592' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 55, Mix: 'Berycled, Shinkiba', Notes: '', Id: 803, Pos: '29408,30608 31232,30800 31856,30224 31184,29024 30880,29344 30800,29728 29472,29328 29056,30192' },
      { Min: 'Provider', Max: 'Dominant', Maturity: 'mid', Density: 'Very Low', DensitySize: 5, Mix: 'Atrax, Longu', Notes: '', Id: 808, Pos: '33926,29770 35206,29882 36374,29402 36134,27642 34838,27626 33958,28234 33606,29050' },
      { Min: 'Dominant', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 5, Mix: 'Neconu', Notes: '', Id: 811, Pos: '33606,27594 35814,27514 36246,27290 36198,26522 35606,26154 34086,26538 33478,26810 33350,27290' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Very Low', DensitySize: 15, Mix: '', Notes: '', Id: 843, Pos: '33014,38970 33286,39050 33654,38954 33862,38554 34678,38282 34678,37962 34534,37818 34662,37642 34630,37322 34822,37274 34774,36890 34070,36826 33478,37018 33638,37626 33686,38042 33158,38538' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Very Low', DensitySize: 3, Mix: 'Bristlehog, Exarosaur, Hiryuu', Notes: '', Id: 968, Pos: '35725.9,49104.67 39193.49,49123.87 39193.49,49027.87 38235.59,48663.07 38149.38,47789.47 37814.12,47683.87 37785.38,47770.27 37794.96,47760.67 38024.86,47991.07 37852.43,48451.87 37086.11,48864.67 36367.69,48691.87 35955.79,48663.07 35725.9,48807.07 35697.16,48960.67' },
    ]
  },
  { // Tripudion
    Name: 'Tripudion',
    id: '73',
    notes: '',
    spawns: [
      { Min: 'Alpha', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 80, Mix: '', Notes: '', Id: 677, Pos: '72289.65,80912.63 72696.76,81056.63 72756.63,80708.63 72756.63,80540.63 72876.36,80264.63 72816.49,79964.63 72708.73,79856.63 72744.65,79616.63 72612.94,79604.63 72588.99,79808.63 72720.7,80120.63 72744.65,80300.63 72648.86,80480.63 72553.07,80576.63 72265.7,80792.63' },
      { Min: 'Young', Max: 'Provider', Maturity: 'small', Density: 'Medium', DensitySize: 50.2, Mix: '', Notes: '', Id: 678, Pos: '64530.66,75728.63 64626.45,75824.63 64746.19,75800.63 64782.11,75656.63 64949.74,75536.63 64949.74,75464.63 64806.06,75476.63 64758.16,75404.63 64626.45,75332.63 64506.71,75356.63 64410.92,75500.63 64530.66,75680.63' },
      { Min: 'Young', Max: 'Old Alpha', Maturity: 'mid', Density: 'Medium', DensitySize: 50.1, Mix: 'Allophyl', Notes: '', Id: 679, Pos: '74919.88,69020.8 75121.04,69001.6 75274.31,68876.8 75370.1,68742.4 75389.25,68636.8 75609.57,68627.2 75705.36,68550.4 75868.2,68348.8 75935.26,68060.8 75954.41,67868.8 75916.1,67686.4 75753.26,67744 75628.73,67840 75571.25,68032 75389.25,68118.4 75370.1,68214.4 75427.57,68291.2 75456.31,68396.8 75408.41,68492.8 75140.2,68704 74881.57,68915.2 74881.57,68992' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: 'Allophyl', Notes: '', Id: 680, Pos: '75935.26,68838.4 76299.26,68838.4 76328,68963.2 76577.05,69097.6 76950.63,69174.4 77113.47,68944 77084.74,68704 77075.16,68444.8 77027.26,68320 76787.79,68435.2 76730.31,68368 76653.68,68396.8 76442.94,68281.6 76356.73,68214.4 76117.26,68262.4 76040.63,68550.4 75925.68,68694.4' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Low', DensitySize: 30.2, Mix: 'Atrax, Merp', Notes: '', Id: 681, Pos: '72502.78,68304 72630.9,68376 73109.85,68652 73277.48,68568 73349.33,68724 73504.98,68844 73696.56,68892 73696.64,67812 73481.04,67908 73325.38,67704 73145.77,67812 72990.11,67716 72774.59,67704 72606.95,67656 72295.64,67752 72319.58,67908 72523.14,68004 72642.87,68172' },
      { Min: 'Puny', Max: '', Maturity: 'small', Density: 'Low', DensitySize: 30.1, Mix: '', Notes: '', Id: 682, Pos: '63590.72,87636 63985.85,87660 63890.06,87492 63674.54,87456 63506.9,87492' },
      { Min: 'Young', Max: 'Guardian', Maturity: 'small', Density: 'Low', DensitySize: 30, Mix: 'Allophyl, Diripi, Gokibusagi', Notes: '', Id: 683, Pos: '52640.73,64554.67 52853.59,64458.67 53087.75,64277.33 53162.25,64512 53364.47,64597.33 53417.69,64341.33 53438.97,64149.33 53215.47,64010.67 53268.68,63914.67 53492.19,64021.33 53715.7,64021.33 54056.29,64192 53928.57,64437.33 53960.5,64618.67 54130.79,64586.67 54375.59,64074.67 54652.32,63957.33 54535.24,63658.67 54811.97,63541.33 54780.04,63360 54258.51,63434.67 54173.37,63712 53726.35,63530.67 53854.07,63424 53875.35,63178.67 54226.58,63018.67 54247.87,62858.67 53992.43,62901.33 53683.77,63061.33 53438.97,62997.33 53247.4,63114.67 52757.8,63146.67 52555.58,63637.33 52906.81,63850.67 52811.02,64192 52640.73,64320' },
      { Min: 'Young', Max: 'Old', Maturity: 'small', Density: 'Low', DensitySize: 25, Mix: 'Nexnecis', Notes: 'East side', Id: 782, Pos: '25736,51149 26104,51085 26088,51197 26376,51053 26456,51117 26952,50925 27016,50781 27160,50717 27448,50845 27880,50701 28264,49773 28456,49165 26856,49165 26504,49437 25640,49757 25656,50765 25608,50829 25688,50973' },
      { Min: 'Young', Max: '', Maturity: 'small', Density: 'Very Low', DensitySize: 10, Mix: 'Molisk', Notes: '', Id: 856, Pos: '27350,46634 28278,46538 29126,46682 29542,46474 29686,45914 29398,43786 27974,43978 27366,43722 26886,44170 27190,44762 27078,45450' },
    ]
  },
  { // Traeskeron
    Name: 'Traeskeron',
    id: '295',
    notes: '',
    spawns: [
      { Min: 'Young', Max: 'Matriarch', Maturity: 'mid', Density: 'Big', DensitySize: 80, Mix: '', Notes: 'Event', Id: 684, Pos: '53811.49,59978.67 54045.65,60053.33 54077.58,59989.33 54354.3,60064 54194.65,60298.67 54492.67,60394.67 54769.39,60149.33 54641.67,60021.33 54460.74,59648 54088.22,59733.33 54056.29,59626.67 53907.28,59680 53854.07,59808' },
      { Min: 'Mature', Max: 'Alpha', Maturity: 'mid', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 685, Pos: '50638.71,60993 51021.87,61281 51385.88,61319.4 51596.61,61127.4 51519.98,60801 51462.51,60532.2 51232.61,60455.4 51098.51,60589.8 50926.08,60359.4 50581.24,60417 50523.77,60724.2' },
      { Min: 'Prowler', Max: 'Stalker', Maturity: 'big', Density: 'Big', DensitySize: 95, Mix: 'Equus, Molisk', Notes: '', Id: 840, Pos: '34438,36522 34822,36618 35318,36602 35382,36090 35318,35626 35142,35610 34598,35210 34214,35306 34246,36058' },
    ]
  },
  { // Trooper
    Name: 'Trooper',
    id: '74',
    notes: '',
    spawns: [
      { Min: 'Gen 01', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 80.3, Mix: 'Warrior', Notes: '', Id: 686, Pos: '68607.72,72312 68847.2,72456 69481.8,71964 69314.17,71868 69170.49,71736 68954.96,71700 68787.33,71532 68547.85,71388 68356.27,71556 68200.61,71664 68164.69,71772 68332.32,71772 68296.4,71976 68416.14,72132 68535.88,72300' },
      { Min: 'Gen 01', Max: 'Gen 04', Maturity: 'small', Density: 'Big', DensitySize: 80.2, Mix: 'Droka, Legionnaire', Notes: '', Id: 687, Pos: '76626.54,79756.8 76846.86,79728 77115.07,79920 77268.33,80121.6 77431.18,80179.2 77689.81,80496 77890.97,80419.2 78159.18,80649.6 78389.08,80601.6 78436.97,80428.8 78762.66,80438.4 78868.03,80140.8 78580.66,79872 78647.71,79488 78484.87,79363.2 78647.71,79008 78676.45,78633.6 78283.71,78460.8 77852.65,78950.4 77546.13,79008 77009.7,79017.6 76636.12,79497.6' },
      { Min: 'Gen 01', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 80.1, Mix: '', Notes: '', Id: 688, Pos: '84029.91,84552 84544.78,84564 84568.73,84804 84724.39,84792 84736.36,84360 84664.52,84264 84628.6,84120 84029.91,84120' },
      { Min: 'Gen 05', Max: 'Gen 08', Maturity: 'mid', Density: 'Big', DensitySize: 80, Mix: 'Legionnaire', Notes: '', Id: 689, Pos: '67613.9,71196 67769.56,71424 68044.95,71616 68272.46,71472 68487.98,71232 69014.83,71280 69182.46,71160 69194.43,70824 69050.75,70704 68966.93,70692 69230.36,70344 69326.15,69900 69230.36,69636 69074.7,69732 69050.75,70044 68990.88,70308 68535.88,70644 68404.17,70464 67865.35,70332 67817.45,70116 67853.37,69900 68044.95,69540 68787.33,69120 69002.85,69144 69146.54,69108 69050.75,68880 68895.09,68616 68667.59,68568 68128.77,68640 67518.11,68748 67601.93,69024 67482.19,69204 67146.92,69720 67087.05,69888 67374.42,70044 67506.14,70296 67458.24,70500 67518.11,70752' },
      { Min: 'Gen 05', Max: '', Maturity: 'mid', Density: 'Medium', DensitySize: 50.8, Mix: 'Legionnaire', Notes: '', Id: 690, Pos: '68225.76,72940.8 68311.97,72902.4 68743.02,72873.6 68877.13,72816 68896.29,72652.8 68723.87,72556.8 68493.97,72460.8 68139.55,72508.8 68101.23,72796.8' },
      { Min: 'Gen 01', Max: 'Gen 03', Maturity: 'small', Density: 'Medium', DensitySize: 50.7, Mix: '', Notes: '', Id: 691, Pos: '79809.16,72228 80048.64,72408 80168.38,72408 80216.27,72324 80312.06,72324 80371.93,72408 80491.67,72396 80599.43,72468 80779.04,72240 80623.38,72084 80479.69,71976 80503.64,71856 80288.11,71772 80036.67,71820 79761.27,71796 79725.35,72048 79821.14,72060' },
      { Min: 'Gen 03', Max: 'Gen 05', Maturity: 'small', Density: 'Medium', DensitySize: 50.6, Mix: '', Notes: '', Id: 692, Pos: '79370.92,72230.4 79562.5,72259.2 79667.87,72201.6 79600.82,72172.8 79639.14,72105.6 79696.61,72086.4 79734.93,71808 79581.66,71625.6 79485.87,71644.8 79428.4,71712 79303.87,71750.4 79270.66,71712 79210.61,71827.2 79370.92,72019.2 79323.03,72144' },
      { Min: 'Gen 07', Max: '', Maturity: 'mid', Density: 'Medium', DensitySize: 50.5, Mix: '', Notes: '', Id: 693, Pos: '78882.4,72364.8 79064.4,72451.2 79332.61,72278.4 79284.71,72163.2 79236.82,71990.4 79150.61,71952 78997.34,72057.6 78901.55,72240 78882.4,72355.2' },
      { Min: 'Gen 04 + Gen 07', Max: '', Maturity: 'mid', Density: 'Medium', DensitySize: 50.4, Mix: 'Legionnaire, Warrior', Notes: '', Id: 694, Pos: '78556.71,71779.2 78719.55,71923.2 78911.13,71980.8 78987.76,72048 79035.66,71990.4 79198.5,71721.6 79121.87,71644.8 79035.66,71644.8 79016.5,71577.6 78652.5,71577.6 78527.97,71654.4' },
      { Min: 'Gen 06', Max: '', Maturity: 'mid', Density: 'Medium', DensitySize: 50.3, Mix: 'Legionnaire, Warrior', Notes: '', Id: 695, Pos: '35125.52,55939.2 35230.89,56169.6 35432.05,56361.6 35633.21,56486.4 35796.05,56572.8 35930.16,56630.4 36054.69,56496 36121.74,56188.8 36236.69,55968 36265.42,55814.4 36121.74,55737.6 35901.42,55708.8 35566.16,55699.2 35460.79,55795.2' },
      { Min: 'Gen 01', Max: 'Gen 03', Maturity: 'small', Density: 'Medium', DensitySize: 50.2, Mix: 'Drone', Notes: '', Id: 696, Pos: '74133.61,76140 74911.9,76116 75462.69,76128 75821.9,76008 76049.41,75684 75965.59,75528 75151.38,75804 74804.14,75792 74672.43,75720 74420.98,75720 74025.84,75744 74025.84,76008' },
      { Min: 'Gen 04', Max: 'Gen 10', Maturity: 'big', Density: 'Medium', DensitySize: 50.1, Mix: 'Droka, Legionnaire', Notes: '', Id: 697, Pos: '82378.73,75158.4 83097.16,75148.8 83202.52,75206.4 83250.42,75292.8 83231.26,75657.6 83307.89,75744 83863.48,75753.6 83595.26,75465.6 83327.05,75033.6 82982.21,74697.6 82857.68,74736 82666.1,74908.8 82541.57,74928 82330.84,74918.4 82273.36,75024 82292.52,75139.2' },
      { Min: 'Gen 01', Max: 'Gen 02', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: 'Drone, Legionnaire, Warrior', Notes: '', Id: 698, Pos: '50331.12,59754.67 50607.85,59648 50916.51,59690.67 51246.45,59200 51118.73,58549.33 50767.5,58400 50277.9,58261.33 49990.54,58421.33 50001.18,58709.33 50224.69,59061.33 50245.98,59210.67 50384.34,59381.33' },
      { Min: 'Gen 01', Max: 'Gen 10', Maturity: 'mid', Density: 'Low', DensitySize: 25, Mix: 'Molisk, Shinkiba, Warrior', Notes: '', Id: 778, Pos: '32120,50893 32664,50781 32728,49773 32632,49261 32200,49165 30376,49197 30552,49581 31784,50717' },
    ]
  },
  { // TskTsk
    Name: 'TskTsk',
    id: '106',
    notes: '',
    spawns: [
      { Min: 'Watcher', Max: 'Soldier', Maturity: 'small', Density: 'Very Low', DensitySize: 5, Mix: 'Snarg, Sumima', Notes: '', Id: 870, Pos: '19626.7,63619.2 20488.81,63696 20460.07,63312 20335.55,63187.2 19530.91,63196.8 19454.28,63264' },
      { Min: 'Watcher', Max: 'Hunter', Maturity: 'small', Density: 'Low', DensitySize: 25, Mix: 'Longu', Notes: '', Id: 888, Pos: '22371.77,64151.31 23013.56,63680.91 23128.51,63421.71 23463.78,63690.51 23684.09,63690.51 23751.15,63968.91 23674.51,64007.31 23626.62,64103.31 23377.56,64256.91 23147.67,64266.51 22936.93,64362.51 22841.14,64352.91 22687.88,64458.51 22611.24,64228.11 22448.4,64199.31' },
      { Min: 'Watcher', Max: 'Hunter', Maturity: 'small', Density: 'Medium', DensitySize: 60, Mix: 'Snarg', Notes: '', Id: 890, Pos: '23128.51,63344.91 23492.51,63661.71 23712.83,63642.51 23827.78,63556.11 24009.78,63728.91 24191.78,63623.31 24191.78,63440.91 23990.62,63354.51 23875.67,63152.91 24019.36,62922.51 24115.15,62884.11 24134.31,62759.31 24009.78,62730.51 23904.41,62749.71 23894.83,62615.31 23789.46,62567.31 23578.72,62596.11 23310.51,62663.31 23195.56,62807.31 23214.72,62932.11 23195.56,63066.51' },
      { Min: 'Hunter', Max: 'Queen', Maturity: 'mid', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 892, Pos: '23927.87,63167.81 24014.08,63321.41 24234.39,63427.01 24368.5,63379.01 24493.03,63196.61 24636.71,63177.41 24617.55,62649.41 24196.08,62659.01 24148.18,62908.61 24042.81,62947.01' },
    ]
  },
  { // Warlock
    Name: 'Warlock',
    id: '77',
    notes: '',
    spawns: [
      { Min: 'Gen 07', Max: '', Maturity: 'mid', Density: 'Medium', DensitySize: 50.3, Mix: '', Notes: '', Id: 699, Pos: '69032.79,77516.63 69164.5,77564.63 69332.13,77456.63 69356.08,77312.63 69356.08,77156.63 69188.45,77072.63 68984.89,77156.63 69008.84,77408.63' },
      { Min: 'Gen 08', Max: '', Maturity: 'big', Density: 'Medium', DensitySize: 50.2, Mix: '', Notes: '', Id: 700, Pos: '61285.77,83952 61410.3,83884.8 61544.41,84019.2 61735.99,83942.4 61793.46,83779.2 61678.51,83692.8 61429.46,83673.6 61228.3,83731.2' },
      { Min: 'Gen 09', Max: '', Maturity: 'big', Density: 'Medium', DensitySize: 50.1, Mix: '', Notes: '', Id: 701, Pos: '61189.98,83683.2 61448.62,83625.6 61448.62,83462.4 61486.93,83351.2 61352.83,83351.2 61209.14,83414.4' },
      { Min: 'Gen 07', Max: '', Maturity: 'mid', Density: 'Medium', DensitySize: 50, Mix: '', Notes: '', Id: 702, Pos: '61480.55,83641.6 61681.7,83680 61815.81,83747.2 61959.5,83555.2 61930.76,83392 61796.65,83344 61528.44,83392 61480.55,83507.2' },
      { Min: 'Gen 09', Max: 'Gen 10', Maturity: 'big', Density: 'Very Low', DensitySize: 10, Mix: 'Eviscerator, Warrior', Notes: '', Id: 942, Pos: '69763.19,77672.63 70194.24,77684.63 70170.3,77588.63 70050.56,77432.63 70230.16,77336.63 70110.43,77216.63 69870.95,77132.63 69643.45,77176.63 69679.37,77456.63' },
    ]
  },
  { // Warrior
    Name: 'Warrior',
    id: '78',
    notes: '',
    spawns: [
      { Min: 'Gen 05', Max: '', Maturity: 'mid', Density: 'Big', DensitySize: 95, Mix: '', Notes: '', Id: 707, Pos: '36849.74,55219.2 37625.64,55228.8 37625.64,54854.4 36859.32,54854.4' },
      { Min: 'Gen 05', Max: '', Maturity: 'mid', Density: 'Medium', DensitySize: 85, Mix: 'Legionnaire, Trooper', Notes: '', Id: 712, Pos: '35125.52,55939.2 35230.89,56169.6 35432.05,56361.6 35633.21,56486.4 35796.05,56572.8 35930.16,56630.4 36054.69,56496 36121.74,56188.8 36236.69,55968 36265.42,55814.4 36121.74,55737.6 35901.42,55708.8 35566.16,55699.2 35460.79,55795.2' },
      { Min: 'Gen 01', Max: '', Maturity: 'small', Density: 'Big', DensitySize: 80.4, Mix: 'Trooper', Notes: '', Id: 703, Pos: '68607.72,72312 68847.2,72456 69481.8,71964 69314.17,71868 69170.49,71736 68954.96,71700 68787.33,71532 68547.85,71388 68356.27,71556 68200.61,71664 68164.69,71772 68332.32,71772 68296.4,71976 68416.14,72132 68535.88,72300' },
      { Min: 'Gen 08', Max: 'Gen 10', Maturity: 'big', Density: 'Big', DensitySize: 80.3, Mix: '', Notes: '', Id: 704, Pos: '84524.43,85977.6 85032.11,85977.6 85051.27,85286.4 84524.43,85286.4' },
      { Min: 'Gen 06', Max: 'Gen 07', Maturity: 'mid', Density: 'Big', DensitySize: 80.2, Mix: '', Notes: '', Id: 705, Pos: '84017.94,85260 84700.44,85260 84700.44,84912 84520.83,84912 84520.83,84588 84029.91,84588' },
      { Min: 'Gen 01', Max: 'Gen 07', Maturity: 'mid', Density: 'Big', DensitySize: 80.1, Mix: 'Drone', Notes: '', Id: 706, Pos: '83024.12,74688 83359.38,75024 83658.72,75468 83946.09,75720 84245.44,75720 85203.34,75696 85239.26,75240 85478.73,74628 84987.81,74400 84880.05,74400 84760.31,74436 83934.12,74448 83467.14,74496 83227.67,74532' },
      { Min: 'Elite', Max: '', Maturity: 'big', Density: 'Medium', DensitySize: 50.6, Mix: 'Eviscerator, Warlock', Notes: '', Id: 708, Pos: '69763.19,77672.63 70194.24,77684.63 70170.3,77588.63 70050.56,77432.63 70230.16,77336.63 70110.43,77216.63 69870.95,77132.63 69643.45,77176.63 69679.37,77456.63' },
      { Min: 'Gen 08', Max: 'Gen 09', Maturity: 'big', Density: 'Medium', DensitySize: 50.5, Mix: '', Notes: '', Id: 709, Pos: '67859.74,72184.42 68003.42,72261.22 68252.48,72194.02 68386.58,72117.22 68319.53,72002.02 68108.79,71992.42 67993.85,72011.62 67840.58,72136.42' },
      { Min: 'Gen 01', Max: 'Gen 04', Maturity: 'small', Density: 'Medium', DensitySize: 50.4, Mix: 'Drone', Notes: '', Id: 710, Pos: '74510.78,68292 74642.49,68352 74618.54,68544 74750.25,68544 75001.7,68508 75061.57,68376 75037.62,68232 75121.44,68076 75265.13,67944 75265.13,67764 75348.94,67656 75564.47,67644 75552.5,67500 75372.89,67380 75145.39,67368 75109.47,67500 74858.02,67824 74582.62,68088' },
      { Min: 'Gen 04', Max: '', Maturity: 'small', Density: 'Medium', DensitySize: 50.3, Mix: 'Legionnaire, Trooper', Notes: '', Id: 711, Pos: '78556.71,71779.2 78719.55,71923.2 78911.13,71980.8 78987.76,72048 79035.66,71990.4 79198.5,71721.6 79121.87,71644.8 79035.66,71644.8 79016.5,71577.6 78652.5,71577.6 78527.97,71654.4' },
      { Min: 'Gen 01', Max: 'Gen 04', Maturity: 'small', Density: 'Medium', DensitySize: 50.1, Mix: 'Drone', Notes: '', Id: 713, Pos: '76923.49,76348.8 77143.81,76329.6 77239.6,76204.8 77335.39,76272 77536.55,76070.4 77383.28,75936 77383.28,75715.2 77469.49,75571.2 77622.76,75350.4 77402.44,75158.4 77412.02,74985.6 77344.97,74899.2 77134.23,74937.6 77038.44,75168 76904.33,75177.6 76798.96,75120 76722.33,75206.4 76693.59,75331.2 76597.8,75436.8 76636.12,75542.4 76770.23,75600 76875.6,76022.4 76789.38,76032 76856.44,76214.4' },
      { Min: 'Gen 01', Max: 'Gen 02', Maturity: 'small', Density: 'Medium', DensitySize: 50, Mix: 'Drone, Legionnaire, Trooper', Notes: '', Id: 714, Pos: '50331.12,59754.67 50607.85,59648 50916.51,59690.67 51246.45,59200 51118.73,58549.33 50767.5,58400 50277.9,58261.33 49990.54,58421.33 50001.18,58709.33 50224.69,59061.33 50245.98,59210.67 50384.34,59381.33' },
      { Min: 'Gen 04', Max: 'Gen 05', Maturity: 'mid', Density: 'Low', DensitySize: 30.1, Mix: '', Notes: '', Id: 715, Pos: '76109.27,75720 76540.33,75564 76528.36,75432 76097.3,75444 76001.51,75516' },
      { Min: 'Gen 03', Max: 'Gen 07', Maturity: 'mid', Density: 'Low', DensitySize: 30, Mix: '', Notes: '', Id: 716, Pos: '61219.92,79292.63 61519.26,79316.63 61674.92,79292.63 61830.58,79544.63 62046.11,79892.63 62213.74,79952.63 62345.45,80336.63 62525.06,80276.63 62429.27,79952.63 62450.06,79748.63 62357.42,79040.63 61722.81,79100.63 61447.42,79148.63 61195.97,79100.63' },
      { Min: 'Gen 01', Max: 'Gen 10', Maturity: 'mid', Density: 'Medium', DensitySize: 50, Mix: 'Molisk, Shinkiba, Trooper', Notes: '', Id: 777, Pos: '32120,50893 32664,50781 32728,49773 32632,49261 32200,49165 30376,49197 30552,49581 31784,50717' },
      { Min: 'Gen 01', Max: '', Maturity: 'small', Density: 'Small', DensitySize: 15, Mix: 'Droka, Drone', Notes: '', Id: 879, Pos: '35767.32,53548.8 36322.9,53539.2 36322.9,53107.2 35882.26,53088 35671.53,53136 35700.26,53241.6 35757.74,53260.8' },
    ]
  },
];
